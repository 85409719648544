import {
  Box,
  Button,
  Flex,
  Text,
  Icon,
  useStyleConfig,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Switch,
  Spacer,
  useColorModeValue,
  MenuItem,
  Heading,
} from "@chakra-ui/react";
import {
  MdPreview
} from 'react-icons/md'

import React, { useRef, useEffect, forwardRef, useState } from 'react';
import { ViewIcon } from "@chakra-ui/icons";

const Shadow = forwardRef((props, ref) => {
  const shadowRef = useRef(null);

  useEffect(() => {
    if (shadowRef.current) {
      const shadowRoot = shadowRef.current.attachShadow({mode: 'open'});
      shadowRoot.innerHTML=props.html;
    }
  }, []);

  return (
    <Box
      display={props.visible ? 'initial' : 'none'}
      position='relative'
      ref={shadowRef}
    />
  )
})


const TemplateModal = forwardRef((props, ref) => {
  const { team, variant, children, title, iconColor, ...rest } = props;
  const { isOpen, onOpen, onClose } = props.disclosure

  const [recipient, setRecipient] = useState('sender')
  const [sendMethod, setSendMethod] = useState('email')

  const fillText = (values, recip) => {
    if (!values || props.emailOnly) { return }
    var sms = props.editing ? values.sms : values[recip+'_message'].plain_text
    const codeType = props.editing ? values[recip].discount.type : values[recip+'_message'].discount.type
    const code = codeType === 'static' ? (
      props.editing ?
        values[recip].discount.code :
        values[recip+'_message'].discount.code
    ) : 'A8C3-SD32'

    // const code =  'A8C3-SD32'
    const incentive =
      props.editing ?
        values[recip].incentive ?? '(no incentive found)' :
        values[recip+'_message'].incentive  ?? '(no incentive found)'
    const organization = values.organization ?? team?.team_name ?? ''
    const organization_url = values.organization_url ?? team?.team_url ?? ''
    const sender_name = 'John Doe'
    const sender_contact = '+123456789'
    const sender_message = 'Hey John, check this out!'

    const matches = sms.match(/(\[.*?\])/g);
    if (matches) {
      for (const match of matches) {
        switch (match) {
          case "[sender_name]":
            sms = sms.replace(match, sender_name);
            break;
          case "[sender_contact]":
            sms = sms.replace(match, sender_contact);
            break;
          case "[sender_message]":
            sms = sms.replace(match, sender_message);
            break;
          case "[incentive]":
            sms = sms.replace(match, incentive);
            break;
          case "[code]":
            sms = sms.replace(match, code);
            break;
          case '[url]':
            sms = sms.replace(match, organization_url);
            break;
          case '[organization]':
            sms = sms.replace(match, organization);
            break;
          case '[client_name]':
            sms = sms.replace(match, organization);
            break;
          default:
            break;
        }
      }
    }
    return sms
  }

  const fillTemplate = (values, recip) => {
    var html = props.editing ? values.html : values[recip+'_message'].html

    const message = props.editing ? values[recip] : values[recip+'_message']
    const discount = message.discount.shopify || message.discount.static || message.discount
    const code = discount.type === 'static' ? discount.code : 'A8C3-SD32'

    const incentive =
      props.editing ?
        values[recip].incentive ?? '(no incentive found)' :
        values[recip+'_message'].incentive  ?? '(no incentive found)'
    const organization = values.organization ?? team?.team_name ?? ''
    const organization_url = values.organization_url ?? team?.team_url ?? ''
    const sender_name = 'John Doe'
    const sender_contact = '+123456789'
    const sender_message = 'Hey John, check this out!'

    const tags = props.editing ? [
      values[recip].tags.header,
      values[recip].tags.body,
      values[recip].tags.footer
    ] : [
      values[recip+'_message'].replacement_tags.header,
      values[recip+'_message'].replacement_tags.main,
      values[recip+'_message'].replacement_tags.footer
    ]

    var filledTags = []
    for (var tag of tags) {
      const matches = tag.match(/(\[.*?\])/g);
      if (matches) {
        for (const match of matches) {
          switch (match) {
            case "[sender_name]":
              tag = tag.replace(match, sender_name);
              break;
            case "[sender_contact]":
              tag = tag.replace(match, sender_contact);
              break;
            case "[sender_message]":
              tag = tag.replace(match, sender_message);
              break;
            case "[incentive]":
              tag = tag.replace(match, incentive);
              break;
            case "[code]":
              tag = tag.replace(match, code);
              break;
            case '[url]':
              tag = tag.replace(match, `<a href='${organization_url}'>${organization}</a>`);
              break;
            case '[organization]':
              tag = tag.replace(match, organization);
              break;
            case '[client_name]':
              tag = tag.replace(match, organization);
              break;
            default:
              break;
          }
        }
      }
      filledTags.push(tag)
    }

    const matches = html.match(/(\[.*?\])/g);
    if (matches) {
      for (const match of matches) {
        switch (match) {
          case "[header]":
            html = html.replace(match, filledTags[0]);
            break;
          case "[main]":
            html = html.replace(match, filledTags[1]);
            break;
          case "[button]":
            html = html.replace(match, code);
            break;
          case "[footer]":
            html = html.replace(match, filledTags[2]);
            break;
          default:
            break;
        }
      }
    }
    return html
  }

  const senderHtml = fillTemplate(props.values, 'sender')
  const receiverHtml = fillTemplate(props.values, 'receiver')
  const senderText = fillText(props.values, 'sender')
  const receiverText = fillText(props.values, 'receiver')

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        w='60vw'
        maxW='100vw'
        minH='50vh'
        m='auto'
      >
        <ModalHeader fontSize='3xl' pb='0px'>
          Campaign Preview
        </ModalHeader>
        <ModalCloseButton _focus={{ border: 'none' }} />
        <ModalBody overflow='auto'>
          <Shadow visible={ recipient === 'sender' && sendMethod === 'email' } html={senderHtml} />
          <Shadow visible={ recipient === 'receiver' && sendMethod === 'email' } html={receiverHtml} />
          { sendMethod === 'text' && ( recipient === 'sender' ? (
            <Text>
              {senderText}
            </Text>
          ) : (
            <Text>
              {receiverText}
            </Text>
          ))}
        </ModalBody>

        <ModalFooter>
          <Flex w='100%' alignItems='center' ml='12px'>
            <Box>
              <Flex>
                <Text mr='12px'>
                  Sender
                </Text>
                <Switch
                  variant='eitherGreen'
                  size='md'
                  flex='0 1 0'
                  onChange={()=>{
                    recipient === 'sender' ?
                      setRecipient('receiver') :
                      setRecipient('sender')
                  }}
                />
                <Text ml='12px'>
                  Receiver
                </Text>
              </Flex>
              { !props.emailOnly && (
                <Flex>
                  <Text mr='12px'>
                    Email&nbsp;&nbsp;&nbsp;
                  </Text>
                  <Switch
                    variant='eitherGreen'
                    size='md'
                    flex='0 1 0'
                    onChange={()=>{
                      sendMethod === 'email' ?
                        setSendMethod('text') :
                        setSendMethod('email')
                    }}
                  />
                  <Text ml='12px'>
                    Text
                  </Text>
                </Flex>
              ) }
            </Box>
            <Spacer/>
            <Button colorScheme='green' mr={3} onClick={onClose}>
              Close
            </Button>
          </Flex>
          {/*<Button variant='ghost'>Secondary Action</Button>*/}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
})

export default TemplateModal;
