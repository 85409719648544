import React from "react"
import { Icon } from "@chakra-ui/react"
const Disabled = (props) => (
  <Icon
    w={props.size}
    h={props.size}
    {...props} >
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 22 22" xmlSpace="preserve">
      <path fill={props.fill ?? '#FFFFFF'} d="M11,22c6.1,0,11-4.9,11-11S17.1,0,11,0S0,4.9,0,11S4.9,22,11,22z"/>
      <line
        fill={'none'}
        stroke={props.stroke ?? '#C91818'}
        strokeWidth={'1.6022'}
        strokeLineCap={'round'}
        strokeLineJoin={'round'}
        x1="15.2" y1="6.8" x2="6.8" y2="15.2"/>
      <line
        fill={'none'}
        stroke={props.stroke ?? '#C91818'}
        strokeWidth={'1.6022'}
        strokeLineCap={'round'}
        strokeLineJoin={'round'}
        x1="15.2" y1="15.2" x2="6.8" y2="6.8"/>

    </svg>
  </Icon>
)
export default Disabled
