// Chakra imports
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  SimpleGrid,
  Text,
  useColorModeValue,
  Flex,
  Button,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  useDisclosure,
  AccordionPanel,
  Switch,
  Box,
  Icon,
  useToast,
  Spinner,
  Input,
  Tr,
  Td,
  Stack,
  Link,
  CheckboxGroup,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon, DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons'
import { MdEdit, MdClose, MdSave, MdDelete } from "react-icons/md";
// Custom components
import Card from "components/card/Card.js";
// import MiniStatistics from "components/card/MiniStatistics";
// import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useRef } from "react";
// import Information from "views/admin/widgets/components/Information";
// import MessageInformation from "views/admin/widgets/components/MessageInformation";
// import FontInformation from "views/admin/widgets/components/FontInformation";
// import ColorInformation from "views/admin/widgets/components/ColorInformation";
// import SizeInformation from "views/admin/widgets/components/SizeInformation";
import { globalStyles } from "theme/styles";
import CreateModal from "./CreateModal";
import axios from "axios";
import { Auth } from "aws-amplify";
import _ from "lodash";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import DeleteModal from "./DeleteModal";

// Assets
export default function WidgetRow({activeTenant, ...props}) {
  var editedWidget = _.cloneDeep(props.data.config);
  const [editing, setEditing] = useState(false);
  const [accordionIndex, setAccordionIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isNew, setIsNew] = useState(props.isnew);
  const [widget_id, setWidget_id] = useState(props.data.widget_id);
  const createModalDisclosure = useDisclosure();
  const deleteModalDisclosure = useDisclosure();
  // const toast = useToast();
  // const { ...rest } = props;

  // console.log("Rerender");

  const isInitialMount1 = useRef(true);
  useEffect(() => {
    if (isNew && isInitialMount1.current) {
      isInitialMount1.current = false;
      // setEditing(true);
      // setAccordionIndex(0);
    }
  });

  useEffect(() => {
    if (deleting) {
      updateWidget();
    }
  }, [deleting]);

  const saveWidget = (widget) => {
    createModalDisclosure.onClose()
    editedWidget = widget;
    // console.log("Saving:", editedCampaign);
    updateWidget();
  };

  const updateWidget = () => {
    setSaveLoading(true);
    Auth.currentSession()
      .then((session) => {
        const jwtToken = session.getIdToken().getJwtToken();
        // console.log("widget_id", widget_id);
        // console.log("deleting", deleting);
        const request = {
          tenant_id: activeTenant,
          widget_id: widget_id,
          config: !deleting ? editedWidget : {},
          resource_type: "widget",
        };
        // console.log("req", request);
        axios.post("https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-create-config",
          request,
          {
            headers: { Authorization: jwtToken },
          })
          .then((response) => {
            setIsNew(0);
            setReload(true);
          })
          .catch((error) => {
            setError("Error Saving Widget");
            setLoading(false);
            console.error(error);
          })
          .finally(() => {
            setSaveLoading(false);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const isInitialMount2 = useRef(true);
  useEffect(() => {
    if (isInitialMount2.current) {
      isInitialMount2.current = false;
    } else if (reload) {
      // console.log(!deleting, isNew != 1, isNew, props.isNew);
      if (!deleting && !isNew) {
        Auth.currentSession()
          .then((session) => {
            const jwtToken = session.getIdToken().getJwtToken();
            axios.get("https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-get-configs", {
              headers: { Authorization: jwtToken },
              params: {
                tenant_id: activeTenant,
                config_type: "widget",
                resource_id: widget_id,
              },
            })
              .then((response) => {
                props.update(props.index, response.data);
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                setLoading(false);
                // setEditing(!editing);
                // setAccordionIndex(0);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        props.remove(props.index);
        setLoading(false);
        // setEditing(!editing);
        // setAccordionIndex(0);
      }
      props.rerenderwidgets();
      setReload(false);
    }
  }, [reload]);
  // console.log(props.data);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("brand.600", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const inputBg = useColorModeValue("white", "brand.700");
  const textColorValue = useColorModeValue("brand.600", "secondaryGray.100");
  const gradient = `linear-gradient(to bottom, ${useColorModeValue(
    // globalStyles.colors.secondaryGray[200],
    "#FFFFFF",
    globalStyles.colors.brand[900]
  )}, ${useColorModeValue(
    globalStyles.colors.secondaryGray[200],
    globalStyles.colors.secondaryGray[600]
  )})`;

  // console.log(props.data);
  const handleDelete = () => {
    if (!loading) {
      if (isNew == 1) {
        props.remove(props.index)
      } else {
        setDeleting(true);
      }
      // setEditing(!editing); setAccordionIndex(0)
    }
  }

  const Options = ({handleDelete, ...rest}) => {
    return (
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              border='1px solid #AFAFAF'
              ml='12px'
              _hover={{
                bg: "none",
                borderColor: "brand.800",
              }}
              isActive={isOpen}
              as={Button}
              rightIcon={ isOpen ? <ChevronUpIcon /> : <ChevronDownIcon /> }
            >
              Options
            </MenuButton>
            <MenuList>
              <MenuItem onClick={createModalDisclosure.onOpen}>
                Edit Widget
                <EditIcon position='absolute' right='15px'/>
                <CreateModal
                  disclosure={createModalDisclosure}
                  widget={props.data}
                  isNew={false}
                  save={saveWidget}
                  activeTenant={activeTenant}
                />
              </MenuItem>
              {/*
              <MenuItem onClick={()=>{}}>
                Preview Widget
                <ViewIcon position='absolute' right='15px'/>
              </MenuItem>
              */}
              <MenuItem onClick={deleteModalDisclosure.onOpen}
                color='red.500' fontWeight='bold'>
                Delete Widget
                <DeleteIcon position='absolute' right='15px'/>
                <DeleteModal handleDelete={handleDelete} disclosure={deleteModalDisclosure}/>
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    )
  }

  return (
    <Tr _hover={{ background: "#f2faf8" }}>
      <Td>
        <Box>
          <CheckboxGroup
            colorScheme="green"
            defaultValue={["Naruto", "Sasuke"]}
          >
            <Stack spacing={[1, 5]} direction={["column", "row"]}>
              {/*
              <Checkbox size="md" value="naruto"></Checkbox>
              */}
              <Link>
                <Text color="brand" fontWeight="semibold">
                  {props.data.config.widget_name}
                  {/*
                  <chakra.Span
                    fontSize="sm"
                    fontWeight="normal"
                    color="brand.500"
                    ml="6px"
                  >
                    - Ariel Ben-Ami
                  </chakra.Span>
                  */}
                </Text>
                <Text fontSize="xs" color="#adadad">
                  Widget ID: {props.data.widget_id}
                </Text>
              </Link>
            </Stack>
          </CheckboxGroup>
        </Box>
      </Td>
      <Td>"{props.data.config.StartText}"</Td>
      <Td>
        <Flex>
          <Box
            bg={props.data.config.Color}
            w="20px"
            h="20px"
            borderRadius="5px"
            border="1px solid #adadad"
            mr="6px"
          />
          <Text>{props.data.config.Color}</Text>
        </Flex>
      </Td>
      <Td>{props.data.config.CTAFontSize}px</Td>
      <Td>{props.data.config.Corner}</Td>
      <Td isNumeric>
        <Options handleDelete={handleDelete}/>
      </Td>
    </Tr>
  )
}

//
//   return (
//     <Card mb="20px" pt="12px" pb="0px" bg="brand.200" border="1px solid #ccc">
//       <Flex justifyContent="space-between">
//         <Flex>
//           {editing ? (
//             <Input
//               border="none"
//               borderBottom="2px"
//               borderBottomColor={inputBg}
//               px="8px"
//               py="0px"
//               mb="18px"
//               _focus={{
//                 border: "none",
//                 borderBottom: "2px",
//                 borderBottomColor: inputBg,
//               }}
//               _hover={{
//                 border: "none",
//                 borderBottom: "2px",
//                 borderBottomColor: inputBg,
//               }}
//               onChange={(e) => {
//                 editedWidget.widget_name = e.target.value;
//               }}
//               textColor={textColorValue}
//               fontSize={"2xl"}
//               fontWeight="bold"
//               placeholder={props.data.config.widget_name}
//               defaultValue={props.data.config.widget_name}
//             />
//           ) : (
//             <Flex flexDir="column">
//               <Text
//                 color="brand.600"
//                 fontWeight="bold"
//                 fontSize="xl"
//                 whiteSpace="nowrap"
//                 mr="24px"
//               >
//                 {props.data.config.widget_name}
//               </Text>
//               <Text
//                 color="brand.500"
//                 fontSize="xs"
//                 whiteSpace="nowrap"
//                 mr="24px"
//                 mb="4px"
//               >
//                 {widget_id}
//               </Text>
//             </Flex>
//           )}
//           {/*
//           <Card
//             bg={'#2A5C4D'}
//             p='0'
//             pt='4px'
//             px='18px'
//             mb='8px'
//             justifyContent='center'
//             alignItems='center'
//             flexDir='row'
//             rounded='12px'
//           >
//             <Flex flexDir='column'>
//               <Text fontSize='xs' lineHeight='1em' p='0'>Referrals Sent</Text>
//               <Text fontSize='xl' fontWeight='bold' p='0' lineHeight='1.2em'>1000</Text>
//             </Flex>
//           </Card>
//           */}
//         </Flex>
//         <Menu>
//           {({ isOpen }) => (
//             <>
//               <MenuButton
//                 border='1px solid #AFAFAF'
//                 ml='12px'
//                 _hover={{
//                   bg: "none",
//                   borderColor: "brand.800",
//                 }}
//                 isActive={isOpen}
//                 as={Button}
//                 rightIcon={ isOpen ? <ChevronUpIcon /> : <ChevronDownIcon /> }
//               >
//                 Options
//               </MenuButton>
//               <MenuList>
//                 <MenuItem onClick={createModalDisclosure.onOpen}>
//                   Edit Widget
//                   <EditIcon position='absolute' right='15px'/>
//                   <CreateModal
//                     disclosure={createModalDisclosure}
//                     widget={props.data}
//                     isNew={false}
//                     save={saveWidget}
//                     user={props.user}
//                   />
//                 </MenuItem>
//                 {/*
//                 <MenuItem onClick={()=>{}}>
//                   Preview Widget
//                   <ViewIcon position='absolute' right='15px'/>
//                 </MenuItem>
//                 */}
//                 <MenuItem onClick={handleDelete}
//                   color='red.500' fontWeight='bold'>
//                   Delete Widget
//                   <DeleteIcon position='absolute' right='15px'/>
//                 </MenuItem>
//               </MenuList>
//             </>
//           )}
//         </Menu>
//       </Flex>
//       <Accordion allowToggle index={accordionIndex}>
//         <AccordionItem
//           display="flex"
//           flexDir="column"
//           alignItems="center"
//           border="0px"
//           _focus={{}}
//         >
//           {({ isExpanded }) => (
//             <>
//               <SimpleGrid
//                 mb='24px'
//                 w="100%"
//                 gridGap="12px"
//                 gridTemplateAreas={{
//                   base: `'font font' 'color size'`,
//                   xl: `'font color size'`,
//                 }}
//                 templateColumns={{ base: "1fr 1fr", xl: "2fr 1fr 1fr" }}
//               >
//                 <FontInformation
//                   advanced={props.advanced}
//                   gridArea="font"
//                   discounts={props.discounts}
//                   boxShadow={cardShadow}
//                   currentwidget={props.data.config}
//                   editedwidget={editedWidget}
//                   expanded={isExpanded ? 1 : 0}
//                   editing={editing ? 1 : 0}
//                 />
//                 {props.advanced ? (
//                   <>
//                     <ColorInformation
//                       gridArea="color"
//                       discounts={props.discounts}
//                       boxShadow={cardShadow}
//                       currentwidget={props.data.config}
//                       editedwidget={editedWidget}
//                       expanded={isExpanded ? 1 : 0}
//                       editing={editing ? 1 : 0}
//                     />
//                     <SizeInformation
//                       gridArea="size"
//                       discounts={props.discounts}
//                       boxShadow={cardShadow}
//                       currentwidget={props.data.config}
//                       editedwidget={editedWidget}
//                       expanded={isExpanded ? 1 : 0}
//                       editing={editing ? 1 : 0}
//                     />
//                   </>
//                 ) : null}
//                 {/*
//                 <MessageInformation
//                   discounts={props.discounts}
//                   boxShadow={cardShadow}
//                   title='Sender'
//                   currentwidget={props.data.config.sender_message}
//                   editedwidget={editedWidget.sender_message}
//                   expanded={isExpanded ? 1 : 0}
//                   editing={editing ? 1 : 0}
//                 />
//                 <MessageInformation
//                   discounts={props.discounts}
//                   boxShadow={cardShadow}
//                   title='Receiver'
//                   currentwidget={props.data.config.receiver_message}
//                   editedwidget={editedWidget.receiver_message}
//                   expanded={isExpanded ? 1 : 0}
//                   editing={editing ? 1 : 0}
//                 />
//                 <Information
//                   boxShadow={cardShadow}
//                   title='Widget'
//                   value={props.data.config.widget_name}
//                 />
//                 */}
//               </SimpleGrid>
//               {/*
//               <AccordionButton
//                 h="48px"
//                 mx="-24px"
//                 w="calc(100% + 40px)"
//                 roundedBottom="20px"
//                 display="flex"
//                 justifyContent="center"
//                 alignItems="center"
//                 boxShadow="none !important"
//                 visibility={editing ? "hidden" : "visible"}
//                 opacity={editing ? 0 : 1}
//                 transition="opacity .2s linear, visibility .2s linear"
//                 // Can't transition gradients w/o magic
//                 // transition='background-color 2s ease'
//                 // _hover={{ background: gradient }}
//                 _hover={{ background: "none" }}
//                 onClick={() => setAccordionIndex(accordionIndex === 0 ? -1 : 0)}
//               >
//                 <AccordionIcon />
//                 <Box
//                   w="100%"
//                   h="48px"
//                   bottom="0"
//                   roundedBottom="20px"
//                   transition="opacity .6s linear"
//                   position="absolute"
//                   bg={gradient}
//                   opacity={0}
//                   _hover={{ opacity: 0.25 }}
//                 />
//               </AccordionButton>
//               */}
//             </>
//           )}
//         </AccordionItem>
//       </Accordion>
//       <Flex
//         opacity={loading ? 1 : 0}
//         transition="opacity .1s ease-in-out"
//         position="absolute"
//         bg="#00000030"
//         left="0"
//         top="0"
//         rounded="20px"
//         w="100%"
//         h="100%"
//         pointerEvents={loading ? "all" : "none"}
//         justifyContent="center"
//         alignItems="center"
//       />
//     </Card>
//   );
// }
