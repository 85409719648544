import React from "react";

function Icon() {
  return (
    <svg x="0" y="0" version="1.1" viewBox="0 0 72 72" xmlSpace="preserve">
      <path
        d="M16 0h40c8.8 0 16 7.2 16 16v40c0 8.8-7.2 16-16 16H16C7.2 72 0 64.8 0 56V16C0 7.2 7.2 0 16 0z"
        fill="#D7F2E9"
      ></path>
      <path
        d="M57.2 46.6l-10.6-5.8L36 35l-10.6 5.8-10.6 5.8 10.6 5.8L36 58.2l10.6-5.8 10.6-5.8z"
        fill="#9BEAD1"
      ></path>
      <path
        d="M57.2 24.5l-10.6-5.8L35.9 13l-10.6 5.8-10.6 5.8 10.6 5.8 10.6 5.3 10.6-5.3 10.7-5.9z"
        fill="#184E3E"
      ></path>
      <path
        d="M14.6 24.1v23.1l21.3-11.5-21.3-11.6zM57.2 24.1v23.1L35.9 35.6l21.3-11.5z"
        fill="#63E2BD"
      ></path>
    </svg>
  );
}

export default Icon;
