import { mode } from "@chakra-ui/theme-tools";
export const tooltipStyles = {
  components: {
    Tooltip: {
      baseStyle: { },
      variants: {
        modal: (props) => ({
          bg: "gray.900",
          color: "brand.100",
          textAlign: "center",
        })
      },
    },
  },
};
