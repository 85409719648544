import React, { useEffect, useState } from 'react';
// import { Options } from 'react-select';
import { useField } from 'formik';
import { chakraComponents, Select } from 'chakra-react-select';
import { Box, Spacer, Flex, Tooltip, Icon, Text, FormControl, FormLabel } from "@chakra-ui/react";
import { MdHelp } from 'react-icons/md';
import _ from 'lodash';

function FormikSelect({ options, field, placeholder, validate, valuePath, labelPath, label, labelProps, ...props }) {
  const [ fieldName, meta, helpers ] = field
  const { setValue, setError, setTouched } = helpers;
  const { value, initialValue, touched } = meta;
  const defaultSelected = () => {
    if (labelPath && valuePath) {
      return value
    } else if (labelPath) {
      return ((value && {label: value, value: ''}) ||
      (initialValue && {label: initialValue, value: ''}))
    } else if (valuePath) {
      return ((value && {label: '', value: value}) ||
      (initialValue && {label: '', value: initialValue}))
    }
  }
  const [ selected, setSelected ] = useState(defaultSelected());
  // console.log(value)
  // console.log(value && {label: value, value: ''})
  // console.log("Selected:",selected);
  // console.log("Value:",value);

  const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  const customComponents = {
    Option: ({ children, ...props }) => (
      <chakraComponents.Option {...props}>
        <Box>
          <Text fontSize='md' fontWeight='medium'>{capitalize(props.data.label)}</Text>
          <Text fontSize='xs' textColor='#adadad'>{props.data.value}</Text>
        </Box>
      </chakraComponents.Option>
    )
  };
  // console.log(initialValue);

  useEffect(async () => {
    validate(value).then((out)=>{
        setError(out ? out : null);
      }).catch((err)=>{
        console.log(err);
      });
  }, [value])

  return (
    <FormControl isRequired mb='15px'
      isInvalid={meta.error && meta.touched}
    >
        { label && (
          <Flex alignItems='center' {...labelProps}>
            <FormLabel variant='modal' {...labelProps.label} >
              {label}
            </FormLabel>
            <Spacer />
            <Tooltip variant="modal"
              alignContent="end"
              hasArrow
              label={props.tooltipLabel}
            >
              <Box>
                <Icon w="18px" h="18px" mx="5px"
                  as={MdHelp}
                  color={"black"}
                />
              </Box>
            </Tooltip>
          </Flex>
        ) }
        <Select
          {...props}
          size='lg' height="48px" maxWidth="100%"
          // defaultValue={value}
          value={
            (labelPath && valuePath &&
              {label: _.get(selected, labelPath), value: _.get(selected, valuePath)}
            ) || selected }
          bg='brand.100'
          options={options}
          placeholder={placeholder}
          autoBlur={true}
          onBlur={fieldName.onBlur}
          onChange={(option) => {
            if (labelPath && valuePath) {
              var out = props.object;
              _.set(out, labelPath, option.label);
              _.set(out, valuePath, option.value);
              setSelected(out);
              setValue(out);
            } else if (labelPath) {
              setSelected(option);
              setValue(option.label)
            } else if (valuePath) {
              setSelected(option);
              setValue(option.value)
            }
          }}
          components={customComponents}
        />
    </FormControl>
  );
};

export default FormikSelect;
