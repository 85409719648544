import {
  Tooltip,
  Flex,
  useToken,
  Input,
  InputGroup,
  Icon,
  Text,
  useColorModeValue,
  Box,
  Center,
} from "@chakra-ui/react";
import FormikObjectInput from './FormikObjectInput';
import { MdHelp } from "react-icons/md";
import { useField } from 'formik';
import PersonIcon from 'assets/img/avatars/PersonIcon';
import FormikInput from "./FormikInput";

function SenderIncentive({ inputs, ...props }) {
  const incentiveField = inputs.fields[0].field
  const incentiveValidate = inputs.fields[0].validate
  const green = useColorModeValue('#2BB78E', '#2BB78E')
  return (
    <Box {...props}>
      <Center w='460px'>
        <Text variant='modal'>Next, give a description of your
          &nbsp;
          <Text display='inline' fontWeight="bold" decoration="underline" color={green} >
            SENDER
          </Text>
          &nbsp;
          reward
        </Text>
        <PersonIcon color={green} size='16px' ml='10px'/>
        <Tooltip hasArrow variant='modal' label="This is used to fill '[incentive]' in your messages">
          <Box> <Icon w="16px" h="16px" as={MdHelp} color={"black"} ml="5px" /> </Box>
        </Tooltip>
      </Center>
      <FormikInput mt="15px" mb="20px" maxWidth="100%"
        _focus={{ border: "2px solid", borderColor: green }}
        field={useField({ name: incentiveField, validate: incentiveValidate})}
        validate={incentiveValidate}
        placeholder='20% off any item'
      />
      <Text variant='hintModal' textDecoration='none' cursor='default'>
        This will be used to fill in the [incentive] variable
      </Text>
    </Box>
  );
}

export default SenderIncentive;
