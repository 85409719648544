// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  MenuGroup,
  Text,
  useColorModeValue,
  useColorMode,
  useBreakpoint,
  Box,
  Divider,
  Link as ChakraLink,
  chakra,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ChevronDownIcon, SettingsIcon } from '@chakra-ui/icons';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import {
  MdNotificationsNone,
  MdInfoOutline,
} from 'react-icons/md';
import { FaEthereum } from 'react-icons/fa';
import {
  FiSun,
  FiMoon,
} from 'react-icons/fi'
import { ThemeEditor } from './ThemeEditor';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import { GoSignOut } from 'react-icons/go';
import { PiSignOutBold } from 'react-icons/pi';
import OrganizationAvatar from 'assets/img/svgs/OrganizationAvatar';
export default function HeaderLinks(props) {
  const { secondary } = props;
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('brand.600', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
  const { colorMode, toggleColorMode } = useColorMode()
  const navigate = useNavigate();

  const { userState, updateUser, activeTenantState, tenantsState, updateTenants, resetState } = useUserContext();
  const [user, setUser] = userState;
  const [tenants, setTenants] = tenantsState;
  const [activeTenant, setActiveTenant] = activeTenantState;

  const [activeIndex, setActiveIndex] = useState(null);
  useEffect(()=>{
    if (!tenants) { return }
    setActiveIndex(tenants.findIndex(tenant => tenant.tenant_id === activeTenant));
  },[activeTenant,tenants])
  // console.log(activeTenant, tenants, activeIndex, !!(activeIndex && tenants))

  return (
    <Flex>
      { activeIndex !== null && tenants && tenants[activeIndex] && user && (
        <Menu>
          <MenuButton
            boxShadow='lg'
            borderRadius='full'
            padding='10px'
            mr="20px"
            h="50px"
            bg="white"
            border="1px solid #dedede"
            as={Button}
            rightIcon={<ChevronDownIcon />}
            _hover={{
              background: "brand.200",
            }}
            _active={{
              background: "brand.200",
            }}
          >
            <Flex>
              { tenants[activeIndex].team_logo ? (
                <Image
                  border='1px solid #adadad'
                  boxSize='40px'
                  borderRadius="full"
                  ml='-6px'
                  mr="10px"
                  src={tenants[activeIndex].team_logo}
                />
              ) : (
                <Avatar
                  border='1px solid #adadad'
                  boxSize='40px'
                  borderRadius="full"
                  ml='-6px'
                  mr="10px"
                  bg='secondaryGray.200'
                  icon={<OrganizationAvatar/>}
                />
              ) }
              <Box pr='24px' borderRightWidth='1px' borderColor='#ccc' minW='160px'>
                <Text pr='12px' textAlign='start'>
                  {tenants[activeIndex].team_name}
                </Text>
                <Text align="start" fontSize="xs" color="#adadad">
                  {tenants[activeIndex].team_url}
                </Text>
              </Box>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuOptionGroup title="Organizations" value={activeIndex}>
              {tenants !== null &&
                tenants.map((tenant, index) => {
                  return (
                    <MenuItemOption key={index}
                      onClick={()=>{
                        localStorage.setItem('activeTenant', JSON.stringify({
                          'user_id': user.attributes.sub,
                          'tenant_id': tenant.tenant_id
                        }))
                        setActiveTenant(tenant.tenant_id)
                      }}
                      value={index} fontWeight='semibold'>
                      <Text pr='12px' textAlign='start'>
                        {tenant.team_name}
                      </Text>
                      <Text align="start" fontSize="xs" color="#adadad">
                        {tenant.team_url}
                      </Text>
                    </MenuItemOption>
                  );
                })}
              {/*
              <MenuItemOption value="workspace1" fontWeight='semibold'>
                Prosper Technology Solutions
              </MenuItemOption>
              <MenuItemOption value="workspace2">
                Summit Golf Co.
              </MenuItemOption>
              */}
            </MenuOptionGroup>
            <MenuDivider />
            <MenuItem onClick={()=>{navigate('/auth/organizations')}} icon={<SettingsIcon />}>Manage Organizations</MenuItem>
            <MenuItem onClick={()=>{props.signOut(); navigate('/auth/sign-in'); resetState()}} icon={<PiSignOutBold/>}> Sign out </MenuItem>
            {/*
            <MenuDivider />
            <MenuGroup title="My Account">
              <MenuItem>Profile Settings</MenuItem>
              <MenuItem>Billing </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title="Help">
              <MenuItem>Learning Hub</MenuItem>
              <MenuItem>Contact Us</MenuItem>
            </MenuGroup>
            */}
          </MenuList>
        </Menu>
      ) }
      {/*
      <Menu>
        <MenuButton
          boxShadow='lg'
          w='max-content'
          borderRadius='full'
          padding='10px'
          mr="20px"
          h="50px"
          bg="white"
          border="1px solid #adadad"
          as={Button}
          _hover={{
            background: "brand.200",
          }}
          _active={{
            background: "brand.200",
          }}
        >
          <Flex>
            <Box>
            <Image
              border='1px solid #adadad'
              boxSize="2rem"
              borderRadius="full"
              src="https://media.licdn.com/dms/image/D560BAQEPBPcFfkFeDw/company-logo_100_100/0/1690481648776?e=1706745600&v=beta&t=I4_ArNEi086gqz6aDse5bLqBznIfTaYgRIKv8fGDWyQ"
              alt="Client logo"
            />
            </Box>
            <Divider orientation='vertical' />
          <Box>
          </Box>
          </Flex>
        </MenuButton>
        <MenuList>
          <MenuOptionGroup title="Workspaces" defaultValue="workspace1">
            <MenuItemOption value="workspace1" fontWeight='semibold'>
              Prosper Technology Solutions
            </MenuItemOption>
            <MenuItemOption value="workspace2">
              Summit Golf Co.
            </MenuItemOption>
            <ChakraLink as={ReactRouterLink} to='/auth/workspaces'>
            <MenuItem icon={<SettingsIcon />}>Manage Workspaces</MenuItem>
            </ChakraLink>
          </MenuOptionGroup>
          <MenuDivider />
          <MenuGroup title="My Account">
            <MenuItem>Profile Settings</MenuItem>
            <MenuItem>Billing </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup title="Help">
            <MenuItem>Learning Hub</MenuItem>
            <MenuItem>Contact Us</MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
      */}

      <SidebarResponsive routes={props.routes} />
      {/*
      <HamburgerIcon boxSize={6} margin='auto' mr='10px'/>
      */}
    </Flex>
  );
}

// HeaderLinks.propTypes = {
//   variant: PropTypes.string,
//   fixed: PropTypes.bool,
//   secondary: PropTypes.bool,
//   onOpen: PropTypes.func
// };

    // <Flex
    //   w={{ xxs: '100%', md: 'auto' }}
    //   alignItems="center"
    //   bgGradient='linear(to-tl, brand.400 -80%, brand.100 120%)'
    //   flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
    //   p="10px"
    //   borderRadius="30px"
    //   boxShadow={props.shadow}>
    //   {/*
    //   <SearchBar mb={secondary ? { base: '10px', md: 'unset' } : 'unset'} me="10px" borderRadius="30px" />
    //   */}
    //   <Flex>
    //     {/*
    //     <Flex
    //       bg={ethBg}
    //       display={secondary ? 'flex' : 'none'}
    //       borderRadius="30px"
    //       ms="auto"
    //       p="6px"
    //       align="center"
    //       me="6px">
    //       <Flex align="center" justify="center" bg={ethBox} h="29px" w="29px" borderRadius="30px" me="7px">
    //         <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
    //       </Flex>
    //       <Text w="max-content" color={ethColor} fontSize="sm" fontWeight="700" me="6px">
    //         1,924
    //         <Text as="span" display={{ base: 'none', md: 'unset' }}>
    //           {' '}
    //           ETH
    //         </Text>
    //       </Text>
    //     </Flex>
    //     */}
    //
    //
    //     {/*
    //     <Menu>
    //       <MenuButton p="0px">
    //         <Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />
    //       </MenuButton>
    //       <MenuList
    //         boxShadow={shadow}
    //         p="20px"
    //         borderRadius="20px"
    //         bg={menuBg}
    //         border="none"
    //         mt="22px"
    //         me={{ base: '30px', md: 'unset' }}
    //         minW={{ base: 'unset', md: '400px', xl: '450px' }}
    //         maxW={{ base: '360px', md: 'unset' }}>
    //         <Flex jusitfy="space-between" w="100%" mb="20px">
    //           <Text fontSize="md" fontWeight="600" color={textColor}>
    //             Notifications
    //           </Text>
    //           <Text fontSize="sm" fontWeight="500" color={textColorBrand} ms="auto" cursor="pointer">
    //             Mark all read
    //           </Text>
    //         </Flex>
    //         <Flex flexDirection="column">
    //           <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
    //             <ItemContent info="Horizon UI Dashboard PRO" aName="Alicia" />
    //           </MenuItem>
    //           <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
    //             <ItemContent info="Horizon Design System Free" aName="Josh Henry" />
    //           </MenuItem>
    //         </Flex>
    //       </MenuList>
    //     </Menu>
    //     */}
    //
    //   {/*
    //     <Menu>
    //       <MenuButton p="0px" onClick={toggleColorMode}>
    //         <Icon mt="6px" as={ colorMode !== 'light' ? FiSun : FiMoon } color={navbarIcon} w="18px" h="18px" me="10px" />
    //       </MenuButton>
    //     </Menu>
    //   */}
    //
    //   {/*
    //     <Menu>
    //       <MenuButton p="0px" pe="12px" >
    //         {useBreakpoint()}
    //       </MenuButton>
    //     </Menu>
    //   */}
    //
    //     {/*
    //     <Menu>
    //       <MenuButton p="0px">
    //         <Icon mt="6px" as={MdInfoOutline} color={navbarIcon} w="18px" h="18px" me="10px" />
    //       </MenuButton>
    //       <MenuList
    //         boxShadow={shadow}
    //         p="20px"
    //         me={{ base: '30px', md: 'unset' }}
    //         borderRadius="20px"
    //         bg={menuBg}
    //         border="none"
    //         mt="22px"
    //         minW={{ base: 'unset' }}
    //         maxW={{ base: '360px', md: 'unset' }}>
    //         <Image src={navImage} borderRadius="16px" mb="28px" />
    //         <Flex flexDirection="column">
    //           <Link w="100%" href="https://horizon-ui.com/pro">
    //             <Button w="100%" h="44px" mb="10px" variant="brand">
    //               Buy Horizon UI PRO
    //             </Button>
    //           </Link>
    //           <Link w="100%" href="https://horizon-ui.com/documentation/docs/introduction">
    //             <Button
    //               w="100%"
    //               h="44px"
    //               mb="10px"
    //               border="1px solid"
    //               bg="transparent"
    //               borderColor={borderButton}>
    //               See Documentation
    //             </Button>
    //           </Link>
    //           <Link w="100%" href="https://github.com/horizon-ui/horizon-ui-chakra">
    //             <Button w="100%" h="44px" variant="no-hover" color={textColor} bg="transparent">
    //               Try Horizon Free
    //             </Button>
    //           </Link>
    //         </Flex>
    //       </MenuList>
    //     </Menu>
    //     */}
    //
    //     {/*
    //     <ThemeEditor navbarIcon={navbarIcon} />
    //     */}
    //
    //     <Menu>
    //       <MenuButton p="0px">
    //         <Avatar
    //           _hover={{ cursor: 'pointer' }}
    //           color="white"
    //           name=""
    //           bg="brand.400"
    //           size="sm"
    //           w="40px"
    //           h="40px"
    //         />
    //       </MenuButton>
    //       <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
    //         {/*
    //         <Flex w="100%" mb="0px">
    //           <Text
    //             ps="20px"
    //             pt="16px"
    //             pb="10px"
    //             w="100%"
    //             borderBottom="1px solid"
    //             borderColor={borderColor}
    //             fontSize="sm"
    //             fontWeight="700"
    //             color={textColor}>
    //             👋&nbsp; Hey, Adela
    //           </Text>
    //         </Flex>
    //         */}
    //         <Flex flexDirection="column" p="10px">
    //           <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }}
    //             onClick={ () => navigate('/admin/account') }
    //             borderRadius="8px" px="14px">
    //             <Text fontSize="sm">Account Settings</Text>
    //           </MenuItem>
    //           {/*
    //           <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
    //             <Text fontSize="sm">Newsletter Settings</Text>
    //           </MenuItem>
    //           */}
    //           <MenuItem
    //             _hover={{ bg: 'none' }}
    //             _focus={{ bg: 'none' }}
    //             color="red.400"
    //             borderRadius="8px"
    //             px="14px"
    //             onClick={props.signOut}>
    //             <Text fontSize="sm">Log out</Text>
    //           </MenuItem>
    //         </Flex>
    //       </MenuList>
    //     </Menu>
    //   </Flex>
    // </Flex>
