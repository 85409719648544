// Chakra imports
import { Box, Flex, Icon, Spacer, Text, useBreakpoint, useColorModeValue } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// Custom components
import { NavLink } from "react-router-dom";
// Assets
import { FaChevronLeft } from "react-icons/fa";
import { globalStyles } from "theme/styles"
import { useUserContext } from "contexts/UserContext";

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;

  const { userState, resetState } = useUserContext();
  const [user, setUser] = userState

  // Chakra color mode
  return (
    <Flex
      position='relative'
      h='min-content'
      // bg={`url(${illustrationBackground})`}
      bgGradient='linear(to-tl, brand.800 -100%, brand.200 50%)'
    >
      <Flex
        minH='100vh'
        w='100%'
        maxW={{xxs: '80%', md: "80%", lg: "70%"}}
        mx='auto'
        pt='40px'
        justifyContent='start'
        direction='column'
      >
        <Flex align='center' w='fit-content' cursor='pointer' mb='48px'
          onClick={()=>{
            if (user) { user.signOut(); resetState() }
            else { window.location.href = 'https://getprosper.io' }
          }}
        >
          <Icon
            as={FaChevronLeft}
            me='12px'
            h='13px'
            w='8px'
            color='secondaryGray.600'
          />
          <Text ms='0px' fontSize='sm' color='secondaryGray.600'>
            { props.signOut ? 'Sign Out' : 'Back to Prosper' }
          </Text>
        </Flex>
        <Flex w='100%' justifyContent='center' >
          {children}
        </Flex>
        <Spacer/>
        <Footer />
      </Flex>
    </Flex>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
