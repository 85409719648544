import {
  TabIndicator,
  Textarea,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  useToken,
  Input,
  Button,
  Text,
  useColorModeValue,
  Box,
  TabList,
  FormLabel,
  FormControl,
  Popover,
  PopoverBody,
  PopoverTrigger,
  PopoverCloseButton,
  Code,
  PopoverArrow,
  PopoverContent,
  PopoverHeader,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useField } from "formik";
import FormikInput from './FormikInput';
import FormikTextArea from './FormikTextArea';

function Email({ inputs, validateField, setCurrentStep, ...props}) {
  const senderSubjectField = inputs.fields[0].field;
  const senderSubjectValidate = inputs.fields[0].validate;
  const senderHeaderField = inputs.fields[1].field;
  const senderHeaderValidate = inputs.fields[1].validate;
  const senderBodyField = inputs.fields[2].field;
  const senderBodyValidate = inputs.fields[2].validate;
  const senderFooterField = inputs.fields[3].field;
  const senderFooterValidate = inputs.fields[3].validate;
  const receiverSubjectField = inputs.fields[4].field;
  const receiverSubjectValidate = inputs.fields[4].validate;
  const receiverHeaderField = inputs.fields[5].field;
  const receiverHeaderValidate = inputs.fields[5].validate;
  const receiverBodyField = inputs.fields[6].field;
  const receiverBodyValidate = inputs.fields[6].validate;
  const receiverFooterField = inputs.fields[7].field;
  const receiverFooterValidate = inputs.fields[7].validate;
  const label = inputs.label
  const whiteBg = useColorModeValue( "brand.100", "brand.100");
  const navigate = useNavigate();
  return (
    <Box height="min-content" {...props}>
      <Tabs variant="unstyled">
        <TabList border="none" height="40px">
          <Tab _selected={{ fontWeight: "semibold" }} _focus="none">
            Sender Email
          </Tab>
          <Tab _selected={{ fontWeight: "semibold" }} _focus="none">
            Receiver Email
          </Tab>
          <Button onClick={()=>{setCurrentStep('Template')}} bg="brand.300" height="40px" ml="35px">
            Custom Template
          </Button>
        </TabList>
        <TabIndicator height="2px" bg="brand.400" borderRadius="1px" />
        <TabPanels mt="5px">
          <TabPanel>

            <FormikInput
              field={useField({ name: senderSubjectField, validate: senderSubjectValidate })}
              validate={senderSubjectValidate}
              label='Sender Email Subject Line'
            />
            <FormikTextArea
              field={useField({ name: senderHeaderField, validate: senderHeaderValidate })}
              validate={senderHeaderValidate}
              label='Sender Email Header'
              labelProps={{ mt: '20px' }}
              minRows={2}
            />
            <FormikTextArea
              field={useField({ name: senderBodyField, validate: senderBodyValidate })}
              validate={senderBodyValidate}
              label='Sender Email Body'
              labelProps={{ mt: '20px' }}
              minRows={4}
            />
            <FormikTextArea
              field={useField({ name: senderFooterField, validate: senderFooterValidate })}
              validate={senderFooterValidate}
              label='Sender Email Footer'
              labelProps={{ mt: '20px' }}
              minRows={2}
            />

            {/*
            <FormControl isRequired>
              <FormLabel variant='modal' >
                Sender Email Subject Line
              </FormLabel>
              <Input
                _focus={{ border: "1px solid #C9CED2" }}
                bg={whiteBg}
                defaultValue="[organization]: Here’s your 20% OFF for you!"
                size="md"
              />
            </FormControl>
            <FormControl isRequired mt="20px">
              <FormLabel variant='modal' >
                Sender Email Header
              </FormLabel>
              <Textarea
                variant='modal'
                defaultValue="Your referral sent! Claim your discount here."
              />
            </FormControl>
            <FormControl isRequired mt="20px">
              <FormLabel variant='modal'>
                Sender Email Body
              </FormLabel>
              <Textarea
                variant='modal'
                height="95px"
                defaultValue="Thanks [sender_name]! We told your friend about the deal at [organization]. You can claim your reward below."
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel mt="20px" variant='modal' >
                Sender Email Footer
              </FormLabel>
              <Textarea
                variant='modal'
                defaultValue="If you have any questions, please contact us!"
              />
            </FormControl>
          */}
          </TabPanel>
          <TabPanel>
            <FormikInput
              // variant='modal'
              field={useField({ name: receiverSubjectField, validate: receiverSubjectValidate })}
              validate={receiverSubjectValidate}
              label='Receiver Email Subject Line'
            />
            <FormikTextArea
              field={useField({ name: receiverHeaderField, validate: receiverHeaderValidate })}
              validate={receiverHeaderValidate}
              label='Receiver Email Header'
              labelProps={{ mt: '20px' }}
              minRows={2}
              maxRows={3}
            />
            <FormikTextArea
              field={useField({ name: receiverBodyField, validate: receiverBodyValidate })}
              validate={receiverBodyValidate}
              label='Receiver Email Body'
              labelProps={{ mt: '20px' }}
              minRows={4}
            />
            <FormikTextArea
              field={useField({ name: receiverFooterField, validate: receiverFooterValidate })}
              validate={receiverFooterValidate}
              label='Receiver Email Footer'
              labelProps={{ mt: '20px' }}
              minRows={2}
            />
          {/*
            <FormControl isRequired>
              <FormLabel variant='modal' >
                Receiver Email Subject Line
              </FormLabel>
              <Input
                _focus={{ border: "1px solid #C9CED2" }}
                bg={whiteBg}
                defaultValue="[organization]: Here’s your 20% OFF for you!"
                size="md"
              />
            </FormControl>
            <FormControl isRequired mt="20px">
              <FormLabel variant='modal' >
                Receiver Email Header
              </FormLabel>
              <Textarea
                variant='modal'
                defaultValue="Your referral sent! Claim your discount here."
              />
            </FormControl>
            <FormControl isRequired mt="20px">
              <FormLabel variant='modal' >
                Receiver Email Body
              </FormLabel>
              <Textarea
                variant='modal'
                height="95px"
                defaultValue="Thanks [sender_name]! We told your friend about the deal at [organization]. You can claim your reward below."
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel mt="20px" variant='modal' >
                Receiver Email Footer
              </FormLabel>
              <Textarea
                variant='modal'
                defaultValue="If you have any questions, please contact us!"
              />
            </FormControl>
            */}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Popover placement="top">
        <PopoverTrigger>
          <Text
            position="absolute"
            bottom="21px"
            left="46px"
            cursor="pointer"
            textDecoration="underline"
            fontSize="13px"
            color="rgba(45, 55, 72, 0.5)"
          >
            How to use variables <br />
            in your referral template
          </Text>
        </PopoverTrigger>
        <PopoverContent
          style={{ border: "none" }}
          _focus={{ border: "none" }}
          w="max-content"
        >
          <PopoverArrow />
          <PopoverHeader>Using Variables</PopoverHeader>
          <PopoverCloseButton mt="4px" />
          <PopoverBody bg="#00000022">
            <Text maxW="500px" whiteSpace="pre-wrap">
              <Code colorScheme="green">[organization]</Code>: Displays the name
              of the organization/company the referral is being sent for. This
              can be changed in settings.
              <br />
              <br />
              <Code colorScheme="green">[url]</Code>: Displays the website URL
              that the referral will direct people to. This can be changed in
              settings.
              <br />
              <br />
              <Code colorScheme="yellow">[sender_name]</Code>: Displays the name
              of the person sending the referral.
              <br />
              <br />
              <Code colorScheme="yellow">[sender_contact]</Code>: Displays the
              contact info (phone number or email) of the person who sent the
              referral.
              <br />
              <br />
              <Code colorScheme="blue">[incentive]</Code>: Displays the
              name of the reward that users will receive sending and/or
              receiving a referral. This can be changed in your campaign.
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

export default Email;
