import React, { useState, setShow } from "react";
import { NavLink, useLocation } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import { Auth } from 'aws-amplify';



function Forgot() {
  // Chakra color mode
  const textColor = useColorModeValue("brand.600", "brand.600");
  const textColorSecondary = "brand.600";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "brand.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const allowGoogle = false;
  const location = useLocation();

  const [username, setUsername] = useState(location.state ? location.state.username : "" );
  const [codeSent, setCodeSent] = useState(location.state && location.state.username ? true : false);
  const [codeVerified, setCodeVerified] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");


  const handleForgotInit = async () => {
    try {
      Auth.forgotPassword(email)
        .then((data) => {
          setCodeSent(true);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log('error signing in', error);
    }
  }

  const handleForgotSubmit = async () => {
    try {
      Auth.forgotPasswordSubmit(email, code, password)
        .then((data) => {
          Auth.signIn(email, password);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log('error signing in', error);
    }
  }


  // // Collect confirmation code and new password, then
  // Auth.forgotPasswordSubmit(username, code, new_password)
  //   .then((data) => console.log(data))
  //   .catch((err) => console.log(err));

  // // Send confirmation code to user's email
  // Auth.forgotPassword(username)
  //   .then((data) => console.log(data))
  //   .catch((err) => console.log(err));
  //
  // // Collect confirmation code and new password, then
  // Auth.forgotPasswordSubmit(username, code, new_password)
  //   .then((data) => console.log(data))
  //   .catch((err) => console.log(err));

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            { codeSent ? "New Password" : "Forgot Password?" }
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            { codeSent ? "Let us verify it's you and enter your new password." : "Enter your email for us to reset it!" }
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>

          <>
              <FormControl>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                { codeSent ? "Code" : "Email" }
                <Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  borderColor='brand.400'
                  border='2px'
                  value={ codeSent ? code : email }
                  onChange={(event) => { codeSent ? setCode(event.target.value) : setEmail(event.target.value) }}
                  onKeyDown={(event) => {if (!codeSent && event.key === 'Enter') {handleForgotInit()}}}
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='email'
                  placeholder={ codeSent ? "123456" : 'mail@simmmple.com' }
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                />
                 { codeSent ? (
                  <>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Password<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        id='password'
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        onKeyDown={(event) => {if (event.key === 'Enter') {handleForgotSubmit()}}}
                        isRequired={true}
                        fontSize='sm'
                        placeholder='Min. 8 characters'
                        mb='24px'
                        size='lg'
                        type={show ? "text" : "password"}
                        variant='auth'
                      />
                      <InputRightElement display='flex' alignItems='center' mt='4px'>
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: "pointer" }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </>
                 ) : null }
                <Button
                  fontSize='sm'
                  color='brand.700'
                  variant='brand'
                  fontWeight='500'
                  w='100%'
                  h='50'
                  mb='24px'
                  _hover={{ bg: "brand.300" }}
                  onClick={ codeSent ? handleForgotSubmit : handleForgotInit }
                >
                  { codeSent ? "Reset Password" : 'Send Password Reset Email' }
                </Button>
              </FormControl>
            </>

          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Remembered it?
              <NavLink to='/auth/sign-in'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'
                  // onClick={}
                >
                  Return to Sign In
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Forgot;
