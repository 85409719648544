import * as React from "react"
import { Box, useToken } from '@chakra-ui/react'
const PersonIcon = (props) => (
  <Box {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox="0 0 149 149"
      {...props}
    >
      <path
        d="M 74.5 74.5 C 95.08062535524368 74.5 111.75 57.83062535524368 111.75 37.25 C 111.75 16.669374644756317 95.08062535524368 0 74.5 0 C 53.91937464475632 0 37.25 16.669374644756317 37.25 37.25 C 37.25 57.83062535524368 53.91937464475632 74.5 74.5 74.5 Z M 74.5 93.125 C 49.635624289512634 93.125 0 105.60374920070171 0 130.375 L 0 149 L 149 149 L 149 130.375 C 149 105.60374920070171 99.36437571048737 93.125 74.5 93.125 Z"
        fill={props.color}
      />
    </svg>
  </Box>
)
export default PersonIcon
