// Chakra imports
import {
  Box,
  SimpleGrid,
  Switch,
  Text,
  Flex,
  useColorModeValue,
  Button,
  Input,
  FormLabel,
  Spacer,
} from "@chakra-ui/react";
import React, {useState, useEffect} from 'react';
import Card from "components/card/Card.js";
import Tier from "views/pricing/components/Tier";
import { tiers } from "views/pricing/variables/tiers";
import { globalStyles } from "theme/styles"
import { useOutletContext, useLocation } from 'react-router-dom'
import { useUserContext } from "contexts/UserContext";
import FormikInput from "components/formik/FormikInput";
import { Tooltip } from "recharts";
import { InfoIcon } from "@chakra-ui/icons";
const stripe = require("stripe")(
  "sk_test_51MaEYACRe9xuvcuCQBAclsB3vzxlEy8Kfk4RDS5JzBpUqM6GRnWayzTxm8uGsSl8iGeoYCNOWKUl1tmu2IHLBeis00NUNTQPNO"
);

export default function Pricing() {
  // Chakra Color Mode
  const mutedColor = useColorModeValue("#585858", "#999999");
  const [monthly, setMonthly] = useState(true);
  const location = useLocation();
  const isShopify = location.state?.shopify ?? false;
  const { userState, updateUser, updateTenants } = useUserContext();
  const [user, setUser] = userState
  useEffect(()=>{
    updateUser();
    updateTenants();
  },[])
  useEffect(()=>{
    if (user.attributes?.['custom:stripe_customer_id']) {
      const customer_id = user.attributes?.['custom:stripe_customer_id'];
      customerPortal(customer_id);
    }
  },[user])
  const [text, setText] = useState('');

  const [session, setSession] = useState();

  async function customerPortal(customer_id) {
    const response = await stripe.billingPortal.sessions.create({
      customer: customer_id,
      return_url: "https://app.getprosper.io/admin/home",
    });
    setSession(response);
  }

  return (
    <Flex mb='60px' flexDirection='column'  alignItems='center'>
      <Flex
        pt={{ base: "130px", md: "80px", xl: "80px" }}
        mb='60px' flexDirection='column' alignItems='center'
        w='100vw'
      >
        <Text mb='24px' fontSize="6xl" fontWeight="extrabold" textAlign='center'>Plans for Every Business </Text>
        <Flex px='24px' maxW='900px' w='100%' justifyContent='space-evenly' alignItems='start' mt='24px'>
          <Text maxW='560px' mr='24px' fontSize="2xl" fontWeight="normal" textAlign='center'>
            We want to help you scale, whether you're just getting started or already established.
          </Text>
          {/*
          <Flex alignItems='center' justifyContent='center'>
            <Text fontSize="2xl" fontWeight="medium"> Monthly <br/> </Text>
            <Switch
              variant={useColorModeValue('mainLight', 'mainDark')}
              mx='10px' size='lg' flex='0 1 0'
              defaultChecked={!monthly}
              onChange={ () => {setMonthly(!monthly)} }/>
            <Box fontSize="2xl" fontWeight="medium">
              Annually
              <Text fontSize="xl" color={mutedColor} position='absolute'>
                (20% Off)
              </Text>
            </Box>
          </Flex>
          */}
          <Box mt='6px' alignSelf='end'>
            <Flex alignItems='center'>
              <FormLabel variant='modal' whiteSpace='nowrap' >
                Have a discount code?
              </FormLabel>
              <Spacer/>
              <Tooltip variant='modal'
                hasArrow
                label="test"
                fontSize="md"
              >
                <InfoIcon/>
              </Tooltip>
            </Flex>
            <Input
              // bg={'brand.100'}
              spellCheck='false'
              boxShadow='lg'
              variant='modal'
              value={text}
              onChange={(e) => { setText(e.target.value) }}
              placeholder='A3V5-FS80'
              border={'2px solid'}
              borderColor='brand.200'
              _focus={{
                border: '2px solid',
                borderColor: 'brand.300'
              }}
              _hover={{
                border: '2px solid',
                borderColor: 'brand.200'
                // borderColor: props.focusBorderColor ?? 'transparent',
              }}
            />
          </Box>
        </Flex>
      </Flex>
      <SimpleGrid
        columns={{ base: 1, md: 1, xl: 2 }}
        w='auto' maxW='1440px'
        gridColumnGap={{ md: '6%', xl: '48px' }}
        gridRowGap={{ base: '36px', md: '4%', xl: 0 }}
      >
        { tiers.map((tier, index) => <Tier key={index} user={user} discountCode={text} isShopify={isShopify} primary={!!Number((index+1)%2)} data={tier} monthly={monthly}/>) }
      </SimpleGrid>
    </Flex>
  );
}
