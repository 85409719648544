import React, { useEffect, useState} from 'react';
import { Field, FieldValidator } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Box,
} from "@chakra-ui/react";
import { AutoResizeTextarea } from "components/textarea/textarea"

function FormikTextArea({ label, labelProps, field, placeholder, validate, ...props }) {
  const [ fieldName, meta, helpers] = field;
  // console.log(helpers, meta, fieldName)
  const { setValue, setError, setTouched } = helpers;
  const { value, initialValue } = meta;
  const [text, setText] = useState(value ?? initialValue);
  const [isRunning, setIsRunning] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  // console.log( value, initialValue );

  useEffect(async () => {
    validate(text).then((out)=>{
        setError(out ? out : null);
      }).catch((err)=>{
        console.log(err);
      });
  }, [text])

  return (
    <FormControl isRequired mb='15px' {...labelProps}
      isInvalid={meta.error && meta.touched}
    >
      { label && (
        <FormLabel variant='modal' >
          {label}
        </FormLabel>
      ) }
      {/*
        <Input
          variant='modal'
          {...props}
          value={text}
          placeholder={placeholder}
          onBlur={fieldName.onBlur}
          onChange={(e) => {
            setText(e.target.value)
            setValue(e.target.value)
          }}
          border='2px solid'
          _focus={{ borderColor: meta.error ? 'red.500' : props.focusBorderColor ?? 'transparent' }}
          borderColor={ meta.error ? 'red.500' : 'transparent'}
        />
      */}
      <AutoResizeTextarea
        variant='modal'
        {...props}
        value={text}
        placeholder={placeholder}
        onBlur={fieldName.onBlur}
        onChange={(e) => {
          setText(e.target.value)
          setValue(e.target.value)
        }}
        fontSize='md'
        border='2px solid'
        _focus={{ borderColor: meta.error ? 'red.500' : props.focusBorderColor ?? 'transparent' }}
        borderColor={ meta.error ? 'red.500' : 'transparent'}
        // onChange={(e) => { props.editedcampaign.plain_text=e.target.value }}
        // placeholder={props.currentcampaign.plain_text} defaultValue={props.currentcampaign.plain_text}
      />
      <FormErrorMessage position='absolute'>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}

export default FormikTextArea;
