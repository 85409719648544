import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  ModalCloseButton,
  Button,
  Box,
} from '@chakra-ui/react'

const RemoveModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent margin="auto" bg="brand.200">
        <ModalHeader>Remove Domain</ModalHeader>
        <ModalCloseButton _focus="none" />
        <ModalBody>
          <Text>
            Are you sure you'd like to remove your domain{" "}
            <Box fontWeight="bold" display="inline">
              {props.domain}
            </Box>
            ? This action cannot be undone.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            mr={3}
            border="1px solid #ccc"
            onClick={props.onClose}
          >
            Back
          </Button>
          <Button variant="red"
            onClick={()=>{
              props.remove()
              props.onClose()
            }}
          >Remove</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RemoveModal
