import React, { useEffect, useState } from 'react';
// import { Options } from 'react-select';
import { useField } from 'formik';
import { chakraComponents, Select } from 'chakra-react-select';
import { Box, Text, FormControl, FormLabel } from "@chakra-ui/react";
import _ from 'lodash';

function FormikSelect({ options, field, placeholder, validate, valuePath, labelPath, label, labelProps, ...props }) {
  const [ fieldName, meta, helpers ] = field
  const { setValue, setError, setTouched } = helpers;
  const { value, initialValue, touched } = meta;
  const [ selected, setSelected ] = useState(value);

  const customComponents = {
    Option: ({ children, ...props }) => (
      <chakraComponents.Option {...props}>
        <Box>
          <Text fontSize='md' fontWeight='medium'>{props.data.label}</Text>
          <Text fontSize='xs' textColor='#adadad'>{props.data.value}</Text>
        </Box>
      </chakraComponents.Option>
    )
  };
  // console.log(initialValue);

  useEffect(async () => {
    validate(selected).then((out)=>{
        setError(out ? out : null);
      }).catch((err)=>{
        console.log(err);
      });
  }, [selected])

  return (
    <FormControl isRequired mb='15px'
      isInvalid={meta.error && meta.touched}
    >
        { label && (
          <FormLabel variant='modal' {...labelProps} >
            {label}
          </FormLabel>
        ) }
        <Select
          {...props}
          // defaultValue={value}
          value={{label: _.get(value, labelPath), value: _.get(value, valuePath)}}
          bg='brand.100'
          options={options}
          placeholder={placeholder}
          autoBlur={true}
          onBlur={fieldName.onBlur}
          onChange={(option) => {
            var out = props.object;
            _.set(out, labelPath, option.label);
            _.set(out, valuePath, option.value);
            setSelected(out);
            setValue(out);
            // console.log("Selected:",selected);
            // console.log("Value:",value);
          }}
          components={customComponents}
        />
    </FormControl>
  );
};

export default FormikSelect;
