import { mode } from "@chakra-ui/theme-tools";
export const textStyles = {
  components: {
    Text: {
      baseStyle: { },
      variants: {
        modal: (props) => ({
          fontFamily: "Inter",
          lineHeight: "1.41",
          fontWeight: "semibold",
          fontSize: "17px",
          color: "gray.700",
        }),
        hintModal: (props) => ({
          mt: "5px",
          mb: "10px",
          cursor: "pointer",
          fontFamily: "Inter",
          lineHeight: "1.71",
          fontWeight: "regular",
          fontSize: "14px",
          textDecoration: "underline",
          color: "rgba(45, 55, 72, 0.5)",
          maxWidth: "100%",
          textAlign: "center",
        }),
        boldModal: (props) => ({
          fontWeight: "bold",
          mb: "15px",
          mt: "15px",
          fontFamily: "Inter",
          lineHeight: "1.4",
          fontSize: "17px",
          color: "gray.700",
          height: "21px",
          maxWidth: "100%",
          textAlign: "center",
        }),
        // boldModal: (props) => ({
        //   fontWeight: "boldModal",
        //   mb: "15px",
        //   mt: "15px",
        //   fontFamily: "Inter",
        //   lineHeight: "1.4",
        //   fontSize: "17px",
        //   color: "gray.700",
        //   height: "21px",
        //   maxWidth: "100%",
        //   textAlign: "center",
        // })
      },
    },
  },
};
