import { mode } from "@chakra-ui/theme-tools";
export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        borderRadius: "8px",
        boxShadow: "45px 76px 113px 7px rgba(99, 226, 190, 0.08)",
        transition: ".25s all ease",
        boxSizing: "border-box",
        _focus: {
          boxShadow: "none",
        },
        _active: {
          boxShadow: "none",
        },
      },
      variants: {
        outline: () => ({
          borderRadius: "8px",
        }),
        darkBrand: (props) => ({
          bg: mode("brand.900", "brand.400")(props),
          color: "white",
          _focus: {
            bg: mode("brand.900", "brand.400")(props),
          },
          _active: {
            bg: mode("brand.900", "brand.400")(props),
          },
          _hover: {
            bg: mode("brand.800", "brand.400")(props),
          },
        }),
        lightBrand: (props) => ({
          bg: mode("#F2EFFF", "whiteAlpha.100")(props),
          color: mode("brand.500", "white")(props),
          _focus: {
            bg: mode("#F2EFFF", "whiteAlpha.100")(props),
          },
          _active: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _hover: {
            bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
          },
        }),
        light: (props) => ({
          bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          color: mode("brand.600", "white")(props),
          _focus: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _active: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _hover: {
            bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
          },
        }),
        action: (props) => ({
          fontWeight: "500",
          borderRadius: "8px",
          bg: mode("secondaryGray.300", "brand.400")(props),
          color: mode("brand.500", "white")(props),
          _focus: {
            bg: mode("secondaryGray.300", "brand.400")(props),
          },
          _active: { bg: mode("secondaryGray.300", "brand.400")(props) },
          _hover: {
            bg: mode("secondaryGray.200", "brand.400")(props),
          },
        }),
        setup: (props) => ({
          fontWeight: "500",
          borderRadius: "8px",
          bg: mode("transparent", "brand.400")(props),
          border: mode("1px solid", "0px solid")(props),
          borderColor: mode("secondaryGray.400", "transparent")(props),
          color: mode("brand.600", "white")(props),
          _focus: {
            bg: mode("transparent", "brand.400")(props),
          },
          _active: { bg: mode("transparent", "brand.400")(props) },
          _hover: {
            bg: mode("secondaryGray.100", "brand.400")(props),
          },
        }),


        brand: (props) => ({
          bg: mode("brand.300", "brand.300")(props),
          borderRadius: "8px",
          // boxShadow: "45px 76px 113px 7px rgba(99, 226, 190, 0.08)",
          transition: ".25s all ease",
          boxSizing: "border-box",
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            bg: mode("brand.400", "gray.200")(props),
          },
          _active: {
            boxShadow: "none",
            bg: mode("brand.500", "gray.300")(props),
          },
        }),
        newBrand: (props) => ({
          bg: mode("newBrand.300", "newBrand.300")(props),
          borderRadius: "8px",
          transition: ".25s all ease",
          boxSizing: "border-box",
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            bg: mode("newBrand.200", "gray.200")(props),
          },
          _active: {
            boxShadow: "none",
            bg: mode("newBrand.100", "gray.300")(props),
          },
        }),
        boldModal: (props) => ({
          mt: "15px",
          mb: "15px",
          bg: "brand.100",
          height: "40px",
          borderWidth: "1px",
          borderColor: "#C9CED2",
        }),
        white: (props) => ({
          bg: mode("gray.100", "gray.100")(props),
          border: '1px solid #C9CED2',
          borderRadius: "8px",
          boxShadow: "45px 76px 113px 7px rgba(99, 226, 190, 0.08)",
          transition: ".25s all ease",
          boxSizing: "border-box",
          _focus: {
            boxShadow: "none",
          },
          _active: {
            boxShadow: "none",
          },
        }),
        red: (props) => ({
          bg: mode("red.500", "red.500")(props),
          color: mode('brand.100', 'brand.100')(props),
          borderRadius: "8px",
          boxShadow: "45px 76px 113px 7px rgba(99, 226, 190, 0.08)",
          transition: ".25s all ease",
          boxSizing: "border-box",
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            bg: mode("brand.700", "brand.700")(props),
          },
          _active: {
            boxShadow: "none",
            bg: mode("gray.300", "gray.300")(props),
          },
        }),
        accent: (props) => ({
          bg: mode("brand.800", "brand.800")(props),
          color: mode('brand.700', 'brand.700')(props),
          borderRadius: "8px",
          boxShadow: "45px 76px 113px 7px rgba(99, 226, 190, 0.08)",
          transition: ".25s all ease",
          boxSizing: "border-box",
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            bg: mode("gray.300", "gray.300")(props),
          },
          _active: {
            boxShadow: "none",
            bg: mode("gray.200", "gray.200")(props),
          },
        }),

      },
    },
  },
};
