import { mode } from "@chakra-ui/theme-tools";
export const globalStyles = {
  colors: {
    brand: {
      100: "#FAF9F6",
      200: "#daf0ea",
      300: "#63E2BD",
      450: "#53caa7",
      400: "#3DBF99",
      500: "#26735C",
      600: "#0f3d2d",
      700: "#1A1A1A",
      800: "#FFBF00",
      900: "#FFFFFF",
    },
    newBrand: {
      100: '#faf9f6',
      200: '#cbebdf',
      300: '#9cdcc8',
      400: '#6dceb1',
      500: '#51b296',
      600: '#35967a',
      700: '#2c6d5a',
      800: '#23443a',
      900: '#1a1a1a',
    },
    brandGray: {
      100: '#faf9f6',
      200: '#dae5e0',
      300: '#b9d1c9',
      400: '#9bb9b0',
      500: '#7da197',
      600: '#638179',
      700: '#49615b',
      800: '#323e3b',
      900: '#1a1a1a'
    },
    brandGray: {
      100: '#faf9f6',
      200: '#dae5e0',
      300: '#b9d1c9',
      400: '#9bb9b0',
      500: '#7da197',
      600: '#638179',
      700: '#49615b',
      800: '#323e3b',
      900: '#1a1a1a'
    },
    brandLight: {
      100: '#EBF5F0',
      200: '#E4F3ED',
      300: '#DCF0E9',
      400: '#D5EEE6',
      500: '#CDECE2',
      600: '#C5EADF',
      700: '#BDE7DB',
      800: '#B8E1D5',
      900: '#B3DBCF'
    },
    secondaryGray: {
      100: "#FAF9F6",
      200: "#a7c9c1",
      300: "#63E2BD",
      400: "#3DBF99",
      500: "#184E3E",
      600: "#132C23",
      700: "#1A1A1A",
      800: "#FFBF00",
      900: "#132C23",
    },
    red: {
      100: "#FEEFEE",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    orange: {
      100: "#FFF6DA",
      200: "#FFE6B6",
      300: "#FFD691",
      400: "#FFC66C",
      500: "#FFB547",
      700: "#BF8835",
    },
    green: {
      100: "#E6FAF5",
      500: "#3DBF99",
    },
    black: {
      50 : '#ffffff',
      100: '#f0f0f0',
      150: '#e1e1e1',
      200: '#d2d2d2',
      250: '#c3c3c3',
      300: '#b4b4b4',
      350: '#a5a5a5',
      400: '#969696',
      450: '#878787',
      500: '#787878',
      550: '#696969',
      600: '#5a5a5a',
      650: '#4b4b4b',
      700: '#3c3c3c',
      750: '#2d2d2d',
      800: '#1e1e1e',
      850: '#0f0f0f',
      900: '#000000',
    }
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("brand.100", "brand.100")(props),
        fontFamily: "Inter",
        letterSpacing: "-0.5px",
      },
      input: {
        color: "gray.700",
      },
      html: {
        fontFamily: "Inter",
      },
    }),
  },
};
