import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  Button,
  Tooltip,
  Icon,
  Text,
  IconButton,
  Flex,
} from "@chakra-ui/react"
import { MdContentCopy } from "react-icons/md";

import React, { useState } from "react"


function EmbedPopover(props) {
  const [copied, setCopied] = useState(false);
  const embedText = `<div id="prosper-container"
  data-tenant_id="${props.tenantId}"
  data-campaign_id="${props.campaignId}"
></div>
<script src="https://prosper-aws.netlify.app/index.js"></script>`

  return (
    <Popover placement='top' >
      <PopoverTrigger>
        {props.children}
      </PopoverTrigger>
      <PopoverContent
        style={{ border: 'none' }}
        _focus={{ border: 'none' }}
        w='max-content'>
        <PopoverArrow />
        <PopoverCloseButton mt='4px'/>

        <PopoverHeader>
          <Flex>
            <Text>HTML Embed</Text>
            <Tooltip placement='top' closeOnClick={false} onClose={() => setCopied(false)} label={ copied ? 'copied' : 'copy' }>
              <IconButton
                icon={<Icon m='0' p='1px' w='16px' as={MdContentCopy}/>}
                position='absolute' right='0' mr='36px'
                h='auto' minW='none' rounded='4px' p='4px'
                bg='none'
                cursor='pointer'
             //   _hover={{ bg: '#FFFFFF11' }}
                onClick={() => { setCopied(true);
                  navigator.clipboard.writeText(embedText) }}
              />
            </Tooltip>
          </Flex>
        </PopoverHeader>
        <PopoverBody bg='#00000022'>
            <Text w='max-content' whiteSpace='pre-wrap' textAlign='start'>
              {embedText}
            </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default EmbedPopover;
