// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Link,
  chakra,
  Text,
  Button,
  Input,
  Checkbox,
  Stack,
  CheckboxGroup,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useDisclosure,
  Switch,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  DeleteItem,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdOutlineViewComfy,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import Card from "components/card/Card.js";
// import Widget from "views/admin/widgets/components/Widget";
import {
  Outlet,
  NavLink,
  useNavigate,
  useOutlet,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import CreateModal from "./components/CreateModal";
import WidgetRow from "./components/WidgetRow";
import { useUserContext } from "contexts/UserContext";

export default function Widgets(props) {
  const textColorPrimary = useColorModeValue("brand.600", "white");
  const [file, setFile] = useState("");
  const [tier, setTier] = useState("");
  const [maxWidgets, setMaxWidgets] = useState(0);
  const [rerenderCount, setRerenderCount] = useState(0);
  const createModalDisclosure = useDisclosure();

  const { userState, widgetsState, updateWidgets, activeTenantState } = useUserContext();
  const [activeTenant, setActiveTenant] = activeTenantState;
  const [user, setUser] = userState;
  const [widgets, setWidgets] = widgetsState;

  function rerenderWidgets() {
    setRerenderCount(rerenderCount + 1);
  }

  const emptyWidget = {
    widget_name: "New Widget",
    CTAFontSize: 15.0,
    SecondaryFontSize: 12.0,
    TertiaryFontSize: 11.0,
    FontFamily: "'Barlow Condensed', sans-serif",
    FontUrl:
      "https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;600&display=swap",

    Corner: 40.0,
    Width: 305.0,
    HeightOpen1: 180.0,
    HeightOpen2: 134.0,
    HeightClosed: 56.0,

    Color: "#63E2BD",
    SendButtonArrowColor: "#184E3E",
    IconColor: "#184E3E",
    ButtonColor: "#31B08B",
    ErrorColor: "#FF5959",
    InitialLicenseColor: "#184E3E",
    SecondaryColor: "#184E3E",
    Border: null,

    DefaultMessage:
      "Prosper provides the quickest and most flexible sharing platform for retail and e-commerce! Boost your business today with Prosper and get your first month free with the code from the demo.",
    StartText: "Click Here To Try Prosper",
    EndText: "Click here to share again",

    Verify: false,
    CopyType: "code",
    RewardUrl: null,
    Code: null,
    LinkRefferal: false,
    deferred: true,
    HoverColor: null,
  };

  function updateWidgetList(index, newDatum) {
    setWidgets((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = newDatum;
      return updatedData;
    });
  }

  function removeWidget(index) {
    setWidgets((prevData) => {
      var updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
  }


  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const iconColor = useColorModeValue("brand.300", "brand.300");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const createBg = useColorModeValue("brand.800", "brand.800");

  const saveWidget = (widget, widgetId) => {
    Auth.currentSession()
      .then((session) => {
        const jwtToken = session.getIdToken().getJwtToken();
        const request = {
          tenant_id: activeTenant,
          widget_id: widgetId,
          config: widget,
          resource_type: "widget",
        };
        axios.post("https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-create-config",
          request,
          { headers: { Authorization: jwtToken }, }
        )
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
    updateWidgetList(widgets.length, {
      widget_id: widgetId,
      tenant_id: activeTenant,
      config: widget,
      // "new": true
    });
  };

  const location = useLocation();
  useEffect(() => {
    if (location?.state?.openNew) {
      createModalDisclosure.onOpen();
    }
  }, []);
  const navigate = useNavigate();
  const outlet = useOutlet();


  return outlet ? (
    <Outlet />
  ) : (
    <Box>
      <Flex justifyContent="space-between" alignItems="end" mb="12px">
        <Box
          boxShadow='md'
          py={"8px"}
          px={"16px"}
          width={"100%"}
          position={"relative"}
          borderRadius={"12px"}
          wordWrap={"break-word"}
          backgroundClip={"border-box"}
          bg="white"
          border="1px solid #eee"
          h='100%'
          w='160px'
        >
          <Flex justifyContent='space-between'>
            <Text fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
              WIDGETS
            </Text>
            <Text fontWeight={'bold'} fontSize={'lg'} letterSpacing='wider' color='black.400'>
              {widgets?.length}
            </Text>
          </Flex>
        </Box>

        {user && (
          <CreateModal
            disclosure={createModalDisclosure}
            widgets={widgets}
            save={saveWidget}
            isNew={true}
            activeTenant={activeTenant}
          >
            <Button
              border="1px solid #AFAFAF"
              w="auto"
              color={textColorPrimary}
              ml="12px"
              onClick={createModalDisclosure.onOpen}
              bg={createBg}
              fontSize="sm"
              boxShadow="lg"
              py='4px'
            >
              Create Widget
            </Button>
          </CreateModal>
        )}
        {/*
        <Button
          border="1px solid #AFAFAF"
          w="auto"
          color={textColorPrimary}
          py="4px"
          ml="12px"
          bg={createBg}
          fontSize="sm"
          boxShadow="lg"
          _hover={{
            bg: "none",
            borderColor: "brand.800",
          }}
          // onClick={() => navigate('/admin/widgets/edit', {state: ""})}
          onClick={() => {
            updateWidgetList(data.length, {
              widget_id: uuidv4(),
              tenant_id: "prosper",
              config: emptyWidget,
              new: true,
            });
          }}
        >
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="l"
            textAlign="center"
            whiteSpace="nowrap"
          >
            Create Widget
          </Text>
        </Button>

      <SimpleGrid columns={{ base: 1 }} mb="20px">
        {loading
          ? null
          : data
              .slice(0)
              .reverse()
              .map((widget, index) => {
                return widget ? (
                  <Widget
                    key={data.length - index - 1}
                    index={data.length - index - 1}
                    advanced={advanced}
                    data={widget}
                    update={updateWidgetList}
                    remove={removeWidget}
                    isnew={widget.new ? 1 : 0}
                    rerenderwidgets={rerenderWidgets}
                    user={user}
                  />
                ) : null;
              })}
      </SimpleGrid>
      */}
      </Flex>
      <Box
        borderRadius="12px"
        mb="20px"
        bg="#fff"
        border="1px solid #eee"
        pt='10px'
        pb='10px'
        boxShadow='lg'
      >
        <TableContainer>
          <Table variant="simple">
            <TableCaption>Need more Widgets? Upgrade your plan</TableCaption>
            <Thead>
              <Tr>
                <Th>Widget Name</Th>
                <Th>Call to Action</Th>
                <Th>Primary Color</Th>
                <Th>Primary Font Size</Th>
                <Th>Corner Radius</Th>
                <Th isNumeric>
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {widgets && widgets.slice(0).reverse().map((widget, index) => {
                return widget && (
                  <WidgetRow
                    key={widgets.length - index - 1}
                    index={widgets.length - index - 1}
                    data={widget}
                    update={updateWidgetList}
                    remove={removeWidget}
                    isnew={widget.new ? 1 : 0}
                    rerenderwidgets={rerenderWidgets}
                    activeTenant={activeTenant}
                  />
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
