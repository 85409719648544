import React, { useEffect, useState, useRef } from 'react';
import { Field, FieldValidator } from 'formik';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Box,
  Tooltip,
  Spacer,
} from "@chakra-ui/react";
import { InfoIcon } from '@chakra-ui/icons';

function FormikInput({ label, tooltipLabel, field, placeholder, validate, validateField, ...props }) {
  const [ fieldName, meta, helpers ] = field;
  // console.log(helpers, meta, fieldName)
  const { setValue, setError, setTouched } = helpers;
  const { value, initialValue } = meta;
  const [text, setText] = useState(value ?? initialValue);
  const [isRunning, setIsRunning] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  // console.log( value, initialValue );

  // useEffect(() => {
  //   let timerInterval;
  //
  //   // console.log(timeRemaining)
  //   if (isRunning && timeRemaining > 0) {
  //     timerInterval = setInterval(() => {
  //       setTimeRemaining((prevTime) => prevTime - 1);
  //     }, 10);
  //   } else if (timeRemaining === 0) {
  //     setIsRunning(false);
  //   }
  //
  //   return () => clearInterval(timerInterval); // Cleanup interval on unmount
  // }, [timeRemaining]);

  const isInitialMount = useRef(true);

  useEffect(async () => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return
    }

    validate(text).then((out)=>{
        setError(out ? out : null);
      }).catch((err)=>{
        console.log(err);
      });

    // console.log(!isRunning)
    // if (!isRunning) {
      // setTimeRemaining(100);
      // setIsRunning(true);
    // }
  }, [text])

  // console.log(meta, fieldName, helpers);
  // console.log(meta.error);
  return (
    <FormControl isRequired mb='28px'
      isInvalid={meta.error}
    >
      { label && (
        <Flex alignItems='center'>
          <FormLabel variant='modal' >
            {label}
          </FormLabel>
          <Spacer/>
          { tooltipLabel && (
            <Tooltip variant='modal'
              hasArrow
              label={tooltipLabel}
              fontSize="md"
            >
              <InfoIcon/>
            </Tooltip>
          ) }
        </Flex>
      ) }
      <Input
        // bg={'brand.100'}
        variant='modal'
        value={text}
        placeholder={placeholder}
        onBlur={fieldName.onBlur}
        onChange={(e) => {
          setText(e.target.value)
          setValue(e.target.value)
        }}
        borderColor={ meta.error && 'red.500' }
        {...props}
        border={meta.error ? '2px solid' : '1px solid'}
        _focus={{
          border: meta.error ? '2px solid' : '1px solid',
          borderColor: meta.error ? 'red.500' : props.focusBorderColor ?? 'transparent',
        }}
        _hover={{
          border: meta.error ? '2px solid' : '1px solid',
          borderColor: meta.error ? 'red.500' : props.focusBorderColor ?? 'transparent',
        }}
      />
      <FormErrorMessage position='absolute'>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}
// function FormikInput({ label, field, validate, validateField, ...props }) {
//   // const debouncedValidate =
//   return (
//     <Box>
//       <Field name={field} validate={validate}>
//         {({ field, form }) => {
//           const errors = _.get(form.errors, field.name);
//           const touched = _.get(form.touched, field.name);
//           // form.validateField(field.name)
//           return (
//             <FormControl isRequired mb='15px'
//               isInvalid={errors && touched}
//             >
//               { label && (
//                 <FormLabel variant='modal' >
//                   {label}
//                 </FormLabel>
//               ) }
//
//               <Input {...field} {...props}
//                   onChange={(e)=>{field.onChange(e); validateField(field.name); console.log("!")}}
//                   onBlur={() => {form.validateField(field.name)}}
//                   variant='modal' border='2px solid' borderColor={ errors ? 'red.500' : 'transparent'}/>
//               <FormErrorMessage position='absolute'>{errors}</FormErrorMessage>
//             </FormControl>
//           )
//         }}
//       </Field>
//     </Box>
//   );
// }

export default FormikInput;
