import React, { useState, useMemo } from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { BrowserRouter, Route, Routes, Navigate, useRoutes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import config from './aws-exports';
import { Amplify } from "aws-amplify";
import routes from 'routes'
import Hotjar from '@hotjar/browser';
import { AuthProvider } from "contexts/AuthContext";
import { UserProvider } from "contexts/UserContext";


Hotjar.init(3631033, 6);

Amplify.configure(config);

function RouteRenderer() {
  return useRoutes(routes);
}

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <BrowserRouter>
          <AuthProvider>
            <UserProvider>
              <RouteRenderer />
            </UserProvider>
          </AuthProvider>
        </BrowserRouter>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
