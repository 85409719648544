import React from "react";

// chakra imports
import {
  Box,
  Button,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";

// Assets
import { IoMenuOutline } from "react-icons/io5";

function Sidebar(props) {
  const { routes } = props;

  let variantChange = "width 0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("brand.100", "brand.100");
  let sidebarMargins = "0px";

  // SIDEBAR
  return (
    <Box display={{ xxs: "none", xl: "block" }} position='fixed' minH='100%'>
      <Box
        bgGradient='linear(to-tl, brand.400 -200%, brand.100 60%)'
        borderRight='1px solid #e0e0e0'
        boxShadow='2xl'
        transition={variantChange}
        w='240px'
        h='100vh'
        m={sidebarMargins}
        minH='100%'
        overflowX='hidden'
        boxShadow={shadow}>
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}>
          <Content routes={routes} />
        </Scrollbars>
      </Box>
    </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive(props) {
  let sidebarBackgroundColor = useColorModeValue("black", "black");
  let menuColor = useColorModeValue("black", "black");
  // // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  //  BRAND
  // let isWindows = navigator.platform.startsWith("Win");
  const { routes } = props;

  return (
      <Button
        onClick={onOpen}
        boxShadow='lg'
        roundedStart='full'
        padding='10px'
        h="50px"
        bg="white"
        border="1px solid #dedede"
        as={Button}
        _hover={{
          background: "brand.200",
        }}
        _active={{
          background: "brand.200",
        }}
        display={{ xxs: "flex", xl: "none" }}
      >
      <Flex alignItems='center'>
        <Flex ref={btnRef}>
          <Icon
            as={IoMenuOutline}
            color={menuColor}
            w='24px'
            h='24px'
            mx='10px'
            _hover={{ cursor: "pointer" }}
          />
        </Flex>
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          placement={document.documentElement.dir === "rtl" ? "right" : "left"}
          finalFocusRef={btnRef}>
          <DrawerOverlay />
          <DrawerContent w='285px' maxW='285px'
            bgGradient='linear(to-tl, brand.400 -200%, brand.100 60%)'
          >
            <DrawerCloseButton
              zIndex='3'
              onClose={onClose}
              _focus={{ boxShadow: "none" }}
              _hover={{ boxShadow: "none" }}
            />
            <DrawerBody maxW='285px' px='0rem' pb='0'>
              <Scrollbars
                autoHide
                renderTrackVertical={renderTrack}
                renderThumbVertical={renderThumb}
                renderView={renderView}>
                <Content routes={routes} />
              </Scrollbars>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Button>
  );
}
// PROPS

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
