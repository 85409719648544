const html = `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
<title>Order</title>
<!--[if !mso]><!-- -->
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<!--<![endif]-->
<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<style type="text/css"> span.productOldPrice { color: #A0131C; text-decoration: line-through;} #outlook a { padding: 0; } body { margin: 0; padding: 0; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; } table, td { border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; } img { border: 0; height: auto; line-height: 100%; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; } p { display: block; margin: 13px 0; } </style>
<!--[if mso]>
<xml>
<o:OfficeDocumentSettings>
<o:AllowPNG/>
<o:PixelsPerInch>96</o:PixelsPerInch>
</o:OfficeDocumentSettings>
</xml>
<![endif]--> <!--[if lte mso 11]>
<style type="text/css"> .outlook-group-fix { width:100% !important; } </style>
<![endif]--> <!--[if !mso]><!-->
<link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700" rel="stylesheet" type="text/css">
<style type="text/css"> @import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700); </style>
<!--<![endif]-->
<style type="text/css"> @media only screen and (min-width:480px) { .column-per-100 { width: 100% !important; max-width: 100%; } .column-per-25 { width: 25% !important; max-width: 25%; } .column-per-75 { width: 75% !important; max-width: 75%; } .column-per-48-4 { width: 48.4% !important; max-width: 48.4%; } .column-per-50 { width: 50% !important; max-width: 50%; } } </style>
<style type="text/css"> @media only screen and (max-width:480px) { table.full-width-mobile { width: 100% !important; } td.full-width-mobile { width: auto !important; } } noinput.menu-checkbox { display: block !important; max-height: none !important; visibility: visible !important; } @media only screen and (max-width:480px) { .menu-checkbox[type="checkbox"]~.inline-links { display: none !important; } .menu-checkbox[type="checkbox"]:checked~.inline-links, .menu-checkbox[type="checkbox"]~.menu-trigger { display: block !important; max-width: none !important; max-height: none !important; font-size: inherit !important; } .menu-checkbox[type="checkbox"]~.inline-links>a { display: block !important; } .menu-checkbox[type="checkbox"]:checked~.menu-trigger .menu-icon-close { display: block !important; } .menu-checkbox[type="checkbox"]:checked~.menu-trigger .menu-icon-open { display: none !important; } } </style>
<style type="text/css"> @media only screen and (min-width:481px) { .products-list-table img { width: 120px !important; display: block; } .products-list-table .image-column { width: 20% !important; } } a { color: #000; } .server-img img { width: 100% } .server-box-one a, .server-box-two a { text-decoration: underline; color: #2E9CC3; } .server-img img { width: 100% } .server-box-one a, .server-box-two a { text-decoration: underline; color: #2E9CC3; } .server-img img { width: 100% } .server-box-one a, .server-box-two a { text-decoration: underline; color: #2E9CC3; } </style>
</head>
<body style="background-color:#ffffff;">
<div style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; background-color: #ffffff;">
<!-- Body Wrapper --> <!--[if mso | IE]>
<table align="center" border="0" cellpadding="0" cellspacing="0" class="body-wrapper-outlook" style="width:600px;" width="600" >
<tr>
<td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]-->
<div class="body-wrapper" style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; padding-bottom: 10px; box-shadow: 0 4px 10px #ddd; background: #E3E3E3; background-color: #E3E3E3; margin: 0px auto; max-width: 600px; margin-bottom: 10px;">
<table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#E3E3E3;background-color:#E3E3E3;width:100%;">
<tbody>
<tr>
<td style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; direction: ltr; font-size: 0px; padding: 10px 20px; text-align: center;" align="center">
<!--[if mso | IE]>
<table role="presentation" border="0" cellpadding="0" cellspacing="0">
<![endif]--> <!-- Pre-Headers --> <!--[if mso | IE]>
<tr>
<td class="pre-header-outlook" width="600px" >
<table align="center" border="0" cellpadding="0" cellspacing="0" class="pre-header-outlook" style="width:560px;" width="560" >
<tr>
<td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]-->
<div class="pre-header" style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; height: 1px; overflow: hidden; margin: 10px auto; max-width: 560px;">
   <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
      <tbody>
         <tr>
            <td style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; direction: ltr; font-size: 0px; padding: 0px; text-align: center;" align="center">
               <!--[if mso | IE]>
               <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                  <tr>
                     <td class="" style="vertical-align:top;width:560px;" >
                        <![endif]-->
                        <div class="column-per-100 outlook-group-fix" style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; font-size: 0px; text-align: left; direction: ltr; display: inline-block; vertical-align: top; width: 100%;">
                           <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                              <tr>
                                 <td align="center" style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; font-size: 0px; padding: 0; word-break: break-word;">
                                    <div style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; font-size: 1px; font-weight: 400; line-height: 0; text-align: center; color: #E3E3E3;">[header]</div>
                                 </td>
                              </tr>
                           </table>
                        </div>
                        <!--[if mso | IE]>
                     </td>
                  </tr>
               </table>
               <![endif]-->
            </td>
         </tr>
      </tbody>
   </table>
</div>
<!--[if mso | IE]>
</td>
</tr>
</table>
</td>
</tr>
<![endif]--> <!-- header --> <!--[if mso | IE]>
<tr>
<td class="header-outlook" width="600px" >
<table align="center" border="0" cellpadding="0" cellspacing="0" class="header-outlook" style="width:560px;" width="560" >
<tr>
<td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]-->
​
<!--[if mso | IE]>
</td>
</tr>
</table>
</td>
</tr>
<![endif]--> <!-- notice --> <!--[if mso | IE]>
<tr>
<td class="notice-wrap-outlook margin-bottom-outlook" width="600px" >
<table align="center" border="0" cellpadding="0" cellspacing="0" class="notice-wrap-outlook margin-bottom-outlook" style="width:560px;" width="560" >
<tr>
<td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]-->
<div class="notice-wrap margin-bottom" style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; margin: 0px auto; max-width: 560px; margin-bottom: 15px;">
   <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
      <tbody>
         <tr>
            <td style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; direction: ltr; font-size: 0px; padding: 0px; text-align: center;" align="center">
               <!--[if mso | IE]>
               <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                  <tr>
                     <td class="" style="vertical-align:top;width:560px;" >
                        <![endif]-->
                        <div class="column-per-100 outlook-group-fix" style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; font-size: 0px; text-align: left; direction: ltr; display: inline-block; vertical-align: top; width: 100%;">
                           <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                              <tbody>
                                 <tr>
                                    <td style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; background-color: #faf9f6; border-radius: 10px; vertical-align: top; padding: 30px 25px;" bgcolor="#faf9f6" valign="top">
                                       <table border="0" cellpadding="0" cellspacing="0" role="presentation" style width="100%">
                                          <tr>
                                             <td align="left" style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; font-size: 0px; padding: 0; word-break: break-word;">
                                                <div style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; font-size: 26px; font-weight: bold; line-height: 30px; text-align: left; color: #4F4F4F; margin-bottom: 20px;">[header]</div>
                                             </td>
                                          </tr>
                                          <tr>
                                             <td align="left" class="link-wrap" style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; font-size: 0px; padding: 0; padding-bottom: 20px; word-break: break-word;">
                                                <div style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 25px; text-align: left; color: #4F4F4F;">[main]</div>
                                             </td>
                                          </tr>
                                          <tr>
                                             <td align="left" vertical-align="middle" style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; font-size: 0px; padding: 0; word-break: break-word;">
                                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;line-height:100%;">
                                                   <tr>
                                                      <td align="center" bgcolor="#fe6700" role="presentation" style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; border: none; border-radius: 25px; cursor: auto; mso-padding-alt: 10px 25px; background: #1a1a1a;" valign="middle"> <div style="display: inline-block; background: #5EB5E0; color: #faf9f6; font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; font-size: 15px; font-weight: medium; line-height: 120%; margin: 0; text-decoration: none; text-transform: none; padding: 10px 30px; mso-padding-alt: 0px; border-radius: 40px;" target="_blank">[button]</div>
                                                      </td>
                                                   </tr>
                                                </table>
                                             </td>
                                          </tr>
                                          <tr>
                                             <td align="left" class="link-wrap" style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; font-size: 0px; padding: 0; padding-bottom: 20px; word-break: break-word;">
                                                <div style="font-family: Open Sans, Helvetica, Tahoma, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 25px; text-align: left; color: #4F4F4F; margin-top: 36px;">[footer]</div>
                                             </td>
                                          </tr>
                                       </table>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                        <!--[if mso | IE]>
                     </td>
                  </tr>
               </table>
               <![endif]-->
            </td>
         </tr>
      </tbody>
   </table>
</div>
<!--[if mso | IE]>
</td>
</tr>
</table>
</td>
</tr>
<![endif]-->
​
<!--[if mso | IE]>
<tr>
<td class="server-box-one-outlook" width="600px" >
<table align="center" border="0" cellpadding="0" cellspacing="0" class="server-box-one-outlook" style="width:560px;" width="560" >
<tr>
<td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]-->
`

const defaultCampaign = {
  name: "New Campaign",
  id: "",
  tenant_id: "",
  widget: {},
  url: "", // gets filled in by fillDefaults in CreateModal
  organization: "", // gets filled in by fillDefaults in CreateModal
  html: html,
  sender:{
    discount: {
      static: { type: "static", code: "", name: "" },
      shopify: { type: "shopify", shopify_discount_id: "", shop_url: "", name: ""}
    },
    sms: "[organization]: Hey [sender_name] 😄\n\nThanks for sending a referral!\n\nYour discount code is [code] and is good for [incentive].\n\n[url]",
    subject: "[organization] Reward",
    tags: {
        header: "Your referral sent! Claim your discount here.",
        body: "Thanks [sender_name]! We told your friend about the deal at [organization]. You can claim your reward for [incentive] at [url].",
        footer: "If you have any questions, please contact us!"
    }
  },

  receiver: {
    discount: {
      static: { type: "static", code: "", name: "" },
      shopify: { type: "shopify", shopify_discount_id: "", shop_url: "", name: ""}
    },
    sms: "[organization]: You've just received a referral from [sender_name] ([sender_contact])!\n\nIf you see something you like at [organization], this referral is good for [incentive]. Use code: [code]\n[url]\n\nHappy shopping 😄\n\nReply STOP to unsubscribe from any future referrals.",
    subject: "[sender_name] sent you a [organization] discount!",
    tags: {
      header: "[sender_name] has sent you [incentive] at [organization]",
      body: "Congratulations!<br/><br/>Your friend [sender_name] ([sender_contact]) was shopping at [organization] and thought of you! They sent you a [incentive] coupon which can be redeemed during your next purchase at [url].",
      footer: "\"[sender_message]\" - [sender_name]<br/>If you have any questions, please contact us!"
    }
  }
};
export default defaultCampaign;
