import { Icon } from "@chakra-ui/react";
import { FaEthereum } from 'react-icons/fa';
import StarterIcon from 'assets/img/pricing/starter';
import ProIcon from 'assets/img/pricing/pro';
import EnterpriseIcon from 'assets/img/pricing/enterprise';

export const tiers = [
  {
    header: "Pro",
    subheader: "For start-ups & midsize online businesses looking to improve their existent referrals.",
    setPrice: true,
    icon: <ProIcon/>,
    monthly: "$25",
    monthlyAmount: "$25",
    yearly: "$20",
    yearlyAmount: "$20",
    monthlyReferral: "75¢",
    monthlyReferralAmount: "75¢",
    yearlyReferral: "75¢",
    yearlyReferralAmount: "75¢",
    buttonText: "Get Started",
    enabledPerks: [
      "Integrate to unlimited stores",
      "Email Support",
      "Managed Launch and Campaign (optional) $250/month",
      "Widget Customization",
      "Zapier Integration",
      "Custom Email Domain",
      "Customer Success Team",
    ],
    disabledPerks: [
      ""
    ]
  },
  {
    header: "Enterprise",
    subheader: "For larger companies who need unique features and custom pricing.",
    setPrice: false,
    topRightText: null,
    icon: <EnterpriseIcon />,
    monthly: "Custom",
    yearly: "Custom",
    monthlyReferral: "Custom",
    yearlyReferral: "Custom",
    buttonText: "Talk to Sales",
    enabledPerks: [
      "Integrate to unlimited stores",
      "Email Support",
      "Managed Launch and Campaign (included)",
      "Widget Customization",
      "Zapier Integration",
      "Custom Email Domain",
      "Customer Success Team",
    ],
    disabledPerks: [
      "",
      ""
    ]
  }
];
