import React, { useEffect, useState, useRef } from 'react';
// import { Options } from 'react-select';
import { Select } from 'chakra-react-select';
import {
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import _ from 'lodash';
import { useIsMount } from 'components/useIsMount/UseIsMount';

function FormikObjectSelect({ field, label, placeholder, valuePath, validate, optional, ...props }) {
  const [ fieldName, meta, helpers] = field;
  const { setValue, setError, setTouched } = helpers;
  const { value, initialValue } = meta;
  const [ text, setText ] = useState(value?.code ?? initialValue);

  useEffect(async () => {
    // console.log("validate")
    validate(value).then((out)=>{
        setError(out ? out : null);
      }).catch((err)=>{
        // console.log(err);
      });
  }, [text])

  return (
    <FormControl isRequired mb='15px'
      isInvalid={meta.error && meta.touched}
    >
      { label && (
        <FormLabel variant='modal' >
          {label}
        </FormLabel>
      ) }
      <Input
        variant='modal'
        {...props}
        value={text}
        placeholder={placeholder}
        onBlur={fieldName.onBlur}
        onChange={(e) => {
          var out = props.object;
          _.set(out, valuePath, e.target.value)
          setValue(out)
          setText(e.target.value)
          setTouched(true)
        }}
        border='2px solid' borderColor={ meta.error && meta.touched ? 'red.500' : 'transparent'}
      />
      <FormErrorMessage position='absolute'>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default FormikObjectSelect;
