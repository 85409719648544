import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  SimpleGrid,
  useToken
} from "@chakra-ui/react";
import 'react-date-range/dist/theme/default.css'; // theme css file
import './DateRangeStyle.css'; // main style file
import { DateRangePicker } from 'react-date-range';
import { globalStyles } from "theme/styles"
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import {
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns';

// import { RangeDatepicker, RangeDatepickerProps, SingleDatepicker, SingleDatepickerProps } from 'chakra-dayzed-datepicker';
// import Card from "components/card/Card.js";
// // Custom components
// import { barChartDataConsumption, barChartOptionsConsumption, } from "variables/charts";
// import { MdBarChart } from "react-icons/md";
// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, CartesianAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function DateRangePickerComponent ({startDateState, endDateState, defineds, onChange, ...props}) {

  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };

  function createStaticRanges(ranges) {
    return ranges.map(range => ({ ...staticRangeHandler, ...range }));
  }

  const staticRanges = createStaticRanges([
    {
      label: 'Today',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
    {
      label: 'This Week',
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Last Week',
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
      }),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
    },
    {
      label: 'All Time',
      range: () => ({
        startDate: defineds.earliest,
        endDate: defineds.endOfToday,
      }),
    },
  ])

  return (
    <Box
      border="1px solid #eee"
      boxShadow="lg"
      borderRadius="10px"
      overflow="hidden"
      w='100%'
      minW={props.minW}
      maxW={props.maxW}
    >
      <DateRangePicker
        rangeColors={[useToken('colors', 'newBrand.400'), "#3DBF99"]}
        moveRangeOnFirstSelection={false}
        ranges={[{
          startDate: startDateState[0],
          endDate: endDateState[0],
          key: 'selection'
        }]}
        onChange={item => {startDateState[1](item.selection.startDate); endDateState[1](item.selection.endDate)}}
        fixedHeight={true}
        minDate={defineds.earliest}
        maxDate={defineds.endOfToday}
        staticRanges={staticRanges}
        inputRanges={[]}
        retainEndDateOnFirstSelection={true}

        // // scroll is cool imo but right margin gets effed on latest month
        // // and the display on top is off by one. also it is laggy as hell rip
        // scroll={{enabled: true}}
        // direction='horizontal'
      />
    </Box>
  )
}
