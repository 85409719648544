
export const maxMembersByTier = {
  testing: -1, beta: -1,
  starter: 1, growth: -1, pro: 10, enterprise: -1,
  appsumo1: 1, appsumo2: 5, appsumo3: 10,
  prosper_tier1: 1, prosper_tier2: 5, prosper_tier3: -1
}

export const maxCampaignsByTier = {
  beta: -1, testing: -1,
  starter: 1, growth: 10, pro: 20, enterprise: -1,
  appsumo1: -1, appsumo2: -1, appsumo3: -1,
  prosper_tier1: -1, prosper_tier2: -1, prosper_tier3: -1
};

export const printableTierByTier = {
  beta: 'Beta', testing: 'Testing',
  starter: 'Starter', growth: 'Growth', pro: 'Pro', enterprise: 'Enterprise',
  appsumo1: 'Tier 1', appsumo2: 'Tier 2', appsumo3: 'Tier 3',
  prosper_tier1: 'Tier 1', prosper_tier2: 'Tier 2', prosper_tier3: 'Tier 3'
};

export const formatTier = (tier) => {
  return printableTierByTier[tier]
}

export const organizationMax = {
  beta: -1, testing: -1,
  starter: -1, growth: -1, pro: -1, enterprise: -1,
  appsumo1: 1, appsumo2: 1, appsumo3: 5,
  prosper_tier1: 1, prosper_tier2: 1, prosper_tier3: 5
};

export const maxOrganizationsByTier = (tier) => {
  return organizationMax[tier]
}
