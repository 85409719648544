import { mode } from "@chakra-ui/theme-tools";
export const tableStyles = {
  components: {
    Table: {
      parts: ['th', 'td', 'tr'],
      baseStyle: {
        table: {
          fontVariantNumeric: "lining-nums tabular-nums",
          borderCollapse: "collapse",
          width: "full",
        },
        th: {
          fontFamily: "heading",
          fontWeight: "bold",
          textTransform: "uppercase",
          letterSpacing: "wider",
          textAlign: "start",
        },
        td: {
          textAlign: "start",
        },
        caption: {
          mt: 4,
          fontFamily: "heading",
          textAlign: "center",
          fontWeight: "medium",
        },
        // th: {
        //   borderColor: 'brand.100',
        // },
        // td: {
        //   borderColor: 'brand.300',
        //   px: '0px',
        // },
        // tr: {
        //   borderColor: 'brand.300',
        // },
      },
      variants: {
        recordsTable: {
          table: {
            fontVariantNumeric: "lining-nums tabular-nums",
            borderCollapse: "collapse",
            width: "full",
          },
          th: {
            fontFamily: "heading",
            fontWeight: "bold",
            textTransform: "uppercase",
            letterSpacing: "wider",
            textAlign: "start",
          },
          td: {
            textAlign: "start",
            px: {xxs: "0rem", md: "1.5rem"},
          },
          caption: {
            mt: 4,
            fontFamily: "heading",
            textAlign: "center",
            fontWeight: "medium",
          },
        }
      }
    },
  },
};
