import FormikInput from './FormikInput';
import {
  useToken,
  Input,
  InputGroup,
  Stack,
  Button,
  Icon,
  Tooltip,
  Text,
  useStyleConfig,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useColorModeValue,
  Divider,
  Box,
  Flex,
  SimpleGrid,
  Code,
  Image,
  Center,
  FormLabel,
} from "@chakra-ui/react";
import {
  MdHelp
} from 'react-icons/md'
import { Select } from "chakra-react-select";
import { useField } from 'formik';
import FormikSelect from './FormikSelect';
import { useNavigate } from 'react-router-dom';

function Widget({ inputs, widgets, ...props }) {
  const navigate = useNavigate();
  const field = inputs.fields[0].field;
  const validate = inputs.fields[0].validate;
  const label = inputs.label;
  // const validate = (value) => {
  //   // const max = 48;
  //   // const uuidv4Regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89aAbB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  //   // if (!uuidv4Regex.test(value.id)) { return 'Widget has invalid id' }
  //   // if (value.name.length > 48) { return '' }
  //   return (!value.id || !value.name) && "Invalid widget"
  // }
  // console.log("Widget:", props.widgets);
  return (
    <Box {...props} >
      <Center mb='15px'>
          <Text variant='modal'>Now, choose a widget to be used with your campaign.</Text>
          <Text variant='modal' color='red'>*</Text>
          <Tooltip hasArrow variant='modal' label="Your campaign will display this widget">
            <Box> <Icon w="16px" h="16px" as={MdHelp} color={"black"} ml="5px" /> </Box>
          </Tooltip>
        </Center>
      <FormikSelect
        options={widgets}
        field={useField({name: field, validate: validate, initialTouched: true})}
        validate={validate}
        labelProps={{variant:'modal', textAlign:"center", mb:'15px' }}
        placeholder="Choose a Widget..."
        labelPath={'name'}
        valuePath={'id'}
        object={{name: null, id: null}}
        size='lg' mt='20px' height="48px" maxWidth="100%"
        focusBorderColor={'#C9CED2'}
        selectedOptionStyle="check"
        chakraStyles={{
          control: (provided) => ({ ...provided, background: 'brand.100' })
        }}
      />
      <Text variant='boldModal' > OR </Text>
      <Center>
        <Button bg="brand.300" height="40px" mb="5px" onClick={()=>navigate('/admin/widgets', { state: { openNew: true } })}>
          Design a New Widget
        </Button>
      </Center>
      <Text variant='hintModal' onClick={()=>window.open('/admin/home')}>
        Don’t have one? Check out our tutorial on creating a custom widget
      </Text>
    </Box>
  )
}

export default Widget;
