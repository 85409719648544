import React, { createContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext();

export const UserProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [campaigns, setCampaigns] = useState(null);
  const [widgets, setWidgets] = useState(null);
  const [tenants, setTenants] = useState(null);
  const [activeTenant, setActiveTenant] = useState(null);
  const [members, setMembers] = useState(null);
  const [billingType, setBillingType] = useState(null);
  const [invites, setInvites] = useState(null);
  const [tier, setTier] = useState(null);
  const [domains, setDomains] = useState(null);
  // console.log('campaigns', campaigns)
  // console.log('widgets', widgets)
  // console.log('activeTenant:', activeTenant, 'storedTenant:', localStorage.getItem('activeTenant'))
  // console.log('tenants', tenants)

  useEffect(()=>{
    if (!activeTenant) { return }
    updateMembers()
    updateInvites()
    updateWidgets()
    updateCampaigns()
    updateTenants()
    updateUser()
    updateDomains()
    // updateBillingType()
  },[activeTenant])

  useEffect(()=>{
    if (tenants && activeTenant) {
      const tenant = tenants.find(tenant => tenant.tenant_id === activeTenant)
      setTier(tenant.tier)
    }
  },[activeTenant,tenants])

  useEffect(()=>{
    updateUser()
  },[])

  const resetState = () => {
    setUser(null);
    setCampaigns(null);
    setWidgets(null);
    setTenants(null);
    setActiveTenant(null);
    setMembers(null);
    setBillingType(null);
    setInvites(null);
    setDomains(null);
  }

  const navigate = useNavigate();

  const updateActiveTenant = async () => {
    const userPromise = await Auth.currentAuthenticatedUser();
    const stored = JSON.parse(localStorage.getItem('activeTenant'))
    if (stored && stored.user_id == userPromise.attributes.sub && tenants.findIndex(tenant => tenant.tenant_id === stored.tenant_id) !== -1) {
      setActiveTenant(stored.tenant_id)
    } else {
      navigate('auth/organizations')
    }
  }

  const updateMembers = () => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/get-team-members',
          { "tenant_id": activeTenant },
          { headers: { "Authorization": jwtToken } })
          .then(response => {
            setMembers(response.data.sort((a,b)=>{
              const roles = {owner: 0, admin: 1, member: 2}
              const sign = Math.sign(roles[a.role]-roles[b.role])
              return sign
            }))
          })
          .catch(error => {
            console.error(error);
          })
        })
        .catch(error => {
          console.error(error);
      });
  }

  const updateInvites = () => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/get-team-invites',
          { "tenant_id": activeTenant },
          { headers: { "Authorization": jwtToken } })
          .then(response => {
            setInvites(response.data)
          })
          .catch(error => {
            console.error(error);
          })
        })
        .catch(error => {
          console.error(error);
      });
  }

  const updateBillingType = () => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-get-billing',
          { "tenant_id": activeTenant },
          { headers: { "Authorization": jwtToken } })
          .then(response => {
            setBillingType(response.data)
          })
          .catch(error => {
            console.error(error);
          })
        })
        .catch(error => {
          console.error(error);
      });
  }

  const updateTenants = () => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        axios.get('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/get-user-tenants', {
          headers: { "Authorization": jwtToken },
        })
          .then(response => { setTenants(response.data); })
          .catch(error => { console.error(error); })
    }).catch(error => { console.error(error); });
  }

  const updateUser = async () => {
    const userPromise = await Auth.currentAuthenticatedUser().catch(()=>{});
    if (userPromise){
      const signOut = () => {
        resetState()
        Auth.signOut()
      };
      userPromise.signOut = signOut;
      setUser(userPromise);
    }
  }

  const updateCampaigns = () => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        axios.get('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-get-configs', {
          headers: { "Authorization": jwtToken },
          params: {
              "tenant_id": activeTenant,
              "config_type": "campaign"
        }})
          .then(response => { setCampaigns(response.data); })
          .catch(error => { console.error(error); })
    }).catch(error => { console.error(error); });
  }

  const updateWidgets = () => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        axios.get('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-get-configs', {
          headers: { "Authorization": jwtToken },
          params: {
              "tenant_id": activeTenant,
              "config_type": "widget"
            }
        })
          .then(response => { setWidgets(response.data); })
          .catch(error => { console.error(error); })
    }).catch(error => { console.error(error); });
  }

  const updateDomains = () => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        const action = 'getAll'
        const params = {}
        const request = {
          tenant_id: activeTenant,
          action: action,
          params: params,
        }
        return axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/domains',
          request,
          {
            headers: { "Authorization": jwtToken },
          }).then(response => {
             setDomains(response.data)
          })
          .catch(error => {
            console.error(error);
          })
        })
        .catch(error => {
          console.error(error);
      });
  }

  return (
    <UserContext.Provider value={{
      userState: [user, setUser],
      updateUser: updateUser,
      campaignsState: [campaigns, setCampaigns],
      updateCampaigns: updateCampaigns,
      widgetsState: [widgets, setWidgets],
      updateWidgets: updateWidgets,
      tenantsState: [tenants, setTenants],
      updateTenants: updateTenants,
      activeTenantState: [activeTenant, setActiveTenant],
      updateActiveTenant: updateActiveTenant,
      membersState: [members, setMembers],
      updateMembers: updateMembers,
      invitesState: [invites, setInvites],
      updateInvites: updateInvites,
      billingTypeState: [billingType, setBillingType],
      updateBillingType: updateBillingType,
      domainsState: [domains, setDomains],
      updateDomains: updateDomains,
      resetState: resetState,
      tier: tier,
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const userContext = React.useContext(UserContext);
  if (userContext === undefined) {
    throw new Error('useUserContext must be inside a UserProvider');
  }
  return userContext;
};
