import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray, Field, useFormikContext } from 'formik';
import {
  useToken,
  Input,
  InputGroup,
  Stack,
  Button,
  Icon,
  Text,
  useStyleConfig,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useColorModeValue,
  Divider,
  Box,
  Flex,
  SimpleGrid,
  Code,
  Image,
  Center,
} from "@chakra-ui/react";
import { Name, Widget, SenderShopify, ReceiverShopify, SenderStatic, ReceiverStatic, SenderIncentive, ReceiverIncentive, OrgInfo, Template, Email, SMS, Finish } from './createModal/';
import SaveOrDiscardModal from "./SaveOrDiscardModal";
import defaultCampaign from "../variables/defaultCampaign"
import defaultCampaignErrors from "../variables/defaultCampaignErrors"
import defaultCampaignTouched from "../variables/defaultCampaignTouched"
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import TemplateModal from "components/templateModal/TemplateModal";
import SenderSelect from "./createModal/SenderSelect";
import ReceiverSelect from "./createModal/ReceiverSelect";

// const FormikValueObserver = ({ onChange }) => {
//   const { values, initialValues } = useFormikContext()
//   useEffect(() => onChange({values, initialValues}), [values, initialValues, onChange])
//   return null
// }

function CreateModal(props) {
  const whiteBg = useColorModeValue( "brand.100", "brand.100");
  const modalBg = useColorModeValue( "brand.200", "brand.200");
  const continueButtonBg = useColorModeValue("brand.300", "brand.300");
  const previewButtonBg = useColorModeValue("brand.800", "brand.800");

  const { isNew, user, activeTenant, team, widgets, discounts, save } = { ...props };
  const { isOpen, onOpen, onClose } = props.disclosure;

  const fillDefaults = (input) => {
    return {
      ...input,
      'url': team.team_url,
      'organization': team.team_name,
  } }
  const transformCampaign = (campaign) => {
    // console.log(campaign)
    var out = {
      name: campaign.config.campaign_name,
      widget: campaign.config.widget,
      url: campaign.config.sender_message.url,
      organization: campaign.config.sender_message.from_name,
      html: campaign.config.sender_message.html,

      sender: {
        incentive: campaign.config.receiver_message?.incentive,
        discount: { 'static': {} },
        subject: campaign.config.sender_message.subject,
        tags: {
          header: campaign.config.sender_message.replacement_tags.header,
          body: campaign.config.sender_message.replacement_tags.main,
          footer: campaign.config.sender_message.replacement_tags.footer
        },
        sms: campaign.config.sender_message.plain_text
      },
      receiver: {
        incentive: campaign.config.receiver_message?.incentive,
        discount: { 'static': {} },
        subject: campaign.config.receiver_message.subject,
        tags: {
          header: campaign.config.receiver_message.replacement_tags.header,
          body: campaign.config.receiver_message.replacement_tags.main,
          footer: campaign.config.receiver_message.replacement_tags.footer
        },
        sms: campaign.config.receiver_message.plain_text
      }
    }
    if (campaign.config.sender_message.discount.type) {
      out.sender.discount[campaign.config.sender_message.discount.type] = campaign.config.sender_message.discount;
      // setSenderRewardType(campaign.config.sender_message.discount.type)
    }
    if (campaign.config.receiver_message.discount.type) {
      out.receiver.discount[campaign.config.receiver_message.discount.type] = campaign.config.receiver_message.discount;
      // setReceiverRewardType(campaign.config.receiver_message.discount.type)
    }
    return out;
  }
  const campaign = isNew ? fillDefaults(defaultCampaign) : transformCampaign(props.campaign);
  const [campaignId, setCampaignId] = useState(isNew ? uuidv4() : props.campaign?.campaign_id);

  const [senderRewardType, setSenderRewardType] = useState(props.campaign?.config?.sender_message?.discount?.type ?? '');
  const [receiverRewardType, setReceiverRewardType] = useState(props.campaign?.config?.receiver_message?.discount?.type ?? '');
  const senderRewardStep = 'Sender' + senderRewardType.charAt(0).toUpperCase() + senderRewardType.slice(1)
  const receiverRewardStep = 'Receiver' + receiverRewardType.charAt(0).toUpperCase() + receiverRewardType.slice(1)

  const alert = useDisclosure();
  const previewDisclosure = useDisclosure();

  const [isDirty, setIsDirty] = useState(false);
  const [currentStep, setCurrentStep] = useState('Name');

  const handleClose = () => {
    if (currentStep != 'Finish' && isDirty) {
      alert.onOpen();
    }
    else { onClose(); }
  };

  const validator = (schema) => {
    return async (value) => {
      let error = undefined;
      try { await schema.validate(value); }
      catch (err) {
        error = err.errors[0];
        // console.log("error:", err);
      }
      return error;
    }
  }

  const shopifyRewardShape = yup.object().shape({
    type: yup.string().nonNullable().required(),
    name: yup.string().nonNullable().required(),
    'shop_url': yup.string().matches('[a-zA-Z0-9-._~]*\.myshopify\.com').required(),
    'shopify_discount_id': yup.string().required(),
  }).required()

  const stepsFields = {
   'Name': {
      label: 'Name',
      fields: [ {
        field: 'name',
        validate: validator(yup.string().max(48).required())
      } ],
    },
   'Widget': {
      label: 'Widget',
      fields: [ {
        field: 'widget',
        validate: validator(yup.object().shape({
          id: yup.string().uuid().required(),
          name: yup.string().max(48).required()
        }).required())
      } ],
    },
   'SenderSelect': {},
   'SenderShopify': {
      label: 'Sender Shopify Reward',
      fields: [ {
        field: 'sender.discount.shopify',
        validate: validator(shopifyRewardShape)
      } ],
    },
   'SenderStatic': {
      label: 'Sender Static Reward',
      fields: [ {
        field: 'sender.discount.static.name',
        validate: validator(yup.string().trim().required())
      }, {
        field: 'sender.discount.static.code',
        validate: validator(yup.string().trim().required())
      } ],
    },
   'SenderIncentive':
    {
      label: 'Sender Incentive',
      fields: [ {
        field: 'sender.incentive',
        validate: validator(yup.string().trim().required())
      } ],
    },
   'ReceiverSelect': {},
   'ReceiverShopify': {
      label: 'Receiver Shpoify Reward',
      fields: [ {
        field: 'receiver.discount.shopify',
        validate: validator(shopifyRewardShape)
      } ],
    },
   'ReceiverStatic': {
      label: 'Receiver Static Reward',
      fields: [ {
        field: 'receiver.discount.static.name',
        validate: validator(yup.string().trim().required())
      }, {
        field: 'receiver.discount.static.code',
        validate: validator(yup.string().trim().required())
      } ],
    },
   'ReceiverIncentive': {
      label: 'Receiver Incentive',
      fields: [ {
        field: 'receiver.incentive',
        validate: validator(yup.string().trim().required())
      } ],
    },
   'OrgInfo': {
      label: 'Organization Info',
      fields: [ {
        field: 'url',
        validate: validator(
          yup.string()
            // .matches('^https://.*', "Must start with https://")
            .required())
      }, {
        field: 'organization',
        validate: validator(yup.string())
      } ],
    },
   'Email': {
      label: 'Email',
      fields: [ {
      //   field: 'sender.from_name',
      //   validate: validator(yup.string().required("This is required"))
      // }, {
      //   field: 'sender.from_email',
      //   validate: validator(yup.string().required("This is required"))
      // }, {
        field: 'sender.subject',
        validate: validator(yup.string().required("This is required"))
      }, {
        field: 'sender.tags.header',
        validate: validator(yup.string().required("This is required"))
      }, {
        field: 'sender.tags.body',
        validate: validator(yup.string().required("This is required"))
      }, {
      //   field: 'sender.replacement_tags.button',
      //   validate: validator(yup.string().required("This is required"))
      // }, {
        field: 'sender.tags.footer',
        validate: validator(yup.string().required("This is required"))
      }, {
      //   field: 'receiver.from_name',
      //   validate: validator(yup.string().required("This is required"))
      // }, {
      //   field: 'receiver.from_email',
      //   validate: validator(yup.string().required("This is required"))
      // }, {
        field: 'receiver.subject',
        validate: validator(yup.string().required("This is required"))
      }, {
        field: 'receiver.tags.header',
        validate: validator(yup.string().required("This is required"))
      }, {
        field: 'receiver.tags.body',
        validate: validator(yup.string().required("This is required"))
      // }, {
      //   field: 'receiver.tags.button',
      //   validate: validator(yup.string().required("This is required"))
      }, {
        field: 'receiver.tags.footer',
        validate: validator(yup.string().required("This is required"))
      } ],
    },
   'Template': {
      label: 'Template',
      fields: [ {
        field: 'html',
        validate: validator(yup.string().required("This is required"))
      } ],
    },
   'SMS': {
      label: 'SMS',
    fields: [ {
      //   field: 'config.sender_message.from_phone',
      //   validate: validator(yup.string().required("This is required"))
      // }, {
      field: 'sender.sms',
      validate: validator(yup.string().required("This is required"))
      // }, {
      //   field: 'config.receiver_message.from_phone',
      //   validate: validator(yup.string().required("This is required"))
    }, {
        field: 'receiver.sms',
        validate: validator(yup.string().required("This is required"))
      } ],
    },
   'Finish': {
      label: 'Finish',
      fields: [ ],
    },
 };


  // console.log(currentStep)
  const steps = {
    'Name':              <Name              inputs={stepsFields['Name'             ]}                                                                       />,
    'Widget':            <Widget            inputs={stepsFields['Widget'           ]} widgets={widgets}                                                     />,
    'SenderSelect':      <SenderSelect      inputs={stepsFields['SenderSelect'     ]} rewardState={[senderRewardType, setSenderRewardType]} setCurrentStep={setCurrentStep}   />,
    'SenderShopify':     <SenderShopify     inputs={stepsFields['SenderShopify'    ]} discounts={discounts} setCurrentStep={setCurrentStep}                 />,
    'SenderStatic':      <SenderStatic      inputs={stepsFields['SenderStatic'     ]}                                                                       />,
    'SenderIncentive':   <SenderIncentive   inputs={stepsFields['SenderIncentive'  ]}                                                                       />,
    'ReceiverSelect':    <ReceiverSelect    inputs={stepsFields['ReceiverSelect'   ]} rewardState={[receiverRewardType, setReceiverRewardType]} setCurrentStep={setCurrentStep}/>,
    'ReceiverShopify':   <ReceiverShopify   inputs={stepsFields['ReceiverShopify'  ]} discounts={discounts} setCurrentStep={setCurrentStep}                 />,
    'ReceiverStatic':    <ReceiverStatic    inputs={stepsFields['ReceiverStatic'   ]}                                                                       />,
    'ReceiverIncentive': <ReceiverIncentive inputs={stepsFields['ReceiverIncentive']}                                                                       />,
    'OrgInfo':           <OrgInfo           inputs={stepsFields['OrgInfo'          ]}                                                                       />,
    'Email':             <Email             inputs={stepsFields['Email'            ]} setCurrentStep={setCurrentStep}                                       />,
    'Template':          <Template          inputs={stepsFields['Template'         ]}                                                                       />,
    'SMS':               <SMS               inputs={stepsFields['SMS'              ]}                                                                       />,
    'Finish':            <Finish            inputs={stepsFields['Finish'           ]} tenantId={activeTenant} campaignId={campaignId}/>,
  };


  const order = {
   'Name':              { prev: null,                next: 'Widget'            },
   'Widget':            { prev: 'Name',              next: 'SenderSelect'      },
   'SenderSelect':      { prev: 'Widget',            next: null                },
   'SenderShopify':     { prev: 'SenderSelect',      next: 'SenderIncentive'   },
   'SenderStatic':      { prev: 'SenderSelect',      next: 'SenderIncentive'   },
   'SenderIncentive':   { prev: senderRewardStep,    next: 'ReceiverSelect'    },
   'ReceiverSelect':    { prev: 'SenderIncentive',   next: null                },
   'ReceiverShopify':   { prev: 'ReceiverSelect',    next: 'ReceiverIncentive' },
   'ReceiverStatic':    { prev: 'ReceiverSelect',    next: 'ReceiverIncentive' },
   'ReceiverIncentive': { prev: receiverRewardStep,  next: 'OrgInfo'           },
   'OrgInfo':           { prev: 'ReceiverIncentive', next: 'Email'             },
   'Email':             { prev: 'OrgInfo',           next: 'SMS'               },
   'Template':          { prev: 'Email',             next: 'SMS'               },
   'SMS':               { prev: 'Email',             next: 'Finish'            },
   'Finish':            { prev: null,                next: null                },
  };

  // const order = [
  //   { prev: null, next: 1 }, // 0 name
  //   { prev: 0, next: 2 },    // 1 widget
  //   { prev: 1, next: 4 },    // 2 sender shopify
  //   { prev: 2, next: 4 },    // 3 sender static
  //   { prev: 2, next: 5 },    // 4 sender incentive
  //   { prev: 4, next: 7 },    // 5 receiver shopify
  //   { prev: 5, next: 7 },    // 6 receiver static
  //   { prev: 5, next: 8 },    // 7 receiver incentive
  //   { prev: 7, next: 9 },    // 8 org info
  //   { prev: 8, next: 11 },   // 9 email
  //   { prev: 9, next: 11 },   // 10 template
  //   { prev: 9, next: 12 },   // 11 sms
  //   { prev: null, next: null },
  // ];

  const stepHasErrors = (errors) => {
    return !!stepsFields[currentStep].fields.some(field => _.get(errors, field.field))
  }

  const saveCampaign = (values) => {
    if (senderRewardType === 'static') {
      values.sender.discount[senderRewardType].type = 'static'
    }
    if (receiverRewardType === 'static') {
      values.receiver.discount[receiverRewardType].type = 'static'
    }
    const out = {
      "campaign_name": values.name || campaign.name,
      "widget": values.widget || campaign.widget,

      "sender_message": {
        "incentive": values.sender.incentive || campaign.sender.incentive,
        "discount": values.sender.discount[senderRewardType] || campaign.sender.discount.shopify || campaign.sender.discount.static,
        "url": values.url || campaign.url,

        "from_phone": "+18315808073",
        "plain_text": values.sender.sms || campaign.sender.sms,

        "from_name": values.organization || campaign.organization,
        "from_email": "rewards@prosperreferrals.com",
        "subject": values.sender.subject || campaign.sender.subject,
        "html": values.html || campaign.html,
        "replacement_tags": {
          "header": values.sender.tags.header || campaign.sender.tags.header,
          "main": values.sender.tags.body || campaign.sender.tags.body,
          "button": "[code]",
          "footer": values.sender.tags.footer || campaign.sender.tags.footer
        }
      },

      "receiver_message": {
        "incentive": values.receiver.incentive || campaign.receiver.incentive,
        "discount": values.receiver.discount[receiverRewardType] || campaign.receiver.discount.shopify || campaign.receiver.discount.static,
        "url": values.url || campaign.url,

        "from_phone": "+18315808073",
        "plain_text": values.receiver.sms || campaign.receiver.sms,

        "from_name": values.organization || campaign.organization,
        "from_email": "rewards@prosperreferrals.com",
        "subject": values.receiver.subject || campaign.receiver.subject,
        "html": values.html || campaign.html,
        "replacement_tags": {
          "header": values.receiver.tags.header || campaign.receiver.tags.header,
          "main": values.receiver.tags.body || campaign.receiver.tags.body,
          "button": "[code]",
          "footer": values.receiver.tags.footer || campaign.receiver.tags.footer
        }
      },
    };
    // console.log('Sender', senderRewardType, values.sender.discount)
    // console.log('Receiver', receiverRewardType, values.receiver.discount)
    // console.log(out)
    save(out, campaignId);
  }

  const handleContinue = () => {
    // if (currentStep === 'SenderShopify') {setSenderRewardType('shopify')}
    // else if (currentStep === 'SenderStatic') {setSenderRewardType('static')}
    // else if (currentStep === 'ReceiverShopify') {setReceiverRewardType('shopify')}
    // else if (currentStep === 'ReceiverStatic') {setReceiverRewardType('static')}
    if (currentStep === 'SenderSelect') {
      setCurrentStep(senderRewardStep)
    } else if (currentStep === 'ReceiverSelect') {
      setCurrentStep(receiverRewardStep)
    } else {
      setCurrentStep(order[currentStep].next)
    }
  }
  const handleSubmit = (values) => {
    // console.log(values);
  };

  return (
    <>
      {props.children}
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay bg="rgba(0,0,0,0.8)" />
        <ModalContent
          bg={modalBg}
          width="540px"
          height="auto"
          maxWidth="100%"
          margin="auto"
        >
          <ModalCloseButton size="md" _focus='none'/>

          <ModalHeader>
            <Text>{isNew ? 'Create' : 'Edit'} Campaign</Text>
          </ModalHeader>
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={campaign}
            // initialErrors={campaignErrors}
            onSubmit={handleSubmit}>
            {({ values, handleSubmit, errors }) => {
              setIsDirty( !_.isEqual(values, campaign));

              return (
                <>
                  <ModalBody margin="auto" display={"flex"} alignItems={"center"}>
                    <Form onSubmit={handleSubmit}>
                      {steps[currentStep]}
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Stack
                      alignSelf="stretch"
                      direction="row"
                      justify="flex-end"
                      align="center"
                      spacing="12px"
                    >
                      {order[currentStep].prev !== null && (
                        <Button
                          onClick={() => {setCurrentStep(order[currentStep].prev)}} border='1px solid #C9CED2' >
                          Back
                        </Button>
                      )}

                      {currentStep === 'Email' && (
                        <>
                          <Button
                            bg={previewButtonBg}
                            onClick={previewDisclosure.onOpen}
                          >
                            Preview
                          </Button>
                          <TemplateModal
                            emailOnly={true}
                            disclosure={previewDisclosure}
                            values={values}
                            team={team}
                            editing={true}
                          />
                        </>

                      )}

                      { currentStep === 'SMS' && (
                        <Button disabled={!isDirty} onClick={() => {saveCampaign(values);setCurrentStep(order[currentStep].next)}} bg={continueButtonBg}>
                          Save Campaign
                        </Button>
                      )}
                      { currentStep === 'Finish' && (
                        <Button onClick={() => {setCurrentStep('Name');onClose()}} border='1px solid #adadad' >
                          Done
                        </Button>
                      )}
                      { !['SMS', 'Finish'].includes(currentStep) && (
                        <Button onClick={handleContinue} bg={continueButtonBg}
                          disabled={
                            (currentStep === 'SenderSelect' && !senderRewardType) ||
                            (currentStep === 'ReceiverSelect' && !receiverRewardType) ||
                            (!['SenderSelect', 'ReceiverSelect'].includes(currentStep) && stepHasErrors(errors))
                          }
                        >
                          Continue
                        </Button>
                      ) }
                      <SaveOrDiscardModal isNew={isNew} setCurrentStep={setCurrentStep} isOpen={alert.isOpen} onOpen={alert.onOpen} onClose={alert.onClose} onDiscard={onClose} onSave={()=>{saveCampaign(values)}}/>
                    </Stack>
                  </ModalFooter>
                </>
            )}}
          </Formik>

        </ModalContent>
      </Modal>
    </>
  );
}

export default CreateModal;
