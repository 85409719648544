// SaveOrDiscardModal.js

import React from 'react';
import { Button,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const ShopifyModal = ({ isOpen, onOpen, onClose, onSave, amount, shopUrl, handleSubscribe }) => {
  const backRef = React.useRef();
  const navigate = useNavigate();

  return (
    <AlertDialog
      isOpen={isOpen} onClose={onClose}
      closeOnOverlayClick={true} closeOnEsc={true}
      leastDestructiveRef={backRef}
      isCentered={true}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Accept Charges
          </AlertDialogHeader>

          <AlertDialogBody>
            By clicking Purchase, you are authorizing Prosper Technology Solutions Inc. to charge you the sum of <Text display='inline' fontWeight='bold'>{amount}</Text> directly to your Shopify account at <Text display='inline' fontWeight='bold'>{shopUrl}</Text>.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant='white' ref={backRef} onClick={onClose}>
              Back
            </Button>
            <Button bg='brand.300' ml={3}
              onClick={()=>{
                handleSubscribe();
                onClose();
              }}
            >
              Purchase
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ShopifyModal;
