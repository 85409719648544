/*eslint-disable*/
import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  Button,
  useColorMode,
  useColorModeValue,
  chakra
} from "@chakra-ui/react";
import TermsModal from "../termsModal/TermsModal";

export default function Footer() {
  const textColor = useColorModeValue("#DDDCD9", "brand.100");
  const { toggleColorMode } = useColorMode();
  return (
    <Flex
      zIndex='3'
      // flexDirection={{
      //   xxs: "column",
      //   xl: "row",
      // }}
      // alignItems={{
      //   xxs: "center",
      //   xl: "start",
      // }}
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      px={{ base: "30px", md: "50px" }}
      pb='30px'>
      <Text
        color={textColor}
        // textAlign={{
        //   xxs: "center",
        //   xl: "start",
        // }}
        fontSize='xx-small'
        mb={{ base: "20px", xl: "0px" }}>
        {" "}
        &copy; {1900 + new Date().getYear()}
        <chakra.span as='span' fontWeight='500' ms='4px'>
          Prosper. All Rights Reserved.
        </chakra.span>
      </Text>
      {/*
      <List display='flex'>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={textColor}
            href='mailto:dylan@getprosper.io'>
            Support
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
            <TermsModal textColor={textColor} fontWeight='medium'/>
        </ListItem>
        <ListItem>
          <Link
            fontWeight='500'
            color={textColor}
            href='https://getprosper.io/blog'>
            Blog
          </Link>
        </ListItem>
      </List>
      */}
    </Flex>
  );
}
