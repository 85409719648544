import React, { useEffect, useState, useRef } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  Flex,
  Button,
  Input,
  Text,
  Table,
  Td,
  Tr,
  Th,
  Tbody,
  Select,
  Spacer,
  Thead,
  Icon,
  Tag,
  TableCaption,
  Alert,
  AlertIcon,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useClipboard,
  useDisclosure,
  useBreakpoint,
} from '@chakra-ui/react'
import Card from 'components/card/Card'
import { AiFillCopy } from "react-icons/ai";
import RemoveModal from "./RemoveModal.js";
import axios from 'axios'
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext.js';

function CustomDomains(props){
  const [domains, setDomains] = useState([])
  const [newDomain, setNewDomain] = useState('')
  const [selectedDomain, setSelectedDomain] = useState({})
  const [selectedDomainName, setSelectedDomainName] = useState('')
  const [verifyFailure, setVerifyFailure] = useState(false)
  const [verifySuccess, setVerifySuccess] = useState(false)
  const [update, setUpdate] = useState(0)

  const navigate = useNavigate();

  const tagVariantByStatus = {
    'verified': 'success',
    'not_started': 'neutral',
    'pending': 'warning',
    'failed': 'failure',
    'example': null
  }

  const { activeTenantState } = useUserContext();
  const [activeTenant, setActiveTenant] = activeTenantState


  const handleDomain = (action, params, setLoading) => {
    if (setLoading) { setLoading(true) }
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        const request = {
          tenant_id: activeTenant,
          action: action,
          params: params,
        }
        return axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/domains',
          request,
          {
            headers: { "Authorization": jwtToken },
          }).then(response => {
            // console.log(action, response)

            if (action === 'add') { setUpdate(update + 1) }
            else if (action === 'remove') { setUpdate(update + 1) }
            else if (action === 'verify') { setUpdate(update + 1) }
            else if (action === 'get') {  }
            else if (action === 'getAll') { setDomains(response.data) }
            else if (action === 'list') { }
          })
          .catch(error => {
            console.error(error);
          })
          .finally(()=>{
            if (setLoading) { setLoading(false) }
          })
        })
        .catch(error => {
          console.error(error);
      });
  }

  const [domainsLoading, setDomainsLoading] = useState(false)
  useEffect(()=>{
    handleDomain('getAll', {}, setDomainsLoading)
  },[update,activeTenant])
  useEffect(()=>{
    setSelectedDomain(domains[0])
    setSelectedDomainName(domains[0]?.name)
  },[domains])


  const [verifyLoading, setVerifyLoading] = useState(false)
  const handleVerify = () => {
    if (selectedDomain.status == 'not_started') {
      handleDomain('verify', {domain_id: selectedDomain.id}, setVerifyLoading)
    } else {
      setUpdate(update+1)
    }
  }

  const [addLoading, setAddLoading] = useState(false)
  const handleAdd = () => {
    if (newDomain === '' || !domainValid) { return }
    handleDomain('add', {domain: newDomain}, setAddLoading)
  }

  const [removeLoading, setRemoveLoading] = useState(false)
  const handleRemove = (id) => {
    handleDomain('remove', {domain_id: id}, setRemoveLoading)
  }

  const DomainRow = (props) => {
    const disclosure = useDisclosure();
    const status = props.status
      .replace("_", " ")
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.substr(1))
      .join(" ");
    return (
      <Tr>
        <Td>{props.name}</Td>
        <Td>
          <Tag variant={tagVariantByStatus[props.status]} >
            {status}
          </Tag>
        </Td>
        <Td>
          <Button onClick={disclosure.onOpen} variant="red">
            Remove
          </Button>
          <RemoveModal remove={()=>{handleRemove(props.id)}} {...disclosure} domain={newDomain}/>
        </Td>
      </Tr>
    )
  }


  const breakpoint = useBreakpoint();
  const RecordRows = (props) => {
    // console.log(props)
    const name = useClipboard(props.name);
    const value = useClipboard(props.value);
    const status = props.status
      .replace("_", " ")
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.substr(1))
      .join(" ");
    return (
      <>
        <Tr>
          <Td px={{xxs: "1rem", lg: "1.5rem"}}>
            {props.type}
          </Td> <Td isTruncated maxW='15vw' px={{xxs: "1rem", lg: "1.5rem"}}>
            <Icon cursor='pointer' as={AiFillCopy} onClick={name.onCopy} mr="10px" />
            {props.name}
          </Td> <Td isTruncated maxW='20vw' px={{xxs: "1rem", lg: "1.5rem"}}>
            <Icon cursor='pointer' as={AiFillCopy} onClick={value.onCopy} mr="10px" />
            {props.value}
          </Td> <Td isTruncated maxW='5vw'>
            {props.priority}
          </Td> <Td isTruncated maxW='8vw' px={{xxs: "1rem", lg: "1.5rem"}}>
            {props.ttl}
          </Td> <Td>
            <Tag variant={tagVariantByStatus[props.status]} >
              {status}
            </Tag>
          </Td>
        </Tr>
      </>
    )
  }

  const [domainValid, setDomainValid] = useState(true)
  const validateDomain = (d) => {
    var pattern = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z]{2,})$|^$/;
    setDomainValid(pattern.test(d));
  }
  useEffect(()=>{
    validateDomain(newDomain)
  },[newDomain])

  // console.log(selectedDomain)
  return (
    <Accordion
      border="1px solid #eee"
      allowToggle
      bg="white"
      borderRadius="20px"
      boxShadow='lg'
      {...props}
    >
      <AccordionItem border="none" ml="10px">
        <AccordionButton _focus={"none"} _hover={"none"}>
          <Box as="span" flex="1" textAlign="left">
            <Heading size="md" color="brand.600" mt="10px" mb="10px">
              Custom Domain Setup
            </Heading>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={5}>
          <Card border="1px solid #ccc" bg='white' mb="20px">
            <Flex flexDir={{ xxs: 'column', lg: 'row' }}>
              <Box mr='24px' mb='24px'>
                <Box>
                  <Heading size="sm" mb="10px">
                    Domain Setup
                  </Heading>
                  <Text mt="10px">
                    Add a new domain you wish to start sending referrals from
                  </Text>
                  <Text mb="10px" fontSize='sm' color='black.400'>
                    * Please note, a domain can only be owned by one organization.
                  </Text>
                  <Flex>
                    <Input value={newDomain}
                      isInvalid={!domainValid}
                      onKeyDown={(event) => {if (event.key === 'Enter') {handleAdd()}}}
                      onChange={(e)=>{setNewDomain(e.target.value)}} placeholder="www.getprosper.io"></Input>
                    <Button isLoading={addLoading} onClick={handleAdd} minW="70px" variant="brand" ml="10px">
                      Add
                    </Button>
                  </Flex>
                </Box>
                {/*
                <Box>
                  <Heading size="sm" mt="20px" mb="10px">
                    Sender Address Setup
                  </Heading>
                  <Text mt="10px" mb="10px">
                    Add a sender address you wish to start sending referrals
                    from
                  </Text>
                  <Flex>
                    <Input maxW="200px" placeholder="rewards"></Input>
                    <Select ml="10px" placeholder="@getprosper.io"></Select>
                    <Button minW="70px" variant="brand" ml="10px">
                      Add
                    </Button>
                  </Flex>
                </Box>
                */}
              </Box>
              <Spacer/>
              <Box>
                <Table variant="simple" mb="20px">
                  <Thead><Tr><Th>Domain</Th><Th>Status</Th><Th>Actions</Th></Tr></Thead>
                  <Tbody>
                    { domains.map((domain, index)=>{ return (
                      <DomainRow key={index} {...domain}/>
                    )})}
                  </Tbody>
                </Table>
              </Box>
            </Flex>
          </Card>
          <Card border="1px solid #ccc" bg='white'>
            <Box display="flex" alignItems="end" mb="20px">
              <Heading size="sm" mb="10px">
                DNS Setup
              </Heading>
              <Spacer />
              <Select maxW='200px' mr="10px"
                value={selectedDomainName}
                onChange={(e)=>{
                  setSelectedDomainName(e.target.value)
                  setSelectedDomain(domains.find((obj)=> obj.name === e.target.value))
                }}
              >
                { domains.map((d, i)=>{
                  return ( <option key={i} value={d.name}>{d.name}</option> )
                }) }
              </Select>
              <Button variant="brand"
                disabled={selectedDomain?.status === 'verified'}
                isLoading={verifyLoading}
                onClick={handleVerify}
              >
                Verify Records
              </Button>
            </Box>
            { verifySuccess && (
              <Alert status="warning" border="1px solid #ccc" mb="10px">
                <AlertIcon />
                Access the DNS settings page of the DNS provider and add all the
                following DNS records to the records section. Once all are
                added, click the ”Verify Records” button above.
              </Alert>
            ) }
            { verifyFailure && (
              <Alert status="success" border="1px solid #ccc" mb="10px">
                <AlertIcon />
                Well done! All the DNS records are verified. You are ready to
                start sending emails with this domain.
              </Alert>
            ) }
            <Box overflowX='scroll'
            >
              <Table mb="20px">
                <Thead><Tr><Th>Type</Th><Th>Hostname</Th><Th>Value</Th><Th>Priority</Th><Th>TTL</Th><Th>Status</Th></Tr></Thead>
                <Tbody>
                  { selectedDomain?.records?.map((record, index)=>{ return (
                    <RecordRows key={index} {...record}/>
                  )})}
                </Tbody>
                <TableCaption >
                  <Text cursor='pointer' onClick={()=>{navigate('/admin/home')}}>
                    Need help setting up? View documentation here
                  </Text>
                </TableCaption>
              </Table>
            </Box>
          </Card>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default CustomDomains
