// Chakra imports
import {
  Box,
  SimpleGrid,
  Switch,
  Text,
  Flex,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import React, {useState, useEffect} from 'react';
import Card from "components/card/Card.js";
import { globalStyles } from "theme/styles"
import { NavLink, useNavigate, useOutletContext } from "react-router-dom";
import { Hub } from 'aws-amplify'

export default function Pricing() {
  // Chakra Color Mode

  // const [user, updateUser] = useOutletContext();
  // useEffect(() => {
  //   Hub.dispatch('auth', {});
  // }, []);
  // setTimeout(() => {
  //   Hub.dispatch(
  //       'CatsChannel',
  //       {
  //             event: 'drinkMilk',
  //             data: {
  //                 breed: 'Persian',
  //                 age: 5
  //             },
  //             message: `The cat ${cat.name} has finished her milk`
  //         });
  // }, 5000)

  const mutedColor = useColorModeValue("#585858", "#999999");

  const gradient = `linear-gradient(to bottom, ${useColorModeValue(
    // globalStyles.colors.secondaryGray[200],
    "#FFFFFF",
    globalStyles.colors.secondaryGray[800]
  )}, ${useColorModeValue(
    globalStyles.colors.secondaryGray[200],
    globalStyles.colors.brand[600]
  )})`;

  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(5);
  const [intervalId, setIntervalId] = useState(null);
  const [cancelled, setCancelled] = useState(false);

  useEffect(() => {
    if (cancelled) { return }
    const id = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);
    setIntervalId(id);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      navigate('/admin/home');
    }
  }, [timeLeft, navigate]);

  function cancelRedirect() {
    clearInterval(intervalId);
    setCancelled(true);
  }

  return (
    <Flex bg={gradient} h='calc(100vh - 48px)' w='100%' flexDirection='column' justifyContent='center' alignItems='center'>
      <Card w='min-content' py='24px' px='96px' alignItems='center'>
        <Text fontSize='9xl'>Success!</Text>
        { !cancelled ? <Text>Returning to dashboard in {timeLeft} seconds.</Text> : null }
        {/*
        <NavLink to='/admin/home'>
          <Text
            as='span'
            ms='5px'
            fontWeight='500'
            // onClick={}
          >
            Return { cancelled ? "to Dashboard" : "Now" }
          </Text>
        </NavLink>
        { !cancelled ? <Text onClick={cancelRedirect}>Cancel</Text> : null }
        */}
      </Card>
    </Flex>
  );
}
