// Chakra imports
import { Portal, Box, useDisclosure, Link, BreadcrumbItem, Breadcrumb, BreadcrumbLink, useColorModeValue, Image, Center, Flex, useToken } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
// Layout components
import Navbar from "components/navbar/NavbarAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState, useRef, useEffect } from "react";
import { Outlet, useLocation, matchRoutes } from "react-router-dom";
import routes from "routes.js";
import { renderThumb, renderTrack, renderView, } from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import { bg } from "date-fns/locale";
import { UserProvider, useUserContext } from "contexts/UserContext";
import axios from 'axios'
import { Auth } from "aws-amplify";


// Custom Chakra theme
export default function Dashboard(props) {

  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const adminRoutes = routes.find(route => route.name === "Admin").children;
  const location = useLocation();

  const secondaryTextColor = useColorModeValue('brand.700', 'brand.700');
  const mainTextColor = useColorModeValue('brand.600', 'brand.600');
  const bgColor = useColorModeValue('brand.200', 'brand.200');

  // functions for changing the states from components
  const getActiveRoute = () => {
    return matchRoutes(adminRoutes, location)[0].route;
  };

  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();

  const { activeTenantState, updateActiveTenant, tenantsState, updateTenants } = useUserContext();
  const [activeTenant, setActiveTenant] = activeTenantState
  const [tenants, setTenants] = tenantsState

  useEffect(()=>{
    if (!tenants) { updateTenants() }
  },[])

  useEffect(()=>{
    if (tenants && !activeTenant) { updateActiveTenant(); }
  },[tenants])

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}>
        <Sidebar routes={adminRoutes} {...rest} />
        <Box
          bg={useColorModeValue('#FDFCFB', '#181A1B')}
          boxShadow='2xl'
          // bgGradient='linear(to-tl, brand.700 -200%, brand.100 0%)'
          //style={{ bgImage:`url(${backgroundImage})`}}
          pt={{ xxs: '80px', xs: '0'  }}
          float='right'
          minHeight='100vh'
          position='relative'
          h='auto'
          w={{ base: "100%", xl: "calc( 100% - 240px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 240px )" }}
          // transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          // transitionDuration='.2s, .2s, .35s'
          // transitionProperty='top, bottom, width'
          // transitionTimingFunction='linear, linear, ease'
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"Horizon UI Dashboard PRO"}
                /*
                brandText={getActiveRoute(adminRoutes)}
                secondary={getActiveNavbar(adminRoutes)}
                message={getActiveNavbarText(adminRoutes)}
                */
                brandText={getActiveRoute().name}
                category='Admin'
                secondary={null}
                // message={""}
                setWidth={false}
                fixed={fixed}
                routes={adminRoutes}
                user={props.user}
                signOut={props.signOut}
                {...rest}
              />
            </Box>
          </Portal>

          <Box
            mx='12px'
            my='12px'
            px='24px'
            py='4px'
            borderRadius='18px'
            >
            <Breadcrumb>
              <BreadcrumbItem color={secondaryTextColor} fontSize='sm' mb='5px'>
                <BreadcrumbLink href='#' color={secondaryTextColor}>
                  {"Admin"}
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem color={secondaryTextColor} fontSize='sm'>
                <BreadcrumbLink href='#' color={secondaryTextColor}>
                  {getActiveRoute().name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Link
              color={mainTextColor}
              href='#'
              bg='inherit'
              borderRadius='inherit'
              fontWeight='bold'
              fontSize='34px'
              _hover={{ color: { mainTextColor } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}>
              {getActiveRoute().name}
            </Link>
          </Box>

          <Box
            minH='calc(100vh - 174px)'
            mx='auto'
            p={{ base: "20px", md: "30px" }}
            pt='0px'>

            <Outlet context={[ props.user, props.loading, props.updateUser ]} />
          </Box>
          <Footer />
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
