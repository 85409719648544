import React, { useState, useEffect, useRef } from "react";
import { NavLink, Navigate, useNavigate, useLocation } from "react-router-dom";
// Chakra imports
import {
  Center,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Image,
  useColorModeValue,
  Alert,
  Badge,
  AlertTitle,
  Tooltip,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  AlertDescription,
  useDisclosure,
  Spacer,
  Divider,
  SimpleGrid,
  useBreakpointValue,
  Avatar,
  Stack,
  Spinner,
} from "@chakra-ui/react";
import { ChevronRightIcon, AddIcon, InfoIcon } from "@chakra-ui/icons";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import axios from "axios";
import { MdExitToApp, MdOutlineRemoveRedEye, MdSettings, MdTimeToLeave } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import { Auth } from "aws-amplify";
import { useAuthContext } from "contexts/AuthContext";
import { useUserContext } from "contexts/UserContext";
import Card from "components/card/Card";
import OrganizationModal from "./OrganizationModal";
import { maxOrganizationsByTier } from "variables/tiers";
import ShopifyLogo from "assets/img/logos/ShopifyLogo";
import DeleteModal from "./DeleteModal";
import OrganizationAvatar from "assets/img/svgs/OrganizationAvatar";

function Organizations() {
  // Chakra color mode
  const textColor = useColorModeValue("brand.600", "brand.600");
  const textColorSecondary = "brand.600";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);

  const { tier, userState, updateUser, activeTenantState, tenantsState, updateTenants } = useUserContext();
  const [user, setUser] = userState;
  const [tenants, setTenants] = tenantsState;
  const [activeTenant, setActiveTenant] = activeTenantState;
  const [ownedTenants, setOwnedTenants] = useState()

  useEffect(()=>{
    if (!tenants || !user) { return }
    setOwnedTenants(tenants.filter((tenant)=>{return tenant.owner_id === user.attributes.sub}).map((tenant)=> tenant.tenant_id) ?? [])
  },[tenants, user])

  useEffect(() => {
    updateUser()
    updateTenants()
  }, [])

  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteDisclosure = useDisclosure();
  const navigate = useNavigate();
  const createDisclosure = useDisclosure();
  const createShopifyDisclosure = useDisclosure();
  const accountTier = user?.attributes?.['custom:tier']

  const {token, setToken} = useAuthContext();
  const [shopUrl, setShopUrl] = useState('');
  const [shopId, setShopId] = useState('');
  const [existingTenant, setExistingTenant] = useState('');
  const getShopUrl = () => {
    axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-shopify-shop-url',
      { 'shopifyToken': token.value },
      { headers: {  } })
      .then(response => {
        setShopUrl(response.data?.shop_url);
        setShopId(response.data?.shop_id);
        setExistingTenant(response.data?.existing_tenant);
      })
      .catch(error => {
        console.error(error);
      })
  }
  useEffect(()=>{
    if (token?.name === 'shopify') {
      getShopUrl()
    }
  },[token])
  useEffect(()=>{
    if (token?.name === 'shopify' && tenants && shopUrl && !tenants.find((tenant)=>{return tenant.tenant_id === existingTenant})) {
      createShopifyDisclosure.onOpen()
    }
  },[shopUrl, tenants])

  const handleShopifyAuth = async (tenant_id, disclosure, setSaving) => {
    const shopifyToken = token.value;
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-connect-shopify',
          {
            tenant_id: tenant_id,
            shopifyToken: shopifyToken
          },
          { headers: { "Authorization": jwtToken } })
          .then(response => {
            // navigate("/pricing/tiers", { state: {shopify: true}, replace: true })
            updateTenants()
            disclosure && disclosure.onClose()
            setSaving && setSaving(false)
            updateTenants()
          })
          .catch(error => {
            console.error(error);
          })
        })
        .catch(error => {
          console.error(error);
      });
    setToken(null);
  }

  const handleDelete = (setLoading, tenant_id) => {
    Auth.currentSession()
      .then((session) => {
        const jwtToken = session.getIdToken().getJwtToken();
        axios.post("https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/workspaces", {
          action: 'remove_organization',
          params: { tenant_id: tenant_id }
        }, {
          headers: { Authorization: jwtToken },
        })
          .then((response) => {
            updateTenants()
            deleteDisclosure.onClose()
            setLoading(false)
          })
          .catch((error) => { console.error(error); })
      })
      .catch((error) => { console.error(error); });
  }

  const roleBadges = {
    'owner':
      <Badge variant='subtleBrandDark' colorScheme='blue' w='70px' textAlign='center' mr='5px' borderRadius='5px' border='1px solid #ccc'>
        Owner
      </Badge>,
    'member':
      <Badge variant='subtleBrandLight' colorScheme='green' w='70px' textAlign='center' borderRadius='5px' border='1px solid #ccc'>
        Member
      </Badge>,
    'admin':
      <Badge variant='subtleBrandGrayLight' colorScheme='red' w='70px' textAlign='center' borderRadius='5px' border='1px solid #ccc'>
        Admin
      </Badge>
    }

  const settingsButtonText = useBreakpointValue({ "base": 'Settings', xxs: '', md: 'Settings' })
  function OrgRow({tenant, index}) {
    const updateDisclosure = useDisclosure();
    useEffect(()=>{
      if (token?.name === 'shopify' && existingTenant == tenant.tenant_id) {
        updateDisclosure.onOpen()
        createShopifyDisclosure.onClose()
      }
    },[shopUrl])
    const [rowHover, setRowHover] = useState(false)
    return (
      <Flex
        cursor={
          !(token?.name === 'shopify' && !tenant.shopify_shop_url)
            ? 'default'
            : 'pointer'
        }
        w='100%'
        h='56px'
        key={index}
        borderRadius="10px"
        justifyContent='space-between'
        padding="7px"
        color='white'
        _hover={{ background:
          (token?.name === 'shopify' && tenant.shopify_shop_url)
            ? 'white'
            : '#ebebeb',
          color: 'black.300',
          cursor: 'pointer'
        }}
        onMouseEnter={()=>{setRowHover(true)}}
        onMouseLeave={()=>{setRowHover(false)}}
        onClick={() => {
          if (token?.name === 'shopify') {
            if (!tenant.shopify_shop_url) {
              handleShopifyAuth(tenant.tenant_id).then(()=>{
                setActiveTenant(tenant.tenant_id);
                localStorage.setItem('activeTenant', JSON.stringify({
                  'user_id': user.attributes.sub,
                  'tenant_id': tenant.tenant_id
                }))
                navigate("/admin/home");
              })
            }
          } else {
            setActiveTenant(tenant.tenant_id);
            localStorage.setItem('activeTenant', JSON.stringify({
              'user_id': user.attributes.sub,
              'tenant_id': tenant.tenant_id
            }))
            navigate("/admin/home");
          }
        }}
      >
        <Flex justifyContent='space-between'>
          { tenant.team_logo ? (
            <Image
              border='1px solid #adadad'
              boxSize="2rem"
              borderRadius="full"
              src={tenant.team_logo}
              mr="10px"
            />
          ) : (
            <Avatar
              boxSize='2rem'
              borderRadius="full"
              mr="10px"
              bg='secondaryGray.200'
              icon={<OrganizationAvatar/>}
            />
          ) }
          <Box w='200px' color='black' >
            <Text fontSize="md" fontWeight="semibold">
              {tenant.team_name}
            </Text>
            <Text fontSize={{ "base": "xx-small" }} color="black.300" >
              {tenant.tenant_id}
            </Text>
          </Box>
          <Box margin='auto'>
            {roleBadges[tenant.role]}
          </Box>
          <Box margin='auto'>
            { tenant.shopify_shop_url && (
              <Badge display='flex' alignItems='center' variant='subtle' colorScheme='green' mr='5px' borderRadius='5px' border='1px solid #ccc'>
                <ShopifyLogo mr='6px'/>
                {tenant.shopify_shop_url.slice(0, -14)}
              </Badge>
            )}
          </Box>
        </Flex>
        <Flex>
          { token?.name === 'shopify' ?
            tenant.shopify_shop_url
              ? ( <Text mt='6px' fontSize='sm' fontWeight='semibold'>Existing App Installed</Text>)
              : ( <Text mt='6px' fontSize='sm' fontWeight='semibold' mr='6px'>Select</Text>)
            : (tenant.role !== 'member'
              ? (<Button
                  variant='brand'
                  border='1px solid #ccc'
                  onClick={(e) => {setRowHover(false); updateDisclosure.onOpen(); e.stopPropagation()}}
                  onMouseDown={(e) => {e.stopPropagation()}}
                  rightIcon={<MdSettings />}
                  iconSpacing='0px'
                  color='white'
                  // display={(token?.name === 'shopify' || rowHover) ? 'default' : 'none'}
                  visibility={(token?.name === 'shopify' || rowHover) ? 'visible' : 'hidden'}
                  opacity={(token?.name === 'shopify' || rowHover) ? '1' : '0'}
                />)
              : (<Button
                  rightIcon={<MdExitToApp/>}
                  iconSpacing='0px'
                  colorScheme='red'
                  border="1px solid #ccc"
                  visibility={(token?.name === 'shopify' || rowHover) ? 'visible' : 'hidden'}
                  opacity={(token?.name === 'shopify' || rowHover) ? '1' : '0'}
                  onClick={(e) => {setRowHover(false);deleteDisclosure.onOpen(); e.stopPropagation()}}
                  onMouseDown={(e) => {e.stopPropagation()}}>
                <DeleteModal deleteOrganization={handleDelete} tenant_id={tenant.tenant_id} disclosure={deleteDisclosure}/>
              </Button>))
          }
          <OrganizationModal disclosure={updateDisclosure}
            isOwner={ownedTenants?.includes(tenant.tenant_id)}
            handleDelete={handleDelete}
            handleShopifyAuth={handleShopifyAuth}
            updateTenants={updateTenants}
            tenant_id={tenant.tenant_id}
            initialValues = {{
              'team_name': tenant.team_name,
              'team_url': tenant.team_url,
              'team_logo': tenant.team_logo
            }}
            shopify={token?.name === 'shopify'}
            existing={true}
            shopUrl={shopUrl}
            shopId={shopId}
          />
        </Flex>
      </Flex>
    );
  }

  return (
    <DefaultAuth maxW={{ xxs: '100%', md: '80%', lg: '70%' }} illustrationBackground={illustration} image={illustration} signOut={user?.signOut}>
      <Stack w='100%'>
        <Flex
          p="20px"
          flexDirection="column"
          wordWrap="break-word"
          bg='white'
          m='32px !important'
          shadow="lg"
          rounded="10px"
        >
          { shopUrl && (
            <Center position='relative' m='-20px' top='-40px'>
              <Alert
                bg='#CBE6C7'
                w='100%'
                borderTopRadius='8px'
                borderBottomRadius='0px'
                border='1px solid'
                borderColor='brand.200'
              >
                <ShopifyLogo mr='16px' w='30px' h='30px'/>
                <AlertDescription color='brand.700' fontWeight='medium'>
                  Installing <Text display='inline' fontWeight='bold' color='brand.700'>{shopUrl.slice(0,-14)}'s</Text> Shopify app.<br/>
                  Please create a <Text display='inline' fontWeight='bold' color='brand.700'>new</Text> or select an <Text display='inline' fontWeight='bold' color='brand.700'>existing</Text> organization (maximum one app per organization).
                </AlertDescription>
              </Alert>
            </Center>
          ) }
          <Box display="flex" mb="16px">
            <Image
              src="https://assets-global.website-files.com/6406488304c4e2275cdb8694/64679fb9278d9fd21fa7331a_Logo_ProsperBlack.svg"
              alt="Prosper Logo"
              h="30px"
              objectFit="cover"
            />
            <Spacer />
            <Text color="black" fontSize="lg" fontWeight="semibold">
              My Organizations
            </Text>
          </Box>
          <Divider mb="20px" />
          <SimpleGrid columns="1">
            {tenants !== null
              ? tenants.map((tenant, index)=>{return <OrgRow tenant={tenant} key={index} index={index}/>})
              : <Center><Spinner/></Center>
            }
          </SimpleGrid>
          <Divider mt="15px" mb="15px" />
          { (!['prosper_tier1', 'prosper_tier2', 'prosper_tier3'].includes(accountTier) ||
            ((ownedTenants?.length ?? 0) < maxOrganizationsByTier(accountTier) || maxOrganizationsByTier(accountTier) === -1)
          ) && (
            <Flex borderRadius="10px" padding="12px" _hover={{ background: "#ebebeb" }} onClick={createDisclosure.onOpen} cursor='pointer'>
              <OrganizationModal isNew={true}
                handleShopifyAuth={handleShopifyAuth}
                disclosure={createDisclosure}
                updateTenants={updateTenants}
                shopify={token?.name === 'shopify'}
                shopUrl={shopUrl}
                shopId={shopId}
              />
              <Text fontSize="md">Add a new organization</Text>
              <Spacer />
              <AddIcon my='auto' mr='8px' />
            </Flex>
          ) }
          {/*
          <OrganizationModal isNew={true}
            disclosure={createShopifyDisclosure}
            updateTenants={updateTenants}
            shopify={token?.name === 'shopify'}
            shopUrl={shopUrl}
            shopId={shopId}
          />
          */}
        </Flex>
      </Stack>
    </DefaultAuth>
  );
}

export default Organizations;
