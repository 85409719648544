import { extendTheme } from "@chakra-ui/react";
import { CardComponent } from "./additions/card/card";
import { buttonStyles } from "./components/button";
import { badgeStyles } from "./components/badge";
import { inputStyles } from "./components/input";
import { alertStyles } from "./components/alert";
import { tableStyles } from "./components/table";
import { progressStyles } from "./components/progress";
import { sliderStyles } from "./components/slider";
import { textareaStyles } from "./components/textarea";
import { formLabelStyles } from "./components/formlabel";
import { switchStyles } from "./components/switch";
import { textStyles } from "./components/text";
import { tooltipStyles } from "./components/tooltip";
import { tagStyles } from "./components/tag";
import { checkboxStyles } from "./components/checkbox";
import { linkStyles } from "./components/link";
import { breakpoints } from "./foundations/breakpoints";
import { globalStyles } from "./styles";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default extendTheme(
  { breakpoints }, // Breakpoints
  globalStyles,
  badgeStyles, // badge styles
  buttonStyles, // button styles
  linkStyles, // link styles
  alertStyles,
  tableStyles,
  progressStyles, // progress styles
  sliderStyles, // slider styles
  inputStyles, // input styles
  textareaStyles, // textarea styles
  formLabelStyles, // form label styles
  switchStyles, // switch styles
  textStyles, // text component
  tooltipStyles, // tooltip component
  tagStyles,
  checkboxStyles, // tooltip component
  CardComponent, // card component
  { config: {
    initialColorMode: 'light'
  } }
);
