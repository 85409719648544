// import { Cancel } from '@mui/icons-material';
// import CropIcon from '@mui/icons-material/Crop';
// import {
//   Box,
//   Button,
//   DialogActions,
//   DialogContent,
//   Slider,
//   Typography,
// } from '@mui/material';
import {
  Box,
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  Slider,
  Text,
  useDisclosure,
  ModalContent,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Center,
  Spacer,
  Image,
  Avatar,
} from '@chakra-ui/react';
import { MdCancel } from 'react-icons/md';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
// import { useAuth } from '../../context/AuthContext';
import getCroppedImg from './cropImage';
import FormikFileInput from 'components/formik/FormikFileInput';
import * as yup from 'yup';
import FormikImageInput from 'components/formik/FormikImageInput';
import { useField } from 'formik';

const Crop = ({ ...props }) => {
  // const { setAlert, setLoading } = useAuth();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const validator = (schema) => {
    return async (value) => {
      let error = undefined;
      try { await schema.validate(value); }
      catch (err) {
        error = err.errors[0];
        // console.log("error:", err);
      }
      return error;
    }
  }
  const logoValidate = validator(yup.string().required())
  const handleFileUpload = (e) => {
    setImage(e);
    setRotation(0);
    setZoom(1);
    setCrop({x: 0, y: 0})
  }
  const logo = useField({ name: 'team_logo', validate: logoValidate })

  const [image, setImage] = useState(logo[1].initialValue ?? '');

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    try {
      const { file, url } = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      setImage(url);
      console.log(url, file)
      // setOpenCrop(false);
    } catch (error) {
      console.log(error);
    }
  };

  const disclosure = useDisclosure();



  return (
    <Center {...props}>
      <Box
        w='120px'
        h='120px'
        onClick={disclosure.onOpen}
        cursor='pointer'
      >
        { image ? (
          <Image
            src={image}
            objectFit='cover'
            boxSize='120px'
            roundedTop='12px'
            border='solid 1px'
            borderColor='white'
          />
        ) : (
          <Box
            bg='#1e1e1e30'
            boxSize='120px'
            roundedTop='12px'
            border='solid 1px'
            borderColor='white'
            >
          {/*
          */}
          <Avatar
            boxSize='120px'
            rounded='0'
            roundedTop='12px'
            bg='secondaryGray.200'
          />
          </Box>
        ) }
        <Box bg='white' fontWeight='semibold' color='black.200' textAlign='center' py='6px' roundedBottom='12px'>
          Edit Logo
        </Box>
      </Box>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalContent
          bg='#f0f0f0'
          margin="auto"
          cursor='unset'
        >
          <ModalBody >
            <Box position='relative' w='400px' h='300px' bg='#1e1e1e30' rounded='8px' my='20px'>
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={1}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
                onCropChange={setCrop}
                onCropComplete={cropComplete}
              />
            </Box>
            <Box w='100%'>
              <Box>
                <Text>Zoom: {zoomPercent(zoom)}</Text>
                <Slider
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  onChange={(zoom) => setZoom(zoom)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Box>
              <Box>
                <Text>Rotation: {rotation + '°'}</Text>
                <Slider
                  min={0}
                  max={360}
                  value={rotation}
                  onChange={(rotation) => setRotation(rotation)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Box>

            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              mr='10px'
              // variant="outlined"
              // startIcon={<MdCancel />}
              // onClick={() => setOpenCrop(false)}
              onClick={disclosure.onClose}
            >
              Cancel
            </Button>
            <Spacer/>
            <FormikImageInput
              buttonProps={{mr: "10px" }}
              onFileSelect={handleFileUpload}
              label='Select a new file' w='auto'
              field={logo}
              fileFormats={['png', 'jpg', 'svg']}
            />
            <Button
              // startIcon={<CropIcon />}
              variant="brand"
              onClick={()=>{cropImage(); disclosure.onClose()}}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Center>
  );
};

export default Crop;

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
