// Chakra imports
import {
  useColorModeValue,
  Box,
  Heading,
  Text,
  Button,
  Select,
  Spacer,
  Flex,
  Avatar,
  SimpleGrid,
  Icon,
  FormControl,
  FormLabel,
  useBreakpoint,
  Input,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToken,
  useStyleConfig,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Center,
  Tooltip,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";

// Assets
import React, { useEffect, useState, useRef } from "react";
import { useOutletContext, NavLink, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import axios from "axios";
import { breakpoints } from "@chakra-ui/utils";
import InviteModal from "./components/InviteModal";
import EditModal from "./components/EditModal";
import RemoveModal from "./components/RemoveModal";
import CancelModal from "./components/CancelModal";
import { maxMembersByTier } from "variables/tiers";

import { MdHelp } from "react-icons/md";
import { useUserContext } from "contexts/UserContext";
export default function Overview(props) {
  const overviewBg = useColorModeValue(useToken('colors', 'brand.300')+'20', useToken('colors', 'brand.300')+'20');
  const inviteDisclosure = useDisclosure();

  const { activeTenantState, invitesState, membersState, userState, updateInvites, updateMembers, tier } = useUserContext();
  const [activeTenant, setActiveTenant] = activeTenantState

  const [members, setMembers] = membersState;
  const [invites,setInvites] = invitesState;
  const [user,setUser] = userState;
  const [invited,setInvited] = useState([])
  useEffect(()=>{
    if (invites) {
      setInvited(invites.map((e)=>{return e.email}))
    }
  },[invites])

  const [maxMembers, setMaxMembers] = useState(0)
  const memberRefresh = () => { updateMembers(); updateInvites() }
  const [activeMember, setActiveMember] = useState(null)

  useEffect(() => {
    if (user && members) {
      const index = members.findIndex(tenant => tenant.user_id === user.attributes.sub)
      setActiveMember(members[index])
    }
  },[user, members])

  useEffect(()=>{
    if (tier) { setMaxMembers(maxMembersByTier[tier] ?? 0) }
  },[tier])

  const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  const InviteRow = (invites) => {
    const cancelDisclosure = useDisclosure();
    return (
      <Tr>
        <Td opacity='.5'>Invite pending</Td>
        <Td opacity='.5'>{invites.email}</Td>
        <Td opacity='.5' fontWeight="bold">{capitalize(invites.role)}</Td>
        <Td h='73px'>
          <Flex>
            <Spacer/>
            <Button variant='red' ml="10px" onClick={cancelDisclosure.onOpen} >
              Cancel
            </Button>
            <CancelModal tenant={activeTenant} invited={invites} refresh={memberRefresh} {...cancelDisclosure} />
          </Flex>
        </Td>

      </Tr>
    )
  }

  const MemberRow = (member) => {
    const editDisclosure = useDisclosure();
    const removeDisclosure = useDisclosure();
    return (
      <>
        <Tr>
          <Td>{member.name} {member.family_name}</Td>
          <Td>{member.email}</Td>
          <Td fontWeight="bold">{capitalize(member.role)}</Td>
          <Td h='73px'>
            { member.role.toLowerCase() !== 'owner' &&
              activeMember.role.toLowerCase() !== 'member' && (
              <Flex>
                <Spacer/>
                <Button variant='white' onClick={editDisclosure.onOpen} >
                  Edit
                </Button>
                <EditModal tenant={activeTenant} member={member} refresh={memberRefresh} {...editDisclosure} />
                <Button disabled={member.email === user.attributes['email']} variant='red' ml="10px" onClick={removeDisclosure.onOpen} >
                  Remove
                </Button>
                <RemoveModal tenant={activeTenant} member={member} refresh={memberRefresh} {...removeDisclosure} />
              </Flex>
            ) }
          </Td>
        </Tr>
      </>
    )
  }

  return members && invites && activeMember && (
    <Box
      bg="#fff"
      borderRadius="20px"
      border="1px solid #eee"
      width="100%"
      minH="75vh"
      boxShadow='lg'
    >
      <Box
        display="flex"
        mb='10px'
        borderBottom="1px solid #eee"
        borderTopRadius="10px"
      >
        <Heading padding="20px" as="h3" size="md" width="100%" margin='auto'>
          Team Overview
        </Heading>
        <Button variant='accent'
          mt='10px'
          mr='15px'
          disabled={(maxMembers !== -1 && members.length + invited.length >= maxMembers) || activeMember.role.toLowerCase() === 'member'}
          onClick={inviteDisclosure.onOpen}
        >
          Invite Member
        </Button>
        <InviteModal tenant={activeTenant} refresh={memberRefresh} user={user} invited={invited} {...inviteDisclosure}/>
      </Box>
      <TableContainer>
        <Table variant="simple">
          <TableCaption>
            <Text>Team Members: <Text fontWeight='bold' as='span'>{members.length}{maxMembers !== -1 && `/${maxMembers}`}</Text></Text>
            <Text variant="hintModal" onClick={() => window.open("/pricing/tiers")} >
              Need more users? Upgrade your plan now
            </Text>
          </TableCaption>

          <Thead>
            <Tr>
              <Th>Name</Th> <Th>Email</Th> <Th>Role</Th> <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            { members.map((member, index)=>{return(<MemberRow key={index} {...member}/>)}) }
          </Tbody>
          <Tbody>
            { invites.map((invites, index)=>{return(<InviteRow key={index} {...invites}/>)}) }
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
