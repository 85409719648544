import {
  Flex,
  Box,
  Tooltip,
  Spacer,
  Text,
  Input
} from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons';
import { Form, useField } from 'formik'
import Crop from './Crop';
import FormikInput from 'components/formik/FormikInput';

function OrganizationForm({ name, url, ...props}) {

  const nameField = useField({'name': name[0], 'validator': name[1]})
  const urlField = useField({'name': url[0], 'validator': url[1]})

  return (
    <Form>
      <Flex>
        <Box mr='36px'>
          {/*
          <Flex alignItems='center'>
            <Text mb='6px' whiteSpace='nowrap' fontWeight="semibold" fontSize="lg">
              Organization Logo
            </Text>
            <Spacer minW='24px'/>
            <Tooltip
              label="Can be used to brand your widgets"
              fontSize="md"
            >
              <InfoIcon />
            </Tooltip>
          </Flex>
          */}
          <Crop/>
        </Box>

        <Box w='100%'>
          <FormikInput
            field={nameField}
            validate={name[1]}
            label='Organization Name'
            placeholder='Prosper'
            tooltipLabel="Your organization's name"
            focusBorderColor='#C9CED2'
          />
          <FormikInput
            field={urlField}
            validate={url[1]}
            label='Organization URL'
            placeholder='https://getprosper.io'
            tooltipLabel="Your organization's url"
            focusBorderColor='#C9CED2'
          />
        </Box>
      </Flex>

      {/*
      <Text fontSize="sm" mb="5px">
        Upload a PNG / SVG file, ensure aspect ratio of 1:1
      </Text>
      */}
      <Flex mb="15px">
        {/*
        <Box
          w="80%"
          h="120px"
          border="1px solid #adadad"
          borderRadius="10px"
          padding="7px"
        >
          File input / upload goes here
        </Box>
        <Spacer />
        */}
        {/*
          <Crop {...{ photoURL, setOpenCrop, setPhotoURL, setFile }}/>
          <Box
            w="120px"
            h="120px"
            border="1px solid #adadad"
            borderRadius="10px"
            padding="7px"
          >
            Preview
          </Box>
        */}
      </Flex>
      {/*
      <Flex borderBottomWidth="1px" borderColor="#adadad" mb="15px">
        <Text mb="15px" fontWeight="semibold" fontSize="lg">
          Organization Plan
        </Text>
      </Flex>
      <Flex>
        <Flex w='max-content'>
          <Box border='2px solid' borderColor='brand.400' bg='brand.300' borderRadius='10px' padding='10px' pr='20px'>
            <Text
              fontWeight="semibold"
              color="brand.600"
              fontSize="xl"
            >
              Growth
            </Text>
            <Text>
              <Text display="inline" fontWeight="semibold">
                $25 {""}
              </Text>
              / month
            </Text>
            <Text>
              <Text display="inline" fontWeight="semibold">
                75¢ {""}
              </Text>
              / referral
            </Text>
            <Text fontWeight="semibold">
                Whitelabeling not included
              </Text>
            <Badge border='1px solid #ccc' mt='7px' colorScheme='green'>Active</Badge>
            <Spacer/>
            <Button size='sm' border='1px solid #ccc' mt='10px' disabled={true}>Choose Plan</Button>
          </Box>
          <Box ml='20px' border='2px solid' borderColor='brand.400' bg='brand.200' borderRadius='10px' padding='10px' pr='20px'>
            <Text
              fontWeight="semibold"
              color="brand.600"
              fontSize="xl"
              display='inline'
              mr='7px'
            >
              Enterprise
            </Text>
            <Text>
              <Text display="inline" fontWeight="semibold">
                Custom {""}
              </Text>
              / month
            </Text>
            <Text>
              <Text display="inline" fontWeight="semibold">
                Custom {""}
              </Text>
              / referral
            </Text>
            <Text fontWeight="semibold">
                Whitelabeling included
              </Text>
            <Badge border='1px solid #ccc' mt='7px' mr='5px' colorScheme='red'>Inactive</Badge>
            <Spacer/>
            <Button size='sm' border='1px solid #ccc' mt='10px' bg='brand.800'>Talk to Sales</Button>
          </Box>
        </Flex>
        <Spacer/>
        <Button variant='red'>Cancel Organization</Button>
      </Flex>
      */}
    </Form>
  )
}

export default OrganizationForm;
