import { mode } from "@chakra-ui/theme-tools";
export const badgeStyles = {
  components: {
    Badge: {
      baseStyle: {
        borderRadius: "10px",
        lineHeight: "100%",
        padding: "7px",
        paddingLeft: "12px",
        paddingRight: "12px",
        letterSpacing: "wide"
      },
      variants: {
        outline: () => ({
          borderRadius: "16px",
        }),
        brand: (props) => ({
          bg: mode("brand.500", "brand.400")(props),
          color: "white",
          _focus: {
            bg: mode("brand.500", "brand.400")(props),
          },
          _active: {
            bg: mode("brand.500", "brand.400")(props),
          },
          _hover: {
            bg: mode("brand.600", "brand.400")(props),
          },
        }),
        subtleAccent: (props) => ({
          bg: mode("orange.100", "brand.400")(props),
          color: mode("orange.700", "brand.400")(props),
        }),
        subtleBrandLight: (props) => ({
          bg: mode("newBrand.100", "brand.400")(props),
          color: mode("brandGray.800", "brand.400")(props),
        }),
        subtleBrandGrayLight: (props) => ({
          bg: mode("brandGray.200", "brand.400")(props),
          color: mode("brandGray.800", "brand.400")(props),
        }),
        subtleBrandDark: (props) => ({
          bg: mode("newBrand.300", "brand.400")(props),
          color: mode("brandGray.800", "brand.400")(props),
        }),
      },
    },
  },
};
