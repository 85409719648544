import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 72 72"
      version="1.1"
      viewBox="0 0 72 72"
      xmlSpace="preserve"
    >
      <path
        fill="#D7F2E9"
        d="M16 0h40c8.8 0 16 7.2 16 16v40c0 8.8-7.2 16-16 16H16C7.2 72 0 64.8 0 56V16C0 7.2 7.2 0 16 0z"
      ></path>
      <path fill="#184E3E" d="M17 17H35.5V55H17z"></path>
      <path fill="#63E2BD" d="M35.5 17H55V55H35.5z"></path>
      <path fill="#9BEAD1" d="M35.5 35.5H55V55H35.5z"></path>
    </svg>
  );
}

export default Icon;
