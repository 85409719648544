import React, { useEffect, useState, useRef } from 'react';
import { Field, FieldValidator } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Box,
} from "@chakra-ui/react";

function FormikInput({ label, field, placeholder, validate, validateField, ...props }) {
  const [ fieldName, meta, helpers] = field;
  // console.log(helpers, meta, fieldName)
  const { setValue, setError, setTouched } = helpers;
  const { value, initialValue } = meta;
  const [text, setText] = useState(value ?? initialValue);

  const initial = useRef(false)
  useEffect(async () => {
    validate(text).then((out)=>{
        setError(out ? out : null);
      }).catch((err)=>{
        console.log(err);
      });
  }, [text])

  // console.log(meta, fieldName, helpers);
  // console.log(meta.error);
  return (
    <FormControl isRequired mb='15px'
      isInvalid={meta.error && meta.touched}
    >
      { label && (
        <FormLabel variant='modal' >
          {label}
        </FormLabel>
      ) }
      <Input
        {...props}
        // bg={'brand.100'}
        variant='modal'
        value={text}
        placeholder={placeholder}
        onBlur={fieldName.onBlur}
        onChange={(e) => {
          setTouched(true)
          setText(e.target.value)
          setValue(e.target.value)
        }}
        border='2px solid'
        _focus={{ borderColor: (meta.error && meta.touched) ? 'red.500' : props.focusBorderColor ?? 'transparent' }}
        borderColor={ (meta.error && meta.touched) ? 'red.500' : 'transparent'}
      />
      {/*
      */}
      <FormErrorMessage position='absolute' bottom='0'>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}
// function FormikInput({ label, field, validate, validateField, ...props }) {
//   // const debouncedValidate =
//   return (
//     <Box>
//       <Field name={field} validate={validate}>
//         {({ field, form }) => {
//           const errors = _.get(form.errors, field.name);
//           const touched = _.get(form.touched, field.name);
//           // form.validateField(field.name)
//           return (
//             <FormControl isRequired mb='15px'
//               isInvalid={errors && touched}
//             >
//               { label && (
//                 <FormLabel variant='modal' >
//                   {label}
//                 </FormLabel>
//               ) }
//
//               <Input {...field} {...props}
//                   onChange={(e)=>{field.onChange(e); validateField(field.name); console.log("!")}}
//                   onBlur={() => {form.validateField(field.name)}}
//                   variant='modal' border='2px solid' borderColor={ errors ? 'red.500' : 'transparent'}/>
//               <FormErrorMessage position='absolute'>{errors}</FormErrorMessage>
//             </FormControl>
//           )
//         }}
//       </Field>
//     </Box>
//   );
// }

export default FormikInput;
