// Chakra imports
import {
  useColorModeValue,
  useClipboard,
  Box,
  Text,
  Button,
  Flex,
  Avatar,
  SimpleGrid,
  Icon,
  Tag,
  Heading,
  Spacer,
  Table,
  Select,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Center,
  FormControl,
  FormLabel,
  useBreakpoint,
  Input,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToken,
  useStyleConfig,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import { RiEyeCloseLine } from "react-icons/ri";
import { AiFillCopy } from "react-icons/ai";
import { MdOutlineRemoveRedEye } from "react-icons/md";

// Assets
import React, { useEffect, useState } from "react";
import { useOutletContext, NavLink, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import axios from "axios";
import ShopifyModal from "./components/ShopifyModal.js";
import { breakpoints } from "@chakra-ui/utils";
import CustomDomains from "./components/customDomains/index.js";

import { MdHelp } from "react-icons/md";
import { formatTier } from "variables/tiers.js";
import AppSumoTiers from "./components/AppSumoTiers.js";
import { useUserContext } from "contexts/UserContext.js";
import Stripe from "stripe";

// const stripe = require('stripe')('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');
// const stripe = require("stripe")(
//   "sk_test_....."
// );
const stripe = Stripe('pk_live_51MaEYACRe9xuvcuCI4Tgcrdowjzthv3e0LqO08uyYPXNjKtH5RdhBaATGx9q5FJBeVE4d4LTxd4szW9Rq4RQkt6C00Utvcvnsa');
// const stripe = Stripe('pk_live_51MaEYACRe9xuvcuCI4Tgcrdowjzthv3e0LqO08uyYPXNjKtH5RdhBaATGx9q5FJBeVE4d4LTxd4szW9Rq4RQkt6C00Utvcvnsa');

export default function Overview(props) {
  const {
    isOpen: isRemoveOpen,
    onOpen: onRemoveOpen,
    onClose: onRemoveClose,
  } = useDisclosure();
  const { onCopy, value, setValue, hasCopied } = useClipboard("");
  const [session, setSession] = useState();
  const [showKey, setShowKey] = useState(false);
  const [shopUrl, setShopUrl] = useState("");
  const [token, setToken] = useState("");
  const [showToken, setShowToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shopifyLoading, setShopifyLoading] = useState(false);
  const linkColor = useColorModeValue(
    useToken("colors", ["blue.400"]),
    useToken("colors", ["blue.200"])
  );
  const brandStars = 'red.500'
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userState, activeTenantState } = useUserContext();
  const [activeTenant, setActiveTenant] = activeTenantState
  const [user, setUser] = userState

  async function customerPortal() {
    // call lambda
    // 'https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/stripe-billing-portal'
    Auth.currentSession()
      .then((session) => {
        const jwtToken = session.getIdToken().getJwtToken();
        // const request = {
        //   tenant_id: activeTenant,
        //   access_token: token,
        //   shop_url: shopUrl,
        // };
        const request = {};
        // console.log("req", request);
        axios.post("https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/stripe-billing-portal",
          request,
          { headers: { Authorization: jwtToken }, }
        ).then((response) => {
          setSession(response.data);
        })
        .catch((error) => {
          // setError("Error Saving Campaign")
          setLoading(false);
          console.error(error);
        })
        .finally(() => {});
      })
      .catch((error) => {
        console.error(error);
      });


  }

  useEffect(() => {
    customerPortal()
  }, []);

  const textColor = useColorModeValue("brand.600", "brand.600");
  const textColorSpecial = useColorModeValue("brand.600", "brand.600");

  const navigate = useNavigate();

  // const [deleting, setDeleting] = useState(false);
  // useEffect(() => {
  //   if (deleting) {
  //     updateCampaign();
  //   }
  // }, [deleting])

  const connectShopify = () => {
    setLoading(true);
    Auth.currentSession()
      .then((session) => {
        const jwtToken = session.getIdToken().getJwtToken();
        // console.log("campaign_id", campaign_id);
        // console.log("deleting", deleting);
        const request = {
          tenant_id: activeTenant,
          access_token: token,
          shop_url: shopUrl,
        };
        // console.log("req", request);
        axios.post("https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-connect-shopify",
          request,
          { headers: { Authorization: jwtToken }, }
        ).then((response) => {
          setLoading(false);
          onClose();
        })
        .catch((error) => {
          // setError("Error Saving Campaign")
          setLoading(false);
          console.error(error);
        })
        .finally(() => {});
      })
      .catch((error) => {
        console.error(error);
      });
  };


// https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/domains

  return (
    <Box>
      {/* Main Fields */}
      <Box
        boxShadow='lg'
        p={"20px"}
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        position={"relative"}
        borderRadius={"20px"}
        minWidth={"0px"}
        wordWrap={"break-word"}
        backgroundClip={"border-box"}
        flexDir='row' mb="18px" bg="white" border="1px solid #eee">
        <Box columns={{ base: 1, md: 2 }} >
          <Box>
            <Flex mb="12px">
              <Avatar
                _hover={{ cursor: "pointer" }}
                name=""
                bg="brand.600"
                size="sm"
                w="80px"
                h="80px"
                mr="24px"
              />
              <Box>
                <Text
                  color={textColorSpecial}
                  fontWeight="semibold"
                  fontSize="2xl"
                >
                  {user.attributes.name ? user.attributes.name : "First Name"}{" "}
                  {user.attributes.family_name
                    ? user.attributes.family_name
                    : "Last Name"}
                </Text>
                <Text color={textColorSpecial} fontSize="md">
                  {user.attributes.email}
                </Text>
                <Text color="#adadad" fontSize="xs" fontWeight='medium'>
                  {user.attributes.sub}
                </Text>
              </Box>
            </Flex>
            {user.attributes["custom:api_key"] ? (
              <Box>
                <Text fontSize="xx-small" mr='12px'>Api Key:</Text>
                <Flex w='60%'>
                  <Text
                    fontWeight="bold"
                    fontSize="md"
                    whiteSpace='nowrap'
                    // visibility={!showKey ? 'hidden' : 'visible'}
                    fontFamily='Monospace'
                    color={showKey ? 'black.800' : 'black.400'}
                    transition='color 1s'
                  >
                    {user.attributes["custom:api_key"]}
                    <Box position='relative' left='-5%' top='-100%'
                      backdropFilter={showKey ? 'none' : 'auto'} backdropBlur='3px'
                      transition='backdrop-filter 1s'
                      w='110%' h='100%'/>
                  </Text>
                  <Flex
                    alignItems='center'
                    w='100%'
                    onClick={() => setShowKey(!showKey)}
                  >
                    <Icon
                      // color={textColorSecondary}
                      ms="12px"
                      _hover={{ cursor: "pointer" }}
                      as={showKey ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={() => setShowKey(!showKey)}
                    />
                  </Flex>
                </Flex>
              </Box>
            ) : null}
          </Box>

          {/*
          <NavLink to={'https://billing.stripe.com/p/login/test_4gw6oJchs69w47e7ss?prefilled_email='+encodeURI(user.attributes.email)}>
            Billing
          </NavLink>
          */}


        </Box>
        <Spacer/>
        <Flex
          // justifyContent='right'
          alignItems={{ base: "center", md: "end" }}
          flexDir="column"
        >
          {session ? (
            <Button
              mx="20px"
              mb="12px"
              w="100%"
              maxW={{ base: "100%", md: "180px" }}
              onClick={() => window.open(session)}
            >
              Update Billing
            </Button>
          ) : null}
        </Flex>
        <Flex>
          <Button
            mb='12px'
            border='1px'
            borderColor='#ccc'
            fontSize='sm'
            maxW={{ base: '100%', md: '180px' }}
            bg='brand.800'
            _hover={{
              bg: "none",
              borderColor: "brand.800",
            }}
            onClick={()=>{navigate('/pricing/tiers')}}>
            Change Tier
          </Button>
          { ['prosper_tier1', 'prosper_tier2', 'prosper_tier3'].includes(user.attributes['custom:tier']) && (
            <>
              <Button
                ml='12px'
                mb='12px'
                border='1px'
                borderColor='#ccc'
                fontSize='sm'
                maxW={{ base: '100%', md: '180px' }}
                bg='brand.800'
                _hover={{
                  bg: "none",
                  borderColor: "brand.800",
                }}
                onClick={onOpen}>
                Link Shopify
              </Button>

              <Modal isOpen={isOpen} onClose={onClose}
                // size='xl'
              >
                <ModalOverlay />
                <ModalContent
                  w='60vw'
                  maxW='100vw'
                  h='min-content'
                  maxH='85vh'
                >
                  <ModalHeader
                    fontSize='2xl' pb='0px'
                  >{props.title}</ModalHeader>
                  <ModalCloseButton _focus={{ border: 'none' }} />
                  <ModalBody my='20px' overflow='auto' >
                    <Alert status='info'>
                      <AlertIcon />
                      <AlertDescription>
                        First you must create a custom app on Shopify by following the <NavLink to='/admin/home' style={{ color: linkColor, fontWeight: 'semi-bold'}}>'Connect Shopify'</NavLink> guide.
                      </AlertDescription>
                    </Alert>
                    <Text w='100%'>
                    </Text>
                    <FormControl mt='12px'>
                      <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        display='flex'>
                        Shop URL
                        <Text
                          color={brandStars}
                        >*</Text>
                      </FormLabel>
                      <Input
                        borderColor='#ccc'
                        id='shopurl'
                        value={shopUrl}
                        onChange={(event) => setShopUrl(event.target.value)}
                        onKeyDown={(event) => {if (event.key === 'Enter') {}}}
                        isRequired={true}
                        fontSize='sm'
                        placeholder='storename.myshopify.com'
                        _placeholder={{ color: 'white' }}
                        mb='24px'
                        size='lg'
                        variant='auth'
                      />
                      <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        display='flex'>
                        Admin Access Token
                        <Text
                          color={brandStars}
                        >*</Text>
                      </FormLabel>
                      <InputGroup size='md'>
                        <Input
                          borderColor='#ccc'
                          id='adminToken'
                          value={token}
                          onChange={(event) => setToken(event.target.value)}
                          onKeyDown={(event) => {if (event.key === 'Enter') {}}}
                          isRequired={true}
                          fontSize='sm'
                          // placeholder='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'
                          placeholder='shpat_01234...'
                          _placeholder={{ color: 'white' }}
                          mb='24px'
                          size='lg'
                          type={showToken ? "text" : "password"}
                          variant='auth'
                        />
                        <InputRightElement display='flex' alignItems='center' mt='4px'>
                          <Icon
                            // color={textColorSecondary}
                            _hover={{ cursor: "pointer" }}
                            as={showToken ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={() => { setShowToken(!showToken) }}
                          />
                        </InputRightElement>
                      </InputGroup>
                      <Button
                        variant='brand'
                        w='100%'
                        onClick={connectShopify}
                        isLoading={loading}
                      >
                        Connect Shop
                      </Button>
                    </FormControl>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </>
          ) }
        </Flex>
      </Box>
      <CustomDomains mb='18px'/>
      { ['prosper_tier1', 'prosper_tier2', 'prosper_tier3'].includes(user?.attributes?.['custom:tier']) && (
        <AppSumoTiers user={user}/>
      ) }
    </Box>
  );
}
