import {
  TabIndicator,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Box,
  TabList,
  Popover,
  PopoverBody,
  PopoverTrigger,
  PopoverCloseButton,
  Code,
  PopoverArrow,
  PopoverContent,
  PopoverHeader,
} from "@chakra-ui/react";
import { MdHelp } from "react-icons/md";
import FormikInput from "./FormikInput";
import FormikTextArea from "./FormikTextArea";
import { useField } from "formik";

function CampaignWizardName({ inputs, ...props }) {
  const senderField = inputs.fields[0].field;
  const senderValidate = inputs.fields[0].validate;
  const receiverField = inputs.fields[1].field;
  const receiverValidate = inputs.fields[1].validate;
  const label = inputs.label;
  return (
    <Box {...props}>
      <Tabs variant="unstyled">
        <TabList border="none">
          <Tab _selected={{ fontWeight: "semibold" }} _focus="none">
            Sender SMS
          </Tab>
          <Tab _selected={{ fontWeight: "semibold" }} _focus="none">
            Receiver SMS
          </Tab>
          {/*
          <Button bg="brand.300" height="40px" ml="35px">
            Custom Template
          </Button>
          */}
        </TabList>
        <TabIndicator height="2px" bg="brand.400" borderRadius="1px" />
        <TabPanels mt="5px">
          <TabPanel>
            <FormikTextArea
              field={useField({ name: senderField, validate: senderValidate })}
              validate={senderValidate}
              label="Sender SMS Message"
              minRows={6}
            />
            {/*
            <FormControl isRequired>
              <FormLabel
                mt="5px"
                fontFamily="Inter"
                lineHeight="1.41"
                fontWeight="semibold"
                fontSize="17px"
                color="gray.700"
                width="432px"
                maxWidth="100%"
              >
                Sender Message Body
              </FormLabel>
              <Textarea
                height="150px"
                _focus={{ border: "1px solid #C9CED2" }}
                bg={whiteBg}
                defaultValue="[organization]: Hey [sender_name] 😄

                Thanks for sending a referral!

                Your discount code is [code] and is good for [incentive].

                [url]"
                size="md"
              />
            </FormControl>
            */}
          </TabPanel>
          <TabPanel>
            <FormikTextArea
              field={useField({
                name: receiverField,
                validate: receiverValidate,
              })}
              validate={receiverValidate}
              label="Receiver SMS Message"
              minRows={6}
            />
            {/*
          <FormControl isRequired>
              <FormLabel
                mt="5px"
                fontFamily="Inter"
                lineHeight="1.41"
                fontWeight="semibold"
                fontSize="17px"
                color="gray.700"
                width="432px"
                maxWidth="100%"
              >
                Receiver Message Body
              </FormLabel>
              <Textarea
                height="150px"
                _focus={{ border: "1px solid #C9CED2" }}
                bg={whiteBg}
                defaultValue="[organization]: You've just received a referral from [sender_name] ([sender_contact])!

                If you see something you like at [organization], this referral is good for [incentive]. Use code: [code]
                [url]

                Happy shopping 😄

                Reply STOP to unsubscribe from any future referrals."
                size="md"
              />
            </FormControl>
            */}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Popover placement="top">
        <PopoverTrigger>
          <Text
            position="absolute"
            bottom="21px"
            left="46px"
            cursor="pointer"
            textDecoration="underline"
            fontSize="13px"
            color="rgba(45, 55, 72, 0.5)"
          >
            How to use variables <br />
            in your referral template
          </Text>
        </PopoverTrigger>
        <PopoverContent
          style={{ border: "none" }}
          _focus={{ border: "none" }}
          w="max-content"
        >
          <PopoverArrow />
          <PopoverHeader>Using Variables</PopoverHeader>
          <PopoverCloseButton mt="4px" />
          <PopoverBody bg="#00000022">
            <Text maxW="500px" whiteSpace="pre-wrap">
              <Code colorScheme="green">[organization]</Code>: Displays the name
              of the organization/company the referral is being sent for. This
              can be changed in settings.
              <br />
              <br />
              <Code colorScheme="green">[url]</Code>: Displays the website URL
              that the referral will direct people to. This can be changed in
              settings.
              <br />
              <br />
              <Code colorScheme="yellow">[sender_name]</Code>: Displays the name
              of the person sending the referral.
              <br />
              <br />
              <Code colorScheme="yellow">[sender_contact]</Code>: Displays the
              contact info (phone number or email) of the person who sent the
              referral.
              <br />
              <br />
              <Code colorScheme="blue">[incentive]</Code>: Displays the
              name of the reward that users will receive sending and/or
              receiving a referral. This can be changed in your campaign.
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

export default CampaignWizardName;
