import {
  Text,
  Box,
  Center,
} from "@chakra-ui/react";
import FileUpload from 'components/fileUpload/FileUpload';
import FormikFileInput from './FormikFileInput';
import { useField } from "formik";


function Template({ inputs, ...props}) {
  const field = inputs.fields[0].field;
  const validate = inputs.fields[0].validate;

  const handleFileUpload = (file) => {
    // console.log('Selected file:', file);
  };

  return (
    <Box {...props}>
      <Text
        fontFamily="Inter"
        lineHeight="1.65"
        fontWeight="semibold"
        fontSize="17px"
        color="gray.700"
        height="21px"
        maxWidth="100%"
        textAlign="center"
      >
        Use our email template or upload your own.
      </Text>
        <FormikFileInput onFileSelect={handleFileUpload} label='Upload Your .html Template' w='auto'
          field={useField({ name: field, validate: validate })}
          validate={validate}
        />
      {/*
      <Text variant='boldModal'> OR </Text>
      <Center>
        <Button variant='boldModal' >
          Use Default Referral Template
        </Button>
      </Center>
      */}
      <Box>
        <Text variant='hintModal' width="430px" >
          We highly recommend editing your referral template to improve your
          campaign’s user experience and conversion rate.
        </Text>
      </Box>
    </Box>
  );
}

export default Template;
