// SaveOrDiscardModal.js

import React from 'react';
import { Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdHelp } from 'react-icons/md';
import FormikSelect from 'components/formik/FormikSelect';
import FormikInput from 'components/formik/FormikInput';
import { Formik, useField } from 'formik';
import axios from 'axios';
import * as yup from 'yup';
import { Auth } from 'aws-amplify';

const EditModal = ({ isOpen, onOpen, onClose, onSave, member, refresh, ...props }) => {
  const modalBg = useColorModeValue( "brand.200", "brand.200");

  const backRef = React.useRef();
  const navigate = useNavigate();

  const Inside = (props) => {
    const validator = (schema) => {
      return async (value) => {
        let error = undefined;
        try { await schema.validate(value); }
        catch (err) {
          error = err.errors[0];
        }
        return error;
      }
    }

    const roleValidator = validator(yup.string().required("This is required.").lowercase());
    const roleField = useField({ name: 'role', validate: roleValidator });
    // console.log(roleField)
    return (
      <>
        <ModalBody>
          <FormikSelect
            options={[
              {label: 'Admin', value: 'Can invite, promote and demote team members excluding owner.'},
              {label: 'Member', value: 'Can create campaigns, widgets and view reports.'}]}
            field={roleField}
            validate={roleValidator}
            labelProps={{
              label: { variant:'modal', mb:'15px'},
            }}
            tooltipLabel="Role determines the team member's privileges."
            label='Role'
            labelPath={'role'}
            object={{role: null}}
            focusBorderColor={'#C9CED2'}
            selectedOptionStyle="check"
            chakraStyles={{
              control: (provided) => ({ ...provided, background: 'brand.100' })
            }}
          />
        </ModalBody>


        <ModalFooter>
          <Button
            mr={3}
            onClick={onClose}
            border="1px solid #C9CED2"
          >
            Back
          </Button>
          <Button variant='brand'
            disabled={roleField[1]?.error && roleField[1]?.value !== roleField[1]?.initialValue}
            onClick={props.handleEdit}
          >Confirm</Button>
        </ModalFooter>
      </>
    )
  }
  // console.log("MEM", member)

  const handleEdit = (values) => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/edit_member',
          { tenant_id: props.tenant, user_id: member.user_id, role: values.toLowerCase() },
          { headers: { "Authorization": jwtToken } })
          .then(response => {
            // TODO: do something with response
            // console.log(response)
            refresh()
            onClose()
          })
          .catch(error => { console.error(error); })
        })
        .catch(error => { console.error(error); });
  }


  const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  return (
    <Modal
      isOpen={isOpen} onClose={onClose}
      closeOnOverlayClick={true} closeOnEsc={true}
      leastDestructiveRef={backRef}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent
        bg={modalBg}
        width="540px"
        height="auto"
        maxWidth="100%"
        margin="auto"
      >
        <ModalHeader>Edit {member.name}{member.name.endsWith('s') ? "' " : "'s " } Role</ModalHeader>
        <ModalCloseButton size="md" _focus="none" />
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{role: capitalize(member.role)}}
        >
          {({ values }) => {
            return (
              <Inside handleEdit={()=>{
                handleEdit(values.role.toLowerCase())
              }}/>
          )}}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
