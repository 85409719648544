import {
  Tooltip,
  Flex,
  useToken,
  Input,
  InputGroup,
  Icon,
  Text,
  useColorModeValue,
  Box,
  Center,
} from "@chakra-ui/react";
import FormikObjectInput from './FormikObjectInput';
import { MdHelp } from "react-icons/md";
import { useField } from 'formik';
import PersonIcon from 'assets/img/avatars/PersonIcon';
import FormikInput from "./FormikInput";

function SenderStatic({ inputs, ...props }) {
  const nameField = inputs.fields[0].field
  const nameValidate = inputs.fields[0].validate
  const codeField = inputs.fields[1].field
  const codeValidate = inputs.fields[1].validate
  const green = useColorModeValue('#2BB78E', '#2BB78E')
  return (
    <Box {...props}>
      {/*
      */}
      <Center w='460px'>
        <Text variant='modal'>Next, choose a name for your
          &nbsp;
          <Text display='inline' fontWeight="bold" decoration="underline" color={green} >
            SENDER
          </Text>
          &nbsp;
          discount
        </Text>
        <PersonIcon color={green} size='16px' ml='10px'/>
        <Tooltip hasArrow variant='modal' label="This is just for you to remember it by">
          <Box> <Icon w="16px" h="16px" as={MdHelp} color={"black"} ml="5px" /> </Box>
        </Tooltip>
      </Center>
      <FormikInput mt="15px" mb="20px" maxWidth="100%"
        _focus={{ border: "2px solid", borderColor: green }}
        field={useField({ name: nameField, validate: nameValidate})}
        validate={nameValidate}
        placeholder='Summer Sale'
      />
      <Center>
        <Text variant='modal'>Choose the
          &nbsp;
          <Text display='inline' fontWeight="bold" decoration="underline" color={green} >
            CODE
          </Text>
          &nbsp;
          used to redeem the discount
        </Text>
        <Tooltip hasArrow variant='modal' label="This code is given to the Sender to redeem their reward from you">
          <Box> <Icon w="16px" h="16px" as={MdHelp} color={"black"} ml="5px" /> </Box>
        </Tooltip>
      </Center>
      <FormikInput mt="15px" mb="20px" maxWidth="100%"
        _focus={{ border: "2px solid", borderColor: green }}
        field={useField({ name: codeField, validate: codeValidate})}
        validate={codeValidate}
        placeholder='20OFFANY'
      />
    </Box>
  );
}

export default SenderStatic;
