const defaultWidget = {
  "id": "d276b2b8-3a2e-4289-b95d-f678058c553d",
  "tenant_id": "39a1af12-b6f2-447a-b1dd-c3513f01cc69",

  "widget_name": "New Widget",

  "StartText": "Give 20% Off, Get 20% Off!",
  "DefaultMessage": "I was shopping at [organization] and thought you might also be interested. They're offering 20% off for referrals, so I sent one your way!",
  "EndText": "Click here to share again",

  "Color": "#63E2BD",
  "SecondaryColor": "#184E3E",
  "HoverColor": "#111111",
  "ButtonColor": "#31B08B",
  "ErrorColor": "#FF5959",
  "IconColor": "#184E3E",
  "InitialLicenseColor": "#184E3E",
  "SendButtonArrowColor": "#184E3E",

  "HeightClosed": "56",
  "HeightOpen1": "190",
  "HeightOpen2": "140",
  "Corner": "40",

  "Width": "305",
  "CTAFontSize": "16",
  "SecondaryFontSize": "13",
  "TertiaryFontSize": "13",
  "FontFamily": "'Barlow Condensed', sans-serif",
  "FontUrl": "https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;600&display=swap",
  "CopyType": "code",
  "Border": true,
  "LinkRefferal": false,
  "RewardUrl": true,
  "Verify": false,
  "Code": null,
  "deferred": true, //inverted gotta fix

};
export default defaultWidget;
