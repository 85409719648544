import React, { useEffect, useState, useRef } from 'react';
import { Field, FieldValidator } from 'formik';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Box,
  Tooltip,
  Spacer,
  Checkbox,
} from "@chakra-ui/react";
import { InfoIcon } from '@chakra-ui/icons';

function FormikCheckbox({ label, tooltipLabel, field, placeholder, validate, validateField, labelProps, controlProps, ...props }) {
  const [ fieldName, meta, helpers ] = field;
  // console.log(helpers, meta, fieldName)
  const { setValue, setError, setTouched } = helpers;
  const { value, initialValue } = meta;
  const [text, setText] = useState(value ?? initialValue);
  const [isRunning, setIsRunning] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  // console.log( value, initialValue );

  // useEffect(() => {
  //   let timerInterval;
  //
  //   // console.log(timeRemaining)
  //   if (isRunning && timeRemaining > 0) {
  //     timerInterval = setInterval(() => {
  //       setTimeRemaining((prevTime) => prevTime - 1);
  //     }, 10);
  //   } else if (timeRemaining === 0) {
  //     setIsRunning(false);
  //   }
  //
  //   return () => clearInterval(timerInterval); // Cleanup interval on unmount
  // }, [timeRemaining]);

  const isInitialMount = useRef(true);

  useEffect(async () => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return
    }

    validate(text).then((out)=>{
        setError(out ? out : null);
      }).catch((err)=>{
        console.log(err);
      });

    // console.log(!isRunning)
    // if (!isRunning) {
      // setTimeRemaining(100);
      // setIsRunning(true);
    // }
  }, [text])

  // console.log(meta, fieldName, helpers);
  // console.log(meta.error);
  return (
    <FormControl
      isRequired
      display={'flex'}
      flexDir={'row'}
      isInvalid={meta.error}
      alignItems='center'
      {...controlProps}
    >
      <Checkbox mr='24px' size='lg' variant='brand'
        onChange={(e)=>{setValue(e.target.checked)}}
      />
      { label && (
        <Flex alignItems='center'>
          <FormLabel mb='0' variant='modal' requiredIndicator={false} {...labelProps}>
            {label}
          </FormLabel>
          <Spacer/>
          { tooltipLabel && (
            <Tooltip variant='modal'
              hasArrow
              label={tooltipLabel}
              fontSize="md"
            >
              <InfoIcon/>
            </Tooltip>
          ) }
        </Flex>
      ) }
      <FormErrorMessage position='absolute'>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}

      // <Input
      //   // bg={'brand.100'}
      //   variant='modal'
      //   value={text}
      //   placeholder={placeholder}
      //   onBlur={fieldName.onBlur}
      //   onChange={(e) => {
      //     setText(e.target.value)
      //     setValue(e.target.value)
      //   }}
      //   borderColor={ meta.error && 'red.500' }
      //   {...props}
      //   border={meta.error ? '2px solid' : '1px solid'}
      //   _focus={{
      //     border: meta.error ? '2px solid' : '1px solid',
      //     borderColor: meta.error ? 'red.500' : props.focusBorderColor ?? 'transparent',
      //   }}
      //   _hover={{
      //     border: meta.error ? '2px solid' : '1px solid',
      //     borderColor: meta.error ? 'red.500' : props.focusBorderColor ?? 'transparent',
      //   }}
      // />

export default FormikCheckbox;
