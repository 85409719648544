import { mode } from "@chakra-ui/theme-tools";
export const checkboxStyles = {
  components: {
    Checkbox: {
      baseStyle: { },
      variants: {
        black: (props) => ({
          label: {  },
          control: {
            borderColor: 'black.500',
            _checked: {
              borderColor: 'black.500'
            },

            borderColor: 'black.600',
            borderRadius: '3px',
            border: '2px solid',
            _disabled: {
              bg: 'black.850',
              color: 'white',
              borderColor: 'black.850',
              _checked: {
                bg: 'black.850',
                color: 'white',
                borderColor: 'black.850',
              },
              _hover: {
                bg: 'black.850',
                color: 'white',
                borderColor: 'black.850',
              },
            },
            _checked: {
              bg: 'black.850',
              color: 'white',
              borderColor: 'black.850',
            },
          },
          icon: {
            bg: 'black.850',
            borderColor: 'black.850'
          },
          container: {  },
        }),
        brand: ({fill, ...props}) => ({
          label: { },
          control: {
            _checked: {
              bg: '#7CD3BA',
              borderColor: '#7CD3BA',
              _hover: {
                bg: '#7CD3BA',
                borderColor: '#7CD3BA',
              }
            },

            borderColor: 'black.200',
            borderRadius: '3px',
            border: '2px solid',

            _disabled: {
              bg: 'black.850',
              color: 'white',
              borderColor: 'black.850',
              _checked: {
                bg: 'black.850',
                color: 'white',
                borderColor: 'black.850',
              },
              _hover: {
                bg: 'black.850',
                color: 'white',
                borderColor: 'black.850',
              },
            },

            _focus: {
              boxShadow: 'none'
            },
          },
          icon: {
            bg: '#7CD3BA',
            borderColor: '#7CD3BA',
          },
          container: { },
        })
      },
      defaultProps: {
        size: null,
        variant: null,
      },
    },
  },
};
