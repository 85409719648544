import FormikInput from './FormikInput';
import {
  TabIndicator,
  Textarea,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  useToken,
  Input,
  InputGroup,
  Stack,
  Button,
  Icon,
  Text,
  useStyleConfig,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useColorModeValue,
  Divider,
  Box,
  Flex,
  SimpleGrid,
  Code,
  Image,
  Center,
  TabList,
  FormLabel,
  FormControl,
  FormHelperText,
} from "@chakra-ui/react";
import { PiConfettiDuotone } from "react-icons/pi";
import EmbedPopover from '../EmbedPopover';

function Finish(props) {
  const whiteBg = useColorModeValue(
    useToken("colors", ["brand.100"]),
    useToken("colors", ["brand.100"])
  );
  const modalBg = useColorModeValue(
    useToken("colors", ["brand.200"]),
    useToken("colors", ["brand.200"])
  );
  const buttonBg = useColorModeValue(
    useToken("colors", ["brand.300"]),
    useToken("colors", ["brand.300"])
  );
  const startBg = useColorModeValue(
    useToken("colors", ["brand.800"]),
    useToken("colors", ["brand.800"])
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box {...props}>
    <Center>
    <Icon as={PiConfettiDuotone} boxSize={20} color={'brand.500'} />
    </Center>
      <Text
        fontFamily="Inter"
        lineHeight="1.65"
        fontWeight="semibold"
        fontSize="17px"
        color="gray.700"
        height="30px"
        maxWidth="100%"
        textAlign="center"
      >
        All done! your new campaign is ready.
      </Text>
      <Center mt="20px" mb="15px">
        <EmbedPopover tenantId={props.tenantId} campaignId={props.campaignId}>
          <Button bg="brand.300" height="40px">
            Embed your campaign
          </Button>
        </EmbedPopover>
      </Center>
      <Text
        fontFamily="Inter"
        lineHeight="1.71"
        fontWeight="regular"
        fontSize="14px"
        textDecoration="underline"
        color="rgba(45, 55, 72, 0.5)"
        width="460px"
        maxWidth="100%"
        textAlign="center"
      >
        Not sure how? Check out our tutorial on embedding a Prosper widget
      </Text>
    </Box>
  );
}

export default Finish;
