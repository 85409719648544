import React, { useEffect, useState } from 'react';
import ShopifyLogo from "../../../assets/img/logos/ShopifyLogo";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertTitle,
  AlertIcon,
  AlertDescription,
  useToken,
} from "@chakra-ui/react";
import axios from 'axios';
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import _ from 'lodash';
import { Auth } from 'aws-amplify';
import { useAuthContext } from 'contexts/AuthContext';
import { useUserContext } from 'contexts/UserContext';



function SignIn() {
  const [loading, setLoading] = useState(false);
  const textColor = useColorModeValue("brand.600", "brand.600");
  const textColorSecondary = useColorModeValue("brand.600", "brand.600");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const teamBg = useColorModeValue(useToken('colors', 'brand.400')+'40', useToken('colors', 'brand.300')+'00')
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "brand.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const allowGoogle = false;

  const [forcedReset, setForcedReset] = React.useState(false);

  const { userState } = useUserContext();
  const [user, setUser] = userState;
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [signInFailed, setSignInFailed] = React.useState(false);
  const [signInFailedType, setSignInFailedType] = React.useState("");
  const [signInFailedReason, setSignInFailedReason] = React.useState("");

  const navigate = useNavigate();


  const handleSignIn = async () => {
    setLoading(true);
    if (!validateEmail(email)) {
      setSignInFailed(true);
      setSignInFailedType("Email");
      setSignInFailedReason("Email format is incorrect.");
      setLoading(false);
      return;
    }
    if (!validatePassword(password)) {
      setSignInFailed(true);
      setSignInFailedType("Password");
      setSignInFailedReason("Passwords are at least 8 characters.");
      setLoading(false);
      return;
    }
    try {
      await Auth.signIn(email, password).then((response) => {
        const responseClone = _.cloneDeep(response);
        return responseClone;
      }).then((response) => {
        if (response.challengeName == "NEW_PASSWORD_REQUIRED") {
          setUser(response);
          setForcedReset(true);
          setSignInFailed(true);
          setSignInFailedType("NewPassword");
          setSignInFailedReason("Must reset password before sign in.");
          setLoading(false);
          // navigate('/auth/forgot-password', { state: {
          //   user: JSON.stringify(response),
          //   message: "Must reset password before signing in. An email has been sent to your account."
          // }});
        } else {
          navigate('/auth/organizations');
        }
      });
    } catch (error) {
      setSignInFailed(true);
      setSignInFailedReason("Email or password is incorrect");
      setLoading(false);
      // TODO: differentiate between user not found and incorrect password
      if (error.name === 'UserNotConfirmedException') {
        navigate('/auth/verify-email', { state: {
          email: email,
          error: {name: error.name, message: error.message},
          message: 'Must verify '+email+' before sign-in.'
      }})} else if (error.name === 'UserNotFoundException') {
        setSignInFailed(true);
        setSignInFailedType("NoUser");
        setSignInFailedReason("No account for "+email+".");
        setLoading(false);
      }
    }
  }

  const handleNewPassword = async () => {
    if (!validatePassword(newPassword)) {
      setSignInFailed(true);
      setSignInFailedType("Password");
      setSignInFailedReason("Passwords are at least 8 characters.");
      return;
    }
    try {
      Auth.completeNewPassword( user, newPassword )
        .then(() => {})
    } catch (error) {
      console.log(error);
      // setSignInFailed(true);
      // setSignInFailedReason("Email or password is incorrect");
      // // TODO: differentiate between user not found and incorrect password
      //
      // if (error.name === 'UserNotConfirmedException') {
      //   navigate('/auth/verify-email', { state: {
      //     email: email,
      //     error: {name: error.name, message: error.message},
      //     message: 'Must verify '+email+' before sign-in.'
      // }})} else if (error.name === 'UserNotFoundException') {
      //   setSignInFailed(true);
      //   setSignInFailedType("NoUser");
      //   setSignInFailedReason("No account for "+email+".");
      // }
    }
  }


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [shopifyToken, setShopifyToken] = useState('')
  const [teamToken, setTeamToken] = useState(null);
  // const [appsumoToken, setAppsumoToken] = useState(null);

  const {token, setToken} = useAuthContext();
  useEffect(() => {
    const team = queryParams.get('teamToken')
    const shopify = queryParams.get('shopifyToken')
    // const appsumo = queryParams.get('appsumoToken')
    if (token) {
      if (token.name === 'team') { setTeamToken(token.value) }
      else if (token.name === 'shopify') { setShopifyToken(token.value) }
      // else if (token.name === 'appsumo') { setAppsumoToken(token.value) }
    }
    else if (team) {
      setTeamToken(team);
      setToken({name: 'team', value: team});
    } else if (shopify) {
      setShopifyToken(shopify);
      setToken({name: 'shopify', value: shopify});
    // } else if (appsumo) {
    //   setAppsumoToken(appsumo);
    //   setToken({name: 'appsumo', value: appsumo});
    }
  }, [location.search]);

  const [shopUrl, setShopUrl] = useState('');
  const getShopUrl = () => {
    axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-shopify-shop-url',
      { 'shopifyToken': shopifyToken },
      { headers: {  } })
      .then(response => {
        setShopUrl(response.data?.shop_url);
      })
      .catch(error => {
        console.error(error);
      })
  }
  useEffect(()=>{
    if (shopifyToken) {
      getShopUrl()
    }
  },[shopifyToken])

  const [inviter, setInviter] = useState(null);
  const [role, setRole] = useState('');
  const getInviter = () => {
    axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/get-team-inviter',
      { 'token': teamToken },
      { headers: {  } })
      .then(response => {
        // console.log(response.data)
        setInviter(response.data.inviter);
        setRole(response.data.role);
        setEmail(response.data.email)
      })
      .catch(error => {
        console.error(error);
      })
  }
  useEffect(()=>{
    if (teamToken) {
      getInviter()
    }
  },[teamToken])


  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const re = /^.{8,}$/;
    return re.test(password);
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        alignItems='center'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to access the dashboard.
          </Text>
        </Box>
        { shopUrl && (
          <Alert
            // bg='#95BF47'
            bg={`rgba(149, 191, 55, 0.2)`}
            status='error'
            mb='24px'
          >
            <ShopifyLogo mr='12px'/>
            <AlertDescription color='brand.700'>
              Linking Prosper Account with<br/> {shopUrl}.
            </AlertDescription>
          </Alert>
        ) }
        { inviter && (
          <Alert bg={teamBg} mb='24px' >
            {/* Icon */}
            <AlertDescription color='brand.700'>
              Accept {inviter?.name} {inviter?.family_name}{inviter?.family_name.endsWith('s') ? "' " : "'s " }
              invitation to join {inviter?.organization_name}.<br/> Don't have an account?
              <NavLink to='/auth/sign-up'
                state={{
                  email: email,
              }}>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'
                  // onClick={}
                >
                   Create a new one.
                </Text>
              </NavLink>
            </AlertDescription>
          </Alert>
        ) }
        { signInFailed && (
          <Alert
            status='error'
            mb='24px'
          >
            <AlertIcon/>
            <AlertDescription>
              { signInFailedReason }
              { signInFailedType === "NoUser" &&  (
                <>
                <br/>
                <NavLink to='/auth/sign-up'
                  state={{
                    email: email,
                }}>
                  <Text
                    color={textColorBrand}
                    as='span'
                    ms='5px'
                    fontWeight='500'
                    // onClick={}
                  >
                    Create an Account
                  </Text>
                </NavLink>
                </>
              ) }
            </AlertDescription>
          </Alert>
        ) }
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <Button
            hidden={!allowGoogle}
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button>
          <Flex
            hidden={!allowGoogle}
            align='center'
            mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>
          { forcedReset ? (
          <FormControl>
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                id='password'
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
                onKeyDown={(event) => {if (event.key === 'Enter') {handleNewPassword()}}}
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={handleNewPassword}
            >
              Change Password
            </Button>
          </FormControl>
          ) : (
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              borderColor='brand.400'
              border='2px'
              disabled={teamToken}
              id='email'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              onKeyDown={(event) => {if (event.key === 'Enter') {handleSignIn()}}}
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@simmmple.com'
              mb='24px'
              fontWeight='500'
              size='lg'
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                borderColor='brand.400'
                border='2px'
                id='password'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={(event) => {if (event.key === 'Enter') {handleSignIn()}}}
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              {/*
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Stay logged in
                </FormLabel>
              </FormControl>
              */}
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <Button
              isLoading={loading}
              fontSize='sm'
              color='brand.700'
              _hover={{ bg: "brand.300" }}
              bg='brand.450'
              fontWeight='600'
              w='100%'
              h='50'
              mb='24px'
              onClick={handleSignIn}
            >
              Sign In to Dashboard
            </Button>
          </FormControl>
          ) }
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/sign-up'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'
                  // onClick={}
                >
                  Create an Account
                </Text>
              </NavLink>
            </Text>
            <Text
              mt='12px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              If you have already created an account and didn't
              receive a verification email, try to sign in and it wil be re-sent.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
