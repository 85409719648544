import { mode } from "@chakra-ui/theme-tools";
export const alertStyles = {
  components: {
    Alert: {
      baseStyle: {
        description:{
        },
        container: {
          rounded: '5px',
        },
      },
      variants: {
        modal: (props) => ({
          bg: "gray.900",
          color: "brand.100",
          textAlign: "center",
        })
      },
    },
  },
};
