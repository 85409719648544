// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  Text,
  useColorModeValue,
  useBreakpoint,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  Image,
  Divider,
  LinkProps,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary, message, brandText } = props;
  const breakpoint = useBreakpoint();

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let backgroundColor = useColorModeValue("brand.700", "brand.700");
  let innerBackgroundColor = useColorModeValue("brand.600", "brand.600");
  let innerShadow = "0px 5px 5px 0px #33333322";
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  // let navbarBackdrop = "blur(20px)";
  let navbarBackdrop = "none";
  // let navbarShadow = "0px 5px 5px 0px #00000022";
  let navbarShadow = "none";
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      // background={backgroundColor}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      // transitionDelay='0s, 0s, 0s, 0s'
      // transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
      // transition-property='box-shadow, background-color, filter, border'
      // transitionTimingFunction='linear, linear, linear, linear'
      alignItems={{ xl: "center" }}
      display={secondary ? "block" : "flex"}
      minH="75px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right='0'
      pt="8px"
      top={{ base: "12px", md: "16px", xl: "18px" }}
      w={
        props.setWidth
          ? {
              xxs: "calc(100vw - 36px)",
              "2sm": "calc(100vw - 40px)",
              md: "calc(100vw - 60px)",
              lg: "calc(100vw - 60px)",
              xl: "calc(100vw - 350px)",
              xxl: "calc(100vw - 350px)",
              xxxl: "calc(100vw - 350px)",
            }
          : "auto"
      }
    >
      <Flex
        w="100%"
        flexDirection={{
          xxs: "column",
          md: "row",
        }}
        alignItems={{ xl: "center" }}
        mb={gap}
      >
        {/*
        <Box
          mb={{ xxs: "8px", md: "0px" }}
          px='24px'
          py='4px'
          borderRadius='18px'
          boxShadow={innerShadow}
          bg={innerBackgroundColor}>
          { breakpoint !== '2sm' && breakpoint !== 'sm' ? (
            <Breadcrumb>
              { props.category ? (
                <BreadcrumbItem color={secondaryText} fontSize='sm' mb='5px'>
                  <BreadcrumbLink href='#' color={secondaryText}>
                    {props.category}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              ) : null }
              <BreadcrumbItem color={secondaryText} fontSize='sm'>
                <BreadcrumbLink href='#' color={secondaryText}>
                  {brandText}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          ) : null}
          <Link
            color={mainText}
            href='#'
            bg='inherit'
            borderRadius='inherit'
            fontWeight='bold'
            fontSize='34px'
            _hover={{ color: { mainText } }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}>
            {brandText}
          </Link>
        </Box>
        */}
        <Box ms="auto" w={{ xxs: "100%", md: "unset" }}>
          <AdminNavbarLinks
            bg={innerBackgroundColor}
            shadow={innerShadow}
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
            scrolled={scrolled}
            routes={props.routes}
            user={props.user}
            signOut={props.signOut}
          />
        </Box>
      </Flex>
      {secondary ? <Text color="white">{message}</Text> : null}
    </Box>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
