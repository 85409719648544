//xshakra imports
import {
  Box,
  useToken,
  Text,
  Icon,
  SimpleGrid,
  Flex,
  Avatar,
  Divider,
  chakra,
  Badge,
  Image,
  List,
  ListItem,
  Button,
  useBreakpointValue,
  Spacer,
  useColorModeValue,
} from "@chakra-ui/react";
import d3 from "assets/d3/index.js";
import * as yup from 'yup';
import { subMonths, subDays, addDays } from 'date-fns';

// Assets
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MdCheckBoxOutlineBlank,
  MdAccessTime,
  MdLink,
  MdOutlineCheckBox,
} from "react-icons/md";
import { Auth } from "aws-amplify";
import axios from "axios";
import { isSunday } from 'date-fns';
import { useUserContext } from "contexts/UserContext.js";
import OrganizationAvatar from "assets/img/svgs/OrganizationAvatar.js";
import { Formik, Form, useField } from "formik";
import FormikTextArea from "components/formik/FormikTextArea.js";
import FormikCheckbox from "components/formik/FormikCheckBox.js";
import { FaScroll } from "react-icons/fa";
import { PiScroll } from "react-icons/pi";
import { BsFileText, BsFileTextFill, BsLayoutTextSidebar, BsLayoutTextSidebarReverse } from "react-icons/bs";
import { FiExternalLink } from "react-icons/fi";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export default function Overview(props) {


  const { userState, membersState, tenantsState, activeTenantState, campaignsState, widgetsState, domainsState } = useUserContext();
  const [user, setUser] = userState;
  const [tenants, setTenants] = tenantsState;
  const [activeTenant, setActiveTenant] = activeTenantState;
  const [members, setMembers] = membersState;
  const [campaigns, setCampaigns] = campaignsState;
  const [widgets, setWidgets] = widgetsState;
  const [domains, setDomains] = domainsState;
  const [activeTenantInfo, setActiveTenantInfo] = useState();


  const [referrals, setReferrals] = useState()
  const qs = require('qs');
  const getReferrals = () => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();

        axios.get('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-get-referrals', {
          headers: { "Authorization": jwtToken },
          params: {
            "tenant_id": activeTenant,
            "request_type": "date_range",
            "campaigns": qs.stringify(campaigns.map((camp)=>{return camp.campaign_id})),
            "start_date": subMonths(new Date(), 1).toISOString().slice(0, 10),
            "end_date": addDays(new Date(), 1).toISOString().slice(0, 10)
          }
        })
          .then(response => {
            setReferrals(
              response.data.map((dateData)=>{
                const name = dateData['name']
                dateData['name'] = 0
                dateData['total'] = Object.values(dateData).reduce((acc, val)=>{
                  return acc + val
                });
                dateData['name'] = name
                return dateData
              })
            );
          })
          .catch(error => {
            // Handle any errors
            console.error(error);
          });
        })
        .catch(error => {
          // Handle any errors
          console.error(error);
      });
  }

  useEffect(()=>{
    getReferrals()
  },[campaigns])

  const borderColor = useColorModeValue('#eee', '#303436');
  const bgColor = useColorModeValue('#ffffff','#1A1C1D');
  const black700 = useColorModeValue('black.700', 'black.300');
  const black600 = useColorModeValue('black.600', 'black.400');




  const navigate = useNavigate();

  useEffect(()=>{
    setActiveTenantInfo(tenants.find((tenant)=>{
      return tenant.tenant_id === activeTenant
    }))
  },[])

  const HomeBox = ({children, ...props}) => {
    return (
      <Box
        boxShadow='lg'
        shadow={useColorModeValue(
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);',
            '0 10px 15px -3px rgba(0, 0, 0, 0.3), 0 4px 6px -2px rgba(0, 0, 0, 0.15);',
        )}
        py={"12px"}
        px={"20px"}
        width={"100%"}
        position={"relative"}
        borderRadius={"12px"}
        backgroundClip={"border-box"}
        bg={bgColor}
        // bgGradient='radial(ellipse at 50% -100px, brand.100 0%, brand.400 600%)'
        border="1px solid"
        borderColor={borderColor}
        {...props}
      >
        {children}
      </Box>
    )
  }

  const AccountOverview = (props) => {

    const roleBadges = {
      'owner':
        <Badge variant='subtleBrandDark' fontSize={'xx-small'} py='2px' pr='4px' pl='4px' colorScheme='blue' textAlign='center' borderRadius='5px' border='1px solid #ccc'>
          Owner
        </Badge>,
      'member':
        <Badge variant='subtleBrandLight' fontSize={'xx-small'} py='2px' pr='4px' pl='4px' colorScheme='green' textAlign='center' borderRadius='5px' border='1px solid #ccc'>
          Member
        </Badge>,
      'admin':
        <Badge variant='subtleBrandGrayLight' fontSize={'xx-small'} py='2px' pr='4px' pl='4px' colorScheme='red' textAlign='center' borderRadius='5px' border='1px solid #ccc'>
          Admin
        </Badge>,
      'user':
        <Badge variant='subtle' fontSize={'xx-small'} py='2px' pr='4px' pl='4px' colorScheme='amber' textAlign='center' borderRadius='5px' border='1px solid #ccc'>
          You
        </Badge>,
    }

    return (
      <HomeBox
        {...props}
      >
        <Flex justifyContent='space-between'>
          <Flex mb='12px' alignItems='center'>
            { activeTenantInfo?.team_logo ? (
              <Image
                border='1px solid #adadad'
                boxSize="40px"
                ml='-6px'
                mr="10px"
                borderRadius="full"
                src={activeTenantInfo.team_logo}
              />
            ) : (
              <Avatar
                border='1px solid #adadad'
                boxSize='40px'
                borderRadius="full"
                ml='-6px'
                mr="10px"
                bg='secondaryGray.200'
                icon={<OrganizationAvatar/>}
              />
            ) }
            <Box>
              <Text
                color={black700}
                fontWeight="semibold"
                fontSize="lg"
              >
                {activeTenantInfo?.team_name}
              </Text>
              <Text color={black600} fontWeight='semibold' fontSize="xs">
                {activeTenantInfo?.team_url}
              </Text>
            </Box>
          </Flex>
          {/*
          <FiEdit onClick={()=>{ }}/>
          */}
          <Box>
            <Text
              cursor='pointer'
              color='black.400'
              fontWeight={'semibold'}
              fontSize={'xx-small'}
              letterSpacing='wider'
              onClick={()=>{
                navigate('/auth/organizations', {state:{organization: activeTenant}})
              }}
            >
              VIEW
            </Text>
          </Box>
        </Flex>

        {/*
        <Flex mb='12px'>
          <Avatar
            _hover={{ cursor: "pointer" }}
            name=""
            bg="brand.600"
            size="sm"
            w="60px"
            h="60px"
            mr="12px"
          />
          <Box>
            <Text
              color={textColorSpecial}
              fontWeight="semibold"
              fontSize="lg"
            >
              {user?.attributes?.name}{" "}
              {user?.attributes?.family_name}
            </Text>
            <Text color={textColorSpecial} fontSize="xs">
              {user.attributes.email}
            </Text>
            <Text color="#adadad" fontSize="xx-small" fontWeight='medium'>
              {user.attributes.sub}
            </Text>
          </Box>
        </Flex>
        */}
        <Flex mb='6px' justifyContent='space-between' alignItems='center'>
          <Text fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
            TEAM MEMBERS ·{' '}
            <chakra.span color='black.400'>
              {members?.length}
            </chakra.span>
          </Text>
          {/*
          <Text
            fontSize={'sm'}
            fontWeight='medium'
            color='black.600'>
            Edit Team
          </Text>
          */}
          {/*
          <FiEdit/>
          */}
          <Text
            cursor='pointer'
            color='black.400'
            fontWeight={'semibold'}
            fontSize={'xx-small'}
            letterSpacing='wider'
            onClick={()=>{
              navigate('/admin/team')
            }}
          >
            VIEW
          </Text>
        </Flex>
        { members
          ? <Box mt='-6px'> {
            members.map((member, index) => {
              // if (member?.user_id === user?.attributes?.sub) { return <></> }
              return ( <chakra.span key={index}>
                <Divider mb='6px'/>
                <Flex mb='6px' alignItems='center'>
                  <Avatar
                    border='1px solid #adadad'
                    _hover={{ cursor: "pointer" }}
                    name=""
                    bg="brand.600"
                    size="sm"
                    w="24px"
                    h="24px"
                    mr="12px"
                  />
                  <Box>
                    <Text
                      color={black700}
                      fontWeight="semibold"
                      fontSize="sm"
                    >
                      {member?.name}{" "}
                      {member?.family_name}
                    </Text>
                    <Text color='black.400' fontWeight='semibold' fontSize="x-small">
                      {user.attributes.email}
                    </Text>
                  </Box>
                  <Spacer/>
                  <Flex alignSelf='start'>
                    {roleBadges[member?.role]}
                  </Flex>
                </Flex>
              </chakra.span>)})
          } </Box>
          : null }
      </HomeBox>
    )
  }

  const changes = [
    {
      name: 'DOCS UPDATE',
      date: '02/13/24',
      subitems: [
        {
          name: 'New Home',
          details: 'We have moved docs to their own home outside the dashboard so that you can view them from any device without the hassle of having to be signed in.'
        }, {
          name: 'More Guides',
          details: 'More docs and guides have been added to help you utilize every feature.'
        }
      ]
    },
    {
      name: 'HOMEPAGE UPDATE',
      date: '02/12/24',
      subitems: [
        {
          name: 'Reports Preview',
          details: 'A small preview of the upcoming reports page has been added to give you a brief overview when you arrive.'
        }, {
          name: '\'Get Started\' Section',
          details: 'A short tutorial to guide you through what we think are the most necessary steps to your referral campaigns\' success.'
        }, {
          name: 'Team Preview',
          details: 'Get the context of which organization you are in and who you are working with at a glance.'
        }, {
          name: 'Feedback Section',
          details: 'A place for you to give us any feedback, suggestions or ask for support!'
        }
      ]
    }
  ]

  const ChangeLog = (props) => {

    const ChangeLogItem = ({info}) => {
      const ChangeLogSubItem = ({subinfo}) => {
        return (
          <ListItem ml='6px' my='6px'>
            <Text fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
              {subinfo.name}
            </Text>
            <Text ml='12px' fontSize='xs' fontWeight='medium' color={black600}>
              {subinfo.details}
            </Text>
          </ListItem>
        )
      }
      return (
        <>
          <Flex justifyContent='space-between' flexDir={{xxs: 'row', xs: 'column', sm: 'row'}}>
            <Text fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
              {info.name}
            </Text>
            <Text whiteSpace={'nowrap'} fontWeight={'bold'} fontSize={'xs'} letterSpacing='wider'
              color={useColorModeValue('black.400', 'black.500')}>
              · {info.date}
            </Text>
          </Flex>
          <List
            mx='-20px'
            px='20px'
            // bgGradient={'linear(to-b, brand.100 -25%, white 50%, brand.100 125%)'}
            bg={useColorModeValue('#FDFCFB', '#181A1B')}
          >
            <Divider mt='12px'/>
            {info.subitems.map((subinfo, index)=>{
              return (
                <ChangeLogSubItem subinfo={subinfo} key={index}/>
              )
            })}
            <Divider mt='24px' mb='12px'/>
          </List>
        </>
      )
    }
    return (
      <HomeBox
        overflowY='scroll'
        {...props}
      >
        {changes.map((info, index)=>{ return (
          <ChangeLogItem info={info} key={index}/>
        )})}
      </HomeBox>
    )
  }

  const StatBox = ({stat, passedKey}) => {

    const inactiveColor = useToken('colors', 'secondaryGray.700')
    const cursorColor = useToken('colors', 'black.100')

    const data = [
      { total:  131, revenue:  1310, date: new Date('2024', '1',  '1') },
      { total:  135, revenue:  1350, date: new Date('2024', '1',  '2') },
      { total:  164, revenue:  1640, date: new Date('2024', '1',  '3') },
      { total:  199, revenue:  1990, date: new Date('2024', '1',  '4') },
      { total:  185, revenue:  1850, date: new Date('2024', '1',  '5') },
      { total:  298, revenue:  2980, date: new Date('2024', '1',  '6') },
      { total:  229, revenue:  2290, date: new Date('2024', '1',  '7') },
      { total:  279, revenue:  2790, date: new Date('2024', '1',  '8') },
      { total:  432, revenue:  4320, date: new Date('2024', '1',  '9') },
      { total:  489, revenue:  4890, date: new Date('2024', '1', '10') },
      { total:  312, revenue:  3120, date: new Date('2024', '1', '11') },
      { total:  492, revenue:  4920, date: new Date('2024', '1', '12') },
      { total:  573, revenue:  5730, date: new Date('2024', '1', '13') },
      { total:  533, revenue:  5330, date: new Date('2024', '1', '14') },
      { total:  694, revenue:  6940, date: new Date('2024', '1', '15') },
      { total:  866, revenue:  8660, date: new Date('2024', '1', '16') },
      { total:  690, revenue:  6900, date: new Date('2024', '1', '17') },
      { total:  682, revenue:  6820, date: new Date('2024', '1', '18') },
      { total: 1116, revenue: 11160, date: new Date('2024', '1', '19') },
      { total:  999, revenue:  9990, date: new Date('2024', '1', '20') },
      { total: 1298, revenue: 12980, date: new Date('2024', '1', '21') },
      { total: 1234, revenue: 12340, date: new Date('2024', '1', '22') },
      { total: 1615, revenue: 16150, date: new Date('2024', '1', '23') },
      { total: 1263, revenue: 12630, date: new Date('2024', '1', '24') },
      { total: 2165, revenue: 21650, date: new Date('2024', '1', '25') },
      { total: 1565, revenue: 15650, date: new Date('2024', '1', '26') },
      { total: 1498, revenue: 14980, date: new Date('2024', '1', '27') },
      { total: 1940, revenue: 19400, date: new Date('2024', '1', '28') },
      { total: 1714, revenue: 17140, date: new Date('2024', '1', '29') },
      { total: 2532, revenue: 25320, date: new Date('2024', '1', '30') },
      { total: 2350, revenue: 23500, date: new Date('2024', '1', '31') }
    ];

    const brand100 =  useToken('colors', 'brand.100');
    const brand200 =  useToken('colors', 'brand.200');
    const brand300 =  useToken('colors', 'brand.300');
    const brand400 =  useToken('colors', 'brand.400');
    const brand500 =  useToken('colors', 'brand.500');
    const brand600 =  useToken('colors', 'brand.600');
    const brand700 =  useToken('colors', 'brand.700');
    const brand800 =  useToken('colors', 'brand.800');
    const brand900 =  useToken('colors', 'brand.900');

    // const gradientColorTop    = '#CC7185';
    // const gradientColorTop    = '#FB896E';
    // const gradientColorTop    = '#EA9372';
    // const gradientColorTop    = '#F58A94';
    const gradientColorTop    = '#87D6BF';
    // const gradientColorTop    = '#BDE7D9';

    // const gradientColorBottom = useToken('colors', 'brand.100');
    const gradientColorBottom    = '#FFFFFF';

    const tickColor =  useToken('colors', 'black.400');

    useEffect(() => {
      const getPath = (x, y, width, height, radius, bottom) => {
        if (height === 0) { return }
        height = height + 1
        const halfWidth = width / 2;
        radius = width * 2 / 5;
        x += 1;
        return bottom ? `
          m ${x + halfWidth} ${y}
          l ${-halfWidth + radius} ${0}
          q ${-radius} ${0} ${-radius} ${radius}
          l ${0} ${height-radius}
          l ${width} ${0}
          l ${0} ${-height+radius}
          q ${0} ${-radius} ${-radius} ${-radius}
          l ${-halfWidth + radius} ${0}
        ` : `
          m ${x + halfWidth} ${y}
          l ${-halfWidth + radius} ${0}
          q ${-radius} ${0} ${-radius} ${radius}
          l ${0} ${height}
          q ${0} ${-radius} ${radius} ${-radius}
          l ${(halfWidth - radius)*2} ${0}
          q ${radius} ${0} ${radius} ${radius}
          l ${0} ${- height}
          q ${0} ${-radius} ${-radius} ${-radius}
          l ${-halfWidth + radius} ${0}
        `
      };

      const updateDimensions = () => {
        console.log(passedKey)
        const div = d3.select("#lower"+passedKey)
        const margin = {top: 36, right: 12, bottom: 18, left: 30},
            width = div?.node()?.getBoundingClientRect()?.width - margin.left - margin.right,
            height = div?.node()?.getBoundingClientRect()?.height - margin.bottom - margin.top

        // append the svg object to the body of the page
        div.select('svg').remove()
        const svg = div
          .append('svg')
            .attr('width', width + margin.right + margin.left)
            .attr('height', height + margin.top + margin.bottom)
          .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

          var parseDate = d3.timeParse('%Y-%m-%d');
          var formatDate = d3.timeFormat('%b %d')

          var x = d3.scaleBand().range([0, width]).padding(0.1);
          var y = d3.scaleLinear().range([height, 0]);

          x.domain(data.map(function (d, index) { return d.date; }));
          y.domain([0, d3.max(data, function (d) { return d.total; })]);

          // Create bars
          // svg.selectAll('.bar')
          //   .data(data)
          //   .enter().append('path')
          //     .attr('d', function(d) {
          //       const maxW = 24;
          //       const px = Math.min(Math.max((Math.max(x.bandwidth(), maxW) - maxW), 6), x.bandwidth()*0.2);
          //       return getPath( // x, y, width, heigh, radius, bottom
          //         x(d.date) + px - x.bandwidth()/4,
          //         y(d.total),
          //         x.bandwidth() - px,
          //         height - y(d.total),
          //         x.bandwidth() / 4,
          //         true
          //     )})
          //     .attr('fill', 'url(#Gradient2)'); // Use the linear gradient
              // .attr('fill', barColor)
          var line = d3.line()
              .x(function(d) { return x(d.date); })
              .y(function(d) { return y(d.total); })
              .curve(d3.curveMonotoneX)

          // svg.append("path")
          //     .datum(dataset1)
          //     .attr("class", "line")
          //     .attr("transform", "translate(" + 100 + "," + 100 + ")")
          //     .attr("d", line)
          //     .style("fill", "none")
          //     .style("stroke", "#CC0000")
          //     .style("stroke-width", "2");

          svg.append("path")
              .attr("fill", "none")
              .attr("stroke", '#B0E2D4')
              .attr("stroke-width", 2.5)
              .attr("d", line(data));

          // Define the linear gradient
          svg.append('defs')
              .append('linearGradient')
              .attr('id', 'Gradient2')
              .attr('x1', '0')
              .attr('x2', '0')
              .attr('y1', '0')
              .attr('y2', '1')
              .selectAll('stop')
              .data([
                  { offset: '0%', color: gradientColorTop },
                  { offset: '100%', color: gradientColorBottom }
              ])
              .enter().append('stop')
              .attr('offset', d => d.offset)
              .attr('stop-color', d => d.color)
              .attr('stop-opacity', d => d.opacity || 1);
              // .enter().append('rect')
              // .attr('class', 'bar')
              // .attr('x', function (d) { return x(formatDate(d.date)); })
              // .attr('width', x.bandwidth())
              // .attr('y', function (d) { return y(d.total); })
              // .attr('height', function (d) { return height - y(d.total); });

          // Add x-axis
          // svg.append('g')
          //   .attr('class', 'axis')
          //   .attr('transform', 'translate(0,' + height + ')')
          //   .call(d3.axisBottom(x)
          //     .ticks(d3.timeWeeks)
          //     .tickFormat((d)=>{
          //       return isSunday(d) ? formatDate(d).split('').map(element => element.toUpperCase()).join('') : ''
          //     })
          //   );


          // Add y-axis
          svg.append('g')
            .attr('class', 'axis')
            .call(d3.axisLeft(y).ticks(3).tickFormat((n)=>{
              if (n >= 1000000) {
                return Math.floor(n / 1000000) + ((n % 1000000) ?? '') + 'M';
              } else if (n >= 1000) {
                return Math.floor(n / 1000) + ((n % 1000) ? '.' + ((n % 1000)+'').slice(0,1) : '') + 'k';
              } else {
                return n.toString();
              }
            }));

          svg.selectAll('.axis path')
              .style('display', 'none');

          svg.selectAll('.tick line')
              .style('display', 'none');

          svg.selectAll('.tick text')
              .style('color', tickColor)
              .style('font-family', 'Inter')
              .style('font-size', '12px')
              .style('font-weight', 'var(--chakra-fontWeights-semibold)')
      };

      // Initial setup
      updateDimensions();

      // Attach resize event listener
      window.addEventListener("resize", updateDimensions);

      // Cleanup
      return () => {
        window.removeEventListener("resize", updateDimensions);
      };
    }, []);


    return (
      <HomeBox w='100%' h='120px' display='flex' flexDir='column'>
        <Text justifySelf='start' fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
          {stat.title}
        </Text>
        <Spacer/>
        <Box position='absolute' top='0' left='0' h='100%' w='100%' id={'lower'+passedKey}></Box>
        <Box
          bottom='0px'
          position='absolute'
          justifySelf='end'
          alignSelf='flex-end'
          mb='6px'
        >
          <Text
            fontSize={'x-small'}
            fontWeight={'semibold'}
            // fontWeight={'semibold'}
            // letterSpacing='wider'
            // color='newBrand.600'
            color='black.400'
            textShadow='0px 0px 5px #ffffffff'
            lineHeight='1'
            textAlign='end'
            letterSpacing='wider'
          >
            THIS MONTH
          </Text>
          <Text
            fontSize={'3xl'}
            fontWeight={'medium'}
            // fontWeight={'semibold'}
            // letterSpacing='wider'
            // color='newBrand.600'
            color='black.600'
            textShadow='0px 0px 20px #ffffffff'
            lineHeight='1'
          >
            {data.reduce((acc, d)=>{return acc + d.total},0)}
          </Text>
        </Box>
      </HomeBox>
      // <HomeBox p='8px' display='flex' flexDir='column'>
      // <Box ml='-12px' h='100%' w='calc(100% + 12px)' id={'lower'+props.passedKey}></Box>
      // </HomeBox>
    )
  }



  const ReferralGraph = (props) => {

    const inactiveColor = useToken('colors', 'secondaryGray.700')
    const cursorColor = useToken('colors', 'black.100')

    const data = referrals
    // const data = [
    //   { total:  131, revenue:  1310, date: new Date('2024', '1',  '1') },
    //   { total:  135, revenue:  1350, date: new Date('2024', '1',  '2') },
    //   { total:  164, revenue:  1640, date: new Date('2024', '1',  '3') },
    //   { total:  199, revenue:  1990, date: new Date('2024', '1',  '4') },
    //   { total:  185, revenue:  1850, date: new Date('2024', '1',  '5') },
    //   { total:  298, revenue:  2980, date: new Date('2024', '1',  '6') },
    //   { total:  229, revenue:  2290, date: new Date('2024', '1',  '7') },
    //   { total:  279, revenue:  2790, date: new Date('2024', '1',  '8') },
    //   { total:  432, revenue:  4320, date: new Date('2024', '1',  '9') },
    //   { total:  489, revenue:  4890, date: new Date('2024', '1', '10') },
    //   { total:  312, revenue:  3120, date: new Date('2024', '1', '11') },
    //   { total:  492, revenue:  4920, date: new Date('2024', '1', '12') },
    //   { total:  573, revenue:  5730, date: new Date('2024', '1', '13') },
    //   { total:  533, revenue:  5330, date: new Date('2024', '1', '14') },
    //   { total:  694, revenue:  6940, date: new Date('2024', '1', '15') },
    //   { total:  866, revenue:  8660, date: new Date('2024', '1', '16') },
    //   { total:  690, revenue:  6900, date: new Date('2024', '1', '17') },
    //   { total:  682, revenue:  6820, date: new Date('2024', '1', '18') },
    //   { total: 1116, revenue: 11160, date: new Date('2024', '1', '19') },
    //   { total:  999, revenue:  9990, date: new Date('2024', '1', '20') },
    //   { total: 1298, revenue: 12980, date: new Date('2024', '1', '21') },
    //   { total: 1234, revenue: 12340, date: new Date('2024', '1', '22') },
    //   { total: 1615, revenue: 16150, date: new Date('2024', '1', '23') },
    //   { total: 1263, revenue: 12630, date: new Date('2024', '1', '24') },
    //   { total: 2165, revenue: 21650, date: new Date('2024', '1', '25') },
    //   { total: 1565, revenue: 15650, date: new Date('2024', '1', '26') },
    //   { total: 1498, revenue: 14980, date: new Date('2024', '1', '27') },
    //   { total: 1940, revenue: 19400, date: new Date('2024', '1', '28') },
    //   { total: 1714, revenue: 17140, date: new Date('2024', '1', '29') },
    //   { total: 2532, revenue: 25320, date: new Date('2024', '1', '30') },
    //   { total: 2350, revenue: 23500, date: new Date('2024', '1', '31') }
    // ];

    const brand100 =  useToken('colors', 'brand.100');
    const brand200 =  useToken('colors', 'brand.200');
    const brand300 =  useToken('colors', 'brand.300');
    const brand400 =  useToken('colors', 'brand.400');
    const brand500 =  useToken('colors', 'brand.500');
    const brand600 =  useToken('colors', 'brand.600');
    const brand700 =  useToken('colors', 'brand.700');
    const brand800 =  useToken('colors', 'brand.800');
    const brand900 =  useToken('colors', 'brand.900');

    // const gradientColorTop    = useToken('colors', 'orange.500');
    // const gradientColorBottom = useToken('colors', 'orange.200');
    // const gradientColorTop    = '#56BE8F';
    // const gradientColorBottom = useToken('colors', 'brand.200');
    const gradientColorTop    = brand400;
    const gradientColorBottom = brand200;

    const tickColor =  useToken('colors', 'black.400');

    useEffect(() => {
      if (! data) { return }
      const getPath = (x, y, width, height, radius, bottom) => {
        if (height === 0) { return }
        height = height + 1
        const halfWidth = width / 2;
        radius = radius ? radius : width * 2 / 5;
        x += 1;
        return bottom ? `
          m ${x + halfWidth} ${y}
          l ${-halfWidth + radius} ${0}
          q ${-radius} ${0} ${-radius} ${radius}
          l ${0} ${height-radius}
          l ${width} ${0}
          l ${0} ${-height+radius}
          q ${0} ${-radius} ${-radius} ${-radius}
          l ${-halfWidth + radius} ${0}
        ` : `
          m ${x + halfWidth} ${y}
          l ${-halfWidth + radius} ${0}
          q ${-radius} ${0} ${-radius} ${radius}
          l ${0} ${height}
          q ${0} ${-radius} ${radius} ${-radius}
          l ${(halfWidth - radius)*2} ${0}
          q ${radius} ${0} ${radius} ${radius}
          l ${0} ${- height}
          q ${0} ${-radius} ${-radius} ${-radius}
          l ${-halfWidth + radius} ${0}
        `
      };

      const updateDimensions = () => {

        const div = d3.select("#my_dataviz")
        const margin = {top: 10, right: 10, bottom: 20, left: 28},
            width = div?.node()?.getBoundingClientRect()?.width - margin.left - margin.right,
            height = div?.node()?.getBoundingClientRect()?.height - margin.bottom - margin.top

        // append the svg object to the body of the page
        div.select('svg').remove()
        const svg = div
          .append('svg')
            .attr('width', width + margin.right + margin.left)
            .attr('height', height + margin.top + margin.bottom)
          .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

          var parseDate = d3.timeParse('%Y-%m-%d');
          var formatDate = d3.timeFormat('%b %d')

          var x = d3.scaleBand().range([0, width]).padding(0.1);
          var y = d3.scaleLinear().range([height, 0]);

          x.domain(data.map(function (d) { return new Date(d.name); }));
          y.domain([0, Math.max(d3.max(data, function (d) { return d.total; }), 2)]);

          // Create bars
          svg.selectAll('.bar')
            .data(data)
            .enter().append('path')
              .attr('d', function(d) {
                const maxW = 16;
                console.log(x.bandwidth())
                const px =
                Math.max(
                  Math.max(
                    Math.max(
                      (Math.max(
                        x.bandwidth(),
                        maxW
                      ) - maxW),
                      6
                    ),
                    (x.bandwidth() - maxW) / 2
                  ),
                  x.bandwidth()*0.2
                );
                return getPath( // x, y, width, heigh, radius, bottom
                  x(new Date(d.name)) + px - x.bandwidth()/4,
                  y(d.total),
                  x.bandwidth() - px,
                  height - y(d.total) + 0.5,
                  Math.min((x.bandwidth() - px) * 2 / 5, height - y(d.total) + 0.5),
                  true
              )})
              .attr('fill', 'url(#Gradient3)'); // Use the linear gradient
              // .attr('fill', barColor)

          // Define the linear gradient
          svg.append('defs')
              .append('linearGradient')
              .attr('id', 'Gradient3')
              .attr('x1', '0')
              .attr('x2', '0')
              .attr('y1', '0')
              .attr('y2', '1')
              .selectAll('stop')
              .data([
                  { offset: '0%', color: gradientColorTop },
                  { offset: '100%', color: gradientColorBottom }
              ])
              .enter().append('stop')
              .attr('offset', d => d.offset)
              .attr('stop-color', d => d.color)
              .attr('stop-opacity', d => d.opacity || 1);
              // .enter().append('rect')
              // .attr('class', 'bar')
              // .attr('x', function (d) { return x(formatDate(d.date)); })
              // .attr('width', x.bandwidth())
              // .attr('y', function (d) { return y(d.total); })
              // .attr('height', function (d) { return height - y(d.total); });

          // Add x-axis
          svg.append('g')
            .attr('class', 'axis')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(x)
              .ticks(d3.timeWeeks)
              .tickFormat((d)=>{
                return isSunday(d) ? formatDate(d).split('').map(element => element.toUpperCase()).join('') : ''
              })
            );


          // Add y-axis
          svg.append('g')
            .attr('class', 'axis')
            .call(d3.axisLeft(y).ticks(2).tickFormat((n)=>{
              if (n >= 1000000) {
                return Math.floor(n / 1000000) + ((n % 1000000) ?? '') + 'M';
              } else if (n >= 1000) {
                return Math.floor(n / 1000) + ((n % 1000) ? '.' + ((n % 1000)+'').slice(0,1) : '') + 'k';
              } else {
                return n.toString();
              }
            }));

          svg.selectAll('.axis path')
              .style('display', 'none');

          svg.selectAll('.tick line')
              .style('display', 'none');

          svg.selectAll('.tick text')
              .style('color', tickColor)
              .style('font-family', 'Inter')
              .style('font-size', '12px')
              .style('font-weight', 'var(--chakra-fontWeights-semibold)')
      };

      // Initial setup
      updateDimensions();

      // Attach resize event listener
      window.addEventListener("resize", updateDimensions);

      // Cleanup
      return () => {
        window.removeEventListener("resize", updateDimensions);
      };
    }, [data]);
    console.log(data)
    // console.log(qs.stringify(campaigns.map((camp)=>{return camp.campaign_id})))
    // console.log(campaigns)

    return (
      <Flex
        rounded='20px'
        gap='24px'
        {...props}
      >
        <HomeBox p='8px' display='flex' flexDir='column'>
          <Text ml='12px' mt='4px' fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
            REFERRALS
          </Text>
          <Box h='100%' w='100%' id="my_dataviz"></Box>
        </HomeBox>
      </Flex>
    )
  }


  // needs to be outside to avoid rerenders
  const docText = useBreakpointValue({
    xxs: <Icon as={BsFileText} boxSize='20px' color='black.600'/>,
  })
  const showEstimate = useBreakpointValue({xxs: false, xs: true})
  const Tutorial = () => {

    const tasks = [
      {
        name: 'MAKE A WIDGET',
        docs: 'https://docs.getprosper.io/widgets/overview',
        estimate: '3m',
        completed: !!(widgets?.length)
      }, {
        name: 'MAKE A CAMPAIGN',
        docs: 'https://docs.getprosper.io/campaigns/overview',
        estimate: '5m',
        completed: !!(campaigns?.length)
      }, {
        name: 'CONNECT YOUR DOMAIN',
        docs: 'https://docs.getprosper.io/domains/overview',
        estimate: '5m',
        completed: !!(domains?.length)
      // }, {
      //   name: 'DEPLOY YOUR WIDGET',
      //   docs: '',
      //   estimate: '',
      //   completed: false
      },  {
        name: 'SEND A REFERRAL',
        docs: 'https://docs.getprosper.io/campaigns/deploying',
        estimate: '',
        completed: !!referrals?.reduce((acc, datum)=>{return acc + datum.total},0)
      }

    ]

    const Task = ({task, currentStep}) => {
      return (
        <Flex
          boxShadow='md'
          border="1px solid"
          borderColor={borderColor}
          p='12px'
          px={"20px"}
          rounded={'12px'}
          mb='12px'
          justifyContent='space-between'
          alignItems='center'
          bg={bgColor}
        >
          <Flex>
            <Icon
              as={
                task.completed
                  ? MdOutlineCheckBox
                  : MdCheckBoxOutlineBlank
              }
              fill='black.400'
              mr='12px'/>
            {/*
            <Text mr='4px' color='red.500'>
              *
            </Text>
            */}
            <Text
              fontWeight={'bold'}
              fontSize={'xs'}
              letterSpacing='wider'
              textDecor={
                task.completed
                  ? 'line-through'
                  : ( currentStep
                    ? 'underline'
                    : 'none' )}
              textDecorationColor={
                currentStep
                  ? 'brand.800'
                  : 'black.500'
              }
              textDecorationThickness={'2px'}
            >
              {task.name.toUpperCase()}
            </Text>
          </Flex>

          <Flex>
            { !!task.estimate && showEstimate && (
              <>
                <Icon
                  // mr='36px'
                  mr='6px'
                  as={MdAccessTime}
                  color='black.400'
                  boxSize='18px'
                />
                <Text
                  fontWeight={'bold'}
                  fontSize={'sm'}
                  letterSpacing='wider'
                  color='black.400'
                  mr='36px'
                  // mr='6px'
                >
                  {task.estimate}
                </Text>
              </>
            )}
            <Text
              cursor='pointer'
              onClick={()=>{
                window.open(task.docs)
              }}
              fontWeight={'bold'}
              fontSize={'xs'}
              letterSpacing='wider'
              color='black.600'
            >
              {docText}
            </Text>
          </Flex>
        </Flex>

      )
    }

    const step = Math.min(...tasks.map((task,idx)=> !task.completed ? idx : tasks.length))
    return (
      <HomeBox pb='12px'>
        <Text mb='12px' fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
          GET STARTED ·{' '}
          <chakra.span color='black.400'>
            ({step} / {tasks.length})
          </chakra.span>
        </Text>
        {tasks.map((task, index)=>{return <Task key={index} currentStep={step === index} task={task}/>})}
      </HomeBox>
    )
  }

  const Feedback = (props) => {

    const handleSubmitFeedback = (values) => {
      Auth.currentSession()
        .then(session => {
          const jwtToken = session.getIdToken().getJwtToken();
          const request = {
            feedback: values.feedback,
            should_respond: values.shouldRespond,
            organization_name: activeTenantInfo?.team_name
          }
          axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/submit-feedback',
            request,
            {
              headers: { "Authorization": jwtToken },
            })
            .catch(error => {
              console.error(error);
            })
          })
        .catch(error => {
          console.error(error);
        });
    }

    const FeedbackForm = ({values}) => {
      const validator = (schema) => {
        return async (value) => {
          let error = undefined;
          try { await schema.validate(value); }
          catch (err) {
            error = err.errors[0];
            // console.log("error:", err);
          }
          return error;
        }
      }
      const validateFeedback = validator(yup.string().required())
      const validateResponse = validator(yup.boolean())
      const feedbackField = useField({ name: 'feedback', validate: validateFeedback})
      const responseField = useField({ name: 'shouldRespond', validate: validateResponse})
      return (
        <Form style={{ width: '100%', marginInline: '24px', marginBottom: '24px' }}>
          <Flex w='100%' flexDir={{xxs: 'column'}}>
            <FormikTextArea
              required={true}
              boxShadow={useColorModeValue(
                'inset 0px 0px 10px 0px #FAF9F6',
                'inset 0px 0px 10px 0px #1A1C1D'
              )}
              border='1px solid'
              borderColor={borderColor}
              _focus={{
                borderColor: borderColor
              }}
              bg={useColorModeValue('#FEFEFE', '#181A1B')}
              field={feedbackField}
              validate={validateFeedback}
              minRows={6}
              label="Questions · Comments · Concerns"
              labelProps={{fontSize: 'sm'}}
              controlProps={{
                mr: '36px',
                mb: '0px'
              }}
            />
            <Flex flexDir='row' justifyContent='space-between'>
              <FormikCheckbox
                controlProps={{
                  mt: '36px',
                  mb: '18px',
                  ms: '12px'
                }}
                field={responseField}
                validate={validateResponse}
                label="Would you like us to get back to you?"
                labelProps={{fontSize: 'sm'}}
                minRows={6}
              />
              {/*
              <Button color='white' w='100%' variant='newBrand'>
                Submit
              </Button>
              */}
              <Button

                maxW='120px'
                mt='36px'
                w='100%'
                border='1px solid'
                borderColor={borderColor}
                bg={bgColor}
                boxShadow='sm'
                _hover={{
                  bg: useColorModeValue('#FAFAFA', '#181A1B'),
                }}
                _focus={{
                  boxShadow: "md",
                }}
                _active={{
                  boxShadow: "sm",
                  bg: useColorModeValue('#F0F0F0', '#161616'),
                }}
                onClick={()=>{
                  handleSubmitFeedback(values)
                }}
              >
                Submit
              </Button>
            </Flex>
          </Flex>
        </Form>
      )
    }

    return (
        <HomeBox
          w='100%'
          flexDir={{ xxs: 'column', lg: 'row' }}
          {...props}
        >
          <Text mb='12px' fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
            FEEDBACK
          </Text>
          <Flex
            w='100%'
            // alignItems='center'
            // flexDir='column'
          >
            <Formik
              initialValues={{
                feedback: '',
                shouldRespond: false
              }}
            >
              {({values}) => { return ( <FeedbackForm values={values}/>) }}
            </Formik>
          </Flex>
        </HomeBox>
    )
  }

  const WidgetCount = () => {
    return (
      <HomeBox h='100%' w='100%'>
        <Flex justifyContent='space-between' alignItems='center'>
          <Box>
            <Text fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
              WIDGETS
            </Text>
            <Text
              cursor='pointer'
              color='black.400'
              fontWeight={'semibold'}
              fontSize={'xx-small'}
              letterSpacing='wider'
              onClick={()=>{
                navigate('/admin/widgets')
              }}
            >
              VIEW
            </Text>
          </Box>
          <Text fontWeight={'bold'} fontSize={'lg'} letterSpacing='wider' color='black.400'>
            {widgets?.length}
          </Text>
        </Flex>
      </HomeBox>
    )
  }

  const Resources = () => {


    const helpReviewLinks = [
      {
        text: 'Shopify',
        url: 'https://apps.shopify.com/prosper'
      }, {
        text: 'G2',
        url: 'https://www.g2.com/products/prosper-prosper/reviews'
      }
    ]

    const helpFollowLinks = [
      {
        text: 'LinkedIn',
        url: 'https://www.linkedin.com/company/getprosperio'
      }
    ]

    const docLinks = [
      {
        text: 'Campaigns',
        url: 'https://docs.getprosper.io/campaigns/overview'
      }, {
        text: 'Widgets',
        url: 'https://docs.getprosper.io/widgets/overview'
      }, {
        text: 'Rewards',
        url: 'https://docs.getprosper.io/rewards/overview'
      }, {
        text: 'Organizations',
        url: 'https://docs.getprosper.io/organizations/overview'
      }, {
        text: 'Reports',
        url: 'https://docs.getprosper.io/reports/overview'
      }
    ]

    const aboutLinks = [
      {
        text: 'Our Team',
        url: 'https://getprosper.io/company'
      }, {
        text: 'Our Terms',
        url: 'https://getprosper.io/terms'
      }, {
        text: 'FAQ',
        url: 'https://getprosper.io/faq'
      }, {
        text: 'Whitepaper',
        url: 'https://assets-global.website-files.com/6406488304c4e2275cdb8694/65a9a41c905cdd70ed5a4a19_ProsperWhitePaperJan24.pdf'
      },
    ]

    const HomeLink = ({link}) => {
      return (
        <Text
          whiteSpace='nowrap'
          w='min-content'
          cursor='pointer'
          onClick={()=>{
            window.open(link.url)
          }}
          fontWeight={'normal'}
          fontSize={'xs'}
          letterSpacing='wider'
        >
          <chakra.span color='newBrand.400'>
            •{' '}
          </chakra.span>
          {/*
          */}
          {link.text}
          {/*
          <Icon ml='4px' as={FiExternalLink}/>
          */}
        </Text>
      )
    }

    const linkTypes = [
      {
        name: 'DOCUMENTATION',
        links: docLinks
      }, {
        name: 'ABOUT PROSPER',
        links: aboutLinks
      }, {
        name: 'FOLLOW US',
        links: helpFollowLinks
      }, {
        name: 'REVIEW US',
        links: helpReviewLinks
      }
    ]

    const HomeLinks = ({linkType, columns}) => {
      return (
        <Box w='100%'>
          {/*
          <Text fontWeight={'bold'} fontSize={'x-small'} letterSpacing='wider'>
          */}
          <Text fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
            {linkType.name}
          </Text>
          <Divider mb='6px' w='90%'/>
          <SimpleGrid w='100%' columns={columns}>
            {linkType.links.map((link, index)=>{return (<HomeLink key={index} link={link}/>)})}
          </SimpleGrid>
        </Box>
      )
    }


    return (
      <HomeBox
        pe='12px'
        pb='20px'
        {...props}
      >
        {/*
        <Text fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
          RESOURCES ·{' '}
        </Text>
        <Divider mb='6px'/>
        */}
        <SimpleGrid gridColumnGap='48px' gridRowGap='24px' columns={{ xxs: 3, md: 1 }}>
          {/*
          {linkTypes.map((linkType)=>{return (<HomeLinks columns='2' linkType={linkType}/>)})}
          */}
          <HomeLinks columns={{ xxs: 1, md: 2 }} linkType={linkTypes[0]}/>
          <HomeLinks columns={{ xxs: 1, md: 2 }} linkType={linkTypes[1]}/>

          <SimpleGrid columns={{ xxs: 1, md: 2 }}>
            <HomeLinks columns='1' linkType={linkTypes[2]}/>
            <HomeLinks columns='1' linkType={linkTypes[3]}/>
          </SimpleGrid>
          {/*
          */}
        </SimpleGrid>
        {/*
        <Divider mt='3px' mb='12px'/>
        */}
      </HomeBox>
    )
  }

  const CampaignCount = () => {
    return (
      <HomeBox h='100%' w='100%'>
        <Flex justifyContent='space-between' alignItems='center'>
          <Box>
            <Text fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
              CAMPAIGNS
            </Text>
            <Text
              cursor='pointer'
              color='black.400'
              fontWeight={'semibold'}
              fontSize={'xx-small'}
              letterSpacing='wider'
              onClick={()=>{
                navigate('/admin/campaigns')
              }}
            >
              VIEW
            </Text>
          </Box>
          <Text fontWeight={'bold'} fontSize={'lg'} letterSpacing='wider' color='black.400'>
            {campaigns?.length}
          </Text>
        </Flex>
      </HomeBox>
    )
  }
  const hasCallback = false;

  const stats = [
    {
      title: 'IMPRESSIONS',
      value: '1203'
    }, {
      title: 'CONVERSIONS',
      value: '115'
    }, {
      title: 'REVENUE',
      value: '24'
    }
  ]

  // const StatBox = ({stat, passedKey}) => {
  //   return (
  //         <ConversionGraph passedKey={passedKey} />
  //   )
  // }

  return (
    <Flex w='100%' gap='24px' maxW='1920px' flexDir={{ xxs: 'column', md: 'row' }}>
      <Flex gap='24px' w={{ xxs: '100%', md: 'calc(100% - 300px)' }} flexDir='column'>
        <Flex gap='12px' w='100%' flexDir='column'>
          <Flex gap='12px' w='100%' flexDir={{ xxs: 'column', sm: 'row' }}>
            <Flex gap='12px' w={{ xxs: '100%', sm: '180px' }} flexDir={{ xxs: 'row', sm: 'column' }}>
              <WidgetCount/>
              <CampaignCount/>
            </Flex>
            <ReferralGraph w={{ xxs: '100%', sm: 'calc(100% - 192px)' }} />
          </Flex>

          { !!hasCallback && (
            <Flex gap='12px' w='100%'>
              {stats.map((stat, index)=>{return (<StatBox key={index} passedKey={index} stat={stat}/>)})}
            </Flex>
          ) }
        </Flex>
        <Tutorial/>
        <Feedback/>
        {/*
        <Flex gap='12px' w='100%' flexDir={{ xxs: 'column', lg: 'row' }}>
        </Flex>
        */}
      </Flex>
      <Flex
        w={{ xxs: '100%', md: '300px' }}
        // h='calc(100vh)'
        flexDir='column'
        // position={{ xxs: 'initial', md: 'sticky' }}
        // top={{ xxs: '0px', md: '10px', lg: '20px' }}
        // bottom='24px'
        // position='sticky'
        // top='100px'
        gap='24px'
        // boxShadow='lg'
        // bg='white'
        // rounded='20px'
      >
        <AccountOverview/>
        <Resources/>
        <ChangeLog h={hasCallback ? '466px' : '334px'}/>
      </Flex>
    </Flex>
  )
}
