// SaveOrDiscardModal.js

import React from 'react';
import { Button, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';

const SaveOrDiscardModal = ({ isOpen, onOpen, onClose, onSave, onDiscard, setCurrentStep }) => {
  const backRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen} onClose={() => onClose()}
      closeOnOverlayClick={true} closeOnEsc={true}
      leastDestructiveRef={backRef}
      isCentered={true}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Save Changes?
          </AlertDialogHeader>

          <AlertDialogBody>
            You have unsaved changes. Do you want to save them?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={backRef} onClick={onClose}>
              Back
            </Button>
            <Button bg='brand.300' ml={3} onClick={()=>{}}>
              Save
            </Button>
            <Button bg='red.500' ml={3} onClick={() => {onClose();onDiscard();setCurrentStep(0);/*TODO: discard changes */}}>
              Discard
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default SaveOrDiscardModal;
