import React, { useContext, useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import ShopifyLogo from "../../../assets/img/logos/ShopifyLogo";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertTitle,
  AlertIcon,
  AlertDescription,
  useToken,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import TermsModal from "../../../components/termsModal/TermsModal"

import { Auth, Hub } from 'aws-amplify';
import axios from 'axios';
import { useAuthContext } from "contexts/AuthContext";
import { formatTier } from "variables/tiers";

function makeId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

function SignUp(props) {
  // Chakra color mode
  const [agreed, setAgreed] = useState(false);
  const textColor = useColorModeValue("brand.600", "brand.600");
  const textColorSecondary = "brand.600";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const textColorTerms = useColorModeValue("brand.500", "white");
  const teamBg = useColorModeValue(useToken('colors', 'brand.400')+'40', useToken('colors', 'brand.300')+'00')
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const specialColor = useColorModeValue('#FFBF00', '#BB8800');
  const googleHover = useColorModeValue(
    { bg: "brand.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const allowGoogle = false;

  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = React.useState(location.state ? location.state.email : "");
  const [password, setPassword] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  // const [organizationName, setOrganizationName] = React.useState("");
  // const [organizationURL, setOrganizationURL] = React.useState("");
  const [signUpFailed, setSignUpFailed] = React.useState(false);
  const [signUpSucceeded, setSignUpSucceeded] = React.useState(false);
  const [signUpFailedType, setSignUpFailedType] = React.useState("");
  const [signUpFailedReason, setSignUpFailedReason] = React.useState("");

  const queryParams = new URLSearchParams(location.search);
  const [teamToken, setTeamToken] = useState(null);
  const [shopifyToken, setShopifyToken] = useState(null);
  const [appsumoToken, setAppsumoToken] = useState(null);

  const {token, setToken} = useAuthContext();
  useEffect(() => {
    const team = queryParams.get('teamToken')
    const shopify = queryParams.get('shopifyToken')
    const appsumo = queryParams.get('appsumoToken')
    if (token) {
      if (token.name === 'team') { setTeamToken(token.value) }
      else if (token.name === 'shopify') { setShopifyToken(token.value) }
      else if (token.name === 'appsumo') { setAppsumoToken(token.value) }
    }
    else if (team) {
      setTeamToken(team);
      setToken({name: 'team', value: team});
    } else if (shopify) {
      setShopifyToken(shopify);
      setToken({name: 'shopify', value: shopify});
    } else if (appsumo) {
      setAppsumoToken(appsumo);
      setToken({name: 'appsumo', value: appsumo});
    }
  }, [location.search]);

  // console.log(token)

  const handleShopifyAuth = async () => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-connect-shopify',
          { 'shopifyToken': shopifyToken },
          { headers: { "Authorization": jwtToken } })
          .then(response => {
            // console.log(response);
          })
          .catch(error => {
            console.error(error);
          })
        })
        .catch(error => {
          console.error(error);
      });
  }

  const [shopUrl, setShopUrl] = useState('');
  const getShopUrl = () => {
    axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-shopify-shop-url',
      { 'shopifyToken': shopifyToken },
      { headers: {  } })
      .then(response => {
        setShopUrl(response.data?.shop_url);
      })
      .catch(error => {
        console.error(error);
      })
  }
  useEffect(()=>{
    if (shopifyToken) {
      getShopUrl()
    }
  },[shopifyToken])


  const handleAppsumoAuth = async () => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-connect-appsumo',
          { 'appsumoToken': appsumoToken },
          { headers: { "Authorization": jwtToken } })
          .then(response => {
            // console.log(response);
          })
          .catch(error => {
            console.error(error);
          })
        })
        .catch(error => {
          console.error(error);
      });
  }

  const [tier, setTier] = useState('');
  const checkAppsumoToken = () => {
    axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-get-appsumo-invite',
      { 'appsumoToken': appsumoToken },
      { headers: {  } })
      .then(response => {
        // console.log("check token: ", response.data)
        setTier(response.data.tier);
        setEmail(response.data.email)
      })
      .catch(error => {
        console.error(error);
      })
  }
  useEffect(()=>{
    if (appsumoToken) {
      checkAppsumoToken()
    }
  },[appsumoToken])

  const [inviter, setInviter] = useState(null);
  const [role, setRole] = useState('');
  const getInviter = () => {
    axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/get-team-inviter',
      { 'token': teamToken },
      { headers: {  } })
      .then(response => {
        // console.log(response.data)
        setInviter(response.data.inviter);
        setRole(response.data.role);
        setEmail(response.data.email)
      })
      .catch(error => {
        console.error(error);
      })
  }
  useEffect(()=>{
    if (teamToken) {
      getInviter()
    }
  },[teamToken])

  const handleSignUp = async () => {
    if (!agreed) {
      setSignUpFailed(true);
      setSignUpFailedType("Terms");
      setSignUpFailedReason("Must accept the Terms and Conditions.");
      return;
    }
    if (!validateEmail(email)) {
      setSignUpFailed(true);
      setSignUpFailedType("Email");
      setSignUpFailedReason("Email format is incorrect.");
      return;
    }
    if (!validatePassword(password)) {
      setSignUpFailed(true);
      setSignUpFailedType("Password");
      setSignUpFailedReason("Password must be at least 8 characters.");
      return;
    }

    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          // email,      // optional
          // phone_number,   // optional - E.164 number convention
          // other custom attributes
          // 'custom:tenant_id': makeId(12)
          name: firstName,
          family_name: lastName,
          // 'custom:organization_name': organizationName,
          // 'custom:organization_url': organizationURL
        },
        autoSignIn: { // optional - enables auto sign in after user is confirmed
          enabled: true,
        }
      });
      setSignUpSucceeded(true);
      navigate('/auth/verify-email', {state:{
          email: email,
      }});
    } catch (error) {
      console.log('error signing up:', error);
    }
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const re = /^.{8,}$/;
    return re.test(password);
  };



  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "10vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign Up
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign up!
          </Text>
        </Box>
        { shopUrl && (
          <Alert
            bg='#95BF4733'
            // bg={`rgba(149, 191, 55, 0.2)`}
            mb='24px'
          >
            <ShopifyLogo mr='12px'/>
            <AlertDescription color='brand.700'>
              Linking new Prosper Account with<br/> {shopUrl}.
            </AlertDescription>
          </Alert>
        ) }
        { inviter && (
          <Alert
            // bg='#95BF47'
            // bg={`rgba(149, 191, 55, 0.2)`}
            bg={teamBg}
            mb='24px'
          >
            {/* Icon */}
            <AlertDescription color='brand.700'>
              Accept {inviter?.name} {inviter?.family_name}{inviter?.family_name.endsWith('s') ? "' " : "'s " }
              invitation to join {inviter?.organization_name}.<br/> Already have an account?
              <NavLink to='/auth/sign-in'
                state={{
                  email: email,
              }}>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'
                  // onClick={}
                >
                  Add to existing account.
                </Text>
              </NavLink>
            </AlertDescription>
          </Alert>
        ) }
        { tier && appsumoToken && (
          <Alert
            // bg='#95BF47'
            // bg={`rgba(149, 191, 55, 0.2)`}
            bg={teamBg}
            mb='24px'
          >
            {/* Icon */}
            <AlertDescription color='brand.700'>
              Redeem your AppSumo purchase for<br/>
              Prosper {formatTier(tier)} by creating a new account.
            </AlertDescription>
          </Alert>
        ) }
        { signUpFailed && (
          <Alert
            bg='red.500'
            status='error'
            mb='24px'
          >
            <AlertIcon/>
            <AlertDescription>
              { signUpFailedReason }
            </AlertDescription>
          </Alert>
        ) }

        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <Button
            hidden={!allowGoogle}
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign up with Google
          </Button>
          <Flex
            hidden={!allowGoogle}
            align='center'
            mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>
          <FormControl>
            <Flex gap='32px'>
              <Box>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  First Name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  borderColor='brand.400'
                  border='2px'
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='first_name'
                  placeholder='John'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                />
              </Box>
              <Box>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Last Name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  borderColor='brand.400'
                  border='2px'
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='last_name'
                  placeholder='Doe'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                />
              </Box>
            </Flex>
            {/*
            { !inviter && (
              <>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Organization<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  borderColor='brand.400'
                  border='2px'
                  value={organizationName}
                  onChange={(event) => setOrganizationName(event.target.value)}
                  onKeyDown={(event) => {if (event.key === 'Enter') {handleSignUp()}}}
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='organization'
                  placeholder='ACME Inc.'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                />
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Website<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  borderColor='brand.400'
                  border='2px'
                  value={organizationURL}
                  onChange={(event) => setOrganizationURL(event.target.value)}
                  onKeyDown={(event) => {if (event.key === 'Enter') {handleSignUp()}}}
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='organizationUrl'
                  placeholder='https://www.yourwebsite.com'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                />
              </>
            ) }
            */}
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              borderColor='brand.400'
              border='2px'
              value={email}
              disabled={appsumoToken || teamToken}
              onChange={(event) => setEmail(event.target.value)}
              onKeyDown={(event) => {if (event.key === 'Enter') {handleSignUp()}}}
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='name@domain.com'
              mb='24px'
              fontWeight='500'
              size='lg'
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                borderColor='brand.400'
                border='2px'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={(event) => {if (event.key === 'Enter') {handleSignUp()}}}
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='center' mb='24px' >
              <Checkbox size='md' defaultChecked={false} variant='black' mr='12px' onChange={() => setAgreed(!agreed)}/>
              <Text color={textColorDetails} fontSize='sm'>Agree to</Text>
              {'\u00A0'}
              <TermsModal textColor={textColorTerms}/>
            </Flex>
            <Button
              fontSize='sm'
              color='brand.600'
              variant='brand'
              fontWeight='700'
              w='100%'
              h='50'
              mb='24px'
              _hover={{ bg: "brand.300" }}
              onClick={handleSignUp}
            >
              Sign Up
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Already have an account?
              <NavLink to='/auth/sign-in'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  me='5px'
                  fontWeight='500'
                >
                  Sign In
                </Text>
              </NavLink>
              or
              <NavLink to='/auth/verify-email'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'
                >
                  Resend Verification
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
