import React, { useState } from 'react';
import { Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdHelp } from 'react-icons/md';
import FormikSelect from 'components/formik/FormikSelect';
import FormikInput from 'components/formik/FormikInput';
import { Formik, useField } from 'formik';
import axios from 'axios';
import * as yup from 'yup';
import { Auth } from 'aws-amplify';

const InviteModal = ({ isOpen, onOpen, onClose, onSave, amount, shopUrl, handleSubscribe, refresh, invited, ...props }) => {
  const modalBg = useColorModeValue( "brand.200", "brand.200");

  const backRef = React.useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const Inside = (props) => {
    const validator = (schema) => {
      return async (value) => {
        let error = undefined;
        try { await schema.validate(value); }
        catch (err) {
          error = err.errors[0];
        }
        return error;
      }
    }

    const emailValidator = validator(yup.string().email().notOneOf(invited, "Already a team member.").required("This is required."));
    const roleValidator = validator(yup.string().required("This is required.").lowercase());
    const emailField = useField({ name: 'email', validate: emailValidator });
    const roleField = useField({ name: 'role', validate: roleValidator });
    return (
      <>
        <ModalBody>
          <FormikInput
            field={emailField}
            validate={emailValidator}
            label='Email Address'
          />
          <FormikSelect
            options={[
              {label: 'Admin', value: 'Can invite, promote and demote team members excluding owner.'},
              {label: 'Member', value: 'Can create campaigns, widgets and view reports.'}]}
            field={roleField}
            validate={roleValidator}
            labelProps={{
              mt: '30px',
              label: { variant:'modal', mb:'15px'},
            }}
            tooltipLabel="Role determines the team member's privileges."
            label='Role'
            labelPath={'role'}
            object={{role: null}}
            focusBorderColor={'#C9CED2'}
            selectedOptionStyle="check"
            chakraStyles={{
              control: (provided) => ({ ...provided, background: 'brand.100' })
            }}
          />
        </ModalBody>


        <ModalFooter>
          <Button
            mr={3}
            onClick={onClose}
            border="1px solid #C9CED2"
          >
            Back
          </Button>
          <Button isDisabled={loading} isLoading={loading} variant='brand'
            disabled={emailField[1]?.error || roleField[1]?.error}
            onClick={()=>{setLoading(true); props.handleInvite()}}
          >Invite</Button>
        </ModalFooter>
      </>
    )
  }

  const handleInvite = (values) => {
    // console.log(values);
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();

        axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/invite_member',
          { tenant_id: props.tenant, email: values.email, role: values.role.toLowerCase() },
          { headers: { "Authorization": jwtToken } })
          .then(response => {
            // TODO: do something with response
            // console.log(response)
            onClose()
            refresh()
          })
          .catch(error => { console.error(error); })
        })
        .catch(error => { console.error(error); });
    setLoading(false);
  }


  return (
    <Modal
      isOpen={isOpen} onClose={onClose}
      closeOnOverlayClick={true} closeOnEsc={true}
      leastDestructiveRef={backRef}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent
        bg={modalBg}
        width="540px"
        height="auto"
        maxWidth="100%"
        margin="auto"
      >
        <ModalHeader>Invite a Team Member</ModalHeader>
        <ModalCloseButton size="md" _focus="none" />
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{email:'', role:''}}
        >
          {({ values }) => {
            return (
              <Inside handleInvite={()=>{handleInvite(values)}}/>
          )}}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default InviteModal;
