import React, { useEffect, useState } from 'react';
import FormikInput from './FormikInput';
import { Box } from '@chakra-ui/react';
import { useField } from 'formik';
import _ from 'lodash';

function Name({ inputs, validateField, ...props}) {
  const field = inputs.fields[0].field
  const validate = inputs.fields[0].validate
  const label = inputs.label

  // function asyncDebounce(func, wait) {
  //   const resolveSet = new Set();
  //   const rejectSet = new Set();
  //
  //   const debounced = _.debounce((args) => {
  //     console.log(args)
  //     func(...args)
  //       .then((...res) => {
  //         resolveSet.forEach((resolve) => resolve(...res));
  //         resolveSet.clear();
  //       })
  //       .catch((...res) => {
  //         rejectSet.forEach((reject) => reject(...res));
  //         rejectSet.clear();
  //       });
  //   }, wait);
  //
  //   return (...args) => new Promise((resolve, reject) => {
  //     // console.log(args);
  //     resolveSet.add(resolve);
  //     rejectSet.add(reject);
  //     debounced(args);
  //   });
  // }
  // const debouncedValidate = asyncDebounce(validate, 5000)

  // const asyncDebounce = (func, delay) => {
  //   let timeoutId;
  //   let isExecuting = false;
  //
  //   return async (...args) => {
  //     if (isExecuting) {
  //       return new Promise((resolve) => {
  //         // If the function is already executing, resolve the promise once it's done.
  //         const checkExecution = () => {
  //           if (!isExecuting) { resolve(func(...args));
  //           } else { setTimeout(checkExecution, 10); }
  //         };
  //         checkExecution();
  //       });
  //     }
  //
  //     isExecuting = true; // If not executing, set the isExecuting flag and call the async function.
  //     try {
  //       console.log("!!!")
  //       const result = await func(...args);
  //       isExecuting = false; // Reset the isExecuting flag after the async function completes.
  //       return result;
  //     } catch (error) {
  //       isExecuting = false; // In case of an error, reset the isExecuting flag and throw the error.
  //       throw error;
  //     }
  //   };
  // };
  // const debouncedValidate = asyncDebounce(validate, 5000)

  // const [timerId, setTimerId] = useState(null);
  //
  // const asyncDebounce = (input) => {
  //   console.log('Function called.');
  //   // Place your function logic here
  //
  //   // Clear the existing timer, if any
  //   if (timerId) { clearTimeout(timerId); }
  //
  //   // Set a new timer of, let's say, 2 seconds
  //   const newTimerId = setTimeout(() => {
  //     const asyncLogic = async () => {
  //       try {
  //         console.log('Async operation starting...');
  //         await validate(input);
  //         console.log('Async operation completed.');
  //       } catch (error) {
  //         console.error('Async operation failed:', error);
  //       }
  //     };
  //
  //     asyncLogic();
  //   }, 2000);
  //
  //   // Store the new timer ID in the state
  //   setTimerId(newTimerId);
  // };

  // const debouncedValidate = asyncDebounce(validate, 5000)

  //
  //   console.log('Function called.');
  //   // Place your function logic here
  //
  //   // Clear the existing timer, if any
  //   if (timerId) {
  //     clearTimeout(timerId);
  //   }
  //
  //   // Set a new timer of, let's say, 2 seconds
  //   const newTimerId = setTimeout(() => {
  //     console.log('Timer expired.');
  //     await validate(input);
  //   }, time);
  //
  //   // Store the new timer ID in the state
  //   setTimerId(newTimerId);
  // };

  // Cleanup the timer on component unmount
  // useEffect(() => {
  //   return () => {
  //     if (timerId) {
  //       clearTimeout(timerId);
  //     }
  //   };
  // }, [timerId]);


  // const debouncedValidate = _.debounce(validate, 5000)


  // const debouncedValidate = (input) => {console.log(input)}
  return (
    <Box {...props}>
      <FormikInput
        isRequired
        field={useField({ name: field, validate: validate })}
        // validate={debouncedValidate}
        validate={validate}
        validateField={validateField}
        label='First, let’s give your widget a unique name.'
        focusBorderColor={'#C9CED2'}
      />
    </Box>
  );
}

export default Name;
