import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray, Field, useFormikContext } from "formik";
import {
  useToken,
  Input,
  InputGroup,
  Stack,
  Button,
  Icon,
  IconButton,
  Text,
  useStyleConfig,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useColorModeValue,
  Divider,
  Box,
  Flex,
  SimpleGrid,
  Code,
  Image,
  Center,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import {
  Name,
  Messaging,
  Colors,
  Widget,
  SenderShopify,
  ReceiverShopify,
  SenderStatic,
  ReceiverStatic,
  OrgInfo,
  Template,
  Email,
  SMS,
  Finish,
} from "./createModal/index.js";
import SaveOrDiscardModal from "./SaveOrDiscardModal";
import {
  BsArrowRightShort,
  BsArrowLeftShort,
  BsCircleFill,
} from "react-icons/bs";
// import defaultWidget from "../variables/defaultWidget"
// import defaultWidgetErrors from "../variables/defaultWidgetErrors"
// import defaultWidgetTouched from "../variables/defaultWidgetTouched"
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import defaultWidget from "../variables/defaultWidget";
import { MdContentCopy } from "react-icons/md";

// const FormikValueObserver = ({ onChange }) => {
//   const { values, initialValues } = useFormikContext()
//   useEffect(() => onChange({values, initialValues}), [values, initialValues, onChange])
//   return null
// }

function CreateModal(props) {
  const whiteBg = useColorModeValue("brand.100", "brand.100");
  const modalBg = useColorModeValue("brand.200", "brand.200");
  const continueButtonBg = useColorModeValue("brand.300", "brand.300");
  const previewButtonBg = useColorModeValue("brand.800", "brand.800");

  const { isNew, activeTenant, widgets, discounts, save } = { ...props };
  const { isOpen, onOpen, onClose } = props.disclosure;
  const transformWidget = (widget) => {
    // console.log(widget)
    var out = {
      widget_name: widget.config.widget_name,

      ButtonColor: widget.config.ButtonColor,
      Color: widget.config.Color,
      ErrorColor: widget.config.ErrorColor,
      HoverColor: widget.config.HoverColor,
      IconColor: widget.config.IconColor,
      InitialLicenseColor: widget.config.InitialLicenseColor,
      SecondaryColor: widget.config.SecondaryColor,
      SendButtonArrowColor: widget.config.SendButtonArrowColor,

      DefaultMessage: widget.config.DefaultMessage,
      EndText: widget.config.EndText,
      StartText: widget.config.StartText,

      HeightClosed: widget.config.HeightClosed,
      HeightOpen1: widget.config.HeightOpen1,
      Width: "305",
      HeightOpen2: "134",
      Corner: widget.config.Corner,

      FontFamily: "'Barlow Condensed', sans-serif",
      FontUrl:
        "https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;600&display=swap",
      CTAFontSize: widget.config.CTAFontSize,
      SecondaryFontSize: widget.config.SecondaryFontSize,
      TertiaryFontSize: widget.config.TertiaryFontSize,

      CopyType: "code",
      Border: true,
      Code: widget.config.Code,
      deferred: true,
      LinkRefferal: false,
      RewardUrl: true,
      Verify: false,
    };
    return out;
  };
  const widget = isNew ? defaultWidget : transformWidget(props.widget);
  const [widgetId, setWidgetId] = useState(props.widget?.widget_id ?? uuidv4());

  const alert = useDisclosure();

  const [isDirty, setIsDirty] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const handleClose = () => {
    if (isDirty) {
      alert.onOpen();
    } else {
      onClose();
    }
  };

  const validator = (schema) => {
    return async (value) => {
      // console.log(value)
      let error = undefined;
      try {
        await schema.validate(value);
      } catch (err) {
        error = err.errors[0];
        // console.log("error:", err);
      }
      return error;
    };
  };

  const stepsFields = [
    {
      label: "Name",
      fields: [
        {
          field: "widget_name",
          label: "Name",
          validate: validator(yup.string().trim().max(48).required()),
        },
      ],
    },
    {
      label: "Messaging",
      fields: [
        {
          field: "StartText",
          label: "Call to Action",
          validate: validator(yup.string().trim().max(48).required()),
        },
        {
          field: "EndText",
          label: "Completion Message",
          validate: validator(yup.string().trim().max(48).required()),
        },
        {
          field: "DefaultMessage",
          label: "Default Editable Message",
          validate: validator(yup.string().trim().max(140).required()),
        },
      ],
    },
    {
      label: "Colors",
      fields: [
        {
          field: "Color",
          label: "Primary",
          validate: validator(yup.string().trim().max(48).required()),
        },
        {
          field: "SecondaryColor",
          label: "Secondary",
          validate: validator(yup.string().trim().max(128).required()),
        },
        {
          field: "HoverColor",
          label: "Hover",
          validate: validator(yup.string().trim().max(128).required()),
        },
        {
          field: "IconColor",
          label: "Icon",
          validate: validator(yup.string().trim().max(128).required()),
        },
        {
          field: "ButtonColor",
          label: "Button",
          validate: validator(yup.string().trim().max(48).required()),
        },
        {
          field: "ErrorColor",
          label: "Error",
          validate: validator(yup.string().trim().max(128).required()),
        },
        {
          field: "InitialLicenseColor",
          label: "Initial License",
          validate: validator(yup.string().trim().max(128).required()),
        },
        {
          field: "SendButtonArrowColor",
          label: "Arrow",
          validate: validator(yup.string().trim().max(128).required()),
        },
        {
          field: "HeightClosed",
          label: "Closed Height",
          validate: validator(yup.number().required()),
        },
        {
          field: "HeightOpen1",
          label: "Open Height",
          validate: validator(yup.number().required()),
        },
        {
          field: "Corner",
          label: "Corner Radius",
          validate: validator(yup.number().required()),
        },
        {
          field: "CTAFontSize",
          label: "Primary Font Size",
          validate: validator(
            yup.number().lessThan(17).moreThan(11).required()
          ),
        },
        {
          field: "SecondaryFontSize",
          label: "Secondary Font Size",
          validate: validator(
            yup.number().lessThan(17).moreThan(11).required()
          ),
        },
        {
          field: "TertiaryFontSize",
          label: "Tertiary Font Size",
          validate: validator(
            yup.number().lessThan(17).moreThan(11).required()
          ),
        },
      ],
    },
  ];

  const steps = [
    <Name inputs={stepsFields[0]} />,
    <Messaging inputs={stepsFields[1]} />,
    <Colors inputs={stepsFields[2]} />,
    <Finish
      tenantId={activeTenant}
      widgetId={widgetId}
    />,
  ];

  const order = [
    { prev: null, next: 1 }, // 0 name
    { prev: 0, next: 2 }, // 1 messaging
    { prev: 1, next: 3 }, // 2 colors and sizes
    { prev: null, next: null }, // finish
  ];

  // useEffect(() => {
  //   // console.log(props.editedWidget);
  //   // console.log(widget);
  //   // widget && console.log( _.isEqual(props.widget, props.editedWidget));
  //   // setIsDirty( !_.isEqual(widget, edited));
  //   console.log( !_.isEqual(widget, edited));
  // },[edited])

  const handleSubmit = (values) => {
    // console.log(values);
  };
  // console.log(currentStep, steps[currentStep])
  // console.log(currentStep, steps[currentStep])
  const childProps = (step) => {
    return {
      // height: currentStep == step ? 'initial' : '0px',
      // width: currentStep == step ? 'initial' : '0px',
      display: currentStep == step ? "initial" : "none",
      visibility: currentStep == step ? "visible" : "hidden",
    };
  };
  const shouldDisplay = (step) => {
    return currentStep == step ? "visible" : "hidden";
  };

  const stepHasErrors = (errors) => {
    // _.get(errors, stepsFields[currentStep].fields[0].field)
    // console.log(stepsFields[currentStep].fields.some(field => { console.log("errors, field:", errors, field); const temp = _.get(errors, field.field); console.log("matched errors:",temp); }))
    return !!stepsFields[currentStep].fields.some((field) =>
      _.get(errors, field.field)
    );
  };

  const saveWidget = (values) => {
    // console.log("Values:", values);
    const out = {
      widget_name: values.widget_name,

      ButtonColor: values.ButtonColor,
      Color: values.Color,
      ErrorColor: values.ErrorColor,
      HoverColor: values.HoverColor,
      IconColor: values.IconColor,
      InitialLicenseColor: values.InitialLicenseColor,
      SecondaryColor: values.SecondaryColor,
      SendButtonArrowColor: values.SendButtonArrowColor,

      DefaultMessage: values.DefaultMessage,
      EndText: values.EndText,
      StartText: values.StartText,

      HeightClosed: values.HeightClosed,
      HeightOpen1: values.HeightOpen1,
      HeightOpen2: "134",
      Width: "305",
      Corner: values.Corner,

      FontFamily: "'Barlow Condensed', sans-serif",
      FontUrl: "https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;600&display=swap",
      CTAFontSize: values.CTAFontSize,
      SecondaryFontSize: values.SecondaryFontSize,
      TertiaryFontSize: values.TertiaryFontSize,

      CopyType: "code",
      Border: true,
      Code: null,
      deferred: true,
      LinkRefferal: false,
      RewardUrl: true,
      Verify: false,
    };
    // console.log("Out:", out);
    // console.log(props);
    save(out, widgetId);
  };

  const handleContinue = () => {
    setCurrentStep(order[currentStep].next);
  };

  const [widgetStep, setWidgetStep] = useState(0);
  const previewWidth = 500;
  const modalWidth = 540;

  return (
    <>
      {props.children}
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay bg="rgba(0,0,0,0.8)" />
        <ModalContent
          width={{
            xxs: `${modalWidth}px`,
            lg:
              currentStep === 1 || currentStep === 2
                ? `${540 + previewWidth}px`
                : `${modalWidth}px`,
          }}
          height="auto"
          maxWidth="100%"
          margin="auto"
          bg="none"
        >
          <Flex
            // transition='height 2s ease-in-out' // if only auto -> auto transitions worked rip
            w={`${modalWidth}px`}
            flexDir="column"
            bg={modalBg}
            rounded=".375rem"
          >
            <ModalCloseButton size="md" _focus="none" />

            <ModalHeader>
              <Text>{isNew ? "Create" : "Edit"} Widget</Text>
            </ModalHeader>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={widget}
              // initialErrors={widgetErrors}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleSubmit,
                dirty,
                errors,
                setFieldTouched,
                validateField,
              }) => {
                setIsDirty(!_.isEqual(values, widget));
                return (
                  <>
                    <ModalBody
                      margin="auto"
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Form onSubmit={handleSubmit}>
                        {/*
                         */}
                        {steps[currentStep]}
                      </Form>
                    </ModalBody>
                    <ModalFooter>
                      <Stack
                        alignSelf="stretch"
                        direction="row"
                        justify="flex-end"
                        align="center"
                        spacing="12px"
                      >
                        {currentStep === 1 && (
                          <Popover placement="top">
                            <PopoverTrigger>
                              <Text
                                position="absolute"
                                bottom="21px"
                                left="46px"
                                cursor="pointer"
                                textDecoration="underline"
                                fontSize="13px"
                                color="rgba(45, 55, 72, 0.5)"
                              >
                                How to use variables <br />
                                in your referral template
                              </Text>
                            </PopoverTrigger>
                            <PopoverContent
                              style={{ border: "none" }}
                              _focus={{ border: "none" }}
                              w="max-content"
                            >
                              <PopoverArrow />
                              <PopoverHeader>Using Variables</PopoverHeader>
                              <PopoverCloseButton mt="4px" />
                              <PopoverBody bg="#00000022">
                                <Text maxW="500px" whiteSpace="pre-wrap">
                                  <Code colorScheme="green">[organization]</Code>: Displays the name of the organization/company the referral is being sent for. This can be changed in settings.<br/><br/>
                                  <Code colorScheme="green">[url]</Code>: Displays the website URL that the referral will direct people to. This can be changed in settings.<br/><br/>
                                  <Code colorScheme="yellow">[sender_name]</Code>: Displays the name of the person sending the referral.<br/><br/>
                                  <Code colorScheme="yellow">[sender_contact]</Code>: Displays the contact info (phone number or email) of the person who sent the referral.<br/><br/>
                                  <Code colorScheme="blue">[incentive]</Code>: Displays the name of the reward that users will receive sending and/or receiving a referral. This can be changed in your campaign.
                                </Text>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        )}

                        {order[currentStep].prev !== null && (
                          <Button
                            onClick={() => {
                              setCurrentStep(order[currentStep].prev);
                            }}
                            border="1px solid #C9CED2"
                          >
                            Back
                          </Button>
                        )}

                        {order[currentStep].next !== null ? (
                          order[currentStep + 1].next === null ? (
                            <Button
                              onClick={() => {
                                saveWidget(values);
                                setCurrentStep(order[currentStep].next);
                              }}
                              bg={continueButtonBg}
                            >
                              Save Widget
                            </Button>
                          ) : (
                            <Button
                              disabled={stepHasErrors(errors)}
                              onClick={handleContinue}
                              bg={continueButtonBg}
                            >
                              Continue
                            </Button>
                          )
                        ) : (
                          <Button
                            onClick={() => {
                              setCurrentStep(0);
                              onClose();
                            }}
                            border="1px solid #adadad"
                          >
                            Done
                          </Button>
                        )}
                        <SaveOrDiscardModal
                          setCurrentStep={setCurrentStep}
                          isOpen={alert.isOpen}
                          onOpen={alert.onOpen}
                          onClose={alert.onClose}
                          onDiscard={onClose}
                        />
                      </Stack>
                    </ModalFooter>
                  </>
                );
              }}
            </Formik>

            {(currentStep === 1 || currentStep === 2) && (
              <Flex
                w={`${previewWidth}px`}
                left={{
                  xxs: `${(modalWidth - previewWidth) / 2}px`,
                  lg: `${modalWidth}px`,
                }}
                top={{ xxs: "100%", lg: "36px" }}
                position="absolute"
                bg="#CCFFDD"
                rounded={{ xxs: "0px 0px 6px 6px", lg: "0px 6px 6px 0px" }}
                flexDir="column"
                alignItems="start"
              >
                <Flex
                  flexDir="column"
                  alignItems="flex-start"
                  rounded={{ xxs: "0px 0px 0px 0px", lg: "0px 6px 0px 0px" }}
                  p="12px"
                  w="100%"
                  bg="#eeffee"
                >
                  <Text variant="boldModal">Live Preview</Text>
                  <Center p="12px" w="100%" h="170px" bg="#77ccaa">
                    <Text fontSize="xl" fontWeight="semibold" color="brand.600">
                      Coming Soon!
                    </Text>
                  </Center>
                </Flex>
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  h="64px"
                >
                  <Icon
                    w="48px"
                    h="48px"
                    as={BsArrowLeftShort}
                    onClick={() => {
                      setWidgetStep((((widgetStep - 1) % 6) + 6) % 6);
                    }}
                  />
                  <Flex>
                    <Icon
                      mx="2px"
                      w="12px"
                      h="12px"
                      as={BsCircleFill}
                      fill={widgetStep === 0 ? "#00B28B" : "#76E8CC"}
                    />
                    <Icon
                      mx="2px"
                      w="12px"
                      h="12px"
                      as={BsCircleFill}
                      fill={widgetStep === 1 ? "#00B28B" : "#76E8CC"}
                    />
                    <Icon
                      mx="2px"
                      w="12px"
                      h="12px"
                      as={BsCircleFill}
                      fill={widgetStep === 2 ? "#00B28B" : "#76E8CC"}
                    />
                    <Icon
                      mx="2px"
                      w="12px"
                      h="12px"
                      as={BsCircleFill}
                      fill={widgetStep === 3 ? "#00B28B" : "#76E8CC"}
                    />
                    <Icon
                      mx="2px"
                      w="12px"
                      h="12px"
                      as={BsCircleFill}
                      fill={widgetStep === 4 ? "#00B28B" : "#76E8CC"}
                    />
                    <Icon
                      mx="2px"
                      w="12px"
                      h="12px"
                      as={BsCircleFill}
                      fill={widgetStep === 5 ? "#00B28B" : "#76E8CC"}
                    />
                  </Flex>
                  <Icon
                    w="48px"
                    h="48px"
                    as={BsArrowRightShort}
                    onClick={() => {
                      setWidgetStep((widgetStep + 1) % 6);
                    }}
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CreateModal;
