import React, { useState, useEffect } from "react";
import { NavLink, Navigate, useNavigate, useLocation } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertTitle,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import axios from 'axios'
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import { Auth } from 'aws-amplify';
import { useAuthContext } from "contexts/AuthContext";

function VerifyEmail() {
  // Chakra color mode
  const textColor = useColorModeValue("brand.600", "brand.600");
  const textColorSecondary = "brand.600";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);

  const location = useLocation();
  const email = location.state ? location.state.email : null;
  const [code, setCode] = React.useState("");
  const navigate = useNavigate();

  const [teamToken, setTeamToken] = useState(null);
  const [appsumoToken, setAppsumoToken] = useState(null);

  const {token, setToken} = useAuthContext();
  useEffect(() => {
    if (token?.name === 'team') { setTeamToken(token.value) }
    else if (token?.name === 'appsumo') { setAppsumoToken(token.value) }
  }, [token]);

  const [inviter, setInviter] = useState(null);
  const getInviter = () => {
    axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/get-team-inviter',
      { 'token': teamToken },
      { headers: {  } })
      .then(response => {
        // console.log(response.data)
        setInviter(response.data.inviter);
      })
      .catch(error => {
        console.error(error);
      })
  }
  useEffect(()=>{
    if (teamToken) {
      getInviter()
    }
  },[teamToken])

  const [tier, setTier] = useState('');
  const checkAppsumoToken = () => {
    axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-get-appsumo-invite',
      { 'appsumoToken': appsumoToken },
      { headers: {  } })
      .then(response => {
        // console.log("check token: ", response.data)
        setTier(response.data.tier);
      })
      .catch(error => {
        console.error(error);
      })
  }
  useEffect(()=>{
    if (appsumoToken) {
      checkAppsumoToken()
    }
  },[appsumoToken])

  const handleVerification = async () => {
    try {
      await Auth.confirmSignUp(email, code, (
          inviter && { clientMetadata: { teamToken: teamToken }}
        ) || (
          tier && { clientMetadata: { appsumoToken: appsumoToken }}
        ))

      // navigate('/auth/sign-in', {state:{
      //     email: email,
      // }});
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }

  const handleCodeResend = async () => {
    try {
      await Auth.resendSignUp(email);
      // console.log('code resent successfully');
    } catch (err) {
      console.log('error resending code: ', err);
    }
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      { email ? (
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Verify Your Email
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              We sent an email to <b>{email}</b>.<br/>
              Once you verify you'll able to sign in.
            </Text>
          </Box>
          { false ? (
            <Alert
              status='warning'
              mb='24px'
              mt='-12px'
            >
              <AlertIcon/>
              <AlertDescription>
                { location.state.message }
              </AlertDescription>
            </Alert>
          ) : null }
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            <FormControl>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                6 Digit Code<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                border='2px'
                borderColor='brand.400'
                value={code}
                onChange={(event) => setCode(event.target.value)}
                onKeyDown={(event) => {if (event.key === 'Enter') {handleVerification()}}}
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='code'
                placeholder='123456'
                mb='24px'
                fontWeight='500'
                size='lg'
              />
            </FormControl>
            <Button
              color='brand.700'
              fontSize='sm'
              variant='brand'
              fontWeight='700'
              w='100%'
              h='50'
              mb='24px'
              onClick={handleVerification}
            >
              Check Code
            </Button>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
              mt='0px'>
              <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                Didn't receive an email?
                <Text
                  color={textColorBrand}
                  as='span'
                  mx='5px'
                  fontWeight='500'
                  onClick={handleCodeResend}
                >
                  Resend Code
                </Text>
                or
                <NavLink to='/auth/sign-in'>
                  <Text
                    color={textColorBrand}
                    as='span'
                    ms='5px'
                    me='5px'
                    fontWeight='500'
                  >
                    Return to Sign In
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Navigate to='/auth/sign-in' />
      ) }
    </DefaultAuth>
  );
}

export default VerifyEmail;
