import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  SimpleGrid,
  Text,
  useColorModeValue,
  Flex,
  Button,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  Switch,
  Box,
  Icon,
  IconButton,
  useToast,
  Spinner,
  Input,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  useDisclosure,
  Td,
  Tr,
  CheckboxGroup,
  Stack,
  Link,
  chakra,
  Checkbox,
  Center,
} from '@chakra-ui/react'
import {
  MdContentCopy,
  MdEdit,
  MdClose,
  MdSave,
  MdDelete,
  MdBuild
} from "react-icons/md"
// Custom components
import { ChevronDownIcon, ChevronUpIcon, DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons'
import Card from "components/card/Card.js";
// import MiniStatistics from "components/card/MiniStatistics";
// import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useRef } from "react";
// import WidgetInformation from "views/admin/campaigns/components/WidgetInformation";
// import MessageInformation from "views/admin/campaigns/components/MessageInformation";
import CreateModal from "../components/CreateModal";
import { globalStyles } from "theme/styles"
import axios from 'axios';
import { Auth } from 'aws-amplify';
import _ from "lodash";
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy, } from "react-icons/md";
import TemplateModal from "components/templateModal/TemplateModal";
import EmbedPopover from "./EmbedPopover.js"

import ShopifyLogo from "assets/img/logos/ShopifyLogo";
import { CgCodeSlash } from "react-icons/cg";
import { MdAbc } from "react-icons/md";
import SaveOrDiscardModal from './SaveOrDiscardModal';
import DeleteModal from './DeleteModal';

// Assets
export default function CampaignRow({activeTenant, team, ...props}) {
  var editedCampaign = _.cloneDeep(props.data.config);
  const [editing, setEditing] = useState(false);
  const [accordionIndex, setAccordionIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isNew, setIsNew] = useState(props.isnew);
  const [campaign_id, setCampaign_id] = useState(props.data.campaign_id);
  const [copied, setCopied] = useState(false);
  const createModalDisclosure = useDisclosure();
  const deleteModalDisclosure = useDisclosure();
  const previewModalDisclosure = useDisclosure();

  // const toast = useToast();
  // const { ...rest } = props;

  // console.log("Rerender");

  const isInitialMount1 = useRef(true);
  useEffect(() => {
    if (isNew && isInitialMount1.current) {
      isInitialMount1.current = false;
      setEditing(true);
      setAccordionIndex(0);
    }
  })

  useEffect(() => {
    if (deleting) {
      updateCampaign();
    }
  }, [deleting])

  const saveCampaign = (campaign) => {
    createModalDisclosure.onClose()
    editedCampaign = campaign;
    // console.log("Saving:", editedCampaign);
    updateCampaign();
  };

  const updateCampaign = () => {
    setSaveLoading(true);
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        // console.log("campaign_id", campaign_id);
        // console.log("deleting", deleting);
        const request = {
          tenant_id: activeTenant,
          campaign_id: campaign_id,
          config: !deleting ? editedCampaign : {},
          resource_type: 'campaign'
        }
        // console.log("req", request);
        axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-create-config',
          request,
          {
            headers: { "Authorization": jwtToken },
          })
          .then(response => {
            setIsNew(0);
            // setReload(true);
            props.updateCampaigns()
          })
          .catch(error => {
            setError("Error Saving Campaign")
            setLoading(false)
            console.error(error);
          })
          .finally(() => {
            setSaveLoading(false)
          })
        })
        .catch(error => {
          console.error(error);
      });
  }

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("brand.600", "brand.600");
  const textColorSecondary = "brand.600";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const inputBg = useColorModeValue("white", "brand.700");
  const textColorValue = useColorModeValue("brand.600", "secondaryGray.100");
  const gradient = `linear-gradient(to bottom, ${useColorModeValue(
    // globalStyles.colors.secondaryGray[200],
    "#FFFFFF",
    globalStyles.colors.brand[900]
  )}, ${useColorModeValue(
    globalStyles.colors.secondaryGray[200],
    globalStyles.colors.secondaryGray[600]
  )})`;

  const handleDelete = () => {
    if (!loading) {
      if (isNew == 1) {
        props.remove(props.index)
      } else {
        setDeleting(true);
      }
      // setEditing(!editing); setAccordionIndex(0)
    }
  }

  const Options = ({...rest}) => {
    return (
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              border='1px solid #AFAFAF'
              ml='12px'
              _hover={{
                bg: "none",
                borderColor: "brand.800",
              }}
              isActive={isOpen}
              as={Button}
              rightIcon={ isOpen ? <ChevronUpIcon /> : <ChevronDownIcon /> }
            >
              Options
            </MenuButton>
            <MenuList>
              <MenuItem onClick={createModalDisclosure.onOpen}>
                Edit Campaign
                <EditIcon position='absolute' right='15px'/>
                <CreateModal
                  disclosure={createModalDisclosure}
                  campaign={props.data}
                  discounts={props.discounts}
                  widgets={props.widgets}
                  save={saveCampaign}
                  // editedCampaign={editedCampaign}
                  activeTenant={activeTenant}
                  team={team}
                />
              </MenuItem>
              <MenuItem onClick={()=>{previewModalDisclosure.onOpen()}}>
                Preview
                <ViewIcon position='absolute' right='15px'/>
                <TemplateModal
                  disclosure={previewModalDisclosure}
                  values={props.data.config}
                  team={team}
                  editing={false}
                />
              </MenuItem>
              {/*
              */}
              <MenuItem onClick={deleteModalDisclosure.onOpen}
                color='red.500' fontWeight='bold'>
                Delete Campaign
                <DeleteIcon position='absolute' right='15px'/>
                <DeleteModal campaign_id={props.data.campaign_id} handleDelete={handleDelete} disclosure={deleteModalDisclosure}/>
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    )
  }

  const sender_discount = props?.data?.config?.sender_message?.discount;
  const receiver_discount = props?.data?.config?.receiver_message?.discount;
  const rewardLogos = {
    'static': MdAbc,
    'shopify': ShopifyLogo
  }

  return (
    <Tr _hover={{ background: "#f2faf8" }}>
      <Td>
        <Box>
          <CheckboxGroup
            colorScheme="green"
            defaultValue={["Naruto", "Sasuke"]}
          >
            <Stack spacing={[1, 5]} direction={["column", "row"]}>
              {/*
              <Checkbox size="md" value="naruto"></Checkbox>
              */}
              <Link>
                <Text color="brand" fontWeight="semibold">
                  {props.data.config.campaign_name}
                  {/*
                  <chakra.Span
                    fontSize="sm"
                    fontWeight="normal"
                    color="brand.500"
                    ml="6px"
                  >
                    - Ariel Ben-Ami
                  </chakra.Span>
                  */}
                </Text>
                <Text fontSize="xs" color="#adadad">
                  Campaign ID: {props.data.campaign_id}
                </Text>
              </Link>
            </Stack>
          </CheckboxGroup>
        </Box>
      </Td>
      {/*
      <Td>
        <Text textAlign="center">156</Text>
      </Td>
      <Td>
        <Text textAlign="center">22</Text>
      </Td>
      */}
      <Td>
        <Flex>
          <Center w='24px' h='24px' mr='6px'>
            <Icon as={rewardLogos[props?.data?.config?.receiver_message?.discount?.type]} w='auto' h='auto' />
          </Center>
          <Box>
            <Text color="brand" fontWeight="semibold">
              {props?.data?.config?.sender_message?.incentive ?? ''}
            </Text>
            <Text fontSize="xs" color="#adadad">
              {sender_discount?.name ?? ''}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td>
        <Flex>
          <Center w='24px' h='24px' mr='6px'>
            <Icon as={rewardLogos[props?.data?.config?.receiver_message?.discount?.type]} w='auto' h='auto' />
          </Center>
          <Box>
            <Text color="brand" fontWeight="semibold">
              {props?.data?.config?.receiver_message?.incentive ?? ''}
            </Text>
            <Text fontSize="xs" color="#adadad">
              {receiver_discount?.name ?? ''}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td>{props?.widget?.config?.widget_name}</Td>
      <Td isNumeric>
        <EmbedPopover
          tenantId={activeTenant}
          campaignId={props.data.campaign_id}>
          <Button bg="brand.800" height="40px">
            Embed
          </Button>
        </EmbedPopover>
        <Options/>
      </Td>
    </Tr>

  );
}
