// Chakra imports
import {
  Portal,
  Box,
  useDisclosure,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
import { FaChevronLeft } from "react-icons/fa";
// Layout components
import Navbar from "components/navbar/NavbarAdmin.js";
import React, { useState } from "react";
import { NavLink, useLocation, useNavigate, matchRoutes, Outlet, Navigate, useOutlet } from "react-router-dom";
import routes from "routes.js";
import { useUserContext } from "contexts/UserContext";

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const adminRoutes = routes.find(route => route.name === "Pricing").children;
  const location = useLocation();

  // functions for changing the states from components
  const getActiveRoute = () => {
    return matchRoutes(adminRoutes, location)[0].route;
  };

  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();

  const outlet = useOutlet();

  const gradient = `linear-gradient(to bottom, ${useColorModeValue(
    // globalStyles.colors.secondaryGray[200],
    "#FFFFFF",
    useToken('colors', 'secondaryGray.800')
  )}, ${useColorModeValue(
    useToken('colors', 'secondaryGray.200'),
    useToken('colors','brand.600')
  )})`;

  const { tier, activeTenantState, tenantsState } = useUserContext();
  const [ activeTenant, setActiveTenant ] = activeTenantState;
  const [ tenants, setTenants ] = tenantsState;

  const navigate = useNavigate();

  return (
    <Box bg={gradient} h='100%'>
      <Flex position='absolute' color='secondaryGray.600' align='center' mt="40px" ps="25px" cursor='pointer'
        onClick={()=>{navigate(tier ? '/admin/account' : '/auth/organizations')}}
      >
        <Icon me='12px' h='13px' w='8px' as={FaChevronLeft} />
        <Text fontSize='sm'>
          Back to { tier ? 'Account' : 'Organizations' }
        </Text>
      </Flex>
      { outlet ? <Outlet/> : <Navigate to='/pricing/tiers' replace={true} /> }
      <Footer />
    </Box>
  );
}
