import { useState, useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify'

const getUser = async (bypassCache) => {
    try {
        return await Auth.currentAuthenticatedUser({ bypassCache: bypassCache });
    }
    catch (_a) {
        // currentAuthenticatedUser throws an Error if not signed in!
        return null;
    }
};

const useAuth = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const updateUser = async (bypassCache) => {
        // console.log("updateUser called", bypassCache);
        setUser(await getUser(bypassCache));
        setLoading(false);
    };
    const callback = (data) => {
        // console.log("Data:", data);
        if (data.payload.event) {updateUser(false)}
        else {updateUser(true)}
    }
    useEffect(() => {
        Hub.listen("auth", callback); // listen for login/signup events
        updateUser(true); // check manually the first time because we won't get a Hub event
        return () => Hub.remove("auth", callback);
    }, []);
    const signIn = () => Auth.federatedSignIn();
    const signOut = () => Auth.signOut();
    // console.log(user, signIn, signOut, loading);
    return { user, signIn, signOut, loading, updateUser };
};

export default useAuth;
export { getUser };
