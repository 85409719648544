// SaveOrDiscardModal.js

import React, { useState } from 'react';
import { Button, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';

const DeleteModal = (props) => {
  const backRef = React.useRef();

  const [loading, setLoading] = useState(false)

  return (
    <AlertDialog
      {...props.disclosure}
      closeOnOverlayClick={true} closeOnEsc={true}
      leastDestructiveRef={backRef}
      isCentered={true}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {props.isOwner ? 'Delete ' : 'Leave '}Organization
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure?<br/>
            {props.isOwner
              ? 'All changes will be lost permanently.'
              : 'You must be reinvited if you wish to rejoin.'}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={backRef} onClick={props.disclosure.onClose}>
              Cancel
            </Button>
            <Button bg='red.500' ml={3}
              isLoading={loading}
              onClick={() => {
                setLoading(true)
                props.deleteOrganization(setLoading, props.tenant_id)
              }}>
              {props.isOwner ? 'Delete' : 'Leave'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeleteModal;
