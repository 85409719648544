// Chakra imports
import {
  chakra,
  Avatar,
  Box,
  Flex,
  Stat,
  HStack,
  Tag,
  TagLabel,
  ButtonGroup,
  IconButton,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  Center,
  useColorModeValue,
  Heading,
  Spacer,
  Text,
  Button,
  Divider,
  useToken,
  useBreakpointValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import ReferralChart from "views/admin/reports/components/ReferralChart";
import SendChart from "views/admin/reports/components/SendChart";
import LineChart from "views/admin/reports/components/LineChart";
import DateRangePickerComponent from "./components/DateRangePickerComponent";
import { AddIcon, DownloadIcon } from '@chakra-ui/icons'
import StatBox from "./components/StatBox";
import d3 from "assets/d3/index.js";
import CsvDownload from 'react-json-to-csv'

import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdClose,
  MdDownload,
  MdFileCopy,
} from "react-icons/md";
import React, { useEffect, useState, useRef } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import {
  subDays,
  addDays,
  min,
  subMonths,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSunday,
  isFirstDayOfMonth,
  getMonth,
  differenceInDays,
  format
} from "date-fns";
import { Line } from "recharts";
import { useUserContext } from "contexts/UserContext";
import { Bs0Circle } from "react-icons/bs";

export default function Settings() {
  // Chakra Color Mode

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const boxBg = useColorModeValue("white", "whiteAlpha.100");
  const dataColor = useColorModeValue("brand.300, brand.300");
  const brandColor = useColorModeValue("brand.500", "white");
  const iconColor = useColorModeValue("brand.300", "brand.300");

  const { activeTenantState, campaignsState } = useUserContext();
  const [activeTenant, setActiveTenant] = activeTenantState;

  class CurveStepRounded {
    /**
     * Constructor
     *
     * @param {object} context d3.js curve context
     * @param {object} config Optional configuration
     */
    constructor(context, config) {
      this.context = context;
      this.config = {
        distance: 0.5, // Range: 0 to 1
        shift: 0.5, // Range: (0 - distance) to (1 - distance)
        tilt: 0.5, // Range: 0 to 1
        ...config,
      };

      this._t = this.config.shift;
    }

    areaStart() {
      this._line = 0;
    }

    areaEnd() {
      this._line = NaN;
    }

    lineStart() {
      this._x = this._y = NaN;
      this._point = 0;
    }

    lineEnd() {
      if (0 < this._t && this._t < 1 && this._point === 2) this.context.lineTo(this._x, this._y);
      if (this._line || (this._line !== 0 && this._point === 1)) this.context.closePath();
      if (this._line >= 0) { this._t = 1 - this._t; this._line = 1 - this._line; }
    }

    point(x, y) {
      x = +x;
      y = +y;
      switch (this._point) {
      case 0:
        this._point = 1;
        this._line ? this.context.lineTo(x, y) : this.context.moveTo(x, y);
        break;
      case 1:
        this._point = 2; // Proceed
      default:
        {
          if (this._t <= 0) {
            this.context.lineTo(this._x, y);
            this.context.lineTo(x, y);
          } else {
            const x1 = this._x * (1 - this._t) + x * this._t;

            const r = this.config.radius;

            const vOff = this._y == y
              ? 0
              : this._y > y
                ? -r
                : r

            const points = {
              a:  [x1 - r, this._y       ], // Point on left divider
              ab: [x1    , this._y       ], // Point on left divider
              b:  [x1    , this._y + vOff],  // Point in between
              c:  [x1    , y       - vOff],  // Point in between
              cd: [x1    , y             ], // Point on right divider
              d:  [x1 + r, y             ], // Point on right divider
            };

            this.context.lineTo(...points.a);
            this.context.bezierCurveTo(...points.a, ...points.ab, ...points.b);
            this.context.lineTo(...points.c);
            this.context.bezierCurveTo(...points.c, ...points.cd, ...points.d);
          }
          break;
        }
      }
      this._x = x; this._y = y;
    }
  }

  const earliest = new Date("2023-01-01")
  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    earliest: earliest,
  };
  const startDateState = useState(defineds.startOfMonth);
  const [startDate, setStartDate] = startDateState;
  const endDateState = useState(defineds.endOfToday);
  const [endDate, setEndDate] = endDateState;

  const [campaigns, setCampaigns] = campaignsState
  const [campaignIds, setCampaignIds] = useState([])
  const [activeCampaigns, setActiveCampaigns] = useState([])

  useEffect(()=>{
    if (!campaigns) { return }
    const ids = campaigns.map((c)=>{return c.campaign_id})
    setCampaignIds(ids)
    setActiveCampaigns(Array(campaigns.length).fill(true))
  },[campaigns])

  useEffect(()=>{
    getWeeklyReferrals(campaignIds);
  },[campaignIds, endDate])

  const handleToggleCampaign = (index) => {
    setActiveCampaigns(prevState => {
      const newState = [...prevState]; // Create a copy of the state array
      newState[index] = !newState[index]; // Toggle the state of the item at the given index
      return newState;
    });
  };
  const [data,setData] = useState(null)

  const qs = require("qs");
  const getWeeklyReferrals = (campaignIds) => {
    Auth.currentSession()
      .then((session) => {
        const jwtToken = session.getIdToken().getJwtToken();

        axios.get("https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-get-referrals", {
          headers: { Authorization: jwtToken },
          params: {
            tenant_id: activeTenant,
            request_type: "all",
            campaigns: qs.stringify(campaignIds),
            "start_date": startDate.toISOString().slice(0, 10),
            "end_date": subDays(endDate, 1).toISOString().slice(0, 10)
          },
        })
          .then((response) => {
            setData(response.data)
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const borderColor = useColorModeValue('#eee', '#303436');
  const bgColor = useColorModeValue('#ffffff','#1A1C1D');

  const HomeBox = ({children, ...props}) => {
    return (
      <Box
        boxShadow='lg'
        shadow={useColorModeValue(
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);',
            '0 10px 15px -3px rgba(0, 0, 0, 0.3), 0 4px 6px -2px rgba(0, 0, 0, 0.15);',
        )}
        py={"12px"}
        px={"20px"}
        width={"100%"}
        position={"relative"}
        borderRadius={"12px"}
        backgroundClip={"border-box"}
        bg={bgColor}
        // bgGradient='radial(ellipse at 50% -100px, brand.100 0%, brand.400 600%)'
        border="1px solid"
        borderColor={borderColor}
        {...props}
      >
        {children}
      </Box>
    )
  }

  const isLg = useBreakpointValue({xxs: false, lg: true});
  const Stats = () => {
    const all = data?.find(e=>e.id==='all').data
    const totals = {
      receiverConversions: 0,
      receiverDiscountAmount: 0,
      receiverRevenue: 0,
      referrals: 0,
      senderConversions: 0,
      senderDiscountAmount: 0,
      senderRevenue: 0
    }
    all?.forEach((item) => {
        totals.receiverConversions += item.receiverConversions;
        totals.receiverDiscountAmount = totals.receiverDiscountAmount + parseFloat(item.receiverDiscountAmount);
        totals.receiverRevenue = totals.receiverRevenue + parseFloat(item.receiverRevenue);
        totals.referrals += item.referrals;
        totals.senderConversions += item.senderConversions;
        totals.senderDiscountAmount = totals.senderDiscountAmount + parseFloat(item.senderDiscountAmount);
        totals.senderRevenue = totals.senderRevenue + parseFloat(item.senderRevenue);
    });

    const cost = 0.75*totals.referrals
    const conversions = totals.receiverConversions + totals.senderConversions
    const revenue = totals.receiverRevenue + totals.senderRevenue
    const discountedAmount = totals.senderDiscountAmount + totals.receiverDiscountAmount

    const stats = [
      {
        'title': 'Referrals',
        'data': totals.referrals
      }, {
        'title': 'Revenue',
        'data': '$' + revenue.toFixed(2)
      }, {
        'title': 'Converted',
        'data': conversions
      }, {
        'title': 'Conversion Rate',
        'data': totals.referrals !== 0 ? (conversions*100/(totals.referrals * 2)).toFixed(2) + '%' : '-'
      }, {
        'title': 'AoV',
        'data': conversions !== 0 ? '$'+(revenue/conversions).toFixed(2) : '-'
      }, {
        'title': 'CPA',
        'data': totals.referrals !== 0 ? '$'+(cost/(totals.referrals * 2)).toFixed(2) : '-'
      }, {
        'title': 'CPC',
        'data': conversions !== 0 ? '$'+(cost/conversions).toFixed(2) : '-'
      }, {
        'title': 'CPC (Blended)',
        'data': conversions !== 0 ? '$'+((cost + discountedAmount)/conversions).toFixed(2) : '-'
      },
    ]

    const Stat = ({idx, ...props}) => {
      return (
        <Flex>
          { !(idx == 0 || (!isLg && idx == 4)) && (
            <Box ml='-12px' mr='12px'>
              <Divider orientation="vertical"/>
            </Box>
          )}
          <Flex
            mx={{xxs: 0, sm: '12px'}}
            justifyContent='end'
            alignItems='start'
            flexDir='column'
          >
            <Text fontWeight={'semibold'} fontSize={'2xl'} letterSpacing='wider' color='black.600'>
              {props.data}
            </Text>
            <Text whiteSpace={'nowrap'} fontWeight={'medium'} fontSize={'xs'} letterSpacing='wider'>
              {props.title}
            </Text>
          </Flex>
        </Flex>
      )
    }

    return (
      <HomeBox h='100%' w='100%'>
        <SimpleGrid ml={{xxs: 0, sm: '12px'}} rowGap='12px' columns={{xxs: 4, lg: 8}}>
          {stats.map((stat, idx)=><Stat key={idx} idx={idx} {...stat}/>)}
        </SimpleGrid>
      </HomeBox>
    )
  }



  // useEffect(() => {
  //   // Get the current user's session information
  //   if (campaigns.length == 0) { return }
  //   Auth.currentSession()
  //     .then(session => {
  //       // Extract the JWT token from the session information
  //       const jwtToken = session.getIdToken().getJwtToken();
  //
  //       axios.get('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-get-referrals', {
  //         headers: { "Authorization": jwtToken },
  //         params: {
  //           "request_type": "new_date_range",
  //           "campaigns": [campaigns],
  //           "start_date": calendarState[0][0].startDate.toISOString().slice(0, 10),
  //           "end_date": addDays(calendarState[0][0].endDate, 1).toISOString().slice(0, 10)
  //         }
  //       })
  //         .then(response => {
  //           // Handle the response and update the component state
  //           setData(response.data);
  //         })
  //         .catch(error => {
  //           // Handle any errors
  //           console.error(error);
  //         });
  //       })
  //       .catch(error => {
  //         // Handle any errors
  //         console.error(error);
  //     });
  // }, [campaigns]);


  const ReferralGraph = (props) => {

    const inactiveColor = useToken('colors', 'secondaryGray.700')
    const cursorColor = useToken('colors', 'black.100')

    const brand100 =  useToken('colors', 'brand.100');
    const brand200 =  useToken('colors', 'brand.200');
    const brand300 =  useToken('colors', 'brand.300');
    const brand400 =  useToken('colors', 'brand.400');
    const brand500 =  useToken('colors', 'brand.500');
    const brand600 =  useToken('colors', 'brand.600');
    const brand700 =  useToken('colors', 'brand.700');
    const brand800 =  useToken('colors', 'brand.800');
    const brand900 =  useToken('colors', 'brand.900');


    // const gradientColorTop    = useToken('colors', 'orange.500');
    // const gradientColorBottom = useToken('colors', 'orange.200');
    // const gradientColorTop    = '#56BE8F';
    // const gradientColorBottom = useToken('colors', 'brand.200');
    const refGradientColorTop    = brand400;
    const refGradientColorBottom = brand200;
    const convGradientColorTop    = useToken('colors', 'orange.500')+'B0';
    const convGradientColorBottom = useToken('colors', 'orange.300')+'B0';
    const transparent = '#00000000';

    const tickColor =  useToken('colors', 'black.400');

    useEffect(() => {
      if (!data) { return }
      const activeCampaignData = data.filter((el,idx)=>{
        return activeCampaigns[campaignIds.findIndex((c)=> c === el.id)]
      })


      let aggregatedData = activeCampaignData.reduce((accumulator, currentValue) => {
        currentValue.data.forEach(item => {
          let existingItem = accumulator.find(element => element.name === item.name);
          if (existingItem) {
            existingItem.receiverConversions += item.receiverConversions;
            existingItem.receiverDiscountAmount = String(parseFloat(existingItem.receiverDiscountAmount) + parseFloat(item.receiverDiscountAmount));
            existingItem.receiverRevenue = String(parseFloat(existingItem.receiverRevenue) + parseFloat(item.receiverRevenue));
            existingItem.referrals += item.referrals;
            existingItem.senderConversions += item.senderConversions;
            existingItem.senderDiscountAmount = String(parseFloat(existingItem.senderDiscountAmount) + parseFloat(item.senderDiscountAmount));
            existingItem.senderRevenue = String(parseFloat(existingItem.senderRevenue) + parseFloat(item.senderRevenue));
          } else {
            accumulator.push({
              name: item.name,
              receiverConversions: item.receiverConversions,
              receiverDiscountAmount: item.receiverDiscountAmount,
              receiverRevenue: item.receiverRevenue,
              referrals: item.referrals,
              senderConversions: item.senderConversions,
              senderDiscountAmount: item.senderDiscountAmount,
              senderRevenue: item.senderRevenue
            });
          }
        });
        return accumulator;
      }, []);

      const values = aggregatedData.length !== 0 ? aggregatedData : data.find((el)=>{return el.id === 'empty'}).data
      // const values = data && data.find(e => e.id === 'all').data
      if (!values) { return }
      const getPath = (x, y, width, height, radius, bottom) => {
        if (height === 0) { return }
        height = height + 1
        const halfWidth = width / 2;
        radius = radius ? radius : width * 2 / 5;
        x += 1;
        return bottom ? `
          m ${x + halfWidth} ${y}
          l ${-halfWidth + radius} ${0}
          q ${-radius} ${0} ${-radius} ${radius}
          l ${0} ${height-radius}
          l ${width} ${0}
          l ${0} ${-height+radius}
          q ${0} ${-radius} ${-radius} ${-radius}
          l ${-halfWidth + radius} ${0}
        ` : `
          m ${x + halfWidth} ${y}
          l ${-halfWidth + radius} ${0}
          q ${-radius} ${0} ${-radius} ${radius}
          l ${0} ${height}
          q ${0} ${-radius} ${radius} ${-radius}
          l ${(halfWidth - radius)*2} ${0}
          q ${radius} ${0} ${radius} ${radius}
          l ${0} ${- height}
          q ${0} ${-radius} ${-radius} ${-radius}
          l ${-halfWidth + radius} ${0}
        `
      };

      const updateDimensions = () => {

        const div = d3.select("#my_dataviz")
        const margin = {top: 10, right: 10, bottom: 20, left: 28},
            width = div?.node()?.getBoundingClientRect()?.width - margin.left - margin.right,
            height = div?.node()?.getBoundingClientRect()?.height - margin.bottom - margin.top

        // append the svg object to the body of the page
        div.select('svg').remove()
        const svg = div
          .append('svg')
            .attr('width', width + margin.right + margin.left)
            .attr('height', height + margin.top + margin.bottom)
          .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

          var parseDate = d3.timeParse('%Y-%m-%d');
          var formatDate = d3.timeFormat('%b %d')

          var x = d3.scaleBand().range([0, width]).padding(0.1);
          var y = d3.scaleLinear().range([height, 0]);
          var y2 = d3.scaleLinear().range([height, 0]);

          // Assuming values is an array of objects with properties 'name' and 'referrals'
          x.domain(values.map(function (d) { return new Date(d.name); }));
          y.domain([0, Math.max(d3.max(values, function (d) { return d.referrals; }), 2)]);
          y2.domain([0, Math.max(d3.max(values, function (d) { return d.senderRevenue + d.receiverRevenue; }), 2)]);


        // Create bars
        // svg.selectAll('.bar')
        //   .data(values)
        //   .enter().append('path')
        //     .attr("fill", "steelblue")
        //     .attr('d', function(d) {
        //       const maxW = 16;
        //       const px =
        //       Math.max(
        //         Math.max(
        //           Math.max(
        //             (Math.max(
        //               x.bandwidth(),
        //               maxW
        //             ) - maxW),
        //             6
        //           ),
        //           (x.bandwidth() - maxW) / 2
        //         ),
        //         x.bandwidth()*0.2
        //       );
        //       return getPath( // x, y, width, heigh, radius, bottom
        //         x(new Date(d.name)) + px - x.bandwidth()/4,
        //         y(d.referrals),
        //         x.bandwidth() - px,
        //         height - y(d.referrals) + 0.5,
        //         Math.min((x.bandwidth() - px) * 2 / 5, height - y(d.referrals) + 0.5),
        //         true
        //       )
        //     })
        //     .attr('fill', 'url(#Gradient3)'); // Use the linear gradient

        var infoDiv = d3.select("body").append("div")
          .attr("class", "tooltip-donut")
          .style("opacity", 0);

        // Create bars
        svg.selectAll('.bar')
          .data(values)
          .enter().append("rect")
            .attr("x", function(d) { return x(new Date(d.name)); })
            .attr("y", 0 )
            .attr("width", x.bandwidth()+6)
            .attr("height", height )
            .attr("fill", "#DAE5E0")
            .attr('opacity', '0.0')
            .on('mouseover', function (d, i) {
                d3.select(this).transition()
                     .duration('50')
                     .attr('opacity', '.2')
            })
            .on('mouseout', function (d, i) {
                d3.select(this).transition()
                     .duration('100')
                     .attr('opacity', '0.0');
            })



          svg.append('defs')
              .append('linearGradient')
              .attr('id', 'Gradient5')
              .attr('x1', '0')
              .attr('x2', '0')
              .attr('y1', '0')
              .attr('y2', '1')
              .selectAll('stop')
              .data([
                  { offset: '0%', color: '#7AD2B8' },
                  { offset: '98%', color: '#7AD2B8' },
                  { offset: '100%', color: '#7AD2B800' }
              ])
              .enter().append('stop')
              .attr('offset', d => d.offset)
              .attr('stop-color', d => d.color)
              .attr('stop-opacity', d => d.opacity || 1);

          const revArea = d3.area()
            .x(d => x(new Date(d.name)))
            .y0(y(0)-1)
            .y1(d => y2(d.senderRevenue + d.receiverRevenue))
            .curve(
              (context) => {
                return new CurveStepRounded(context, {
                  distance: .5,
                  shift: 1,
                  tilt: 1,
                  radius: Math.min(x.bandwidth()/6.0, 8),
                });
              }
            )

          // svg.append("path")
          //   // .attr("fill", "steelblue")
          //   .attr('fill', 'url(#Gradient5)')
          //   .attr("d", revArea(values))
          //   .on('mouseover', function (d, i) {
          //       d3.select(this).transition()
          //            .duration('50')
          //            .attr('opacity', '.85')
          //   })
          //   .on('mouseout', function (d, i) {
          //       d3.select(this).transition()
          //            .duration('50')
          //            .attr('opacity', '1');
          //   })




          // Define the linear gradient
          svg.append('defs')
              .append('linearGradient')
              .attr('id', 'Gradient3')
              .attr('x1', '0')
              .attr('x2', '0')
              .attr('y1', '0')
              .attr('y2', '1')
              .selectAll('stop')
              .data([
                  // { offset: '0%', color: refGradientColorTop },
                  { offset: '100%', color: refGradientColorBottom }
              ])
              .enter().append('stop')
              .attr('offset', d => d.offset)
              .attr('stop-color', d => d.color)
              .attr('stop-opacity', d => d.opacity || 1);

          const refArea = d3.area()
            .x(d => x(new Date(d.name)))
            .y0(y(0)-1)
            .y1(d => y(d.referrals))
            .curve(
              (context) => {
                return new CurveStepRounded(context, {
                  distance: .5,
                  shift: 1,
                  tilt: 1,
                  radius: Math.min(x.bandwidth()/6.0, 8),
                });
              }
            )

          svg.append("path")
            // .attr("fill", "steelblue")
            .attr('fill', 'url(#Gradient3)')
            .attr("d", refArea(values));


          svg.append('defs')
              .append('linearGradient')
              .attr('id', 'Gradient4')
              .attr('x1', '0')
              .attr('x2', '0')
              .attr('y1', '0')
              .attr('y2', '1')
              .selectAll('stop')
              .data([
                  { offset: '0%', color: '#AFE3D4' },
                  { offset: '98%', color: '#AFE3D4' },
                  { offset: '100%', color: '#AFE3D400' }
              ])
              .enter().append('stop')
              .attr('offset', d => d.offset)
              .attr('stop-color', d => d.color)
              .attr('stop-opacity', d => d.opacity || 1);

          const convArea = d3.area()
            .x(d => x(new Date(d.name)))
            .y0(y(0)-1)
            .y1(d => y(d.senderConversions + d.receiverConversions))
            .curve(
              (context) => {
                return new CurveStepRounded(context, {
                  distance: .5,
                  shift: 1,
                  tilt: 1,
                  radius: Math.min(x.bandwidth()/6.0, 8),
                });
              }
            )

          svg.append("path")
            // .attr("fill", "steelblue")
            .attr('fill', 'url(#Gradient4)')
            .attr("d", convArea(values));


          // Add x-axis
          svg.append('g')
            .attr('class', 'axis')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(x)
              .ticks(d3.timeWeeks)
              .tickFormat((d)=>{
                const dur = differenceInDays(endDate, startDate)
                return dur < 8
                  ? formatDate(d).split('').map(element => element.toUpperCase()).join('')
                  : dur < 60
                    ? isSunday(d) ? formatDate(d).split('').map(element => element.toUpperCase()).join('') : ''
                    : dur < 90
                      ? isFirstDayOfMonth(d) ? formatDate(d).split('').map(element => element.toUpperCase()).join('') : ''
                      : getMonth(d) % 3 == 1 && isFirstDayOfMonth(d) ? formatDate(d).split('').map(element => element.toUpperCase()).join('') : ''
              })
            );



          // Add y-axis
          svg.append('g')
            .attr('class', 'axis')
            .call(d3.axisLeft(y).ticks(5).tickFormat((n)=>{
              if (n !== Math.floor(n)) {
                return ""
              } else if (n >= 1000000) {
                return Math.floor(n / 1000000) + ((n % 1000000) ?? '') + 'M';
              } else if (n >= 1000) {
                return Math.floor(n / 1000) + ((n % 1000) ? '.' + ((n % 1000)+'').slice(0,1) : '') + 'k';
              } else {
                return n.toString();
              }
            }));

          // Add y2-axis
          // svg.append('g')
          //   .attr('class', 'axis')
          //   .call(d3.axisLeft(y2).ticks(5).tickFormat((n)=>{
          //     if (n !== Math.floor(n)) {
          //       return ""
          //     } else if (n >= 1000000) {
          //       return Math.floor(n / 1000000) + ((n % 1000000) ?? '') + 'M';
          //     } else if (n >= 1000) {
          //       return Math.floor(n / 1000) + ((n % 1000) ? '.' + ((n % 1000)+'').slice(0,1) : '') + 'k';
          //     } else {
          //       return n.toString();
          //     }
          //   }));


          svg.selectAll('.axis path')
              .style('display', 'none');

          svg.selectAll('.tick line')
              // .style('opacity', '0.1')
              .style('display', 'none');

          svg.selectAll('.tick text')
              .style('color', tickColor)
              .style('font-family', 'Inter')
              .style('font-size', '12px')
              .style('font-weight', 'var(--chakra-fontWeights-semibold)')
      };

      // Initial setup
      updateDimensions();

      // Attach resize event listener
      window.addEventListener("resize", updateDimensions);

      // Cleanup
      return () => {
        window.removeEventListener("resize", updateDimensions);
      };
    }, [activeCampaigns]);

    return (
      <HomeBox p='8px' display='flex' flexDir='column' w={{ xxs: '100%', md: 'calc(100% - 400px)' }} {...props}>
        <Text ml='12px' mt='4px' fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
          REFERRALS
        </Text>
        <Box h='100%' w='100%' id="my_dataviz"></Box>
      </HomeBox>

    )
  }

  const campaignColors = [
    '#AFE3D4',
    '#B5E6C7',
    '#B7E6B5',
    '#D5E6B5',
    '#E6DDB5',
    '#E6C5B5',
    '#E6B5C1',
    '#E0B5E6',
    '#B5C9E6',
    '#B5DCE6',
  ]
  const [selectAll, setSelectAll] = useState(false)

  const CampaignSelector = () => {

    const CampaignTag = ({name, id, idx, ...props}) => {
      const active = activeCampaigns[idx]
      const color = campaignColors[idx % campaignColors.length];
      return (
        <Tag
          boxShadow={'md'}
          size='lg'
          variant='solid'
          bg={active ? color : 'none'}
          border='2px solid'
          borderColor={ color }
          color={ active ? 'white' : color }
          fontWeight={ active ? 'bold' : 'semibold' }
          letterSpacing={'wide'}
          onClick={()=>{handleToggleCampaign(idx)}}
          cursor='pointer'
        >
          <TagLabel userSelect={'none'}>{name}</TagLabel>
          <Icon as={MdClose}
            ml='6px'
            mr='-6px'
            boxSize={4}
          />
        </Tag>
      );
    };

    return (
      <HomeBox>
        <Flex>
          <Text mb='12px' fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
            CAMPAIGNS
          </Text>
          <Spacer/>
          <Button
            boxShadow={'md'}
            bg="newBrand.400"
            _hover={{
              bg: "newBrand.300"
            }}
            _active={{
              bg: "newBrand.200"
            }}
            color="white"
            size='xs'
            variant='brand'
            onClick={()=>{
              setSelectAll(!selectAll);
              setActiveCampaigns(Array(campaigns.length).fill(selectAll));
            }}
          >
            {selectAll ? 'Select' : 'Deselect'} All
          </Button>
        </Flex>
        <Flex>
          <Flex gap='12px'>
            {campaigns && campaigns.map((c, idx)=>{
              const cdata = {
                'name': c.config.campaign_name,
                'id': c.campaign_id,
                'idx': idx
              };
              return <CampaignTag key={idx} {...cdata}/>
            })}
          </Flex>
        </Flex>
      </HomeBox>
      )
  }

  const ExportButton = () => {

    const csvData = () => {
      const out = data.reduce((acc,cdata)=>{
        if (!activeCampaigns[campaignIds.findIndex((el)=>cdata.id==el)]) {return acc}
        cdata.data.forEach(el => {
          acc.push(el)
        });
        return acc
      },[])
      return out
    }

    const csvHeaders = () => {
      var keys = Object.keys(data[0].data[0])
      keys[0] = 'date'
      return keys ?? []
    }

    return data ? (
      <CsvDownload
        data={csvData()}
        filename={format(startDate, 'yyMMdd')+'-'+format(endDate, 'yyMMdd')}
        delimiter={','}
        headers={csvHeaders()}
      >
        <Flex
          alignItems='center'
          rounded='8px'
          border='1px solid #ccc'
          w='auto'
          px='20px'
          py='8px'
          ml='12px'
          fontWeight='semibold'
          fontSize='sm'
          boxShadow="lg"
          bg="newBrand.400"
          _hover={{
            bg: "newBrand.300"
          }}
          _active={{
            bg: "newBrand.200"
          }}
          color="white"
        >
          Export
          <Icon as={MdDownload} ml='6px'/>
        </Flex>
      </CsvDownload>
    ) : (
      <Flex
        alignItems='center'
        rounded='8px'
        border='1px solid #ccc'
        w='auto'
        px='20px'
        py='8px'
        ml='12px'
        fontWeight='semibold'
        fontSize='sm'
        boxShadow="lg"
        bg="newBrand.400"
        _hover={{
          bg: "newBrand.300"
        }}
        _active={{
          bg: "newBrand.200"
        }}
        color="white"
      >
        Export
        <Icon as={MdDownload} ml='6px'/>
      </Flex>
    )
  }


  return (
    <Box>
      <Flex mb='12px' w='100%' justifyContent='end'>
        <ExportButton/>
      </Flex>
      <Flex mb='24px' gap='12px'>
        <Stats h='min-content'/>
      </Flex>
      <Flex
        gap='24px'
        mb='24px'
        w='100%'
        flexDir={{ xxs: "column", md: "row" }}
      >
        <DateRangePickerComponent minW='380px' maxW={{ xxs: '100%', md: '380px' }} endDateState={endDateState} startDateState={startDateState} defineds={defineds} />
        <ReferralGraph h='383px'/>
      </Flex>
      <CampaignSelector/>
    </Box>
  );
}
