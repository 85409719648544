import { createBreakpoints } from "@chakra-ui/theme-tools";

export const breakpoints = createBreakpoints({
  xxs: "320px",
  xs: "480px",
  sm: "720px",
  md: "900px",
  lg: "1200px",
  xl: "1600px",
  xxl: "1800px",
  xxxl: "1920px",
});
