import React, { useRef, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { Icon, Box, Spinner, Center, Flex, Spacer, useColorModeValue } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdRocketLaunch,
  MdOutlineViewComfy,
  MdSyncAlt,
  MdPalette,
  MdCampaign,
  MdDiscount,
} from "react-icons/md";
import { ProsperLogo } from "components/icons/Icons";

// Layout Imports
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import PricingLayout from "layouts/pricing";
// import RTLLayout from "layouts/rtl";

// Admin Imports
import Home from "views/admin/home";
import Reports from "views/admin/reports";
import Campaigns from "views/admin/campaigns";
import Widgets from "views/admin/widgets";
// import Discounts from "views/admin/discounts";
import Account from "views/admin/account";
import Team from "views/admin/team";
// import RTL from "views/admin/rtl";

// Pricing Imports
import Tiers from "views/pricing";
import Success from "views/pricing/success";
import Failure from "views/pricing/failure";

// Auth Imports
import SignIn from "views/auth/signIn";
import SignUp from "views/auth/signUp";
import Forgot from "views/auth/forgot";
import Verify from "views/auth/verify";

import useAuth from 'useAuth'
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useAuthContext } from "contexts/AuthContext";
import { useUserContext } from "contexts/UserContext";
import Organizations from "views/auth/organizations";
import { UserContext } from "contexts/UserContext";

const handleAppsumoAuth = async ({token, setToken}, navigate) => {
  const appsumoToken = token.value
  Auth.currentSession()
    .then(session => {
      const jwtToken = session.getIdToken().getJwtToken();
      axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-connect-appsumo',
        { 'appsumoToken': appsumoToken },
        { headers: { "Authorization": jwtToken } })
        .then(response => {
          // console.log(response);
          navigate("/pricing/success")
        })
        .catch(error => {
          console.error(error);
        })
      })
      .catch(error => {
        console.error(error);
    });
  setToken(null);
}

const handleTeamAuth = async ({token, setToken}, navigate) => {
  const teamToken = token.value;
  Auth.currentSession()
    .then(session => {
      const jwtToken = session.getIdToken().getJwtToken();
      axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/accept_invite',
        { 'teamToken': teamToken },
        { headers: { "Authorization": jwtToken } })
        .then(response => {navigate('/auth/organizations')})
        .catch(error => {
          console.error(error);
        })
      })
      .catch(error => {
        console.error(error);
    });
  setToken(null);
}

function RequireAuth({ children }) {
  const { user, signOut, loading, updateUser } = useAuth();
  const navigate = useNavigate();

  const authConext = useAuthContext();
  const { updateTenants } = useUserContext();
  const {token, setToken} = authConext;
  var shopifyToken = null;
  var appsumoToken = null;
  var teamToken = null;
  if (token) {
    if (token.name === 'team') { teamToken = token.value }
    else if (token.name === 'appsumo') { appsumoToken = token.value }
    else if (token.name === 'shopify') { shopifyToken = token.value }
    // console.log(token, shopifyToken, appsumoToken)
  }
  if (loading) {
    return <Center mt='40vh'><Flex flexDirection='column' alignItems='center'><ProsperLogo color={"brand.400"} h='100px' w='auto' my='24px'></ProsperLogo><Spinner size='xl' mb='20px'></Spinner></Flex></Center>
  } else if (!loading && user === null) {
    return <Navigate to="/auth/sign-in" replace={true} />;
  } else {
    if (appsumoToken) { handleAppsumoAuth(authConext, navigate) }
    if (teamToken) { handleTeamAuth(authConext, navigate).then(()=>{
      updateTenants()
    })}
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { user, signOut, loading, updateUser })
    );
    return childrenWithProps;
  }
}


function RequireTenant({ children, user }) {
  const { activeTenantState, updateActiveTenant, tenantsState, updateTenants, userState, updateUser } = useUserContext();
  const [activeTenant, setActiveTenant] = activeTenantState;
  const [tenants, setTenants] = tenantsState;

  if (!activeTenant) {
    const stored = JSON.parse(localStorage.getItem('activeTenant'))
    if (!tenants) {
      updateTenants();
      return <Center mt='40vh'><Flex flexDirection='column' alignItems='center'><ProsperLogo color={'brand.400'} h='100px' w='auto' my='24px'></ProsperLogo><Spinner size='xl' mb='20px'></Spinner></Flex></Center>
    } else if (!stored || stored.user_id != user.attributes.sub) {
      return <Navigate to="/auth/organizations" replace={true} />;
    } else {
      updateActiveTenant();
      return <Center mt='40vh'><Flex flexDirection='column' alignItems='center'><ProsperLogo color={'brand.400'} h='100px' w='auto' my='24px'></ProsperLogo><Spinner size='xl' mb='20px'></Spinner></Flex></Center>
    }
  }
  return children;

}

function RequireNoAuth({ children }) {
  const logoColor = useColorModeValue("brand.400", "brand.400");
  const { user, loading } = useAuth();

  const authConext = useAuthContext();
  const location = useLocation();
  const {token, setToken} = useAuthContext();
  console.log(token)
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    const team = queryParams.get('teamToken')
    const shopify = queryParams.get('shopifyToken')
    const appsumo = queryParams.get('appsumoToken')
    if (team) {
      setToken({name: 'team', value: team});
    } else if (shopify) {
      setToken({name: 'shopify', value: shopify});
    } else if (appsumo) {
      setToken({name: 'appsumo', value: appsumo});
    }
  }, [location.search]);

  if (loading) {
    return <Center mt='40vh'><Flex flexDirection='column' alignItems='center'><ProsperLogo color={logoColor} h='100px' w='auto' my='24px'></ProsperLogo><Spinner size='xl' mb='20px'></Spinner></Flex></Center>
  } else if (!loading && user !== null) {
    return <Navigate to="/auth/organizations" replace={true} />;
  } else {
    return children;
  }
}

function RequireTier({ children }) {
  const logoColor = useColorModeValue("brand.400", "brand.400");
  const { user, signOut, loading } = useAuth();
  const { activeTenantState, tenantsState, updateTenants } = useUserContext();
  const [activeTenant, setActiveTenant] = activeTenantState;
  const [tenants, setTenants] = tenantsState;

  if (!tenants) {
    updateTenants()
    return <Center mt='40vh'><Flex flexDirection='column' alignItems='center'><ProsperLogo color={logoColor} h='100px' w='auto' my='24px'></ProsperLogo><Spinner size='xl' mb='20px'></Spinner></Flex></Center>
  } else if (!tenants.find(tenant => tenant.tenant_id === activeTenant).tier) {
    return <Navigate to="/pricing" replace={true} />;
  } else {
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { user, signOut, loading })
    );
    return childrenWithProps;
  }
}


const routes = [
  {
    name: "Redirect",
    path: "*",
    element: <Navigate to="/admin/home" replace={true}/>,
  },
  {
    name: "Pricing",
    path: "/pricing",
    element: <RequireAuth><RequireTenant><PricingLayout /></RequireTenant></RequireAuth>,
    children: [
      {
        name: "Tiers",
        path: "/pricing/tiers",
        element: <Tiers />,
      },
      {
        name: "Success",
        path: "/pricing/success",
        element: <Success />,
      },
      {
        name: "Failure",
        path: "/pricing/failure",
        element: <Failure />,
      },
    ]
  },
  {
    name: "Admin",
    path: "/admin",
    element: <RequireAuth><RequireTenant><RequireTier><AdminLayout /></RequireTier></RequireTenant></RequireAuth>,
    // icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    children: [
      {
        name: "Home",
        path: "/admin/home",
        element: <Home />,
        // icon: <Icon as={MdHome} width='20px' height='20px' color='brand.500' />,
      },
      {
        name: "Widgets",
        path: "/admin/widgets",
        element: <Widgets />,
        // icon: <Icon as={MdOutlineViewComfy} width='20px' height='20px' color='brand.500' />,
      },
      {
        name: "Campaigns",
        path: "/admin/campaigns",
        element: <Campaigns />,
        // icon: <Icon as={MdRocketLaunch} width='20px' height='20px' color='brand.500' />,
      },
      // {
      //   name: "Discounts",
      //   path: "/admin/discounts",
      //   element: <Discounts />,
      //   icon: <Icon as={MdDiscount} width='20px' height='20px' color='inherit' />,
      // },
      // {
      //   name: "Integrations",
      //   path: "/admin/integrations",
      //   element: <MainDashboard />,
      //   icon: <Icon as={MdSyncAlt} width='20px' height='20px' color='inherit' />,
      // },

      // {
      //   name: "NFT Marketplace",
      //   path: "/admin/nft-marketplace",
      //   element: <NFTMarketplace />,
      //   icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
      //   secondary: true,
      // },
      // {
      //   name: "Data Tables",
      //   path: "/admin/data-tables",
      //   element: <DataTables />,
      //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
      // },
      {
        name: "Reports",
        path: "/admin/reports",
        element: <Reports />,
        // icon: <Icon as={MdBarChart} width='20px' height='20px' color='brand.500' />,
      },
      {
        name: "Team",
        path: "/admin/team",
        element: <Team />,
        // icon: <Icon as={MdPerson} width='20px' height='20px' color='brand.500' />,
      },
      {
        name: "Account",
        path: "/admin/account",
        element: <Account />,
        // icon: <Icon as={MdPerson} width='20px' height='20px' color='brand.500' />,
      },
    ]
  },
  {
    name: "Auth",
    path: "/auth",
    element: <AuthLayout />,
    // icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    children: [
      {
        name: "Sign In",
        path: "/auth/sign-in",
        element: <RequireNoAuth><SignIn /></RequireNoAuth>,
        // icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      },
      {
        name: "Organizations",
        path: "/auth/organizations",
        element: <RequireAuth><Organizations/></RequireAuth>,
        // icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      },
      {
        name: "Sign Up",
        path: "/auth/sign-up",
        element: <RequireNoAuth><SignUp /></RequireNoAuth>,
        // icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      },
      {
        name: "Forgot Password",
        path: "/auth/forgot-password",
        element: <RequireNoAuth><Forgot /></RequireNoAuth>,
        // icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      },
      {
        name: "Verify Email",
        path: "/auth/verify-email",
        element: <RequireNoAuth><Verify /></RequireNoAuth>,
        // icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      },
    ]
  },
  // {
  //   name: "RTL Admin",
  //   element: <RTL />,
  //   path: "/rtl/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  // },
];

export default routes;
