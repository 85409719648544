import React from "react";
import {
  Tooltip,
  Button,
  Icon,
  Text,
  Box,
  Center,
  useToken,
  useColorModeValue,
} from "@chakra-ui/react";
import PersonIcon from 'assets/img/avatars/PersonIcon';
import FormikSelect from './FormikSelect';
import { useField } from 'formik';
import { MdHelp } from "react-icons/md";

function SenderShopify ({ inputs, discounts, setCurrentStep, ...props }) {
  const fieldName = inputs.fields[0].field;
  const validate = inputs.fields[0].validate;
  const label = inputs.label;
  const green = useColorModeValue("#2BB78E", "#2BB78E");
  const field = useField({name: fieldName, validate: validate});
  return (
    <Box {...props}>
      <Box>
        <Center w='460px'>
          <Text variant='modal'>Next, choose your </Text>
          &nbsp;
          <Text variant='modal' fontWeight="bold" decoration="underline" color={green} >
            SENDER
          </Text>
          &nbsp;
          <Text variant='modal' fontWeight="semibold">
            discount from
          </Text>
          &nbsp;
          <Text variant='modal' fontWeight="bold">
            Shopify
          </Text>
          <PersonIcon color={green} size='16px' ml='10px'/>
          <Tooltip hasArrow variant='modal' label="We will reward this discount to the sender">
            <Box> <Icon w="16px" h="16px" as={MdHelp} color={"black"} ml="5px" /> </Box>
          </Tooltip>
        </Center>
        <Text variant='hintModal' mt="10px" onClick={()=>window.open('/admin/home')}>
          Don’t have one? Check out our tutorial on creating Shopify discounts
        </Text>
      </Box>
      <FormikSelect
        focusBorderColor="brand.400"
        selectedOptionStyle="check"
        labelPath={'name'}
        valuePath={'shopify_discount_id'}
        object={{
          type: 'shopify',
          name: null,
          shop_url: discounts?.[0]?.['shop_url'],
          shopify_discount_id: null
        }}
        chakraStyles={{
          control: (provided) => ({ ...provided, background: 'brand.100' })
        }}
        //TODO: set proper options
        options={discounts?.[0]?.discounts}
        size='lg'
        mt='20px'
        placeholder="Choose a Discount..."
        height="48px"
        maxWidth="100%"
        // field={field}
        field={field}
        validate={validate}
      />
      {/*
      <Box>
        <Text variant='boldModal' mb='0px' > OR </Text>
        <Center>
          <Button variant='boldModal'
            onClick={() => {
              // field[2].setValue(field[1].initialValue); // wipe field value on navigate to static
              setCurrentStep(3)
            }}
          >
            Use a Custom Static Code
          </Button>
        </Center>
      </Box>
      */}
    </Box>
  );
}

export default SenderShopify;
