import { mode } from "@chakra-ui/theme-tools";

export const switchStyles = {
  components: {
    Switch: {
      baseStyle: {
        thumb: {
          fontWeight: 400,
          borderRadius: "50%",
          w: "16px",
          h: "16px",
          _checked: { transform: "translate(20px, 0px)" },
        },
        track: {
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
          w: "40px",
          h: "20px",
          p: "2px",
          ps: "2px",
          _focus: {
            boxShadow: "none",
          },
        },
      },
      sizes: {
        xxs: {
          track: {
            w: "28px",
            h: "14px",
            p: "1px",
            ps: "1px",
          },
          thumb: {
            w: "10px",
            h: "10px",
            _checked: { transform: "translate(14px, 0px)" },
          },
        },
        md: {
          track: {
            w: "40px",
            h: "20px",
            p: "2px",
            ps: "2px",
          },
          thumb: {
            w: "16px",
            h: "16px",
            _checked: { transform: "translate(20px, 0px)" },
          },
        },
        lg: {
          track: {
            w: "52px",
            h: "30px",
            p: "2px",
            ps: "3px",
          },
          thumb: {
            w: "26px",
            h: "26px",
            _checked: { transform: "translate(21px, 0px)" },
          },
        },
        xl: {
          track: {
            w: "64px",
            h: "36px",
            p: "2px",
            ps: "3px",
          },
          thumb: {
            w: "30px",
            h: "30px",
            _checked: { transform: "translate(28px, 0px)" },
          },
        },
      },
      variants: {
        main: (props) => ({
          track: {
            bg: mode("gray.300", "brand.800")(props),
            _checked: {
              bg: mode("brand.300", "brand.300")(props),
            }
          },
        }),
        either: (props) => ({
          track: {
            bg: mode("brand.300", "brand.900")(props),
            _checked: {
              bg: mode("brand.300", "brand.900")(props),
            }
          },
        }),
        eitherGreen: (props) => ({
          track: {
            bg: mode("green.500", "green.900")(props),
            _checked: {
              bg: mode("green.500", "green.900")(props),
            }
          },
        }),
        mainLight: {
          track: {
            bg: "secondaryGray.50",
            _checked: {
              bg: "brand.300",
            }
          },
        },
        mainDark: {
          track: {
            bg: "secondaryGray.800",
            _checked: {
              bg: "brand.300",
            }
          },
        },
      },
    },
  },
};
