// SaveOrDiscardModal.js

import React from 'react';
import { Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdHelp } from 'react-icons/md';
import FormikSelect from 'components/formik/FormikSelect';
import FormikInput from 'components/formik/FormikInput';
import { Formik, useField } from 'formik';
import axios from 'axios';
import * as yup from 'yup';
import { Auth } from 'aws-amplify';

const CancelModal = ({ isOpen, onOpen, onClose, onSave, invited, refresh, ...props }) => {
  const modalBg = useColorModeValue( "brand.200", "brand.200");

  const backRef = React.useRef();
  const navigate = useNavigate();

  const handleRemove = () => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();

        axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/cancel-invite',
          { tenant_id: props.tenant, email: invited.email, token: invited.token },
          { headers: { "Authorization": jwtToken } })
          .then(response => {
            refresh()
            onClose()
          })
          .catch(error => { console.error(error); })
        })
        .catch(error => { console.error(error); });
  }


  // console.log(invited);

  return (
    <Modal
      isOpen={isOpen} onClose={onClose}
      closeOnOverlayClick={true} closeOnEsc={true}
      leastDestructiveRef={backRef}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent
        bg={modalBg}
        width="540px"
        height="auto"
        maxWidth="100%"
        margin="auto"
      >
        <ModalHeader>Cancel Invite</ModalHeader>
        <ModalCloseButton size="md" _focus="none" />

        <ModalBody>
          <Text mb='15px'>Are you sure you want to cancel the invite for <Text as="span" fontWeight='bold'>{invited.email}</Text>?</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            mr={3}
            onClick={onClose}
            border="1px solid #C9CED2"
          >
            Back
          </Button>
          <Button variant='red'
            onClick={()=>{
              handleRemove()
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelModal;
