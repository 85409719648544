import {
  useToken,
  Input,
  InputGroup,
  Stack,
  Button,
  Icon,
  Text,
  useStyleConfig,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useColorModeValue,
  Divider,
  Box,
  Flex,
  SimpleGrid,
  Code,
  Image,
  Center,
  FormLabel,
} from "@chakra-ui/react";
import FormikInput from './FormikInput';
import FormikObjectInput from './FormikObjectInput';
import { useField } from 'formik';

function Name({ inputs, ...props }) {
  const urlField = inputs.fields[0].field
  const urlValidate = inputs.fields[0].validate
  const orgField = inputs.fields[1].field
  const orgValidate = inputs.fields[1].validate
  const label = inputs.label
  const whiteBg = useColorModeValue(
    useToken("colors", ["brand.100"]),
    useToken("colors", ["brand.100"])
  );
  // console.log(field, validate);
  return (
    <Box {...props}>
      <FormLabel variant='modal' lineHeight="1.65" height="21px" textAlign="center" >
        Enter a URL link to include in the referral
      </FormLabel>
      <Text variant='hintModal' cursor='default' textDecoration='none' mt='10px' width="430px" >
        If you leave this empty, we will use your organization’s URL
      </Text>
      <InputGroup size="lg" width="440px" maxWidth="100%" mb='30px'>
        {/*
        <Input _focus={{border:"1px solid #C9CED2"}} bg={whiteBg} placeholder="https://www.getprosper.io" size="lg" />
        */}
        <FormikInput
          focusBorderColor="#C9CED2" bg={whiteBg} placeholder="https://www.getprosper.io" size="lg"
          field={useField({ name: urlField, validate: urlValidate })}
          validate={urlValidate}
        />
      </InputGroup>
      <FormLabel variant='modal' lineHeight="1.65" height="21px" textAlign="center" >
        Enter an organization name to include in the referral
      </FormLabel>
      <Text variant='hintModal' cursor='default' textDecoration='none' mt='10px' width="430px" >
        If you leave this empty, we will use your organization’s name
      </Text>
      <InputGroup size="lg" width="440px" maxWidth="100%" mb='10px'>
        {/*
        <Input _focus={{border:"1px solid #C9CED2"}} bg={whiteBg} placeholder="Prosper Technology Solutions Inc." size="lg" />
        */}
        <FormikInput
          focusBorderColor="#C9CED2" bg={whiteBg} placeholder="Prosper Technology Solutions Inc." size="lg"
          field={useField({ name: orgField, validate: orgValidate })}
          validate={orgValidate}
        />
      </InputGroup>
    </Box>
  );
}

export default Name;
