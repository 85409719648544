import React, { useEffect, useState} from 'react';
import { Field, FieldValidator } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Tooltip,
  Icon,
  Flex,
  Box,
} from "@chakra-ui/react";
import { MdHelp } from 'react-icons/md';

function FormikInput({ label, field, placeholder, validate, validateField, isRequired, tooltip, children, ...props }) {
  const [ fieldName, meta, helpers] = field;
  // console.log(helpers, meta, fieldName)
  const { setValue, setError, setTouched } = helpers;
  const { value, initialValue } = meta;
  const [text, setText] = useState(value ?? initialValue);
  const [isRunning, setIsRunning] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  // console.log( value, initialValue );
  // console.log(children)

  // useEffect(() => {
  //   let timerInterval;
  //
  //   // console.log(timeRemaining)
  //   if (isRunning && timeRemaining > 0) {
  //     timerInterval = setInterval(() => {
  //       setTimeRemaining((prevTime) => prevTime - 1);
  //     }, 10);
  //   } else if (timeRemaining === 0) {
  //     setIsRunning(false);
  //   }
  //
  //   return () => clearInterval(timerInterval); // Cleanup interval on unmount
  // }, [timeRemaining]);

  useEffect(async () => {

    validate(text).then((out)=>{
        setError(out ? out : null);
      }).catch((err)=>{
        console.log(err);
      });

    // console.log(!isRunning)
    // if (!isRunning) {
      // setTimeRemaining(100);
      // setIsRunning(true);
    // }
  }, [text])

  // console.log(meta, fieldName, helpers);
  // console.log(meta.error);
  return (
    <FormControl isRequired={isRequired} mb='15px'
      isInvalid={meta.error && meta.touched}
    >
      <Flex>
        {label && (
          <FormLabel variant='modal' >
            {label}
          </FormLabel>
        )}
        {tooltip && (
          <Tooltip hasArrow variant='modal' label={tooltip}>
            <Box> <Icon w="16px" h="16px" as={MdHelp} color={"black"} ml="5px" /> </Box>
          </Tooltip>
        )}
      </Flex>
      <InputGroup size="lg">
        {children}
        <Input
          {...props}
          // bg={'brand.100'}
          variant='modal'
          value={text}
          placeholder={placeholder}
          onBlur={fieldName.onBlur}
          onChange={(e) => {
            setText(e.target.value)
            setValue(e.target.value)
          }}
          border='2px solid'
          _focus={{ borderColor: meta.error ? 'red.500' : props.focusBorderColor ?? 'transparent' }}
          borderColor={ meta.error ? 'red.500' : 'transparent'}
        />
        <FormErrorMessage position='absolute'>{meta.error}</FormErrorMessage>
      </InputGroup>
    </FormControl>
  );
}
// function FormikInput({ label, field, validate, validateField, ...props }) {
//   // const debouncedValidate =
//   return (
//     <Box>
//       <Field name={field} validate={validate}>
//         {({ field, form }) => {
//           const errors = _.get(form.errors, field.name);
//           const touched = _.get(form.touched, field.name);
//           // form.validateField(field.name)
//           return (
//             <FormControl isRequired mb='15px'
//               isInvalid={errors && touched}
//             >
//               { label && (
//                 <FormLabel variant='modal' >
//                   {label}
//                 </FormLabel>
//               ) }
//
//               <Input {...field} {...props}
//                   onChange={(e)=>{field.onChange(e); validateField(field.name); console.log("!")}}
//                   onBlur={() => {form.validateField(field.name)}}
//                   variant='modal' border='2px solid' borderColor={ errors ? 'red.500' : 'transparent'}/>
//               <FormErrorMessage position='absolute'>{errors}</FormErrorMessage>
//             </FormControl>
//           )
//         }}
//       </Field>
//     </Box>
//   );
// }

export default FormikInput;
