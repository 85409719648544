import { mode } from "@chakra-ui/theme-tools";
export const formLabelStyles = {
  components: {
    FormLabel: {
      baseStyle: { },
      variants: {
        modal: (props) => ({
          fontFamily: "Inter",
          lineHeight: "1.41",
          fontWeight: "semibold",
          fontSize: "17px",
          color: "gray.700",
        }),
      },
    },
  },
};
