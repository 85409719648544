import React from "react"
import { Icon } from "@chakra-ui/react"
const Enabled = (props) => (
  <Icon
    w={props.size}
    h={props.size}
    {...props} >
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 22 22" xmlSpace="preserve">
    <style type="text/css">
    </style>
    <path fill={'#184E3E'} d="M11,22c6.1,0,11-4.9,11-11S17.1,0,11,0S0,4.9,0,11S4.9,22,11,22z"/>
    <path
      fill={'none'}
      stroke={'#FFFFFF'}
      strokeWidth={'1.5'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      d="M5.5,11.6l3.3,3.3l7.7-7.7"/>
    </svg>
  </Icon>
)
export default Enabled
