// Chakra imports
import {
  AccordionButton,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Text,
  Button,
  Input,
  useDisclosure,
  Tr,
  Td,
  Th,
  Tbody,
  Table,
  TableCaption,
  Thead,
  TableContainer,
  Stack,
  CheckboxGroup,
  Checkbox,
  Link,
  chakra,
} from "@chakra-ui/react";
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useContext, useEffect, useState } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdRocketLaunch,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import CreateModal from "./components/CreateModal";
import { AuthContext } from "contexts/AuthContext";

import Card from "components/card/Card.js";
// import Campaign from "views/admin/campaigns/components/Campaign";
import { Outlet, NavLink, useNavigate, useOutlet, useOutletContext, useLocation } from "react-router-dom";
import axios from 'axios';
import { Auth } from 'aws-amplify';
import {v4 as uuidv4} from 'uuid';
import defaultCampaign from "./variables/defaultCampaign"
import { maxCampaignsByTier } from "variables/tiers";
import { ChevronDownIcon } from "@chakra-ui/icons";
import CampaignRow from "./components/CampaignRow";
import { useUserContext } from "contexts/UserContext";

export default function Campaigns(props) {
  const textColorPrimary = useColorModeValue("brand.600", "brand.600");

  const { userState, tenantsState, activeTenantState, campaignsState, widgetsState, updateCampaigns, updateWidgets } = useUserContext();
  const [user, setUser] = userState;
  const [activeTenant, setActiveTenant] = activeTenantState;
  const [tenants, setTenants] = tenantsState;
  const [campaigns, setCampaigns] = campaignsState;
  const [widgets, setWidgets] = widgetsState;
  const [team, setTeam] = useState();

  const [loading, setLoading] = useState(true);
  const [rerenderCount, setRerenderCount] = useState(0);
  const [shopifyDiscounts, setShopifyDiscounts] = useState([]);
  // const user = useContext(AuthContext);
  const [maxCampaigns, setMaxCampaigns] = useState(0);

  const location = useLocation();
  useEffect(()=>{
    if (location?.state?.openNew) {createModalDisclosure.onOpen()}
  },[])
  const navigate = useNavigate();
  const outlet = useOutlet();
  const createModalDisclosure = useDisclosure();
  const emptyCampaign = defaultCampaign;

  function rerenderCampaigns() {
    setRerenderCount(rerenderCount + 1);
  }

  useEffect(()=>{
    if (!tenants) { return }
    setTeam( tenants.find(tenant => tenant.tenant_id === activeTenant) )
  },[tenants])

  useEffect(()=>{
    if (!team) { return }
    setMaxCampaigns(maxCampaignsByTier[team?.tier] ?? 0)
  },[team])


  const getShopifyDiscounts = () => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();

        axios.get('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-get-shopify-discounts', {
          params: { tenant_id: activeTenant },
          headers: { "Authorization": jwtToken },
        })
          .then(response => {
            response.data.forEach(e => {
              e.discounts = e.discounts.map((el) => { return {
                "label": el.codeDiscount.title,
                "name": el.codeDiscount.title,
                "value": el.id
            }})});
            setShopifyDiscounts(response.data);
          })
          .catch(error => { console.error(error); })
          .finally(() => { setLoading(false); });
        })
        .catch(error => { console.error(error); });
  }

  function updateCampaignList(index, newDatum) {
    setCampaigns(prevData => {
      const updatedData = [...prevData];
      updatedData[index] = newDatum;
      return updatedData;
    });
  }

  function removeCampaign(index) {
    setCampaigns(prevData => {
      var updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
  }

  useEffect(() => {
    getShopifyDiscounts();
  }, [user])

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const iconColor = useColorModeValue("brand.300", "brand.300");
  const boxBg = useColorModeValue("brand.500", "brand.500");
  const createBg = useColorModeValue("brand.800", "brand.800");

  const saveCampaign = (camp, campaignId) => {
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        const request = {
          tenant_id: activeTenant,
          campaign_id: campaignId,
          config: camp,
          resource_type: 'campaign'
        }
        axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-create-config',
          request,
          {
            headers: { "Authorization": jwtToken },
          })
          .catch(error => {
            console.error(error);
          })
        })
        .catch(error => {
          console.error(error);
      });
    updateCampaignList(campaigns.length, {
      "campaign_id": campaignId,
      "tenant_id": activeTenant,
      "config": camp,
      // "new": true
    })
  }
  const getCampaignWidget = (widget_id) => {
    if (!widget_id || !widgets) { return }
    const campaignWidgets = widgets.filter((widg)=>{
      return widg.widget_id === widget_id
    })
    return campaignWidgets[0]
  }

  const CampaignCount = () => {
    return (
      <Box
        boxShadow='md'
        py={"8px"}
        px={"16px"}
        width={"100%"}
        position={"relative"}
        borderRadius={"12px"}
        wordWrap={"break-word"}
        backgroundClip={"border-box"}
        bg="white"
        border="1px solid #eee"
        h='100%'
        w='160px'
      >
        <Flex justifyContent='space-between'>
          <Text fontWeight={'semibold'} fontSize={'xs'} letterSpacing='wider'>
            CAMPAIGNS
          </Text>
          <Text fontWeight={'bold'} fontSize={'lg'} letterSpacing='wider' color='black.400'>
            {campaigns?.length}
          </Text>
        </Flex>
      </Box>
    )
  }

  return (
    outlet ? ( <Outlet/> ) : (
      <Box>
        <Flex justifyContent='space-between' alignItems='end' mb='12px'>
          <CampaignCount/>


          { (maxCampaigns === -1 || maxCampaigns > (campaigns ?? 0).length) && (
            <>
              <CreateModal
                disclosure={createModalDisclosure}
                widgets={widgets && widgets.map((e) => { return {
                  "label": e.config.widget_name,
                  "name": e.config.widget_name,
                  "value": e.widget_id
                }})}
                updateCampaigns={updateCampaigns}
                discounts={shopifyDiscounts}
                activeTenant={activeTenant}
                team={team}
                save={saveCampaign}
                isNew={true}
              >
                <Button
                  border='1px solid #AFAFAF'
                  w='auto'
                  color={textColorPrimary}
                  py='4px'
                  ml='12px'
                  onClick={createModalDisclosure.onOpen}
                  bg={createBg}
                  fontSize='sm'
                  boxShadow="lg"
                  _hover={{
                    bg: "none",
                    borderColor: "brand.800",
                  }}
                >
                  Create Campaign
                </Button>
              </CreateModal>
            </>
          )}
        </Flex>

        {/*
        <SimpleGrid mb='20px'>
          { loading || !user ? null : campaigns.slice(0).reverse().map((campaign, index) => {
            return campaign ? (
              <Campaign
                key={campaigns.length - index - 1}
                index={campaigns.length - index - 1}
                data={campaign}
                update={updateCampaignList}
                remove={removeCampaign}
                discounts={shopifyDiscounts}
                widgets={widgets}
                isnew={campaign.new ? 1 : 0}
                rerendercampaigns={rerenderCampaigns}
                user={user}
              />
            ) : null
          })}
        </SimpleGrid>
        */}
        <Box boxShadow='lg' rounded="12px" mb="20px" bg="#fff" border="1px solid #EEE" pt='10px' pb='10px'>
          <TableContainer>
            <Table variant="simple">
              <TableCaption>Need more Campaigns? Upgrade your plan</TableCaption>
              <Thead>
                <Tr>
                  <Th>Campaign Name</Th>
                  {/*
                  <Th>Referrals Total</Th>
                  <Th>Referrals This Week</Th>
                  */}
                  <Th>Sender Reward</Th>
                  <Th>Receiver Reward</Th>
                  <Th>Assigned Widget</Th>
                  <Th isNumeric>
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                { campaigns !== null && campaigns.slice(0).reverse().map((campaign, index) => {
                  return campaign && (
                    <CampaignRow
                      updateCampaigns={updateCampaigns}
                      key={campaigns.length - index - 1}
                      index={campaigns.length - index - 1}
                      data={campaign}
                      update={updateCampaignList}
                      remove={removeCampaign}
                      discounts={shopifyDiscounts}
                      widgets={widgets && widgets.map((e) => { return {
                        "label": e.config.widget_name,
                        "name": e.config.widget_name,
                        "value": e.widget_id
                      }})}
                      widget={getCampaignWidget(campaign.config.widget.id)}
                      isnew={campaign.new ? 1 : 0}
                      rerendercampaigns={rerenderCampaigns}
                      activeTenant={activeTenant}
                      team={team}
                    />
                )})}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    )
  );
}
