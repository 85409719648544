import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Tag,
  Text,
} from '@chakra-ui/react'
import { AiFillCheckCircle } from 'react-icons/ai'
import Card from 'components/card/Card'

const AppSumoTiers = ({user, ...props}) => {
  return (
    <Card bg="white" border="1px solid #ccc" {...props}>
      <Box display='flex' justifyContent='space-between'>
      <Heading size="lg" mb="20px">
        Plans
      </Heading>
      <Link href='https://appsumo.com/account/products/' isExternal>
        <Button
          colorScheme='orange'
          border='1px solid #AFAFAF'
          w='auto'
          color='brand.600'
          py='4px'
          ml='12px'
          bg='brand.800'
          fontSize='sm'
          boxShadow="lg"
          _hover={{
            bg: "none",
            borderColor: "brand.800",
          }}
        >
          Upgrade Tier
        </Button>
      </Link>
      </Box>
      <Flex>
        <SimpleGrid w='100%' columns={{ xxs: 1, lg: 3 }}>

        <Box
          mr='20px'
          mb='24px'
          borderRadius="20px"
          border="1px solid #ccc"
          bg="brand.100"
          minW="250px"
        >
          <Box bg="brand.600" padding="15px" borderTopRadius="20px">
            <Box display="flex" justifyContent="space-between">
              <Heading size="md" color="white">
                Tier 1
              </Heading>
              <Heading size="md" color="white">
                $59
              </Heading>
            </Box>
            <Box mt="10px" display="flex" justifyContent="space-between">
              { user.attributes['custom:tier'] == 'prosper_tier1' ? (
                <Tag variant="solid" colorScheme="orange" color='black'>
                  Active
                </Tag>
              ) : (
                <Tag variant="solid" colorScheme="red">
                  Inactive
                </Tag>
              )}
              <Text mt="-4px" color="white">
                Lifetime
              </Text>
            </Box>
          </Box>
          <Box
            padding="20px"
            borderRadius="0"
            borderBottom="1px solid #ccc"
            display="flex"
            justifyContent="space-between"
          >
            <Text fontSize="lg">250 Monthly Referrals</Text>
            <Icon as={AiFillCheckCircle} color="green" mt="5px" />
          </Box>
          <Box
            padding="20px"
            borderRadius="0"
            display="flex"
            justifyContent="space-between"
          >
            <Text fontSize="lg">1 Team Members</Text>
            <Icon as={AiFillCheckCircle} color="green" mt="5px" />
          </Box>
        </Box>

        <Box
          mb='24px'
          mr='20px'
          borderRadius="20px"
          border="1px solid #ccc"
          bg="brand.100"
          minW="250px"
        >
          <Box bg="brand.600" padding="15px" borderTopRadius="20px">
            <Box display="flex" justifyContent="space-between">
              <Heading size="md" color="white">
                Tier 2
              </Heading>
              <Heading size="md" color="white">
                $159
              </Heading>
            </Box>
            <Box mt="10px" display="flex" justifyContent="space-between">
              { user.attributes['custom:tier'] == 'prosper_tier2' ? (
                <Tag variant="solid" colorScheme="orange" color='black'>
                  Active
                </Tag>
              ) : (
                <Tag variant="solid" colorScheme="red">
                  Inactive
                </Tag>
              )}
              <Text mt="-4px" color="white">
                Lifetime
              </Text>
            </Box>
          </Box>
          <Box
            padding="20px"
            borderRadius="0"
            borderBottom="1px solid #ccc"
            display="flex"
            justifyContent="space-between"
          >
            <Text fontSize="lg">1000 Monthly Referrals</Text>
            <Icon as={AiFillCheckCircle} color="green" mt="5px" />
          </Box>
          <Box
            padding="20px"
            borderRadius="0"
            display="flex"
            justifyContent="space-between"
          >
            <Text fontSize="lg">5 Team Members</Text>
            <Icon as={AiFillCheckCircle} color="green" mt="5px" />
          </Box>
        </Box>

        <Box
          mb='24px'
          borderRadius="20px"
          border="1px solid #ccc"
          bg="brand.100"
          minW="250px"
        >
          <Box bg="brand.600" padding="15px" borderTopRadius="20px">
            <Box display="flex" justifyContent="space-between">
              <Heading size="md" color="white">
                Tier 3
              </Heading>
              <Heading size="md" color="white">
                $259
              </Heading>
            </Box>
            <Box mt="10px" display="flex" justifyContent="space-between">
              { user.attributes['custom:tier'] == 'prosper_tier3' ? (
                <Tag variant="solid" colorScheme="orange" color='black'>
                  Active
                </Tag>
              ) : (
                <Tag variant="solid" colorScheme="red">
                  Inactive
                </Tag>
              )}
              <Text mt="-4px" color="white">
                Lifetime
              </Text>
            </Box>
          </Box>
          <Box
            padding="20px"
            borderRadius="0"
            borderBottom="1px solid #ccc"
            display="flex"
            justifyContent="space-between"
          >
            <Text fontSize="lg">Unlimited Referrals</Text>
            <Icon as={AiFillCheckCircle} color="green" mt="5px" />
          </Box>
          <Box
            padding="20px"
            borderRadius="0"
            display="flex"
            justifyContent="space-between"
          >
            <Text fontSize="lg">Unlimited Team Members</Text>
            <Icon as={AiFillCheckCircle} color="green" mt="5px" />
          </Box>
        </Box>

        </SimpleGrid>
      </Flex>
      <Text fontSize="xl" fontWeight='semibold'>Included in all Tiers:</Text>
      <Box
        padding="12px"
        borderRadius="0"
        borderBottom="1px solid #ccc"
        display="flex"
        justifyContent="flex-start"
        alignItems='center'
      >
        <Icon as={AiFillCheckCircle} color="green" me="5px" />
        <Text fontSize="lg">Unlimited Campaigns</Text>
      </Box>
      <Box
        padding="12px"
        borderRadius="0"
        borderBottom="1px solid #ccc"
        display="flex"
        justifyContent="flex-start"
        alignItems='center'
      >
        <Icon as={AiFillCheckCircle} color="green" me="5px" />
        <Text fontSize="lg">Widget Customization</Text>
      </Box>
      <Box
        padding="12px"
        borderRadius="0"
        borderBottom="1px solid #ccc"
        display="flex"
        justifyContent="flex-start"
        alignItems='center'
      >
        <Icon as={AiFillCheckCircle} color="green" me="5px" />
        <Text fontSize="lg">Instant Rewards</Text>
      </Box>
      <Box
        padding="12px"
        borderRadius="0"
        borderBottom="1px solid #ccc"
        display="flex"
        justifyContent="flex-start"
        alignItems='center'
      >
        <Icon as={AiFillCheckCircle} color="green" me="5px" />
        <Text fontSize="lg">Data Access and Reporting</Text>
      </Box>
      <Box
        padding="12px"
        borderRadius="0"
        borderBottom="1px solid #ccc"
        display="flex"
        justifyContent="flex-start"
        alignItems='center'
      >
        <Icon as={AiFillCheckCircle} color="green" me="5px" />
        <Text fontSize="lg">Custom Email</Text>
      </Box>
      <Box
        padding="12px"
        borderRadius="0"
        borderBottom="1px solid #ccc"
        display="flex"
        justifyContent="flex-start"
        alignItems='center'
      >
        <Icon as={AiFillCheckCircle} color="green" me="5px" />
        <Text fontSize="lg">Shopify Integration</Text>
      </Box>
      <Box
        padding="12px"
        borderRadius="0"
        borderBottom="1px solid #ccc"
        display="flex"
        justifyContent="flex-start"
        alignItems='center'
      >
        <Icon as={AiFillCheckCircle} color="green" me="5px" />
        <Text fontSize="lg">Zapier Integration</Text>
      </Box>
      <Box
        padding="12px"
        borderRadius="0"
        borderBottom="1px solid #ccc"
        display="flex"
        justifyContent="flex-start"
        alignItems='center'
      >
        <Icon as={AiFillCheckCircle} color="green" me="5px" />
        <Text fontSize="lg">WooCommerce Integration (Coming soon)</Text>
      </Box>
      <Box
        padding="12px"
        borderRadius="0"
        borderBottom="1px solid #ccc"
        display="flex"
        justifyContent="flex-start"
        alignItems='center'
      >
        <Icon as={AiFillCheckCircle} color="green" me="5px" />
        <Text fontSize="lg">Etsy Integration (Coming soon)</Text>
      </Box>
      <Box
        padding="12px"
        borderRadius="0"
        borderBottom="1px solid #ccc"
        display="flex"
        justifyContent="flex-start"
        alignItems='center'
      >
        <Icon as={AiFillCheckCircle} color="green" me="5px" />
        <Text fontSize="lg">Referral Landing Pages (Coming soon)</Text>
      </Box>
      <Box
        padding="12px"
        borderRadius="0"
        display="flex"
        justifyContent="flex-start"
        alignItems='center'
      >
        <Icon as={AiFillCheckCircle} color="green" me="5px" />
        <Text fontSize="lg">QR Code Referral Flow (Coming Soon)</Text>
      </Box>
    </Card>
  )
}
export default AppSumoTiers
