/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("brand.600", "brand.600");
  let inactiveColor = useColorModeValue( "brand.100", "brand.100");
  let activeIcon = useColorModeValue("brand.600", "brand.600");
  let textColor = useColorModeValue("brand.600", "brand.600");
  let brandColor = useColorModeValue("brand.800", "brand.800");

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      const isActive = activeRoute(route.path.toLowerCase());
      return (
        <NavLink key={index} to={route.path}>
          <Box>
            <HStack spacing={ isActive ? "22px" : "26px" } py='5px' ps='10px'>
              <Flex w='100%' alignItems='center' justifyContent='center'>
                {route.icon && (
                  <Box color={ isActive ? activeColor : textColor }
                    me='18px'>
                    {route.icon}
                  </Box>
                )}
                <Text me='auto'
                  color={ isActive ? activeColor : textColor }
                  fontWeight={ isActive ? "bold" : "normal" }>
                  {route.name}
                </Text>
              </Flex>
              <Box
                h='36px'
                w='4px'
                bg={ isActive ? brandColor : "transparent" }
                borderRadius='5px'
              />
            </HStack>
          </Box>
        </NavLink>
      )
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
