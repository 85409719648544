import { mode } from "@chakra-ui/theme-tools";
export const tagStyles = {
  components: {
    Tag: {
      baseStyle: { },
      variants: {
        neutral: (props) => ({
          container: {
            size: "md",
            key: "md",
            variant: "solid",
            bg: mode("gray.300", "gray.300")(props),
            color: "brand.700",
            whiteSpace: 'nowrap',
          },
        }),
        success: (props) => ({
          container: {
            size: "md",
            key: "md",
            variant: "solid",
            bg: mode("brand.300", "brand.300")(props),
            color: "brand.700",
            whiteSpace: 'nowrap',
          },
        }),
        warning: (props) => ({
          container: {
            size: "md",
            key: "md",
            variant: "solid",
            bg: 'yellow.500',
            color: 'white',
            whiteSpace: 'nowrap',
          }
        }),
        failure: (props) => ({
          container: {
            size: "md",
            key: "md",
            variant: "solid",
            bg: 'red.500',
            color: 'brand.100',
            whiteSpace: 'nowrap',
          }
        })
      },
    },
  },
};
