import React, { useEffect, useState, useRef } from 'react';
import {
  Center,
  Box,
  Button,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useField } from 'formik';

function FormikImageInput({ containerProps, buttonProps, onFileSelect, fileFormats, label, field, placeholder, ...props }) {
  const [ fieldName, meta, helpers] = field;
  const { setValue, setError, setTouched } = helpers;
  const { value, initialValue } = meta;
  const [fileContent, setFileContent] = useState(value ?? ''); // To store the HTML content

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(async () => {
    setTouched(false);
  }, [])

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);


    // Read the HTML content of the selected file
    const reader = new FileReader();
    reader.onload = (event) => {
      console.log(event.target.result)
      const content = event.target.result;
      setFileContent(content);
      setValue(content)
      if (onFileSelect) {
        onFileSelect(content);
      }
    };
    reader.readAsDataURL(file); // Read the file as text
  };
  // console.log(fileContent);

  // const handleFileSelect = (e) => {
  //   const file = e.target.files[0];
  //
  //   if (onFileSelect) {
  //     onFileSelect(file);
  //   }
  //   setSelectedFile(file);
  //   setFileContent(file);
  //   setValue(file)
  // };

  return (
    <FormControl w='min-content' isRequired
      isInvalid={meta.error && meta.touched}
    >
      {/*
      { label && (
        <FormLabel variant='modal' >
          {label}
        </FormLabel>
      ) }
      */}
      <Box flexDir='column' {...containerProps}>
        <Button
          variant='accent'
          m='0'
          onClick={() => fileInputRef.current.click()}
          leftIcon={null}
          {...buttonProps}
        >
          {label}
        </Button>
        <Input
          type="file"
          accept={fileFormats}
          ref={fileInputRef}
          onChange={handleFileSelect}
          display="none"
          variant='modal'
          // value={fileContent}
          placeholder={placeholder}
          onBlur={fieldName.onBlur}
          border='2px solid'
          _focus={{ borderColor: meta.error ? 'red.500' : props.focusBorderColor ?? 'transparent' }}
          borderColor={ meta.error ? 'red.500' : 'transparent'}

        />
      {/*
      <Input
        {...props}
        variant='modal'
        value={text}
        placeholder={placeholder}
        onBlur={fieldName.onBlur}
        onChange={(e) => {
          setText(e.target.value)
          setValue(e.target.value)
        }}
        border='2px solid'
        _focus={{ borderColor: meta.error ? 'red.500' : props.focusBorderColor ?? 'transparent' }}
        borderColor={ meta.error ? 'red.500' : 'transparent'}
      />
      */}
        {/* TODO: Style this text below */}
        {/*
        {selectedFile && (
          <Text>Selected File: <Text color='brand.500' fontWeight='semibold' display='inline'>{selectedFile.name}</Text></Text>
        )}
        */}
      </Box>
      <FormErrorMessage position='absolute'>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}

export default FormikImageInput;
