import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
  const [token, setToken] = useState(null);
  return (
    <AuthContext.Provider value={{token, setToken}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const authContext = React.useContext(AuthContext);
  if (authContext === undefined) {
    throw new Error('useAuthContext must be inside a AuthProvider');
  }
  return authContext;
};
