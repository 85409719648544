import React, {useState} from 'react'
import {
  Box,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  TabList,
  TabIndicator,
  Text,
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
  Icon,
  SimpleGrid,
  Flex,
  Switch,
  Tooltip,
} from '@chakra-ui/react'
import {
  MdColorLens,
  MdStraighten,
  MdOutlinePermDataSetting,
  MdHelp,
} from 'react-icons/md'
import {
  AiOutlineFontSize
} from 'react-icons/ai'
import {
  BiFontSize
} from 'react-icons/bi'
import { useField } from "formik";
import FormikInput from './FormikInput';
import FormikColorInput from './FormikColorInput';

function Colors({ inputs, validateField, setCurrentStep, ...props}) {

  const colorInputs = inputs.fields.slice(0, 8);
  const sizeInputs = inputs.fields.slice(8);

  const ColorInput = (props) => {
    const field = useField({ name: props.field, validate: props.validate })
    return (
      <FormikColorInput
        isRequired
        field={field}
        validate={props.validate}
        label={field[0].name}
        focus={props.focus}
        width='240px'
      >
        <InputLeftAddon bg={field[1].value}>
          <Icon as={MdColorLens} />
        </InputLeftAddon>
      </FormikColorInput>
    )
  }

  const SizeInput = (props) => {
    const field = useField({ name: props.field, validate: props.validate })
    return (
      <FormikInput
        {...props}
        field={field}
        validate={props.validate}
        label={props.label}
      >
        <InputLeftAddon>
          <Icon as={props.icon} />
        </InputLeftAddon>
      </FormikInput>
    )
  }
  const [customHeight, setCustomHeight] = useState(false)
  const [resetFocus, setResetFocus] = useState(false)

  return (
    <Box>
      <Tabs variant='unstyled'>
        <TabList border="none" height="40px">
          <Tab _selected={{ fontWeight: "semibold" }} _focus="none">Colors</Tab>
          <Tab _selected={{ fontWeight: "semibold" }} _focus="none">Size and Corners</Tab>
        </TabList>
        <TabIndicator height="2px" bg="brand.400" borderRadius="1px" />

        <TabPanels mt='5px'
        >
          <TabPanel
            // onClick={()=>{setResetFocus(resetFocus+1)}}
          >
            <SimpleGrid columns='2' gap='24px'>
              { colorInputs.map((f, index) => { return <ColorInput focus={resetFocus} key={index} field={f.field} validate={f.validate}/> }) }
            </SimpleGrid>
          </TabPanel>
          <TabPanel>
            <Flex justifyContent='space-between'>
              <Text variant='modal'>Custom Height</Text>
              <Flex mb='12px' alignItems='center'>
                <Switch variant='main' size='lg' mr='12px' value={customHeight} onChange={()=>{setCustomHeight(!customHeight)}}/>
                <Tooltip hasArrow variant='modal' label="We recommend keeping the default height.">
                  <Box> <Icon w="20px" h="20px" as={MdHelp} color={"black"} ml="5px" /> </Box>
                </Tooltip>
              </Flex>
            </Flex>
            <SimpleGrid h={customHeight ? '75px' : '0' } opacity={customHeight ? 1 : 0 } columns='2' gap='24px' transition='height ease-out .5s, opacity ease-out .3s'>
              <SizeInput field={sizeInputs[0].field} validate={sizeInputs[0].validate} icon={MdStraighten}/>
              <SizeInput field={sizeInputs[1].field} validate={sizeInputs[1].validate} icon={MdStraighten}/>
            </SimpleGrid>
            <SizeInput isRequired field={sizeInputs[2].field} validate={sizeInputs[2].validate} label={sizeInputs[2].label} icon={MdOutlinePermDataSetting}/>
            <SizeInput isRequired field={sizeInputs[3].field} validate={sizeInputs[3].validate} label={sizeInputs[3].label} icon={AiOutlineFontSize}/>
            <SizeInput isRequired field={sizeInputs[4].field} validate={sizeInputs[4].validate} label={sizeInputs[4].label} icon={AiOutlineFontSize}/>
            <SizeInput isRequired field={sizeInputs[5].field} validate={sizeInputs[5].validate} label={sizeInputs[5].label} icon={BiFontSize}/>
          </TabPanel>
          {/*





          <TabPanel>

            <Text
              fontFamily="Inter"
              lineHeight="1.41"
              fontWeight="semibold"
              fontSize="17px"
              color="gray.700"
              width="492px"
              maxWidth="100%"
            >
              Custom Height
            </Text>
            <Stack
              padding="2px"
              isChecked
              isDisabled={false}
              size="lg"
              colorScheme="blue"
              width="50px"
              height="28px"
              direction="row"
              justify="flex-end"
              align="center"
              spacing="0px"
            />
            <Box>
              <Text
                fontFamily="Inter"
                lineHeight="1.41"
                fontWeight="regular"
                fontSize="17px"
                color="gray.700"
                width="204px"
              >
                Closed Height
              </Text>
              <InputGroup size="lg" width="235px">
                <InputLeftAddon background="gray.100" />
                <Input placeholder="0px" size="lg" />
              </InputGroup>
              <Icon as={MdColorLens} />
              <Icon as={MdStraighten} />
            </Box>
            <Box>
              <Text
                fontFamily="Inter"
                lineHeight="1.41"
                fontWeight="regular"
                fontSize="17px"
                color="gray.700"
                width="204px"
              >
                Open Height
              </Text>
              <InputGroup size="lg" width="235px">
                <InputLeftAddon background="gray.100" />
                <Input placeholder="0px" size="lg" />
              </InputGroup>
              <Icon as={MdColorLens} />
              <Icon as={MdStraighten} />
            </Box>
            <Text
              fontFamily="Inter"
              lineHeight="1.41"
              fontWeight="regular"
              fontSize="17px"
              color="gray.700"
              width="492px"
              maxWidth="100%"
            >
              Corner Radius
            </Text>
            <InputGroup size="lg" width="492px" maxWidth="100%">
              <InputLeftAddon background="gray.100" />
              <Input placeholder="0px" size="lg" />
            </InputGroup>
            <Icon as={MdOutlinePermDataSetting} />
          </TabPanel>
          */}
        </TabPanels>
      </Tabs>
    </Box>
  )

}

export default Colors
