import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalHeader,
  Flex,
  Box,
  Spacer,
  Tooltip,
  Text,
  Button,
  Badge,
  Input,
  Center,
  Alert,
  AlertDescription,
  useDisclosure,
} from '@chakra-ui/react'
import { Formik, useField } from 'formik';
import { InfoIcon } from "@chakra-ui/icons";
import Crop from './Crop';
import FormikFileInput from 'components/formik/FormikFileInput';
import { useState } from 'react'
import _ from 'lodash'
import { Auth } from 'aws-amplify';
import axios from 'axios'
import { useUserContext } from 'contexts/UserContext';
import FormikInput from 'components/formik/FormikInput';
import OrganizationForm from './OrganizationForm';
import * as yup from 'yup';
import ShopifyLogo from "../../../assets/img/logos/ShopifyLogo";
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import { v4 as uuidv4 } from "uuid";
import DeleteModal from './DeleteModal';

function OrganizationModal({disclosure, shopify, shopUrl, existing, isOwner, ...props}) {

  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(!props.isNew);
  const initialValues = props.initialValues ?? {
    'team_name': '',
    'team_url': '',
    'team_logo': ''
  };

  const validator = (schema) => {
    return async (value) => {
      let error = undefined;
      try { await schema.validate(value); }
      catch (err) {
        error = err.errors[0];
        // console.log("error:", err);
      }

      console.log(error)
      return error;
    }
  }
  const validateName = validator(yup.string().required('Required'))
  const validateUrl = validator(yup.string().matches(/^((https?:\/\/)?[^\s/$.?#]+\.[^\s]+)?$/, "Invalid URL").required('Required'))
  const [saving, setSaving] = useState(false)
  const navigate = useNavigate();

  const deleteDisclosure = useDisclosure();

  const handleSave = async (values, setErrors, setTouched) => {
    setTouched([ 'team_url', 'team_name' ], true, true)
    const nameErrors = await validateName(values.team_name).then((out)=>{return out})
    const urlErrors = await validateUrl(values.team_url).then((out)=>{return out})
    setErrors({ 'team_name': nameErrors, 'team_url': urlErrors })


    // console.log({
    //   owner_id: user?.attributes?.sub,
    //   team_name: values.team_name,
    //   team_url: values.team_url,
    //   image_data: values.team_logo.split(',')[1],
    // })
    const tenant_id = props.isNew ? uuidv4() : props.tenant_id
    setSaving(true)
    Auth.currentSession()
      .then((session) => {
        const jwtToken = session.getIdToken().getJwtToken();
        axios.post("https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/workspaces", {
          action: (props.isNew ? 'create' : 'update') + '_organization',
          params: {
            tenant_id: tenant_id,
            team_name: values.team_name,
            team_url: values.team_url,
            image_data: values.team_logo ? values.team_logo.split(',')[1] : null,
            image_format: 'jpg',
          }
        }, {
          headers: { Authorization: jwtToken },
        })
          .then((response) => {
            if (shopify) {
              props.handleShopifyAuth(tenant_id, disclosure, setSaving)
            } else {
              props.updateTenants()
              disclosure.onClose()
            }
            setSaving(false)
          })
          .catch((error) => { console.error(error); })
      })
      .catch((error) => { console.error(error); });
  };

  return (
    <Modal closeOnEsc={true} isOpen={disclosure.isOpen} onClose={disclosure.onClose}>

      <ModalOverlay />
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        // initialErrors={campaignErrors}
      >
        {({ values, touched, errors, setErrors, setTouched }) => {
          setIsDirty( !_.isEqual(values, initialValues));
          // setIsValid( !_.isEqual(values, initialValues));
          return (
            <ModalContent
              maxW="45rem"
              bg="brand.200"
              height="auto"
              margin="auto"
            >
              <ModalHeader>Organization Settings</ModalHeader>
              { !shopUrl && (
                <ModalCloseButton size="md" _focus="none" />
              ) }
              <ModalBody>
                <OrganizationForm
                  name={['team_name', validateName]}
                  url={['team_url', validateUrl]}
                />
              </ModalBody>
              { shopUrl && (
                <Center position='absolute' h='0' w='100%' top='-12px'>
                  <Alert
                    bg='#CBE6C7'
                    mb='41px'
                    w='100%'
                    borderTopRadius='3px'
                    borderBottomRadius='0px'
                    border='1px solid'
                    borderColor='brand.200'
                  >
                    <ShopifyLogo mr='16px' w='30px' h='30px'/>
                    <AlertDescription color='brand.700' fontWeight='medium'>
                      { existing ? (
                        <>Reinstall app for <br/>{shopUrl}.</>
                      ) : (
                        <>Create a new organization for<br/>{shopUrl}.</>
                      ) }
                    </AlertDescription>
                  </Alert>
                </Center>
              ) }
              <ModalFooter>
                {/*
                { !props.isNew && (
                  <Button variant='red' onClick={disclosure.onClose}>
                    Delete
                  </Button>
                ) }
                <Spacer/>
                */}
                { !shopify && !props.isNew && (
                  <Button color='black.800' colorScheme='red' border="1px solid #ccc" mr="10px" onClick={deleteDisclosure.onOpen}>
                    {isOwner ? 'Delete' : 'Leave'}
                    <DeleteModal isOwner={isOwner} tenant_id={props.tenant_id} deleteOrganization={props.handleDelete} disclosure={deleteDisclosure} onDelete={disclosure.onClose}/>
                  </Button>
                )}
                { !shopUrl && (
                  <Button border="1px solid #ccc" mr="10px" onClick={disclosure.onClose}>
                    Back
                  </Button>
                )}
                <Button isLoading={saving} disabled={ saving || !existing && (!isDirty || errors.team_name || errors.team_url || errors.team_logo || values.team_url === '' || values.team_name === '')} variant='brand' onClick={()=>{handleSave(values, setErrors, setTouched)}}>
                  {/*
                    { !shopify ? 'Save' : existing ? 'Reconnect' : 'Create' }
                  */}
                  Save
                </Button>
              </ModalFooter>
            </ModalContent>
        )}}
      </Formik>
    </Modal>
  )
}

export default OrganizationModal
