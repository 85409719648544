// import { Box, Text, InputGroup, Input } from '@chakra-ui/react'
import {
  TabIndicator,
  Textarea,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  useToken,
  Input,
  Button,
  Text,
  useColorModeValue,
  Box,
  TabList,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useField } from "formik";
import FormikInput from './FormikInput';
import FormikTextArea from './FormikTextArea';
import { max } from "date-fns";

function Messaging({ inputs, validateField, setCurrentStep, ...props}) {
  const startTextField = inputs.fields[0].field;
  const startTextValidate = inputs.fields[0].validate;
  const endTextField = inputs.fields[1].field;
  const endTextValidate = inputs.fields[1].validate;
  const defaultMessageField = inputs.fields[2].field;
  const defaultMessageValidate = inputs.fields[2].validate;
  const label = inputs.label
  const whiteBg = useColorModeValue( "brand.100", "brand.100");
  const navigate = useNavigate();
  return (
    <Box height="min-content" {...props}>
      <FormikInput
        isRequired
        field={useField({ name: startTextField, validate: startTextValidate })}
        validate={startTextValidate}
        label='Call to Action'
      />
      <FormikTextArea
        field={useField({ name: endTextField, validate: endTextValidate })}
        validate={endTextValidate}
        label='Completion Message'
        controlProps={{ mt: '20px' }}
        minRows={2}
      />
      <FormikTextArea
        field={useField({ name: defaultMessageField, validate: defaultMessageValidate })}
        validate={defaultMessageValidate}
        label='Default Editable Message'
        controlProps={{ mt: '20px' }}
        minRows={4}
      />
    </Box>
  );
}

// const Messaging = () => (
//   <Box>
//     <Box>
//       <Text
//         fontFamily="Inter"
//         lineHeight="1.41"
//         fontWeight="regular"
//         fontSize="17px"
//         color="gray.700"
//         width="432px"
//         maxWidth="100%"
//       >
//         Call to Action
//       </Text>
//       <InputGroup size="lg" width="494px" maxWidth="100%">
//         <Input placeholder="Share and get $10 off your order" size="lg" />
//       </InputGroup>
//     </Box>
//     <Box>
//       <Text
//         fontFamily="Inter"
//         lineHeight="1.41"
//         fontWeight="regular"
//         fontSize="17px"
//         color="gray.700"
//         width="432px"
//         maxWidth="100%"
//       >
//         Completion Message
//       </Text>
//       <InputGroup size="lg" width="494px" maxWidth="100%">
//         <Input
//           placeholder="Congratulations and happy shopping!! :)"
//           size="lg"
//         />
//       </InputGroup>
//     </Box>
//     <Box>
//       <Text
//         fontFamily="Inter"
//         lineHeight="1.41"
//         fontWeight="regular"
//         fontSize="17px"
//         color="gray.700"
//         width="432px"
//         height="28.12px"
//         maxWidth="100%"
//       >
//         Editable Message Default
//       </Text>
//       <Input
//         placeholder="I was shopping for a new shirt and thought you might be interested. Prosper is offering a huge discount from referrals, so I wanted to send one your way! <3"
//         width="494px"
//         height="144px"
//         maxWidth="100%"
//       />
//     </Box>
//   </Box>
// )

export default Messaging
