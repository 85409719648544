// Chakra imports
import {
  SimpleGrid,
  Text,
  useColorModeValue,
  Flex,
  Button,
  Icon,
  Box,
  useDisclosure,
  Center,
  Link,
  Spacer,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useState, useEffect } from "react";
import { Auth } from 'aws-amplify'
import axios from 'axios'
import ShopifyModal from "./ShopifyModal";
import Disabled from "assets/img/pricing/Disabled";
import Enabled from "assets/img/pricing/Enabled";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import { UserContext } from "contexts/UserContext";
import ShopifyMonochrome from "assets/img/logos/ShopifyMonochrome";
import StripeMonochrome from "assets/img/logos/StripeMonochrome";

// Assets
export default function Tier(props) {

  function BuyButtonComponent(props) {
    useEffect(() => {
      // Load the Stripe script dynamically
      const stripeScript = document.createElement('script');
      stripeScript.src = 'https://js.stripe.com/v3/buy-button.js';
      stripeScript.async = true;
      document.head.appendChild(stripeScript);
      // Remove the script when the component is unmounted
      return () => {
        document.head.removeChild(stripeScript);
      };
    }, []);
    return (
      <stripe-buy-button
        buy-button-id={props.buyButtonId}
        publishable-key={props.publishableKey}
        client-reference-id={props.username}
      >
      </stripe-buy-button>
    );
  }

  const data = props.data;
  const primary = props.primary;
  const monthly = props.monthly;
  const { ...rest } = props;
  // Chakra Color Mode

  const topRightColor = useColorModeValue('#FFBF00', '#FFBF00');
  const backgroundPrimary = useColorModeValue('#83E2C6', 'brand.600')
  const backgroundSecondary = useColorModeValue('#184E3E', 'secondaryGray.700')
  const buttonColorPrimary = useColorModeValue("#FFFFFF", "brand.800");
  const buttonColorSecondary = useColorModeValue("#97ECD4", "brand.600");
  const headerColorPrimary = useColorModeValue("#184E3E", "white");
  const headerColorSecondary = useColorModeValue("#FFFFFF", "white");
  const textColorPrimary = useColorModeValue("#313131", "white");
  const textColorSecondary = useColorModeValue("#D9DBE9", "white");
  const mutedColorPrimary = useColorModeValue("#585858", "white");
  const mutedColorSecondary = useColorModeValue("#C4C4C4", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const smallIconSize = "20px";
  const shopifyDisclosure = useDisclosure();
  const navigate = useNavigate();

  const { activeTenantState, tier, tenantsState, updateTenants } = useUserContext();
  const [activeTenant, setActiveTenant] = activeTenantState;
  const [tenants, setTenants] = tenantsState;
  const tenant = tenants.find((tenant)=>{return tenant.tenant_id == activeTenant})

  const [subscribeLoading, setSubscribeLoading] = useState(false);

  const handleSubscribe = (processor) => {
    setSubscribeLoading(true);
    const processorUrls = {
      'shopify': 'https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/shopify-billing-create-charge',
      'stripe': 'https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/stripe-billing'
    }
    const url = processorUrls[processor]
    Auth.currentSession()
      .then(session => {
        const jwtToken = session.getIdToken().getJwtToken();
        const request = {
          tier: data.header,
          duration: monthly ? 'month' : 'year',
          discount_code: props.discountCode,
          tenant_id: activeTenant
        }
        axios.post(
          url,
          request,
          {
            headers: { "Authorization": jwtToken },
          })
          .then(response => {
            // console.log(response);
            window.location.href = response.data
          })
          .catch(error => {
            console.error(error);
          }).then( () => {
            setSubscribeLoading(false);
          });
        })
        .catch(error => {
          console.error(error);
      })
  }

  const [shopUrl, setShopUrl] = useState('');
  const getShopUrl = () => {

    Auth.currentSession()
      .then((session) => {
        const jwtToken = session.getIdToken().getJwtToken();

        if (!props.isShopify && !props.user['attributes']['custom:shopify_sub_id']) { return }
        axios.post('https://rdpdgxkkz7.execute-api.us-east-1.amazonaws.com/default/dashboard-shopify-shop-url-pricing',
          {  },
          { headers: { Authorization: jwtToken } })
          .then(response => {
            // console.log(response)
            setShopUrl(response.data);
          })
          .catch(error => {
            console.error(error);
          })
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  }
  useEffect(getShopUrl,[])

  const TierButton = () => {
    if (tier === data.header.toLowerCase()) {
      return <Center fontWeight='semibold' h='24px' rounded='8px' py='21px' mb='6px' w='100%' maxW='288px' background='brand.800' >
        Currently Active
      </Center>
    } else {
      if (!data.setPrice) {
        return <Link
          mb='6px' maxW='288px' py='7px' w='100%' h='100%' rounded='5px'
          bg='brand.400' color='brand.900'
          _hover={{ bg: 'brand.400', boxShadow: 'md' }}
          boxShadow='lg' fontWeight='semibold'
          display='flex' justifyContent='center' alignItems='center'
          href='https://www.getprosper.io/consultation'>
          {data.buttonText}
        </Link>
      } else {
        const processor =
          tenant?.shopify_shop_url ? 'shopify' : 'stripe'
        const processorLogos = {
          'shopify': <ShopifyMonochrome mb='4px' w='max-content' h='80px' color='#FFFFFF'/>,
          'stripe': <StripeMonochrome mb='4px' w='max-content' h='60px' color='#FFFFFFE0'/>
        }
        const logo = processorLogos[processor]
        return <Flex w='100%' maxW='288px' alignItems='end' flexDir='column'>
          <Button w='100%' py='22px' mb='6px' rounded='5px'
            isLoading={subscribeLoading}
            bg='brand.400' color='brand.900'
            _hover={{ bg: 'brand.400', boxShadow: 'md' }}
            boxShadow='lg'
            onClick={ () => { handleSubscribe(processor) } }
          > Subscribe with&nbsp;&nbsp;
            {logo}
          </Button>
        </Flex>
          // return <Button w='100%' maxW='288px' py='22px' mb='6px' rounded='5px'
          //   bg='brand.400' _hover={{ bg: 'brand.400' }} color='brand.900'
          //   boxShadow='lg'
          //   onClick={handleShopifySubscribe}
          // > Subscribe </Button>
          // return <BuyButtonComponent
          //   username={props.user.username}
          //   buyButtonId={ monthly ? data.buyButtonIdMonthly : data.buyButtonIdYearly}
          //   publishabhandleShopifySubscribeleKey={data.publishableKey}
          // />
      }
    }
  }


  // console.log(props.user)
  return (
    <Card
      boxShadow='lg'
      maxW={{ base: '100%', xl: '600px' }}
      mb='20px'
      p='36px'
      background={ primary ? backgroundPrimary : backgroundSecondary  }
    >
      {/*
        <ShopifyModal handleSubscribe={handleShopifySubscribe} amount={monthly ? data.monthlyAmount : data.yearlyAmount} shopUrl={shopUrl} {...shopifyDisclosure}/>
      */}
      { data.topRightText ? (
        <Card
          position='absolute'
          right='12px'
          top='12px'
          color='#000000'
          bg={topRightColor}
          w='min-content'
          h='min-content'
          py="6px"
          px="24px"
          borderRadius="24px"
          justifySelf='flex-end'
        >{data.topRightText}</Card>
      ) : null }
      <Flex
        alignItems='top'
        justifyContent='space-between'
        pb="24px"
      >
        <Flex alignItems='center'>
          <Icon w="60px" h="60px" me="24px" >{data.icon}</Icon>
          <Text
            fontSize="2xl"
            fontWeight="bold"
            color={ primary ? headerColorPrimary : headerColorSecondary }
          >{data.header}</Text>
        </Flex>
      </Flex>
      <Text mb="24px"
            color={ primary ? textColorPrimary : textColorSecondary }
      >{data.subheader}</Text>
      <Flex mb="24px" alignItems='flex-end'>
        <Text pr="6px" lineHeight="1" fontSize="4xl" fontWeight="extrabold"
            color={ primary ? textColorPrimary : textColorSecondary }
        >{ monthly ? data.monthly : data.yearly }</Text>
        <Text
            color={ primary ? mutedColorPrimary : mutedColorSecondary }
        >/month</Text>
        <Box mx='12px'/>
        <Text pr="6px" lineHeight="1" fontSize="4xl" fontWeight="extrabold"
            color={ primary ? textColorPrimary : textColorSecondary }
        >{ monthly ? data.monthlyReferral : data.yearlyReferral }</Text>
        <Text
            color={ primary ? mutedColorPrimary : mutedColorSecondary }
        >/referral</Text>
      </Flex>
      <Text mb="12px" fontSize="lg"
          color={ primary ? headerColorPrimary : headerColorSecondary }
      >What's included</Text>
      <Flex flexDir='column' h='full' justifyContent='flex-end'>
        { data.enabledPerks.map((perk, index) =>
          <Flex key={index} mb="6px" alignItems="center">
            <Enabled visibility={perk ? 'visible' : 'hidden'} mr='12px' size='22px'/>
            <Text
              color={ primary ? textColorPrimary : textColorSecondary }
            >{perk}</Text>
          </Flex>
        ) }
        <Box mb="36px">
          { data.disabledPerks.map((perk, index) =>
            <Flex key={index} mb="6px" alignItems="center">
              <Disabled visibility={perk ? 'visible' : 'hidden' } mr='12px' size='22px'/>
              <Text
                color={ primary ? mutedColorPrimary : mutedColorSecondary }
              >{perk}</Text>
            </Flex>
          ) }
        </Box>

        <Flex w="100%" justifyContent='center'>
          <TierButton/>
        </Flex>
      </Flex>
    </Card>
  );
}
