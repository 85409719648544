import React, { useState } from "react";
import {
  Flex,
  Tooltip,
  Button,
  Icon,
  Text,
  Box,
  Center,
  useToken,
  useColorModeValue,
  Spacer,
} from "@chakra-ui/react";
import PersonIcon from 'assets/img/avatars/PersonIcon';
import FormikSelect from './FormikSelect';
import { useField } from 'formik';
import { MdHelp, MdList, MdViewList } from "react-icons/md";
import Card from "components/card/Card";
import ShopifyLogo from "assets/img/logos/ShopifyLogo";
import { CgCodeSlash } from "react-icons/cg";
import { MdAbc } from "react-icons/md";

function ReceiverSelect ({ inputs, discounts, setCurrentStep, ...props }) {
  const yellow = useColorModeValue('brand.800','brand.800');

  const [receiverRewardType, setReceiverRewardType] = props.rewardState

  const DiscountType = (props) => {

    const selected = receiverRewardType === props.type
    return (
      <Card w='100px' h='auto'
        pt='24px' pb='12px'
        alignItems='center'
        border={ selected ? '2px solid' : '2px solid' }
        borderColor={ selected ? yellow : '#C9CED2'}
        bg={'#E5F0ED'}
        cursor='pointer'
        onClick={() => {
          setReceiverRewardType(props.type)
        }}
      >
        <Icon
          w="40px"
          h="40px"
          as={props.icon}
        />
        <Spacer/>
        <Text mt='12px' fontWeight='medium'>
          {props.label}
        </Text>
        <Tooltip variant='modal'
          hasArrow

          label={props.tooltipLabel}
        >
          <Box position='absolute' right='6px' top='6px'> <Icon w="16px" h="16px" as={MdHelp} color={"black"} ml="5px" /> </Box>
        </Tooltip>
      </Card>
    )
  }

  return (
    <Box {...props}>
      <Box mb='24px'>
        <Center w='460px'>
          <Text variant='modal'>Next, choose the type of</Text>
          &nbsp;
          <Text variant='modal' fontWeight="bold" decoration="underline" color={yellow} >
            RECEIVER
          </Text>
          &nbsp;
          <Text variant='modal' fontWeight="semibold">
            reward
          </Text>
          <PersonIcon color={useToken('colors', yellow)} size='16px' ml='10px'/>
          <Tooltip hasArrow variant='modal' label="We will reward this discount to the receiver">
            <Box> <Icon w="16px" h="16px" as={MdHelp} color={"black"} ml="5px" /> </Box>
          </Tooltip>
        </Center>
        {/*
        <Text variant='hintModal' mt="10px" onClick={()=>window.open('/admin/home')}>
          Don’t have one? Check out our tutorial on creating Shopify discounts
        </Text>
        */}
      </Box>
      {/*
      <FormikSelect
        focusBorderColor="brand.400"
        selectedOptionStyle="check"
        labelPath={'name'}
        valuePath={'shopify_discount_id'}
        object={{
          type: 'shopify',
          name: null,
          shop_url: discounts?.[0]?.['shop_url'],
          shopify_discount_id: null
        }}
        chakraStyles={{
          control: (provided) => ({ ...provided, background: 'brand.100' })
        }}
        //TODO: set proper options
        options={discounts?.[0]?.discounts}
        size='lg'
        mt='20px'
        placeholder="Choose a Discount..."
        height="48px"
        maxWidth="100%"
        // field={field}
        field={field}
        validate={validate}
      />
      */}
      <Box>
        {/*
        <Text variant='boldModal' mb='0px' > OR </Text>
        */}
        <Flex mx='64px' justifyContent='space-around'>
          <DiscountType
            tooltipLabel={'Use a discount from a connected Shopify shop'}
            label='Shopify'
            icon={ShopifyLogo}
            step='ReceiverShopify'
            type='shopify'
          />
          <DiscountType
            tooltipLabel={'Use a static code that won\'t change'}
            label='Static'
            icon={MdAbc}
            step='ReceiverStatic'
            type='static'
          />
          {/*
          <DiscountType
            tooltipLabel={'Give us a list of codes to hand out one by one'}
            label='List'
            icon={MdList}
          />
          <DiscountType
            tooltipLabel={'Upon referral, we\'ll send you code for you to handle'}
            label='Webhook'
            icon={CgCodeSlash}
          />
          */}
          {/*
          <Button variant='boldModal'
            onClick={() => {
              setCurrentStep('ReceiverShopify')
            }}
          >
            Shopify
          </Button>
          <Button variant='boldModal'
            onClick={() => {
              setCurrentStep('ReceiverStatic')
            }}
          >
            Static Code
          </Button>
          */}
        </Flex>
      </Box>
    </Box>
  );
}

export default ReceiverSelect;
