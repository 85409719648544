import React, { useEffect, useState, useRef} from 'react';
import { Field, FieldValidator } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Tooltip,
  Icon,
  Flex,
  Box,
  Popover,
  PopoverBody,
  PopoverAnchor,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react";
import { MdHelp } from 'react-icons/md';
import { HexColorPicker, HexColorInput } from "react-colorful";


function FormikColorInput({ label, field, placeholder, validate, validateField, isRequired, tooltip, children, allowAlpha, focus, width, ...props }) {
  const [ fieldName, meta, helpers] = field;
  // console.log(helpers, meta, fieldName)
  const { setValue, setError, setTouched } = helpers;
  const { value, initialValue } = meta;
  const [text, setText] = useState(value ?? initialValue);
  const [color, setColor] = useState(value);
  const [isRunning, setIsRunning] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(()=>{
    setIsOpen(false)
  },[focus])


  useEffect(async () => {

    if (!validHex(color, allowAlpha)) {
      setError("Invalid Hex Code");
    }
    validate(color).then((out)=>{
        setError(out ? out : null);
      }).catch((err)=>{
        console.log(err);
      });

  }, [color])

  useEffect(() => {
    setValue(color);
  }, [color])



  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const matcher = /^#([0-9A-F]{0,8})$/i;

  const validHex = (value, alpha) => {
    const match = matcher.exec(value);
    const length = match ? match[1].length : null;

    return (
      value === '' || value === '#' || // no color
      length === 3 || // '#rgb' format
      length === 6 || // '#rrggbb' format
      (!!alpha && length === 4) || // '#rgba' format
      (!!alpha && length === 8) // '#rrggbbaa' format
    );
  };

  const [isInputEditable, setInputEditable] = useState(false);

  const handleInputClick = () => {
    setInputEditable(true);
  };

  const handleInputBlur = () => {
    // Use a short delay to check if the input is still focused.
    setTimeout(() => {
      if (!inputRef.current.contains(document.activeElement)) {
        setInputEditable(false);
      }
    }, 10);
  };

  const pickerRef = useRef(null);
  const inputRef = useRef(null);
  const handleBlur = (e) => {
    // console.log("1", e.relatedTarget?.parentNode?.parentNode)
    // console.log("2", pickerRef.current?.children?.[0])
    // console.log(e.relatedTarget?.parentNode?.parentNode === pickerRef.current?.children?.[0])
    // console.log("3", e.relatedTarget)
    // console.log("4", inputRef.current)
    // console.log(e.relatedTarget === inputRef.current)
    // console.log("--------------")
    // // console.log(e.relatedTarget !== pickerRef.current?.children?.[0])
    // // console.log("3", inputRef.current.contains(e.relatedTarget))
    // console.log( (e.relatedTarget?.parentNode?.parentNode === pickerRef.current?.children?.[0]) || (e.relatedTarget === inputRef.current))
    if (
      !((e.relatedTarget?.parentNode?.parentNode === pickerRef.current?.children?.[0]) ||
      (e.relatedTarget === inputRef.current))
    ) {
      handleClose()
    }
  }

  return (
    <Popover
      isOpen={isOpen}
      // onOpen={handleOpen}
      // onClose={handleClose}
      isLazy
      autoFocus={false}
      placement='right'
    >
      <Flex alignItems='center' h='100%'>
          <FormControl isRequired={isRequired} mb='15px'
            isInvalid={meta.error && meta.touched}
          >
            <Flex>
              {label && (
                <FormLabel variant='modal' >
                  {label}
                </FormLabel>
              )}
              {tooltip && (
                <Tooltip hasArrow variant='modal' label={tooltip}>
                  <Box> <Icon w="16px" h="16px" as={MdHelp} color={"black"} ml="5px" /> </Box>
                </Tooltip>
              )}
            </Flex>
            <PopoverTrigger>
              <InputGroup size="lg">
                {children}
                <Input
                  ref={inputRef}
                  {...props}
                  // bg={'brand.100'}
                  variant='modal'
                  value={color}
                  placeholder={placeholder}
                  onClick={handleOpen}
                  onBlur={(e)=>{fieldName.onBlur(e); handleBlur(e)}}
                  // onClick={handleInputClick}
                  onChange={(e) => {
                    var val = e.target.value;
                    val = val.toUpperCase();
                    if (val !== '' && val[0] !== '#') {val = '#'+val}
                    if (validHex(val, false)) {setColor(val)};
                    setColor(val)
                    setValue(val)
                  }}
                  border='2px solid'
                  _focus={{ borderColor: meta.error ? 'red.500' : props.focusBorderColor ?? 'transparent' }}
                  borderColor={ meta.error ? 'red.500' : 'transparent'}
                />
                <FormErrorMessage position='absolute'>{meta.error}</FormErrorMessage>
              </InputGroup>
            </PopoverTrigger>
          </FormControl>
        <PopoverAnchor w='0' h='0'>
          <Box position='relative' />
        </PopoverAnchor>
      </Flex>
      <PopoverContent mr='-12px'>
        <PopoverBody ref={pickerRef}>
          <HexColorPicker onBlur={(e)=>{handleBlur(e)}} style={{ width: '100%' }} color={color} onChange={(e) => { setColor(e.toUpperCase()) }} />
        </PopoverBody>
      </PopoverContent>
      {/*
      */}
    </Popover>
  );
}

export default FormikColorInput;

          // {/*
          // <HexColorInput
          //   prefixed={true}
          //   style={{
          //     background: useToken('colors', [props.bg]),
          //     color: useToken('colors', [props.textColor]),
          //     border: 'none',
          //     padding: '0px 8px',
          //     height: 'min-content',
          //     borderRadius: '6px',
          //     width: '100%',
          //   }}
          //   _focus={{
          //     border: 'none'
          //   }}
          //   color={color}
          //   onChange={(e) => { setColor(e) }}
          // />
          // <Input bg={props.bg} textColor={props.textColor}
          //   border='none' px='8px' py='0px' h='min-content'
          //   onChange={(e) => {
          //     var value = e.target.value;
          //     value = value.toUpperCase();
          //     if (value !== '' && value[0] !== '#') {value = '#'+value}
          //     if (validHex(value, false)) {setColor(value)};
          //     setValue(value);
          //   }}
          //   onBlur={() => {
          //     if (!validHex(value, false)) {setValue(color)}
          //     else if (value === '#') {setValue('')}
          //   }}
          //   value={value}
          //   placeholder='None'
          // />
          // */}
